import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Layout, Menu, Breadcrumb, Icon, Table, Row, Col, Tabs, Anchor } from 'antd'
import get from 'lodash/get'
import PrismCode from '../Tokenization/PrismCode'
import TokenForm from './Form'
import * as actions from '../../actions/qrCashAction'
import { withTranslation } from "react-i18next";

const { Content, Sider } = Layout;
const { TabPane } = Tabs;

const htmlCode = `
<form action="{url}" method="POST"> 
  <input type="hidden" name="token" value="{token}">
  <input type="hidden" name="referenceNo" value="{referenceNo}">
  <input type="hidden" name="backgroundUrl" value="{backgroundUrl}">
  <input type="number" name="amount" maxlength="13" placeholder="Amount" value="{amount}"><br/>
  <input id="button" type="submit" value="Pay Now">
</form>
`

const phpResponse = `
<?php

$respFile = fopen("resp-log.txt", "w") or die("Unable to open file!");

$json_str = file_get_contents('php://input');
fwrite($respFile, $json_str . "\n\n");

$json_obj = json_decode($json_str);

fwrite($respFile, "resultCode=" . $json_obj->resultCode . "\n");
fwrite($respFile, "amount=" . $json_obj->amount . "\n");
fwrite($respFile, "referenceNo=" . $json_obj->referenceNo . "\n");
fwrite($respFile, "gbpReferenceNo=" . $json_obj->gbpReferenceNo . "\n");
fwrite($respFile, "currencyCode=" . $json_obj->currencyCode . "\n");

fclose($respFile);

?>
`

const javaResponse = `

StringBuffer jb = new StringBuffer();
String line = null;

try {

  BufferedReader reader = request.getReader();

  while ((line = reader.readLine()) != null)

    jb.append(line);

} catch (Exception e) { /*report an error*/ }

try {

  JSONObject jsonObject = new JSONObject(jb.toString());
  System.out.println("resultCode=" + jsonObject.getString("resultCode"));
  System.out.println("amount=" + jsonObject.getBigDecimal("amount"));
  System.out.println("gbpReferenceNo=" + jsonObject.getString("gbpReferenceNo"));
  System.out.println("currencyCode=" + jsonObject.getString("currencyCode"));
  
} catch (JSONException e) { /*report an error*/ }
`

const renderFormHtml = data => {
  let url = 'https://api.globalprimepay.com/gbp/gateway/qrcredit'
  if (data.isQr === 'bill') {
    url = 'https://api.globalprimepay.com/gbp/gateway/qrcredit/text'
  }
  let _htmlCode = htmlCode.replace(/{amount}/g, data.amount);
  _htmlCode = _htmlCode.replace(/{url}/g, url);
  return (
    <div>
      <PrismCode
        code={_htmlCode}
        language="html"
        plugins={["line-numbers"]}
      />
    </div>
  )
}

const renderPhpResponse = () => (
  <div>
    <PrismCode
      code={phpResponse}
      language="php"
      plugins={["line-numbers"]}
    />
  </div>
)

const renderJavaResponse = () => (
  <div>
    <PrismCode
      code={javaResponse}
      language="java"
      plugins={["line-numbers"]}
    />
  </div>
)


const codeJsonParam = `
{
  "referenceNo": "20171128001",
  "gbpReferenceNo": "gbp020171128001",
  "amount": 15.00
}
`

const codeJsonResponse = `
{
  "resultCode": "00",
  "referenceNo": "20171128001",
  "gbpReferenceNo": "gbp020171128001"
}
`
const renderFormCode = (code) => (
  <div>
    <PrismCode
      code={code}
      language="json"
      plugins={["line-numbers"]}
    />
  </div>
)
class QRAndBarcode extends Component {
  render() {

    const { t , i18n } = this.props;

    const columns = [
      {
        title: t("field"),
        dataIndex: 'name',
        key: 'name',
        render: text => <code>{text}</code>
      },
      {
        title: t("type"),
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: t("description"),
        dataIndex: 'address',
        key: 'address',
      }
    ];
    
    const columnsResult = [
      {
        title: t("enquiry:code"),
        dataIndex: 'name',
        key: 'name',
        render: text => <code>{text}</code>
      },
      {
        title: t("enquiry:meaning"),
        dataIndex: 'age',
        key: 'age',
      },
    ];
    
    const dataHeader = [
      {
        key: '1',
        name: 'content-type',
        age: 'String',
        address: 'application/x-www-form-urlencoded'
      },
    ];
    
    const dataHeaderImage = [
      {
        key: '0',
        name: 'referenceNo',
        age: 'String (15)',
        address: 'Issued by Merchant (should be a unique number) For example: 20171128001'
      },
      {
        key: '1',
        name: 'gbpReferenceNo',
        age: 'String (250)',
        address: 'GBPrimePay Reference'
      },
      {
        key: '2',
        name: 'resultCode',
        age: 'String (2)',
        address: '00 = Success, XX = Failure (Please see details below)'
      },
    ];
    
    const dataParameter = [
      {
        key: '0',
        name: 'referenceNo',
        age: 'String (15)',
        address: t("referencenodesc")
      },
      {
        key: '1',
        name: 'gbpReferenceNo',
        age: 'String (250)',
        address: t("gbpReferencenodesc")
      },
      {
        key: '2',
        name: 'amount',
        age: 'Number (10, 2)',
        address: t("amountdesc")
      },
    ];
    
    const dataResponse = [
      {
        key: '1',
        name: 'amount',
        age: 'Number (10, 2)',
        address: 'For example: 15.00'
      },
      {
        key: '2',
        name: 'referenceNo',
        age: 'String (15)',
        address: `Issued by Merchant (should be a unique number) For example: 20171128001`
      },
      {
        key: '3',
        name: 'gbpReferenceNo',
        age: 'String (250)',
        address: `GBPrimePay Reference`
      },
      {
        key: '4',
        name: 'resultCode',
        age: 'String (2)',
        address: `00 = Success, XX = Failure (Please see details below)`
      },
      {
        key: '5',
        name: 'date',
        age: 'String (8)',
        address: `DDMMYYYY e.g. 20112007`
      },
      {
        key: '6',
        name: 'time',
        age: 'String (6)',
        address: `HHMMSS e.g. 141222`
      },
      {
        key: '7',
        name: 'expirationMonth',
        age: 'String (2)',
        address: `เดือนที่บัตรหมดอายุ (01)`
      },
      {
        key: '8',
        name: 'cardNo',
        age: 'String (16)',
        address: `16 digits (shift Left) e.g. 4987XXXXXXXX8769`
      },
      {
        key: '9',
        name: 'payMonth',
        age: 'String (2)',
        address: `Number of month for installment e.g. 03 = 3 Months`
      },
      {
        key: '10',
        name: 'amountPerMonth',
        age: 'Number (10,2)',
        address: `Amount payment per Month for smart payment (2 digit Decimal)`
      },
      {
        key: '11',
        name: 'issuerBank',
        age: 'String',
        address: `Bank Name from Card's BIN Number e.g. Kasikornbank Public Company Limited`
      },
      {
        key: '12',
        name: 'detail',
        age: 'String (250)',
        address: 'Product Description'
      },
      {
        key: '13',
        name: 'customerName',
        age: 'String (150)',
        address: 'Customer Name'
      },
      {
        key: '14',
        name: 'customerEmail',
        age: 'String (150)',
        address: 'Customer Email'
      },
      {
        key: '15',
        name: 'merchantDefined1',
        age: 'String (250)',
        address: 'Merchant Defined 1 (reserved)'
      },
      {
        key: '16',
        name: 'merchantDefined2',
        age: 'String (250)',
        address: 'Merchant Defined 2 (reserved)'
      },
      {
        key: '17',
        name: 'merchantDefined3',
        age: 'String (250)',
        address: 'Merchant Defined 3 (reserved)'
      },
      {
        key: '18',
        name: 'merchantDefined4',
        age: 'String (250)',
        address: 'Merchant Defined 4 (reserved)'
      },
      {
        key: '19',
        name: 'merchantDefined5',
        age: 'String (250)',
        address: 'Merchant Defined 5 (reserved)'
      },
    ];
    
    const dataBankCode = [
      {
        key: '1',
        name: '004',
        age: 'Kasikornbank Public Company Limited.',
        address: '3, 4, 5, 6, 7, 8, 9, 10'
      },
      {
        key: '2',
        name: '006',
        age: 'Krung Thai Bank Public Company Limited.',
        address: `3, 4, 5, 6, 7, 8, 9, 10`
      },
      {
        key: '3',
        name: `011`,
        age: `TMBThanachart Bank (ttb)`,
        address: `3, 4, 6, 10`
      },
      {
        key: '4',
        name: '025',
        age: 'Bank of Ayudhya Public Company Limited',
        address: `3, 4, 6, 9, 10`
      },
      {
        key: '5',
        name: '026',
        age: 'Krungsri First Choice',
        address: `3, 4, 6, 9, 10, 12, 18, 24`
      },
      {
        key: '6',
        name: '014',
        age: 'Siam Commercial Bank Public Company Limited',
        address: `3, 4, 6, 10`
      },
    ];
    
    const dataResponseToMerchant = [
      {
        key: '0',
        name: '00',
        age: 'Success',
      },
      {
        key: '1',
        name: '11',
        age: 'Invalid referenceNo',
      },
      {
        key: '2',
        name: '12',
        age: 'Invalid gbpReferenceNo',
      },
      {
        key: '3',
        name: '14',
        age: 'Invalid amount',
      },
      {
        key: '4',
        name: '21',
        age: 'Duplicate transaction',
      },
      {
        key: '5',
        name: '22',
        age: 'Over due',
      },
      {
        key: '6',
        name: '99',
        age: 'System error',
      },
    ];

    return (
      <Fragment>
        {/* <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>QR Code / Enquiry</Breadcrumb.Item>
        </Breadcrumb> */}
        {/* <Content
          style={{
            background: '#fff',
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          <h2>Enquiry</h2>
          <hr />
          <p style={{ color: 'rgba(0, 0, 0, 0.85)' }}>How it works</p>

          <ol>
            <li>
              The merchant call api Create QRCode to create a list in the GBPrimePay system.
            </li>
            <li>
              {`When a customer makes a payment with a QRCode, the
              GBPrimePay system will query the merchant's system for the api
              Verify / Inquiry. If the merchant responds, resultCode = 00 means
              that this transaction can continue. If not, resultCode = 00. The
              GBPrimePay system will not accept payment.`}
            </li>
          </ol>

          <br />

          <p className="font-bold">Verify / Enquiry : Parameter</p>
          <p style={{ color: 'rgba(0, 0, 0, 0.85)' }}>URL : merchant’s url is https.</p>
          <p style={{ color: 'rgba(0, 0, 0, 0.85)' }}>METHOD : POST</p>

          <br />

          <p className="font-bold">Request</p>
          <Table columns={columns} dataSource={dataParameter} pagination={false} bordered />

          <br />
          <br />

          <p className="font-bold">JSON Request</p>
          {renderFormCode(codeJsonParam)}

          <br />
          <br />

          <p className="font-bold">Response</p>
          <Table columns={columns} dataSource={dataHeaderImage} pagination={false} bordered />

          <br />
          <br />
          
          <p className="font-bold">JSON Response</p>
          {renderFormCode(codeJsonResponse)}

          <br />
          <br />

          <p className="font-bold">Result Code</p>
          <Table columns={columnsResult} dataSource={dataResponseToMerchant} pagination={false} bordered />

        </Content> */}

        <Content
          style={{
            background: '#fff',
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          <h2 className="title">{t("enquiry:titleenquiry")}</h2>
          <hr />
          <p>
            {t("enquiry:detailmessage1")}
          </p>

          <br />

          <h2 id="components-enquiry-howtoenable" className="title-sub">{t("enquiry:howtoenable")}</h2>
          <p>{t("enquiry:howtoenabledetail1")}</p>
          <p>{t("enquiry:howtoenabledetail2")}</p>

          <br />

          {/* <h2 id="components-enquiry-paymentflow" className="title-sub2">{t("enquiry:paymentflow")}</h2>
          <img
            src={require('../../res/img/mockup_mobile.png')}
            alt="step1"
            style={{ width: 'auto', height: 'auto' }}
          />
          <img
            src={require('../../res/img/mockup_mobile.png')}
            alt="step2"
            style={{ width: 'auto', height: 'auto' }}
          />
          <img
            src={require('../../res/img/mockup_mobile.png')}
            alt="step3"
            style={{ width: 'auto', height: 'auto' }}
          />
          <img
            src={require('../../res/img/mockup_mobile.png')}
            alt="step4"
            style={{ width: 'auto', height: 'auto' }}
          />

          <br />

          <h2 id="components-enquiry-steptopayment" className="title-sub2">{t("enquiry:steptopayment")}</h2>
          <p>{t("enquiry:steptopaymentdetail1")}</p>
          <WeDemo />

          <br /> */}

          <h2 id="components-enquiry-implementation" className="title-sub">{t("enquiry:implementation")}</h2>
          {/* <p>{t("enquiry:implementationdetail1")}</p>  */}

          {/* <br />

          <p>{t("enquiry:usageexample")}</p>
          {renderFormHtml(this.props.qrCash)}

          <br /> */}

          <h2 id="creating-source" className="title-sub2">{t("enquiry:creatingsource")}</h2>
          <p>{t("enquiry:creatingsourcedetail1")}</p>
          <p>
            <code>referenceNo</code> {' , '} <code>gbpReferenceNo</code>
            {' and '} <code>amount</code> {'.'}
          </p>

          <br />

          <p>
            <text className="font-bold">Verify / Enquiry : Parameter</text>
          </p>
          <p>
            <text>{t("enquiry:urlverify")}</text>
          </p>
          <p>
            <text>Method: POST</text>
          </p>

          <br />

          <p className="font-bold">Request Parameter</p>
          <Table columns={columns} dataSource={dataParameter} pagination={false} bordered />

          <br />

          <p className="font-bold">Form-data Request</p>
          {renderFormCode(codeJsonParam)}

          {/* <br />

          <h2 id="creating-charge" className="title-sub2">{t("enquiry:creatingcharge")}</h2>
          <p>{'-'}</p>
          <p>{'-'}</p> */}

          <br />

          <h2 id="response-data" className="title-sub2">{t("enquiry:responseheader")}</h2>
          <Table columns={columns} dataSource={dataHeaderImage} pagination={false} bordered />

          <br />
          
          <p className="font-bold">JSON Response</p>
          {renderFormCode(codeJsonResponse)}

          <br />

          <p className="font-bold">{t("enquiry:resultcode")}</p>
          <Table columns={columnsResult} dataSource={dataResponseToMerchant} pagination={false} bordered />

          {/* <br />

          <h2 id="components-enquiry-voidrefund" className="title-sub2">{t("enquiry:voidrefunds")}</h2>
          <p>{t("enquiry:voidrefundsdetail1")} {'Refunds API documentation'} {t("enquiry:voidrefundsdetail2")}</p>
          <p>{t("enquiry:voidrefundsdetail3")}</p> */}

          {/* <br />

          <h2 id="components-enquiry-limit" className="title-sub">{t("enquiry:limit")}</h2>
          <p>{t("enquiry:limitdetail1")}</p>
          <p>{i18n.language == 'en' ? t("enquiry:limitdetail2") : ''}</p> */}

        </Content>

        <Sider breakpoint="md" collapsedWidth="0" style={{ background: '#fff' }} h>
          <br />
          <span style={{ color: '#02416D', fontWeight: 'bold', paddingTop: '20px' }}  >
            <Icon type="container" style={{ fontSize: '20px', color: '#08c' }} theme="outlined" />
          </span>
          <span style={{ color: '#02416D', fontWeight: 'bold', paddingTop: '20px' }}>{t("tablecontents")}</span>
          <br />
          <Anchor style={{ paddingTop: '10px' }} >
            <Anchor.Link href="#components-enquiry-howtoenable" title={t("enquiry:howtoenable")} />
            {/* <Anchor.Link href="#components-enquiry-paymentflow" title={t("enquiry:paymentflow")} />
            <Anchor.Link href="#components-enquiry-steptopayment" title={t("enquiry:steptopayment")} /> */}
            <Anchor.Link href="#components-enquiry-implementation" title={t("enquiry:implementation")} >
              <Anchor.Link href="#creating-source" title={t("enquiry:creatingsource")} />
              {/* <Anchor.Link href="#creating-charge" title={t("enquiry:creatingcharge")} /> */}
              <Anchor.Link href="#response-data" title={t("enquiry:response")} />
            </Anchor.Link>
            {/* <Anchor.Link href="#components-enquiry-voidrefund" title={t("enquiry:voidrefunds")} /> */}
            {/* <Anchor.Link href="#components-enquiry-limit" title={t("enquiry:limit")} /> */}
          </Anchor>
        </Sider>
        
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  qrCash: state.qrCash
})
const mapDispatchToProps = dispatch => ({
  updateByKey: (key, value) => dispatch(actions.updateByKey(key, value))
})
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(QRAndBarcode)
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(QRAndBarcode));
