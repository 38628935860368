import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Layout, Menu, Breadcrumb, Icon, Table, Row, Col, Anchor, Tabs } from 'antd'
import get from 'lodash/get'
import PrismCode from '../Tokenization/PrismCode'
import TokenForm from './Form'
import * as actions from '../../actions/installmentAction'
import Demo from './Demo'
import WebHookCode from './WebHookCode'
import { withTranslation } from "react-i18next";

const { Content, Sider } = Layout;
const { TabPane } = Tabs;
const dataHeader = [
  {
    key: '1',
    name: 'content-type',
    age: 'String',
    address: 'application/x-www-form-urlencoded'
  },
];

const htmlCode = `
<form action="{ENV}/v3/installment" method="POST"> 
  <input type="hidden" name="publicKey" value="{public_key}">
  <input type="hidden" name="referenceNo" value="{referenceNo}">
  <input type="hidden" name="responseUrl" value="{responseUrl}">
  <input type="hidden" name="backgroundUrl" value="{backgroundUrl}">
  <input type="hidden" name="detail" placeholder="Detail" value="{detail}"><br/>
  <input type="number" name="amount" maxlength="13" placeholder="Amount" value="{amount}"><br/>
  <input type="text" name="bankCode" maxlength="3" placeholder="Bank Code" value="{bank_code}"><br/>
  <input type="number" name="term" maxlength="2" placeholder="The number of monthly installments" value="{term}"><br/>
  <input type="hidden" name="checksum" placeholder="checksum" value="{checksum}"><br/>
  <input id="button" type="button" onClick="genChecksum()" value="Generate Checksum">
  <input id="button" type="submit" value="Pay Now">
</form>

<script src="https://cdnjs.cloudflare.com/ajax/libs/crypto-js/3.1.9-1/crypto-js.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/crypto-js/3.1.9-1/hmac-sha256.min.js"></script>
<script>
function genChecksum(){
var hash = CryptoJS.HmacSHA256(
           document.getElementsByName("amount")[0].value +
           document.getElementsByName("referenceNo")[0].value + 
           document.getElementsByName("responseUrl")[0].value + 
           document.getElementsByName("backgroundUrl")[0].value + 
           document.getElementsByName("bankCode")[0].value +
           document.getElementsByName("term")[0].value ,
{secret_key});

document.getElementsByName("checksum")[0].value = hash;
}
</script>
`

const phpCode = `
<form action="<?php $_SERVER['PHP_SELF']?>" method="POST"> 
  Amount : <input type="number" name="amount" maxlength="13" placeholder="Amount" value=""><br/>
  Bank Code : <input type="text" name="bankCode" maxlength="3" placeholder="Bank Code" value=""><br/>
  Term : <input type="number" name="term" maxlength="2" placeholder="The number of monthly installments" value=""><br/>
  <input type="hidden" name="publicKey" value="">
  <input type="hidden" name="secretkey" value="">
  <input type="hidden" name="referenceNo" value="">
  <input type="hidden" name="responseUrl" value="">
  <input type="hidden" name="backgroundUrl" value="">
  <input type="hidden" name="detail" placeholder="Detail" value=""><br/>
  <input type="hidden" name="checksum" placeholder="checksum" value=""><br/>
  <input id="button" type="submit" value="Pay Now">
</form>

<?php

    if ($_SERVER["REQUEST_METHOD"] == "POST") {
        
        $installment_amount = $_POST['amount'];
        $installment_referenceNo = $_POST['referenceNo'];
        $installment_responseUrl = $_POST['responseUrl'];
        $installment_backgroundUrl = $_POST['backgroundUrl'];
        $installment_bankCode = $_POST['bankCode'];
        $installment_term = $_POST['term'];
        $installment_secret_key = $_POST['secretkey'];
        $installment_publicKey = $_POST['publicKey'];
      
        //get checksum
        $genChecksum = $installment_amount . $installment_referenceNo . $installment_responseUrl . $installment_backgroundUrl . $installment_bankCode . $installment_term;
        $itemchecksum = hash_hmac("sha256",$genChecksum,$installment_secret_key, false);
        $installmentchecksumhex = $itemchecksum;

        $redirect_collection = array(
         
          "publicKey" => $installment_publicKey,
          "secret_key" => $installment_secret_key,          
          'referenceNo' => $installment_referenceNo,
          'responseUrl' => $installment_responseUrl,
          'backgroundUrl' => $installment_backgroundUrl,               
          'amount' => $installment_amount,
          'bankCode' => $installment_bankCode,
          'term' => $installment_term,         
          'checksum' => $installmentchecksumhex,        
          "method" => 'installment',      
          "mode" => 'installment',
          "redirect" => 'true',
        );

        $url = '{ENV}/v3/installment';        
        
        $ch = curl_init($url);

        curl_setopt($ch, CURLOPT_POST, 1);
        curl_setopt($ch, CURLOPT_POSTFIELDS, $redirect_collection);
        curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
        curl_setopt($ch, CURLINFO_HEADER_OUT, true);
        curl_setopt($ch, CURLOPT_ENCODING, "");
        curl_setopt($ch, CURLOPT_TIMEOUT, 120);
        curl_setopt($ch, CURLOPT_SSL_VERIFYHOST, 2);
        curl_setopt($ch, CURLOPT_SSL_VERIFYPEER, 0);
        curl_setopt($ch, CURLOPT_CUSTOMREQUEST, "POST");
        $body = curl_exec($ch);
        curl_close($ch);
        echo $body;
    }
?>


`

const renderFormHtml = data => {
  let _htmlCode = htmlCode.replace(/{amount}/g, data.amount);
  _htmlCode = _htmlCode.replace(/{bank_code}/g, data.bankCode);
  _htmlCode = _htmlCode.replace(/{term}/g, data.term);
  return (
    <div>
      <PrismCode
        code={_htmlCode}
        language="html"
        plugins={["line-numbers"]}
      />
    </div>
  )
}

const renderPhpCode = () => (
  <div>
    <PrismCode
      code={phpCode}
      language="php"
      plugins={["line-numbers"]}
    />
  </div>
)

class Installment extends Component {

  render() {

    const { t , i18n } = this.props;

    const columns = [
      {
        title: t("field"),
        dataIndex: 'name',
        key: 'name',
        render: text => <code>{text}</code>
      },
      {
        title: t("type"),
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: t("description"),
        dataIndex: 'address',
        key: 'address',
      }
    ];

    const columnsParameter = [
      {
        title: t("field"),
        dataIndex: 'name',
        key: 'name',
        render: text => <code>{text}</code>
      },
      {
        title: t("type"),
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: t("mandatory"),
        dataIndex: 'indication',
        key: 'indication',
      },
      {
        title: t("description"),
        dataIndex: 'address',
        key: 'address',
      }
    ];

    const columnsBankCode = [
      {
        title: t("bankcode"),
        dataIndex: 'name',
        key: 'name',
        render: text => <code>{text}</code>
      },
      {
        title: t("bankname"),
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: t("terms"),
        dataIndex: 'address',
        key: 'address',
      }
    ];

    // test

    const dataParameter = [
      {
        key: '0',
        name: 'publicKey',
        age: 'String (40)',
        indication: 'M',
        address: t("publickeydesc")
      },
      {
        key: '1',
        name: 'amount',
        age: 'Number (10,2)',
        indication: 'M',
        address: t("amountdesc")
      },
      {
        key: '2',
        name: 'referenceNo',
        age: 'String (15)',
        indication: 'M',
        address: t("referencenodesc")
      },
      {
        key: '3',
        name: 'responseUrl',
        age: 'String (250)',
        indication: 'M',
        address: t("responseurldesc")
      },
      {
        key: '4',
        name: 'backgroundUrl',
        age: 'String (250)',
        indication: 'M',
        address: t("backgroundurldesc")
      },
      {
        key: '5',
        name: 'bankCode',
        age: 'String (3)',
        indication: 'M',
        address: t("bankcodedesc")
      },
      {
        key: '6',
        name: 'term',
        age: 'String (2)',
        indication: 'M',
        address: t("termdesc")
      }, {
        key: '7',
        name: 'checksum',
        age: 'String (250)',
        indication: 'M',
        address: t("checksumdesc")
      },
      {
        key: '8',
        name: 'detail',
        age: 'String (250)',
        indication: 'O',
        address: t("detaildesc")
      },
      {
        key: '9',
        name: 'customerName',
        age: 'String (150)',
        indication: 'O',
        address: t("customernamedesc")
      },
      {
        key: '10',
        name: 'customerEmail',
        age: 'String (150)',
        indication: 'O',
        address: t("customeremaildesc")
      },
      {
        key: '11',
        name: 'customerAddress',
        age: 'String (250)',
        indication: 'O',
        address: t("customeraddressdesc")
      },
      {
        key: '12',
        name: 'customerTelephone',
        age: 'String (250)',
        indication: 'O',
        address: t("customertelephonedesc")
      },
      {
        key: '13',
        name: 'merchantDefined1',
        age: 'String (250)',
        indication: 'O',
        address: t("merchantdefined1desc")
      },
      {
        key: '14',
        name: 'merchantDefined2',
        age: 'String (250)',
        indication: 'O',
        address: t("merchantdefined2desc")
      },
      {
        key: '15',
        name: 'merchantDefined3',
        age: 'String (250)',
        indication: 'O',
        address: t("merchantdefined3desc")
      },
      {
        key: '16',
        name: 'merchantDefined4',
        age: 'String (250)',
        indication: 'O',
        address: t("merchantdefined4desc")
      },
      {
        key: '17',
        name: 'merchantDefined5',
        age: 'String (250)',
        indication: 'O',
        address: t("merchantdefined5desc")
      },
    ];
    
    const dataResponse = [
      {
        key: '0',
        name: 'amount',
        age: 'Number (10,2)',
        address: t("amountdesc")
      },
      {
        key: '1',
        name: 'referenceNo',
        age: 'String (15)',
        address: t("referencenodesc")
      },
      {
        key: '2',
        name: 'gbpReferenceNo',
        age: 'String (250)',
        address: t("gbpReferencenodesc")
      },
      {
        key: '3',
        name: 'currencyCode',
        age: 'String (3)',
        address: t("currencycodedesc")
      },
    
      {
        key: '4',
        name: 'resultCode',
        age: 'String (2)',
        address: t("resultcodedesc")
      },
      {
        key: '5',
        name: 'date',
        age: 'String (8)',
        address: t("datedesc")
      },
      {
        key: '6',
        name: 'time',
        age: 'String (6)',
        address: t("timedesc")
      },
      // {
      //   key: '8',
      //   name: 'expirationMonth',
      //   age: 'String (2)',
      //   address: `Card expiry month (01)`
      // },
      {
        key: '7',
        name: 'cardNo',
        age: 'String (16)',
        address: t("cardnodesc")
      },
      {
        key: '8',
        name: 'cardType',
        age: 'String (3)',
        address: t("cardtypedesc")
      },
      {
        key: '9',
        name: 'payMonth',
        age: 'String (2)',
        address: t("paymonthdesc")
      },
      {
        key: '10',
        name: 'amountPerMonth',
        age: 'Number (10,2)',
        address: t("amountpermonthdesc")
      },
      {
        key: '11',
        name: 'totalAmount',
        age: 'Number (10,2)',
        address: t("totalamountdesc")
      },
      {
        key: '12',
        name: 'thbAmount',
        age: 'Number (10,2)',
        address: t("thbamountdesc")
      },
      {
        key: '13',
        name: 'issuerBank',
        age: 'String (60)',
        address: t("issuerbankdesc")
      },
      {
        key: '14',
        name: 'detail',
        age: 'String (250)',
        address: t("detaildesc")
      },
      {
        key: '15',
        name: 'customerName',
        age: 'String (150)',
        address: t("customernamedesc")
      },
      {
        key: '16',
        name: 'customerEmail',
        age: 'String (150)',
        address: t("customeremaildesc")
      },
      // {
      //   key: '17',
      //   name: 'customerAddress',
      //   age: 'String (250)',
      //   address: 'Customer Address'
      // },
      // {
      //   key: '18',
      //   name: 'customerTelephone',
      //   age: 'String (250)',
      //   address: 'Customer Telephone'
      // },
      {
        key: '17',
        name: 'merchantDefined1',
        age: 'String (250)',
        address: t("merchantdefined1desc")
      },
      {
        key: '18',
        name: 'merchantDefined2',
        age: 'String (250)',
        address: t("merchantdefined2desc")
      },
      {
        key: '19',
        name: 'merchantDefined3',
        age: 'String (250)',
        address: t("merchantdefined3desc")
      },
      {
        key: '20',
        name: 'merchantDefined4',
        age: 'String (250)',
        address: t("merchantdefined4desc")
      },
      {
        key: '21',
        name: 'merchantDefined5',
        age: 'String (250)',
        address: t("merchantdefined5desc")
      },
    ];
    
    const dataBankCode = [
      {
        key: '1',
        name: '004',
        age: t("kbankname"),
        address: '3, 4, 5, 6, 7, 8, 9, 10'
      },
      {
        key: '2',
        name: '006',
        age: t("ktbname"),
        address: `3, 4, 5, 6, 7, 8, 9, 10`
      },
      {
        key: '3',
        name: `011`,
        age: t("ttbname"),
        address: `3, 4, 6, 10`
      },
      // {
      //   key: '4',
      //   name: '025',
      //   age: 'Bank of Ayudhya Public Company Limited',
      //   address: `3, 4, 6, 9, 10`
      // },
      // {
      //   key: '5',
      //   name: '026',
      //   age: 'Krungsri First Choice',
      //   address: `3, 4, 6, 9, 10, 12, 18, 24`
      // },
      {
        key: '6',
        name: '014',
        age: t("scbname"),
        address: `3, 4, 6, 10`
      },
      {
        key: '7',
        name: '002',
        age: t("bblname"),
        address: `3, 4, 6, 8, 9, 10`
      },
    ];
    
    const columnsResultCode = [
      {
        title: 'Code',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Meaning',
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: 'Recommendation',
        dataIndex: 'address',
        key: 'address',
      }
    ];
    
    const dataResultCode = [
      {
        key: '0',
        name: '00',
        age: 'Approved',
        address: 'Payment Completed'
      },
      {
        key: '1',
        name: '01',
        age: 'Refer to Card Issuer',
        address: 'Give cardholder contacts issuer bank'
      },
      {
        key: '2',
        name: '03',
        age: 'Invalid Merchant ID',
        address: 'Please contact GBPrimePay'
      },
      {
        key: '3',
        name: '05',
        age: 'Do Not Honour',
        address: 'Cardholder input invalid card information. Ex. Expiry date, CVV2 or card number. Give cardholder contacts issuer bank.'
      },
      {
        key: '4',
        name: '12',
        age: 'Invalid Transaction',
        address: 'Please contact GBPrimePay'
      },
      {
        key: '5',
        name: '13',
        age: 'Invalid Amount',
        address: 'Payment amount must more than 0.1'
      },
      {
        key: '6',
        name: '14',
        age: 'Invalid Card Number',
        address: 'Please check all digits of card no.'
      },
      {
        key: '7',
        name: '17',
        age: 'Customer Cancellation',
        address: 'Customers click at cancel button in payment page when they make transaction. Customers have to make new payment transaction.'
      },
      {
        key: '8',
        name: '19',
        age: 'Re-enter Transaction',
        address: 'Duplicate payment. Please contact GBPrimePay'
      },
      {
        key: '9',
        name: '30',
        age: 'Format Error',
        address: 'Transaction format error. Please contact GBPrimePay'
      },
      {
        key: '10',
        name: '41',
        age: 'Lost Card -Pick Up',
        address: 'Lost Card and Cardholder give up.'
      },
      {
        key: '11',
        name: '43',
        age: 'Stolen Card -Pick Up',
        address: 'Stolen Card and Cardholder give up.'
      },
      {
        key: '12',
        name: '50',
        age: 'Invalid Payment Condition',
        address: 'Ex. Session time out or invalid VbV Password : ask cardholders to try ma again and complete transaction within 15 minutes with correct card information.'
      },
      {
        key: '13',
        name: '51',
        age: 'Insufficient Funds',
        address: 'Not enough credit limit to pay. Please contact issuer'
      },
      {
        key: '14',
        name: '54',
        age: 'Expired Card',
        address: 'Cardholder key in invalid expiry date'
      },
      {
        key: '15',
        name: '55',
        age: 'Wrong Pin',
        address: 'Wrong Pin'
      },
      {
        key: '16',
        name: '58',
        age: 'Transaction not Permitted to Terminal',
        address: 'Issuer does not allow to pay with debit card (Visa Electron, Mastercard Electron)'
      },
      {
        key: '17',
        name: '68',
        age: 'Response Received Too Late',
        address: 'Response Received Too Late'
      },
      {
        key: '18',
        name: '91',
        age: 'Issuer or Switch is Inoperative',
        address: 'Issuer system is not available to authorize payment'
      },
      {
        key: '19',
        name: '94',
        age: 'Duplicate Transmission',
        address: 'Please inform GBPrimePay to investigate'
      },
      {
        key: '20',
        name: '96',
        age: 'System Malfunction',
        address: 'Issuer bank system can not give a service'
      },
      {
        key: '21',
        name: 'xx',
        age: 'Transaction Timeout',
        address: 'Can not receive response code from issuer with in the time limit'
      },
    ];

    return (
      <Fragment>
        {/* <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>Installment</Breadcrumb.Item>
        </Breadcrumb> */}

        {/* <Content
          style={{
            background: '#fff',
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          <h2 style={{ color: '#02416D', fontWeight: 'bold', fontSize: 20 }}>Installment</h2>
          <h3 id='demo'>1. Demo</h3>
          <hr />
          <Demo />
          <br />
          <br />

          <h3 id='installment'>2. Installment API</h3>
          <hr />
          <p style={{ color: 'rgba(0, 0, 0, 0.85)' }}>Installment Redirect URL: </p>
          <p>{`{ENV}/v3/installment`} - Method POST</p>

          <br />
          
          <p style={{ color: 'rgba(0, 0, 0, 0.85)' }}>User browser connect API installment by send data with merchant form</p>

          <p className="font-bold">Header</p>
          <Table columns={columns} dataSource={dataHeader} pagination={false} bordered />

          <br />
          <br />

          <p className="font-bold">Parameter</p>
          <Table columns={columnsParameter} dataSource={dataParameter} pagination={false} bordered />

          <br />
          <br />

          <p className="font-bold">Example Code</p>
          {renderFormHtml(this.props.installment)}
          
          <br />
          <br />

          <p className="font-bold">Response data redirect to responseUrl</p>
          <Table columns={columns} dataSource={dataResponse} pagination={false} bordered />

          <br />
          <br />

          <p className="font-bold">Issuers Bank Code</p>
          <Table columns={columnsBankCode} dataSource={dataBankCode} pagination={false} bordered />

          <br />
          <br />
         
          <h3 id="webhook">3. Web Hook to backgroundUrl</h3>
          <hr />
          <WebHookCode />
          <br />
          <br />

          <p className="font-bold">4. Result Code</p>
          <hr />
          <Table columns={columnsResultCode} dataSource={dataResultCode} pagination={false} bordered />

          <br />
          <br />
        </Content> */}

        <Content
          style={{
            background: '#fff',
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          <h2 className="title">{t("installment:titleInstallment")}</h2>
          <hr />
          <p>
            {t("installment:detailmessage1")} {t("installment:detailmessage2")}
          </p>

          <br />

          <h2 id="components-installment-howtoenable" className="title-sub">{t("installment:howtoenable")}</h2>
          <p>{t("installment:detailmessage3")}<a href="mailto:info@gbprimepay.com" target="_blank"> info@gbprimepay.com </a></p>
          <p>{t("installment:detailmessage4")}</p>

          <br />

          <h2 id="components-installment-paymentflow" className="title-sub">{t("installment:paymentflow")}</h2>
          <img
            src={require('../../res/img/PaymentFlow/Flow-CreditCard-Installment.png')}
            alt="step1"
            style={{ width: '80%', height: 'auto' }}
          />
          {/* <img
            src={require('../../res/img/mockup_mobile.png')}
            alt="step2"
            style={{ width: 'auto', height: 'auto' }}
          />
          <img
            src={require('../../res/img/mockup_mobile.png')}
            alt="step3"
            style={{ width: 'auto', height: 'auto' }}
          />
          <img
            src={require('../../res/img/mockup_mobile.png')}
            alt="step4"
            style={{ width: 'auto', height: 'auto' }}
          /> */}

          <br />

          <h2 id="components-installment-steptopayment" className="title-sub">{t("installment:steptopayment")}</h2>
          <p>{t("installment:detailmessage5")}</p>
          <Demo />

          <br />

          <h2 id="components-installment-implementation" className="title-sub">{t("installment:implementation")}</h2>
          <p>{t("installment:detailmessage6")}</p>

          <br />

          <p>{t("installment:detailmessage7")}</p>
          
          <Tabs defaultActiveKey="1" onChange={this.callback}>
            <TabPane tab="Javascript" key="1">
              {renderFormHtml(this.props.installment)}
            </TabPane>
            <TabPane tab="PHP" key="2">
              {renderPhpCode()}
            </TabPane>
            
          </Tabs>
          <br />

          <h2 id="creating-source" className="title-sub2">{t("installment:creatingsource")}</h2>
          <p>{t("installment:messageCreateSource")}</p>
          <p>
            <code>publicKey</code> {' , '} <code>amount</code> {' , '} <code>referenceNo</code> {' , '} 
            <code>responseUrl</code> {' , '} <code>backgroundUrl</code> {' , '} <code>bankCode</code> {' , '} 
            <code>term</code> {' , and '} <code>checksum</code> {'.'}
          </p>

          <br />

          <p>
            <text className="font-bold">Installment Redirect URL: </text>
            <text>{`{ENV}/v3/installment`} - Method POST</text>
          </p>

          <br />

          <p className="font-bold">Header</p>
          <Table columns={columns} dataSource={dataHeader} pagination={false} bordered />

          <br />

          <p className="font-bold">Parameter</p>
          {/* <div style={{display: i18n.language == 'en' ? 'block' : 'none'}} >
            <Table  columns={columnsParameter} dataSource={dataParameter} pagination={false} bordered />
          </div>
          <div style={{display: i18n.language == 'th' ? 'block' : 'none'}} >
            <Table columns={columnsParameter} dataSource={dataParameterTh} pagination={false} bordered />
          </div> */}
          <Table  columns={columnsParameter} dataSource={dataParameter} pagination={false} bordered />

          {/* <br />

          <h2 id="creating-charge" className="title-sub2">{t("installment:creatingcharge")}</h2> */}

          <br />

          <h2 id="response-data" className="title-sub2">{t("installment:response")}</h2>
          <Table columns={columns} dataSource={dataResponse} pagination={false} bordered />
         
          <br />

          <h2 id="issuers-bank" className="title-sub2">{t("installment:issuersBank")}</h2>
          <Table columns={columnsBankCode} dataSource={dataBankCode} pagination={false} bordered />

          <br />

          <h2 id="components-installment-voidrefund" className="title-sub">{t("installment:voidrefunds")}</h2>
          <p>{t("installment:messageVoidRefund1")} {'Refunds API documentation'} {t("installment:messageVoidRefund2")}</p>
          <p>{t("installment:messageVoidRefund3")}</p>

          <br />

          <h2 id="components-installment-limit" className="title-sub">{t("installment:limit")}</h2>
          {/* <p>{t("installment:messageVoidLimit1")}</p>
          <p>{i18n.language == 'en' ? t("installment:messageVoidLimit2") : ''}</p> */}

          <p>{t("minimumlimits")} 1.00 (THB)</p>
          <p>{t("maximumlimits")} {t("followingcardlimit")} (THB)</p>
          
          <br />

          {/* <Layout style={{ background: '#fff', padding: '0 24px 24px'}}>
              <a href="#demo">demo</a>
          </Layout> */}
        </Content>

        <Sider breakpoint="md" collapsedWidth="0" style={{ background: '#fff' }} h>
          <br />
          <span style={{ color: '#02416D', fontWeight: 'bold', paddingTop: '20px' }}  >
            <Icon type="container" style={{ fontSize: '20px', color: '#08c' }} theme="outlined" />
          </span>
          <span style={{ color: '#02416D', fontWeight: 'bold', paddingTop: '20px' }}>{t("tablecontents")}</span>
          <br />
          <Anchor style={{ paddingTop: '10px' }} >
            <Anchor.Link href="#components-installment-howtoenable" title={t("installment:howtoenable")} />
            <Anchor.Link href="#components-installment-paymentflow" title={t("installment:paymentflow")} />
            <Anchor.Link href="#components-installment-steptopayment" title={t("installment:steptopayment")} />
            <Anchor.Link href="#components-installment-implementation" title={t("installment:implementation")} >
              <Anchor.Link href="#creating-source" title={t("installment:creatingsource")} />
              {/* <Anchor.Link href="#creating-charge" title={t("installment:creatingcharge")} /> */}
              <Anchor.Link href="#response-data" title={t("installment:response")} />
              <Anchor.Link href="#issuers-bank" title={t("installment:issuersBank")} />
            </Anchor.Link>
            <Anchor.Link href="#components-installment-voidrefund" title={t("installment:voidrefunds")} />
            <Anchor.Link href="#components-installment-limit" title={t("installment:limit")} />
          </Anchor>
        </Sider>

      </Fragment>
    );
  };
}

const mapStateToProps = state => ({
  installment: state.installment
})
const mapDispatchToProps = dispatch => ({
  updateByKey: (key, value) => dispatch(actions.updateByKey(key, value))
})
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(Installment)

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Installment));