import React, { Component } from 'react'
import { Row, Col, Descriptions, Tabs } from 'antd'
import Prism from 'prismjs'
import PrismCode from './PrismCode'
import { withTranslation } from "react-i18next";

const { TabPane } = Tabs;

const code = `
<form id="checkout-form" action="/">                     
  <div id="gb-form" style="height: 600px;"></div>  
</form>                                                  
<script src="GBPrimePay.js"></script>  <!-- https://github.com/GBPrimepay/gbprimepay-js --> 
<script>                                                 
  new GBPrimePay({                                             
    publicKey: 'publickey_1234abcd',                           
    gbForm: '#gb-form',                                        
    merchantForm: '#checkout-form',                            
    customStyle: {                                             
      backgroundColor: '#eaeaea'                               
    },                                                         
    env: 'prd' // default prd | optional: test, prd           
  });                                                          
</script>
`

const renderFormCode = () => (
  <div>
    <PrismCode
      code={code}
      language="html"
      plugins={["line-numbers"]}
    />
  </div>
)

// export default class CardForm extends Component {
class CardForm extends Component {
  callback = (key) => {
    console.log(key);
  }
  render() {
    const { t , i18n } = this.props;
    return (
      <div>
        <br />
        <Row>
          <Col sm={5} md={6}>
            {' '}
          </Col>
          <Col sm={14} md={12}>
            <img
              src={require('../../res/img/token-v2-3d.png')}
              alt="3-D Secure"
              style={{ width: '100%', height: 'auto' }}
            />
          </Col>
          <Col sm={5} md={6}>
            {' '}
          </Col>
        </Row>

        <br />

        <ol>
          <li>{t("fullpayment3d:howtoworkdetail1")}</li>
          <li>{t("fullpayment3d:howtoworkdetail2")}</li>
          <li>{t("fullpayment3d:howtoworkdetail3")}</li>
          <li>{t("fullpayment3d:howtoworkdetail4")}</li>
          <li>{t("fullpayment3d:howtoworkdetail5")}</li>
          <li>{t("fullpayment3d:howtoworkdetail6")}</li>
          <li>{t("fullpayment3d:howtoworkdetail7")}</li>
        </ol>

        <br />

        <p className="font-bold">Usage Example: GBPrimePay.js</p>
        {renderFormCode()}

        <br />

        {/* <div>
          <Descriptions layout="vertical" bordered>
            <Descriptions.Item
              label={t("fullpayment3d:howtoworkdmessage1")}
              span={4}
            >{`<input type="hidden" name="gbToken">`}</Descriptions.Item>
            <Descriptions.Item
              label={t("fullpayment3d:howtoworkdmessage2")}
              span={4}
            >{`<input type="hidden" name="gbRememberCard">`}</Descriptions.Item>
            <Descriptions.Item
              label={t("fullpayment3d:howtoworkdmessage3")}
              span={4}
            >
              <a href="mailto:info@gbprimepay.com">info@gbprimepay.com</a>
            </Descriptions.Item>
          </Descriptions>
        </div> */}
      </div>
    );
  }
}

export default withTranslation()(CardForm);