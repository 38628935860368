import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Layout, Button, Breadcrumb, Icon, Table, Row, Col, Tabs, Anchor } from 'antd'
import get from 'lodash/get'
import PrismCode from '../Tokenization/PrismCode'
import TokenForm from './Form'
import * as actions from '../../actions/apiQueryAction'
import WeDemo from './WeDemo'
import { withTranslation } from "react-i18next";

const { Content, Sider } = Layout;
const { TabPane } = Tabs;

const htmlCode = `
<form id="lineform" action="{url}" method="POST"> 
  <input type="hidden" name="publicKey" value="{public_key}" /><br/>
  <input type="hidden" name="customerTelephone" value="{customerTelephone}" /><br/>
  <input type="hidden" name="referenceNo" value="{referenceNo}" /><br/>
  <input type="hidden" name="backgroundUrl" value="{backgroundUrl}" /><br/>
  <label>Amount: </label>
  <input type="number" name="amount" maxlength="13" placeholder="Amount" value="{amount}" /><br/>
  <input type="hidden" name="detail" placeholder="Detail" value="{detail}" /><br/>
  <input type="hidden" name="customerName" value="{customerName}" /><br/>
  <input type="hidden" name="customerEmail" value="{customerEmail}" /><br/>
  <input type="hidden" name="customerAddress" value="{customerAddress}" /><br/>
  <input type="hidden" name="merchantDefined1" value="{merchantDefined1}" /><br/>
  <input type="hidden" name="merchantDefined2" value="{merchantDefined2}" /><br/>
  <input type="hidden" name="merchantDefined3" value="{merchantDefined3}" /><br/>
  <input type="hidden" name="merchantDefined4" value="{merchantDefined4}" /><br/>
  <input type="hidden" name="merchantDefined5" value="{merchantDefined5}" /><br/>
  <div>
  <label>Checksum: </label> 
  <input type="text" name="checksum" value="" /><br/>
  <input id="button" type="button" onClick="genChecksum()" value="Generate Checksum" />
  </div>
  <div> 
    <button type="submit">Pay</button> 
  </div>
</form>

<script src="https://cdnjs.cloudflare.com/ajax/libs/crypto-js/3.1.9-1/crypto-js.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/crypto-js/3.1.9-1/hmac-sha256.min.js"></script>
<script>
function genChecksum(){
  var hash = CryptoJS.HmacSHA256(document.getElementsByName("amount")[0].value +
  document.getElementsByName("referenceNo")[0].value + document.getElementsByName("detail")[0].value,
  {secret_key});
  var hashInBase64 = CryptoJS.enc.Base64.stringify(hash);
    document.getElementsByName("checksum")[0].value = hashInBase64;  
}
</script>
`

const phpResponse = `
<?php

$respFile = fopen("resp-log.txt", "w") or die("Unable to open file!");

$json_str = file_get_contents('php://input');
fwrite($respFile, $json_str . "\\n\\n");

$json_obj = json_decode($json_str);
$json_objdata = json_decode($json_objdata->txn);

fwrite($respFile, "resultCode=" . $json_objdata->resultCode . "\\n");
fwrite($respFile, "amount=" . $json_objdata->amount . "\\n");
fwrite($respFile, "referenceNo=" . $json_objdata->referenceNo . "\\n");
fwrite($respFile, "customerName=" . $json_objdata->customerName . "\\n");
fwrite($respFile, "customerEmail=" . $json_objdata->customerEmail . "\\n");
fwrite($respFile, "detail=" . $json_objdata->detail . "\\n");
fwrite($respFile, "status=" . $json_objdata->status . "\\n");
fwrite($respFile, "gbpReferenceNo=" . $json_objdata->gbpReferenceNo . "\\n");
fwrite($respFile, "merchantDefined1=" . $json_objdata->merchantDefined1 . "\\n");
fwrite($respFile, "merchantDefined2=" . $json_objdata->merchantDefined2 . "\\n");
fwrite($respFile, "merchantDefined3=" . $json_objdata->merchantDefined3 . "\\n");
fwrite($respFile, "merchantDefined4=" . $json_objdata->merchantDefined4 . "\\n");
fwrite($respFile, "merchantDefined5=" . $json_objdata->merchantDefined5 . "\\n");
fclose($respFile);

?>
`

const javaResponse = `

StringBuffer jb = new StringBuffer();
String line = null;

try {

  BufferedReader reader = request.getReader();

  while ((line = reader.readLine()) != null)

    jb.append(line);

} catch (Exception e) { /*report an error*/ }

try {

  JSONObject jsonObject = new JSONObject(jb.toString());
  JSONObject txn = new JSONObject(jsonObject);
  JSONObject dataObject = txn.getJSONObject("txn");

  System.out.println("resultCode=" + dataObject.getString("resultCode"));
  System.out.println("amount=" + dataObject.getString("amount"));
  System.out.println("referenceNo=" + dataObject.getString("referenceNo"));
  System.out.println("customerName=" + dataObject.getString("customerName"));
  System.out.println("customerEmail=" + dataObject.getString("customerEmail"));
  System.out.println("detail=" + dataObject.getString("detail"));
  System.out.println("status=" + dataObject.getString("status"));
  System.out.println("gbpReferenceNo=" + dataObject.getString("gbpReferenceNo"));
  System.out.println("merchantDefined1=" + dataObject.getString("merchantDefined1"));
  System.out.println("merchantDefined2=" + dataObject.getString("merchantDefined2"));
  System.out.println("merchantDefined3=" + dataObject.getString("merchantDefined3"));
  System.out.println("merchantDefined4=" + dataObject.getString("merchantDefined4"));
  System.out.println("merchantDefined5=" + dataObject.getString("merchantDefined5"));
  
} catch (JSONException e) { /*report an error*/ }
`

const renderFormHtml = data => {
  let url = `https://api.globalprimepay.com/v1/check_status_txn`
  if (data.isAq === `bill`) {
    url = `https://api.globalprimepay.com/v1/check_status_txn"`
  }
  let _htmlCode = htmlCode.replace(/{referenceNo}/g, data.referenceNo);
  _htmlCode = _htmlCode.replace(/{url}/g, url);
  return (
    <div>
      <PrismCode
        code={_htmlCode}
        language="html"
        plugins={["line-numbers"]}
      />
    </div>
  )
}

const renderPhpResponse = () => (
  <div>
    <PrismCode
      code={phpResponse}
      language="php"
      plugins={["line-numbers"]}
    />
  </div>
)

const renderJavaResponse = () => (
  <div>
    <PrismCode
      code={javaResponse}
      language="java"
      plugins={["line-numbers"]}
    />
  </div>
)

const codeRequest = `
{
  "referenceNo": "20201200000601"
}
`

const codeResponse = `
{
  "resultCode": "00",
  "txn": {
      "amount": "1.00",
      "referenceNo": "20201200000601",
      "customerEmail": "Test@@ggmail.com.com",
      "gbpReferenceNo": "gbp069311577863",
      "detail": null,
      "customerName": "Test Test",
      "merchantDefined1": null,
      "merchantDefined2": null,
      "merchantDefined3": null,
      "merchantDefined4": null,
      "merchantDefined5": null,
      "status": "S",
      "resultCode": "00"
  }
  }
`

const renderFormCodeResponse = () => (
  <div>
    <PrismCode
      code={codeResponse}
      language="json"
      plugins={["line-numbers"]}
    />
  </div>
)

const renderFormCodeRequest = () => (
  <div>
    <PrismCode
      code={codeRequest}
      language="json"
      plugins={["line-numbers"]}
    />
  </div>
)

class QRAndBarcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }
  changeStep = current => {
    this.setState({ current: current })
    console.log(current)
  }
  render() {
    const { t , i18n } = this.props;

    const columns = [
      {
        title: t("field"),
        dataIndex: 'name',
        key: 'name',
        render: text => <code>{text}</code>
      },
      {
        title: t("type"),
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: t("description"),
        dataIndex: 'address',
        key: 'address',
      }
    ];
    
    const columnsParameter = [
      {
        title: t("field"),
        dataIndex: 'name',
        key: 'name',
        render: text => <code>{text}</code>
      },
      {
        title: t("type"),
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: t("mandatory"),
        dataIndex: 'mandatory',
        key: 'mandatory',
      },
      {
        title: t("description"),
        dataIndex: 'address',
        key: 'address',
      }
    ];
    
    const dataHeader = [
      {
        key: `0`,
        name: `content-type`,
        age: `String`,
        address: `application/json`,
      },
      {
        key: `1`,
        name: `Authorization`,
        age: `String`,
        address: `String	"Basic " + Base64Encode({secret_key} + ":")`,
      },
    ];
    
    const dataHeaderImage = [
      {
        key: `1`,
        name: `content-type`,
        age: `String`,
        address: `image/png`
      },
    ];
    
    const dataParameter = [
      {
        key: `0`,
        name: `referenceNo`,
        age: `String (15)`,
        mandatory: `M`,
        address: t("referencenodesc")
      },
    ];
    
    const dataResponse = [
      {
        key: `1`,
        name: `amount`,
        age: `Number (10, 2)`,
        address: `For example: 15.00`
      },
      {
        key: `2`,
        name: `referenceNo`,
        age: `String (15)`,
        address: `Issued by Merchant (should be a unique number) For example: 20171128001`
      },
      {
        key: `3`,
        name: `gbpReferenceNo`,
        age: `String (250)`,
        address: `GBPrimePay Reference`
      },
      {
        key: `4`,
        name: `resultCode`,
        age: `String (2)`,
        address: `00 = Success, XX = Failure (Please see details below)`
      },
      {
        key: `5`,
        name: `date`,
        age: `String (8)`,
        address: `DDMMYYYY e.g. 20112007`
      },
      {
        key: `6`,
        name: `time`,
        age: `String (6)`,
        address: `HHMMSS e.g. 141222`
      },
      {
        key: `7`,
        name: `expirationMonth`,
        age: `String (2)`,
        address: `เดือนที่บัตรหมดอายุ (01)`
      },
      {
        key: `8`,
        name: `cardNo`,
        age: `String (16)`,
        address: `16 digits (shift Left) e.g. 4987XXXXXXXX8769`
      },
      {
        key: `9`,
        name: `payMonth`,
        age: `String (2)`,
        address: `Number of month for installment e.g. 03 = 3 Months`
      },
      {
        key: `10`,
        name: `amountPerMonth`,
        age: `Number (10,2)`,
        address: `Amount payment per Month for smart payment (2 digit Decimal)`
      },
      {
        key: `11`,
        name: `issuerBank`,
        age: `String`,
        address: `Bank Name from Cards BIN Number e.g. Kasikornbank Public Company Limited`
      },
      {
        key: `12`,
        name: `detail`,
        age: `String (250)`,
        address: `Product Description`
      },
      {
        key: `13`,
        name: `customerName`,
        age: `String (150)`,
        address: `Customer Name`
      },
      {
        key: `14`,
        name: `customerEmail`,
        age: `String (150)`,
        address: `Customer Email`
      },
      {
        key: `15`,
        name: `merchantDefined1`,
        age: `String (250)`,
        address: `Merchant Defined 1 (reserved)`
      },
      {
        key: `16`,
        name: `merchantDefined2`,
        age: `String (250)`,
        address: `Merchant Defined 2 (reserved)`
      },
      {
        key: `17`,
        name: `merchantDefined3`,
        age: `String (250)`,
        address: `Merchant Defined 3 (reserved)`
      },
      {
        key: `18`,
        name: `merchantDefined4`,
        age: `String (250)`,
        address: `Merchant Defined 4 (reserved)`
      },
      {
        key: `19`,
        name: `merchantDefined5`,
        age: `String (250)`,
        address: `Merchant Defined 5 (reserved)`
      },
    ];
    
    const dataBankCode = [
      {
        key: `1`,
        name: `004`,
        age: `Kasikornbank Public Company Limited.`,
        address: `3, 4, 5, 6, 7, 8, 9, 10`
      },
      {
        key: `2`,
        name: `006`,
        age: `Krung Thai Bank Public Company Limited.`,
        address: `3, 4, 5, 6, 7, 8, 9, 10`
      },
      {
        key: `3`,
        name: `011`,
        age: `TMBThanachart Bank (ttb)`,
        address: `3, 4, 6, 10`
      },
      {
        key: `4`,
        name: `025`,
        age: `Bank of Ayudhya Public Company Limited`,
        address: `3, 4, 6, 9, 10`
      },
      {
        key: `5`,
        name: `026`,
        age: `Krungsri First Choice`,
        address: `3, 4, 6, 9, 10, 12, 18, 24`
      },
      {
        key: `6`,
        name: `014`,
        age: `Siam Commercial Bank Public Company Limited`,
        address: `3, 4, 6, 10`
      },
    ];
    
    const renderContent = (value, row, index) => {
      const obj = {
        children: value,
        props: {},
      };
      if (index === 1) {
        obj.props.colSpan = 0;
      }
      return obj;
    };
    
    const columns__1 = [{
      title: t("field"),
      dataIndex: 'name',
      render: (value, row, index) => {
        if (index != 1) {
          return <code>{value}</code>;
        }
        return {
          children: value,
          props: {
            colSpan: 4,
          },
        };
      },
    }, {
      title: t("type"),
      dataIndex: 'age',
      key: 'age',
      render: renderContent,
      
    },
    {
      title: t("mandatory"),
      dataIndex: 'indication',
      key: 'indication',
      render: renderContent,
    },
    {
      title: t("description"),
      dataIndex: 'address',
      key: 'address',
      render: renderContent,
    }];
    
    const dataResponseToMerchant = [
      {
        key: `1`,
        name: `resultCode`,
        age: `String (2)`,
        address: t("query:resultcodedesc")
      },
      {
        key: `2`,
        name: `txn`
        // indication:'',
        // address: ''
      },
      {
        key: `3`,
        name: `amount`,
        age: `Number (10, 2)`,
        address: t("amountdesc")
      },
      {
        key: `4`,
        name: `referenceNo`,
        age: `String (15)`,
        address: t("referencenodesc")
      },
      {
        key: `5`,
        name: `gbpReferenceNo`,
        age: `String (250)`,
        address: t("gbpReferencenodesc")
      },
      {
        key: `6`,
        name: `resultCode`,
        age: `String (2)`,
        address: t("query:resultcodedesc")
      },
      {
        key: `7`,
        name: `detail`,
        age: `String (250)`,
        address: t("detaildesc")
      },
      {
        key: `8`,
        name: `customerName`,
        age: `String (150)`,
        address: t("customernamedesc")
      },
      {
        key: `9`,
        name: `customerEmail`,
        age: `String (150)`,
        address: t("customeremaildesc")
      },
      {
        key: `10`,
        name: `customerAddress`,
        age: `String (150)`,
        address: t("customeraddressdesc")
      },
      {
        key: `11`,
        name: `customerTelephone`,
        age: `String (150)`,
        address: t("customertelephonedesc")
      },
      {
        key: `12`,
        name: `merchantDefined1`,
        age: `String (250)`,
        address: t("merchantdefined1desc")
      },
      {
        key: `13`,
        name: `merchantDefined2`,
        age: `String (250)`,
        address: t("merchantdefined2desc")
      },
      {
        key: `14`,
        name: `merchantDefined3`,
        age: `String (250)`,
        address: t("merchantdefined3desc")
      },
      {
        key: `15`,
        name: `merchantDefined4`,
        age: `String (250)`,
        address: t("merchantdefined4desc")
      },
      {
        key: `16`,
        name: `merchantDefined5`,
        age: `String (250)`,
        address: t("merchantdefined5desc")
      },
      {
        key: `17`,
        name: `status`,
        age: `String (1)`,
        address: t("statusdesc")
      },
      {
        key: `18`,
        name: `PaymentType`,
        age: `String (1)`,
        address: `C = Credit Card Full payment,
                  R = Recurring,
                  I = Credit Card Installment,
                  Q = Qr Cash,
                  B = Bill Payment,
                  W = Wechat,
                  L = Line Payment,
                  T = True Wallet,
                  M = Mobile Banking,
                  D = Direct Debit`
      },
    ];

    return (
      <Fragment>
        {/* <Breadcrumb style={{ margin: `16px 0` }}>
          <Breadcrumb.Item>Api Query</Breadcrumb.Item>
        </Breadcrumb> */}
        {/* <Content
          style={{
            background: `#fff`,
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          <h2>Api Query</h2>
          <hr />
          <p style={{ color: `rgba(0, 0, 0, 0.85)` }}>Api Query URL:</p>
          <p>{`{ENV}/v1/check_status_txn`} - Method POST</p>
          <br />

          <p style={{ color: `rgba(0, 0, 0, 0.85)`, fontWeight: `bold` }}>Header</p>
          <Table columns={columns} dataSource={dataHeader} pagination={false} bordered />

          <br />
          <br />

          <p style={{ color: `rgba(0, 0, 0, 0.85)`, fontWeight: `bold` }}>Parameter</p>
          <Table columns={columns} dataSource={dataParameter} pagination={false} bordered />

          <br />
          <br />
          
          <p style={{ color: `rgba(0, 0, 0, 0.85)`, fontWeight: `bold` }}>JSON Request</p>
          {renderFormCodeRequest()}

          <br />
          <br />

          //<p style={{ color: `rgba(0, 0, 0, 0.85)`, fontWeight: `bold` }}>Example Code</p>
          //{renderFormHtml(this.props.apiQuery)}

          <br />

          <WeDemo />

          <br />
          <br />

          <p style={{ color: `rgba(0, 0, 0, 0.85)`, fontWeight: `bold` }}>Response to Merchant</p>
          <Table columns={columns__1} dataSource={dataResponseToMerchant} pagination={false} bordered />

          <br />
          <br />

          <p style={{ color: `rgba(0, 0, 0, 0.85)`, fontWeight: `bold` }}>JSON Response</p>
          {renderFormCodeResponse()}

          <br />
          <br />

          <p style={{ color: `rgba(0, 0, 0, 0.85)`, fontWeight: `bold` }}>Example Code - Response to Merchant</p>
          <Tabs defaultActiveKey="1" onChange={this.callback}>
            <TabPane tab="PHP" key="1">
              {renderPhpResponse()}
            </TabPane>
            <TabPane tab="Java" key="2">
              {renderJavaResponse()}
            </TabPane>
          </Tabs>

        </Content> */}

        <Content
          style={{
            background: `#fff`,
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          <h2 className="title">{t("query:titleapiquery")}</h2>
          <hr />
          <p>
            {t("query:detailmessage1")}
          </p>

          <br />

          {/* <h2 id="components-query-howtoenable" className="title-sub2">{t("query:howtoenable")}</h2>
          <p>{t("query:howtoenabledetail1")}<a href="support@gbprimepay.com"> support@gbprimepay.com </a></p>
          <p>{t("query:howtoenabledetail2")}</p>

          <br />

          <h2 id="components-query-paymentflow" className="title-sub2">{t("query:paymentflow")}</h2>
          <img
            src={require('../../res/img/mockup_mobile.png')}
            alt="step1"
            style={{ width: 'auto', height: 'auto' }}
          />
          <img
            src={require('../../res/img/mockup_mobile.png')}
            alt="step2"
            style={{ width: 'auto', height: 'auto' }}
          />
          <img
            src={require('../../res/img/mockup_mobile.png')}
            alt="step3"
            style={{ width: 'auto', height: 'auto' }}
          />
          <img
            src={require('../../res/img/mockup_mobile.png')}
            alt="step4"
            style={{ width: 'auto', height: 'auto' }}
          />

          <br />

          <h2 id="components-query-steptopayment" className="title-sub2">{t("query:steptopayment")}</h2>
          <p>{t("query:steptopaymentdetail1")}</p>
          <WeDemo />

          <br /> */}

          <h2 id="components-query-implementation" className="title-sub">{t("query:implementation")}</h2>
          {/* <p>{t("query:implementationdetail1")}</p> */}

          {/* <br />

          <p>{t("query:usageexample")}</p>
          {renderFormHtml(this.props.apiQuery)} */}

          <h2 id="creating-source" className="title-sub2">{t("query:creatingsource")}</h2>
          <p>{t("query:creatingsourcedetail1")}</p>
          <p>
            <code>referenceNo</code>
          </p>

          <br />

          <p>
            <text className="font-bold">Api Query URL: </text>
            <text>{`{ENV}/v1/check_status_txn`} - Method POST</text>
          </p>

          <br />

          <p className="font-bold">Header</p>
          <Table columns={columns} dataSource={dataHeader} pagination={false} bordered />

          <br />

          <p className="font-bold">Parameter</p>
          <Table  columns={columnsParameter} dataSource={dataParameter} pagination={false} bordered />

          <br />

          <p className="font-bold">JSON Request</p>
          {renderFormCodeRequest()}

          <br />

          <WeDemo />

          {/* <br />

          <h2 id="creating-charge" className="title-sub2">{t("query:creatingcharge")}</h2>
          <p>{'-'}</p>
          <p>{'-'}</p> */}

          <br />

          <h2 id="response-data" className="title-sub2">{t("query:responseheader")}</h2>
          <Table columns={columns__1} dataSource={dataResponseToMerchant} pagination={false} bordered />

          <br />
          <br />

          <p className="font-bold">{t("query:jsonresponse")}</p>
          {renderFormCodeResponse()}

          <br />
          <br />

          <p className="font-bold">Example Code - Response to Merchant</p>
          <Tabs defaultActiveKey="1" onChange={this.callback}>
            <TabPane tab="PHP" key="1">
              {renderPhpResponse()}
            </TabPane>
            <TabPane tab="Java" key="2">
              {renderJavaResponse()}
            </TabPane>
          </Tabs>

          {/* <br />

          <h2 id="components-query-voidrefund" className="title-sub2">{t("query:voidrefunds")}</h2>
          <p>{t("query:voidrefundsdetail1")} {'Refunds API documentation'} {t("query:voidrefundsdetail2")}</p>
          <p>{t("query:voidrefundsdetail3")}</p> */}

          {/* <br />

          <h2 id="components-query-limit" className="title-sub">{t("query:limit")}</h2>
          <p>{t("query:limitdetail1")}</p>
          <p>{i18n.language == 'en' ? t("query:limitdetail2") : ''}</p> */}

          <br />

          <h2 id="components-query-limit" className="title-sub">{t("query:limit")}</h2>
          <p>{t("query:limitdetail1")}</p>
        </Content>

        <Sider breakpoint="md" collapsedWidth="0" style={{ background: '#fff' }} h>
          <br />
          <span style={{ color: '#02416D', fontWeight: 'bold', paddingTop: '20px' }}  >
            <Icon type="container" style={{ fontSize: '20px', color: '#08c' }} theme="outlined" />
          </span>
          <span style={{ color: '#02416D', fontWeight: 'bold', paddingTop: '20px' }}>{t("tablecontents")}</span>
          <br />
          <Anchor style={{ paddingTop: '10px' }} >
            {/* <Anchor.Link href="#components-query-howtoenable" title={t("query:howtoenable")} />
            <Anchor.Link href="#components-query-paymentflow" title={t("query:paymentflow")} />
            <Anchor.Link href="#components-query-steptopayment" title={t("query:steptopayment")} /> */}
            <Anchor.Link href="#components-query-implementation" title={t("query:implementation")} >
              <Anchor.Link href="#creating-source" title={t("query:creatingsource")} />
              {/* <Anchor.Link href="#creating-charge" title={t("query:creatingcharge")} /> */}
              <Anchor.Link href="#response-data" title={t("query:response")} />
            </Anchor.Link>
            {/* <Anchor.Link href="#components-query-voidrefund" title={t("query:voidrefunds")} /> */}
            <Anchor.Link href="#components-query-limit" title={t("query:limit")} />
          </Anchor>
        </Sider>

      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  apiQuery: state.apiQuery
})
const mapDispatchToProps = dispatch => ({
  updateByKey: (key, value) => dispatch(actions.updateByKey(key, value))
})
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(QRAndBarcode)
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(QRAndBarcode));