import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Layout, Button, Breadcrumb, Icon, Table, Row, Col, Tabs, Anchor } from 'antd'
import GBLinkPaymentForm from './Form'
import * as actions from '../../actions/qrCashAction'
import { withTranslation } from "react-i18next";

const { Content, Sider } = Layout;
const { TabPane } = Tabs;

class GBLinkPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }
  changeStep = current => {
    this.setState({ current: current })
    console.log(current)
  }
  render() {

    const { t , i18n } = this.props;

    return (
      <Fragment>
        <Content
          style={{
            background: '#fff',
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          <h2 className="title">{t("gblinkpayment:titlegblinkpayment")}</h2>
          <hr />
          <p>
            {t("gblinkpayment:detailmessage1")}
          </p>

          <br />

          <h2 id="components-gblinkpayment-steptopayment" className="title-sub">{t("gblinkpayment:steptogenerate")}</h2>
          <p>1. {t("gblinkpayment:step1")}</p>
          <img
            src={require('../../res/img/GBLinkPayment/createlink_01.png')}
            alt="step1"
            style={{ width: '80%', height: 'auto' }}
          />

         <br />
         <br />

         <p>2. {t("gblinkpayment:step2")}</p>
         <img
            src={require('../../res/img/GBLinkPayment/createlink_02.png')}
            alt="step1"
            style={{ width: '80%', height: 'auto' }}
          />

         <br />
         <br />

         <p>3. {t("gblinkpayment:step3")}</p>
         <img
            src={require('../../res/img/GBLinkPayment/createlink_03.png')}
            alt="step1"
            style={{ width: '80%', height: 'auto' }}
          />
        </Content>

        <Sider breakpoint="md" collapsedWidth="0" style={{ background: '#fff' }} h>
          <br />
          <span style={{ color: '#02416D', fontWeight: 'bold', paddingTop: '20px' }}  >
            <Icon type="container" style={{ fontSize: '20px', color: '#08c' }} theme="outlined" />
          </span>
          <span style={{ color: '#02416D', fontWeight: 'bold', paddingTop: '20px' }}>{t("tablecontents")}</span>
          <br />
          <Anchor style={{ paddingTop: '10px' }} >
            <Anchor.Link href="#components-gblinkpayment-steptopayment" title={t("gblinkpayment:steptogenerate")} />
          </Anchor>
        </Sider>

      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
    gbLinkPayment: state.gbLinkPayment
})
const mapDispatchToProps = dispatch => ({
  updateByKey: (key, value) => dispatch(actions.updateByKey(key, value))
})
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(GBLinkPayment));
