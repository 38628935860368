import React, { Component } from 'react'
import { Row, Col, Descriptions, Table, Tabs } from 'antd'
import Prism from 'prismjs'
import PrismCode from './PrismCode'
import { withTranslation } from "react-i18next";

const { TabPane } = Tabs;

const code = `
{
  "amount": 100,
  "referenceNo": "20171128001",
  "detail": "t-shirt",
  "customerName": "John",
  "customerEmail": "example@gbprimepay.com",
  "merchantDefined1": "Promotion",
  "card": {
      "token": "94a08da1fecbb6e8b46990538c7b50b2"
  },
  "otp": "Y",
  "responseUrl": "https://merchant_url",
  "backgroundUrl": "https://merchant_url"
}
`

const phpRequest = `
<?php
  $public_key = '{public_key}';
?>
<form name="form" action="{ENV}/v2/tokens/3d_secured" method="POST">
  publicKey: <input type="text" name="publicKey" value="<?php echo $public_key; ?>" /><br>
  gbpReferenceNo: <input type="text" name="gbpReferenceNo" value="<?php echo $json['gbpReferenceNo']; ?>" />
  <button type="submit">Pay</button>
</form>
<script>
  window.onload=function(){
    document.forms["form"].submit();
  }
</script>
`


const phpResponse = `
<?php

  $respFile = fopen("resp-log.txt", "w") or die("Unable to open file!");

  $respResultCode = $_POST["resultCode"];
  fwrite($respFile, "resultCode : " . $respResultCode . "\\n");

  $respAmount = $_POST["amount"];
  fwrite($respFile, "amount : " . $respAmount . "\\n");

  $respReferenceNo = $_POST["referenceNo"];
  fwrite($respFile, "referenceNo : " . $respReferenceNo . "\\n");

  $respGbpReferenceNo = $_POST["gbpReferenceNo"];
  fwrite($respFile, "gbpReferenceNo : " . $respGbpReferenceNo . "\\n");

  $respCurrencyCode = $_POST["currencyCode"];
  fwrite($respFile, "currencyCode : " . $respCurrencyCode . "\\n");

  fclose($respFile);

?>
`

const javaResponse = `
// HttpServletRequest request
String respResultCode = request.getParameter("resultCode");
String respAmount = request.getParameter("amount");
String respReferenceNo = request.getParameter("referenceNo");
String respGbpReferenceNo = request.getParameter("gbpReferenceNo");
String respCurrencyCode = request.getParameter("currencyCode");

response.setContentType("text/html;charset=UTF-8");
try (PrintWriter out = response.getWriter()) {
    out.println("<!DOCTYPE html>");
    out.println("<html>");
    out.println("<head>");
    out.println("<title>GB</title>");            
    out.println("</head>");
    out.println("<body>");
    out.println("<p>Result Code = " + respResultCode + "</p>");
    out.println("<p>Amount = " + respAmount + "</p>");
    out.println("<p>Reference No = " + respReferenceNo + "</p>");
    out.println("<p>GBP Reference No = " + respGbpReferenceNo + "</p>");
    out.println("<p>CurrencyCode = " + respCurrencyCode + "</p>");
    out.println("</body>");
    out.println("</html>");
}
`

const codeResponse = `
{
  "resultCode": "00",
  "amount": 100.00,
  "referenceNo": "20171128001",
  "gbpReferenceNo": "gbp0001000900",
  "detail": "t-shirt",
  "customerName": "John",
  "customerEmail": "example@gbprimepay.com",
  "merchantDefined1": "Promotion",
  "currencyCode": '764',
  "date": "28112017",
  "time": "141222",
  "cardNo": "453501XXXXXX5741"
}
`

const renderFormCode = () => (
  <div>
    <PrismCode
      code={code}
      language="json"
      plugins={["line-numbers"]}
    />
  </div>
)

const renderPhpRequest = () => (
  <div>
    <PrismCode
      code={phpRequest}
      language="php"
      plugins={["line-numbers"]}
    />
  </div>
)

const renderPhpResponse = () => (
  <div>
    <PrismCode
      code={phpResponse}
      language="php"
      plugins={["line-numbers"]}
    />
  </div>
)

const renderJavaResponse = () => (
  <div>
    <PrismCode
      code={javaResponse}
      language="java"
      plugins={["line-numbers"]}
    />
  </div>
)

//export default class TDSecureApi extends Component {
class TDSecureApi extends Component {
  render() {

    const { t , i18n } = this.props;

    const columns = [
      {
        title: t("field"),
        dataIndex: 'name',
        key: 'name',
        render: text => <code>{text}</code>
      },
      {
        title: t("type"),
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: t("description"),
        dataIndex: 'address',
        key: 'address',
      }
    ];
    
    const columnsParameter = [
      {
        title: t("field"),
        dataIndex: 'name',
        key: 'name',
        render: text => <code>{text}</code>
      },
      {
        title: t("type"),
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: t("mandatory"),
        dataIndex: 'indication',
        key: 'indication',
      },
      {
        title: t("description"),
        dataIndex: 'address',
        key: 'address',
      }
    ];
    
    const dataHeader = [
      {
        key: '1',
        name: 'Content-Type',
        age: 'String',
        address: 'application/x-www-form-urlencoded'
      },
    ];
    
    const dataParameter = [
      {
        key: '0',
        name: 'publicKey',
        age: 'String (40)',
        indication:'M',
        address: t("publickeydesc"),
      },
      {
        key: '1',
        name: 'gbpReferenceNo',
        age: 'String (250)',
        indication:'M',
        address: t("gbpReferencenodesc"),
      },
    ];
    
    const dataResponse = [
      {
        key: '0',
        name: 'resultCode',
        age: 'String (2)',
        address: t("resultcodedesc")
      },
      {
        key: '1',
        name: 'amount',
        age: 'Number (10,2)',
        address: t("amountdesc")
      },
      {
        key: '2',
        name: 'referenceNo',
        age: 'String (15)',
        address: t("referencenodesc")
      },
      {
        key: '3',
        name: 'gbpReferenceNo',
        age: 'String (250)',
        address: t("gbpReferencenodesc"),
      },
      {
        key: '4',
        name: 'currencyCode',
        age: 'String (3)',
        address: t("currencycodedesc")
      }
    ];

    return (
      <div>
        {/* <p style={{ color: 'rgba(0, 0, 0, 0.85)' }}>3-D Secure Redirect URL:</p>
        <p>{`{ENV}/v2/tokens/3d_secured`} - Method POST</p>

        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)' }}>
          Call this API only if proposal 4. Charge API sends the "otp" value: "Y"</p>

        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>Header</p>
        <Table columns={columns} dataSource={dataHeader} pagination={false} bordered />

        <br />
        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>Parameter</p>
        <Table columns={columnsParameter} dataSource={dataParameter} pagination={false} bordered />

        <br />
        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>Example Code - Redirect User to 3D-Secure Page</p>
        {renderPhpRequest()}

        <br />
        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>Response data redirect to responseUrl</p>
        <Table columns={columns} dataSource={dataResponse} pagination={false} bordered />

        <br />
        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>Example Code - Response data redirect to responseUrl</p>
        <Tabs defaultActiveKey="1" onChange={this.callback}>
          <TabPane tab="PHP" key="1">
            {renderPhpResponse()}
          </TabPane>
          <TabPane tab="Java" key="2">
            {renderJavaResponse()}
          </TabPane>
        </Tabs>

        <br />
        <br /> */}

        {/* <p>{t("fullpayment3d:3dsecuredetail1")}</p> */}

        <br />

        <h2 id="creating-source-3dsecure" style={{ color: '#454545', fontWeight: 'bold', fontSize: 16 }}>{t("fullpayment3d:creatingsource")}</h2>

        <p>
          <code>publicKey</code> {' and '} <code>gbpReferenceNo</code> {'.'}
        </p>

        <br />

        <p>
          <text style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>3-D Secure Redirect URL: </text>
          <text>{`{ENV}/v2/tokens/3d_secured`} - Method POST</text>
        </p>

        <p style={{ color: 'rgba(0, 0, 0, 0.85)' }}>3-D Secure send info follow by</p>
        <p style={{ color: 'rgba(0, 0, 0, 0.85)' }}>Call this API only if proposal 4. Charge API sends the "otp" value: "Y"</p>

        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>Header</p>
        <Table columns={columns} dataSource={dataHeader} pagination={false} bordered />

        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>Parameter</p>
        <Table columns={columnsParameter} dataSource={dataParameter} pagination={false} bordered />

        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>Example Code - Redirect User to 3D-Secure Page</p>
        {renderPhpRequest()}

        <br />

        <h2 id="response-data-3dsecure" style={{ color: '#454545', fontWeight: 'bold', fontSize: 16 }}>{t("fullpayment3d:response")}</h2>
        <Table columns={columns} dataSource={dataResponse} pagination={false} bordered />

        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>Example Code - Response data redirect to responseUrl</p>
        <Tabs defaultActiveKey="1" onChange={this.callback}>
          <TabPane tab="PHP" key="1">
            {renderPhpResponse()}
          </TabPane>
          <TabPane tab="Java" key="2">
            {renderJavaResponse()}
          </TabPane>
        </Tabs>

      </div>
    );
  }
}

export default withTranslation()(TDSecureApi);
