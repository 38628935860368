import React, { Component, Fragment } from 'react'
import { Layout, Menu, Breadcrumb, Icon, Anchor } from 'antd'
import Demo from './Demo'
import CardForm from './CardForm'
import TokenApi from './TokenApi'
import ChargeApi from './ChargeApi'
import TDSecureApi from './TDSecureApi'
import ResultCode from './ResultCode'
import CreditDemo from './CreditDemo'
import WebHookCode from './WebHookCode'

import { withTranslation } from "react-i18next";

const { Content, Sider } = Layout;

// export default class Tokenization extends Component {
class Tokenization extends Component {
  render() {
    const { t , i18n } = this.props;
    return (
      <Fragment>
        {/* <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>Credit/Debit card</Breadcrumb.Item>
          <Breadcrumb.Item>Full Payment (non-3D secure)</Breadcrumb.Item>
        </Breadcrumb> */}
        {/* <Content
          style={{
            background: '#fff',
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          <h2>Full Payment (non-3D secure)</h2>
          <h3>1. Demo</h3>
          <hr />
          //<Demo />

          <CreditDemo />
          
          <br />
          <br />

          <h3>2. How it works</h3>
          <hr />
          <CardForm />

          <br />
          <br />

          //<h3>3. Token API</h3>
          //<hr />
          //<TokenApi />

          <br />
          <br />

          <h3>3. Charge API</h3>
          <hr />
          <ChargeApi />

          <br />
          <br />
          
          <h3>4. Web Hook to backgroundUrl</h3>
          <hr />
          <WebHookCode />
          <br />
          <br />

          <h3>5. Result Code</h3>
          <hr />
          <ResultCode />

        </Content> */}

        <Content
          style={{
            background: "#fff",
            padding: 24,
            margin: 0,
            minHeight: 280
          }}
        >
          <h2 style={{ color: '#02416D', fontWeight: 'bold', fontSize: 24 }}>{t("fullpaymentnon3d:titlefullpaymentnon3d")}</h2>
          <hr />
          <p>
            {t("fullpaymentnon3d:detailmessage1")} {t("fullpaymentnon3d:detailmessage2")}
          </p>

          <br />

          <h2 id="components-fullpaymentnon3d-howtoenable" style={{ color: '#454545', fontWeight: 'bold', fontSize: 20 }}>{t("fullpaymentnon3d:howtoenable")}</h2>
          {/* <p>{t("fullpaymentnon3d:howtoenabledetail1")}<a href="support@gbprimepay.com"> support@gbprimepay.com </a></p>
          <p>{t("fullpaymentnon3d:howtoenabledetail2")}</p>

          <br /> */}

          <CardForm />

          <br />

          <h2 id="components-fullpaymentnon3d-paymentflow" className="title-sub">{t("woocommerce:paymentflow")}</h2>
          <img
            src={require('../../res/img/PaymentFlow/Flow-CreditCard-(non)3DS.png')}
            alt="step1"
            style={{ width: '80%', height: 'auto' }}
          />

          <br />

          <h2 id="components-fullpaymentnon3d-steptopayment" className="title-sub">{t("fullpaymentnon3d:steptopayment")}</h2>
          <p>{t("fullpaymentnon3d:steptopaymentdetail1")} {t("fullpaymentnon3d:steptopaymentdetail2")}</p>

          <br />

          <h2 id="components-fullpaymentnon3d-demo" style={{ color: '#454545', fontWeight: 'bold', fontSize: 20 }}>{t("fullpaymentnon3d:demo")}</h2>
          {/* <p>{t("fullpaymentnon3d:demodetail1")}</p> */}
          <CreditDemo />

          <br />

          <h2 id="components-fullpaymentnon3d-chargeapi" style={{ color: '#454545', fontWeight: 'bold', fontSize: 20 }}>{t("fullpaymentnon3d:chargeapi")}</h2>
          <ChargeApi />

          <br />

          <h2 id="components-fullpaymentnon3d-webhook" style={{ color: '#454545', fontWeight: 'bold', fontSize: 20 }}>{t("fullpaymentnon3d:webhooktobg")}</h2>
          <WebHookCode />

          <br />

          <h2 id="components-fullpaymentnon3d-resultcode" style={{ color: '#454545', fontWeight: 'bold', fontSize: 20 }}>{t("fullpaymentnon3d:resultcode")}</h2>
          <ResultCode />

          <br />

          <h2 id="components-fullpaymentnon3d-limit" style={{ color: '#454545', fontWeight: 'bold', fontSize: 20 }}>{t("fullpaymentnon3d:limit")}</h2>
          {/* <p>{t("fullpaymentnon3d:limitdetail1")}</p>
          <p>{i18n.language == 'en' ? t("fullpaymentnon3d:limitdetail2") : ''}</p>  */}

          <p>{t("minimumlimits")} 1.00 (THB)</p>
          <p>{t("maximumlimits")} {t("followingcardlimit")} (THB)</p>
        </Content>

        <Sider breakpoint="md" collapsedWidth="0" style={{ background: '#fff' }} h>
          <br />
          <span style={{ color: '#02416D', fontWeight: 'bold', paddingTop: '20px' }}  >
            <Icon type="container" style={{ fontSize: '20px', color: '#08c' }} theme="outlined" />
          </span>
          <span style={{ color: '#02416D', fontWeight: 'bold', paddingTop: '20px' }}>{t("tablecontents")}</span>
          <br />
          <Anchor style={{ paddingTop: '10px' }} >
            <Anchor.Link href="#components-fullpaymentnon3d-howtoenable" title={t("fullpaymentnon3d:howtoenable")} />
            <Anchor.Link href="#components-fullpaymentnon3d-paymentflow" title={t("fullpaymentnon3d:paymentflow")} />
            <Anchor.Link href="#components-fullpaymentnon3d-steptopayment" title={t("fullpaymentnon3d:steptopayment")} />
            <Anchor.Link href="#components-fullpaymentnon3d-demo" title={t("fullpaymentnon3d:demo")} />
            <Anchor.Link href="#components-fullpaymentnon3d-chargeapi" title={t("fullpaymentnon3d:chargeapi")} >
              <Anchor.Link href="#creating-source" title={t("fullpaymentnon3d:creatingsource")} />
              <Anchor.Link href="#response-data" title={t("fullpaymentnon3d:response")} />
            </Anchor.Link>
            {/* <Anchor.Link href="#components-fullpaymentnon3d-3dsecure" title={t("fullpaymentnon3d:3dsecure")} >
              <Anchor.Link href="#creating-source-3dsecure" title={t("fullpaymentnon3d:creatingsource")} />
              <Anchor.Link href="#response-data-3dsecure" title={t("fullpaymentnon3d:response")} />
            </Anchor.Link> */}
            <Anchor.Link href="#components-fullpaymentnon3d-webhook" title={t("fullpaymentnon3d:webhook")} >
              <Anchor.Link href="#response-data-webhook" title={t("fullpaymentnon3d:response")} />
            </Anchor.Link>
            <Anchor.Link href="#components-fullpaymentnon3d-resultcode" title={t("fullpaymentnon3d:resultcode")} />
            <Anchor.Link href="#components-fullpaymentnon3d-limit" title={t("fullpaymentnon3d:limit")} />
          </Anchor>
        </Sider>

      </Fragment>
    )
  }
}

export default withTranslation()(Tokenization);