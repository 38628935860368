const initialState = { 
  loading: false,
  amount: '1.00',
  isTr: 'tr',
  mobileNumber: '0830443596',
  referenceNo: ''
};

const shopeePay = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOPEE_PAY/UPDATE_BY_KEY':
      return {
        ...state,
        [action.key]: action.value
      }
    default:
      return state
  }
}
export default shopeePay