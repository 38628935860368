import React, { Component } from 'react'
import { Button, Input, Select } from 'antd'
import get from 'lodash/get'
const { Option } = Select;

export default class TokenForm extends Component {
  onChange = e => {
    this.props.updateByKey(e.target.name, e.target.value)
  }
  onChangeType = v => {
    this.props.updateByKey('isQr', v)
  }
  onSubmit = () => {
    this.props.onSubmit()
  }
  render() {
    return (
      <form action="#" method="POST"> 
        <Input name="amount" value={this.props.data.amount}  maxLength={13} placeholder="Amount ex. 3000.00" onChange={this.onChange} readOnly /><br/>
        <Select name="isQr" defaultValue="qr" onChange={this.onChangeType}>
          <Option value="qr">Image</Option>
          <Option value="bill">Text</Option>
        </Select>
        <br /><br />
        <Button type="primary" onClick={this.onSubmit} block loading={this.props.loading}>Create QR Code</Button><br/><br/>
      </form>
    )
  }
}
