import React, { Component } from 'react'
import { Table } from 'antd'
import { withTranslation } from "react-i18next";

//export default class ResultCode extends Component {
class ResultCode extends Component {
  render() {

    const { t , i18n } = this.props;

    const columnsResultCode = [
      {
        title: 'Code',
        dataIndex: 'name',
        key: 'name',
        render: text => <code>{text}</code>
      },
      {
        title: 'Meaning',
        dataIndex: 'age',
        key: 'age',
      }
    ];
    
    const dataResultCode = [
      {
        key: '1',
        name: '00',
        age: t("recurring:success"),
      },
      {
        key: '2',
        name: '01',
        age: 'Invalid require field ("referenceNo", "processType", "recurringInterval", "recurringPeriod", "recurringAmount", "allowAccumulate", "cardToken")',
      },
      {
        key: '3',
        name: '02',
        age: 'Recurring is not "Y"',
      },
      {
        key: '4',
        name: '03',
        age: 'Duplicate "referenceNo"',
      },
      {
        key: '5',
        name: '04',
        age: 'Invalid "processType"',
      },
      {
        key: '6',
        name: '05',
        age: 'Invalid "recurringInterval"',
      },
      {
        key: '7',
        name: '06',
        age: 'Invalid "allowAccumulate"',
      },
      {
        key: '8',
        name: '07',
        age: 'Invalid "recurringPeriod"',
      },
      {
        key: '9',
        name: '08',
        age: 'Invalid "endingPeriod" because it is a subscription',
      },
      {
        key: '10',
        name: '09',
        age: 'Invalid "recurringCount" because "recurringInterval" is month',
      },
      {
        key: '11',
        name: '10',
        age: 'Invalid "recurringCount" because "recurringInterval" is quarter',
      },
      {
        key: '12',
        name: '11',
        age: 'Invalid "recurringCount" because "recurringInterval" is year',
      },
      {
        key: '13',
        name: '12',
        age: 'Invalid "endingAmount" because "recurringCount" equal 1',
      },
      {
        key: '14',
        name: '13',
        age: 'Invalid "recurringCount" because "recurringCount" less than 1',
      },
      {
        key: '15',
        name: '14',
        age: 'Invalid "recurringAmount" because "recurringAmount" less than 1',
      },
      {
        key: '16',
        name: '15',
        age: 'Invalid "recurringAmount"',
      },
      {
        key: '17',
        name: '16',
        age: 'Invalid format date of "endingPeriod"',
      },
      {
        key: '18',
        name: '17',
        age: 'Invalid "endingPeriod" format is wrong.',
      },
      {
        key: '19',
        name: '18',
        age: 'Invalid "endingPeriod"',
      },
      {
        key: '20',
        name: '19',
        age: 'Invalid "endingAmount"',
      },
      {
        key: '21',
        name: '20',
        age: 'Invalid "endingPeriod" because "recurringInterval" is month',
      },
      {
        key: '22',
        name: '21',
        age: 'Invalid "endingPeriod" because "recurringInterval" is quarter',
      },
      {
        key: '23',
        name: '22',
        age: 'Invalid "endingPeriod" because "recurringInterval" is year',
      },
      {
        key: '24',
        name: '25',
        age: t("recurring:invalidrequire"),
      },
      {
        key: '25',
        name: '26',
        age: t("recurring:nodata"),
      },
      {
        key: '26',
        name: '40',
        age: 'Invalid "cardToken"',
      },
      {
        key: '27',
        name: '41',
        age: t("recurring:paymentfail"),
      },
      {
        key: '28',
        name: '51',
        age: t("recurring:paymentfail"),
      },
      {
        key: '29',
        name: '99',
        age: t("recurring:systemmalfunction"),
      },
    ];

    return (
      <div>
        
        <Table columns={columnsResultCode} dataSource={dataResultCode} pagination={false} bordered />

      </div>
    );
  }
}

export default withTranslation()(ResultCode);