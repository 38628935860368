import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Descriptions, Table, Tabs } from 'antd'
import Prism from 'prismjs'
import get from 'lodash/get'
import * as creditCardAction from '../../actions/creditCardAction'
import PrismCode from './PrismCode'
import TokenForm from './TokenForm'
import { withTranslation } from "react-i18next";

const { TabPane } = Tabs;

const code = `
{
  "rememberCard": false,
  "card": {
      "number": "4535017710535741",
      "expirationMonth": "05",
      "expirationYear": "28",
      "securityCode": "184",
      "name": "Card Test UAT (Server Test)"
  }
}
`
const codeResponse = `
{
  "rememberCard": false,
  "resultCode": "00",
  "card": {
    "token": "b6f5b1e2-030e-401e-9abc-7de97fba501f",
    "number": "453501XXXXXX5741",
    "expirationMonth": "05",
    "expirationYear": "28",
    "name": "Card Test UAT (Server Test)",
    "securityCode": null,
    "cardLocation": "I",
    "cardType": "VIS"
  }
}`


const htmlCode = `
<form action="#" method="POST"> 
  <input id="name" type="text" maxlength="250" placeholder="Holder Name" value="{name}"><br/>
  <input id="number" type="text" maxlength="16" placeholder="Card Number" value="{card_number}"><br/>
  <input id="expirationMonth" type="text" maxlength="2" placeholder="MM" value="{expiration_month}"><br/>
  <input id="expirationYear" type="text" maxlength="2" placeholder="YY (Last Two Digits)" value="{expiration_year}"><br/>
  <input id="securityCode" type="password" maxlength="3" autocomplete="off" placeholder="CVV" value="{security_code}"><br/>
  <input id="button" type="submit" value="Pay Now">
</form>
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
<script>
  document.getElementById("button").addEventListener("click", function(event){
    event.preventDefault();
    var publicKey = "{public_key}";
    var dataReq = {
      "rememberCard": false,
      "card": {
          "name": "{name}",
          "number": "{card_number}",
          "expirationMonth": "{expiration_month}",
          "expirationYear": "{expiration_year}",
          "securityCode": "{security_code}"
      }
    };
    $.ajax({
        type: "POST",
        url: "{ENV}/v2/tokens",       // Test URL: https://api.globalprimepay.com/v2/tokens , Production URL: https://api.gbprimepay.com/v2/tokens
        data: JSON.stringify(dataReq),
        contentType: "application/json",
        dataType: "json",
        headers: {
          "Authorization": "Basic " + btoa(publicKey + ":")
        },
        success: function(dataResp){
          var dataStr = JSON.stringify(dataResp);
          alert(dataStr);
        },
        failure: function(errMsg) {
          alert(errMsg);
        }
    });
  });
</script>
`

const phpCode = `
<form class="row g-3"action="<?php $_SERVER['PHP_SELF']?>" method="post">              
  <div class="col-md-6">
      <label for="cardNumber" class="form-label">CardNumber</label>
      <input type="text" class="form-control" id="cardNumber" name="cardNumber" value=''/>
  </div>
  <div class="col-md-6">
      <label for="expirationMonth" class="form-label">ExpirationMonth</label>
      <input type="text" class="form-control" id="expirationMonth" name="expirationMonth" value=''/>
  </div>
  <div class="col-md-6">
      <label for="expirationYear" class="form-label">ExpirationYear</label>
      <input type="text" class="form-control" id="expirationYear" name="expirationYear" value=''/>
  </div>
  <div class="col-md-6">
      <label for="securityCode" class="form-label">SecurityCode</label>
      <input type="text" class="form-control" id="securityCode" name="securityCode" value=''/>
  </div>
  <button type="submit" class="btn btn-primary">Gen Token</button>
</form>

<?php  
    if ($_SERVER["REQUEST_METHOD"] == "POST") {
      $data = array(
          "rememberCard" => false,
          "card" => array (
              "number" => $_POST['cardNumber'],
              "expirationMonth" => $_POST['expirationMonth'],
              "expirationYear" => $_POST['expirationYear'],
              "securityCode" => $_POST['securityCode'],
              "name" => "Card Test UAT (Server Test)"
          )
      );
      $payload = json_encode($data);
      $url = '{ENV}/v2/tokens';  // Test URL: https://api.globalprimepay.com/v2/tokens , Production URL: https://api.gbprimepay.com/v2/tokens
      $configkey = "{public_key}";  
      $key = base64_encode("{$configkey}".":");  
      $request_headers = array(
          "Accept: application/json",
          "Authorization: Basic {$key}",
          "Cache-Control: no-cache",
          "Content-Type: application/json",
      );
      $ch = curl_init($url);
      curl_setopt($ch, CURLOPT_POST, 1);
      curl_setopt($ch, CURLOPT_POSTFIELDS, $payload);
      curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
      curl_setopt($ch, CURLINFO_HEADER_OUT, true);
      curl_setopt($ch, CURLOPT_ENCODING, "");
      curl_setopt($ch, CURLOPT_TIMEOUT, 120);
      curl_setopt($ch, CURLOPT_SSL_VERIFYHOST, 2);
      curl_setopt($ch, CURLOPT_SSL_VERIFYPEER, 0);
      curl_setopt($ch, CURLOPT_CUSTOMREQUEST, "POST");
      curl_setopt($ch, CURLOPT_HTTPHEADER, $request_headers);      
      $body = curl_exec($ch);
      $json = json_decode($body, true);
      curl_close($ch);
      //Show Response
      echo "<pre>";            
      print_r($json);
    }
?>
`
const renderFormCode = () => (
  <div>
    <PrismCode
      code={code}
      language="json"
      plugins={["line-numbers"]}
    />
  </div>
)

const renderFormCodeResponse = () => (
  <div>
    <PrismCode
      code={codeResponse}
      language="json"
      plugins={["line-numbers"]}
    />
  </div>
)

const renderPhpCode = () => (
  <div>
    <PrismCode
      code={phpCode}
      language="php"
      plugins={["line-numbers"]}
    />
  </div>
)

const renderFormHtml = data => {
  let _htmlCode = htmlCode.replace(/{card_number}/g, data.cardNumber);
  _htmlCode = _htmlCode.replace(/{name}/g, data.name);
  _htmlCode = _htmlCode.replace(/{expiration_month}/g, data.expirationMonth);
  _htmlCode = _htmlCode.replace(/{expiration_year}/g, data.expirationYear);
  _htmlCode = _htmlCode.replace(/{security_code}/g, data.securityCode);
  return (
    <div>
      <PrismCode
        code={_htmlCode}
        language="html"
        plugins={["line-numbers"]}
      />
    </div>
  )
}



class TokenApi extends Component {
  onSubmit = () => {
    this.props.generateToken()
  }
  render() {
    const { t , i18n } = this.props;

    const columns = [
      {
        title: t("field"),
        dataIndex: 'name',
        key: 'name',
        render: text => <code>{text}</code>
      },
      {
        title: t("type"),
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: t("description"),
        dataIndex: 'address',
        key: 'address',
      }
    ];
    const columnsParameter = [
      {
        title: 'Field',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Type',
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: 'Mandatory',
        dataIndex: 'indication',
        key: 'indication',
      },
      {
        title: 'Description',
        dataIndex: 'address',
        key: 'address',
      }
    ];
    
    const dataHeader = [
      {
        key: '1',
        name: 'Authorization',
        age: 'String',
        indication:'',
        address: `"Basic " + Base64Encode({public_key} + ":")`
      },
    ];
    
    const dataParameter = [
      {
        key: '1',
        name: 'rememberCard',
        age: 'Boolean',
        indication:'M',
        address: t("remembercarddesc")
      },
      {
        key: '2',
        name: 'card',
        dataIndex: 'name', 
      },
      {
        key: '3',
        name: 'number',
        age: 'String (16)',
        indication:'M',
        address: t("cardnumberdesc")
      },
      {
        key: '4',
        name: 'expirationMonth',
        age: 'String (2)',
        indication:'M',
        address: t("expirationyonthdesc")
      },
      {
        key: '5',
        name: 'expirationYear',
        age: 'String (2)',
        indication:'M',
        address: t("expirationyear")
      },
      {
        key: '6',
        name: 'securityCode',
        age: 'String (3)',
        indication:'M',
        address: t("securitycodedesc")
      },
      {
        key: '7',
        name: 'name',
        age: 'String (250)',
        indication:'O',
        address: t("namedesc")
      },
    ];
    
    const dataResponse = [
      {
        key: '1',
        name: 'rememberCard',
        age: 'Boolean',
        address: t("remembercarddesc")
      },{
        key: '2',
        name: 'resultCode',
        age: 'String (2)',
        address: t("tokenapi:responseresultcodedesc")
      },
      {
        key: '3',
        name: 'card',
        age: 'Object',
        address: ``
      },
      {
        key: '4',
        name: 'token',
        age: 'String (250)',
        address: t("tokendesc")
      },
      {
        key: '5',
        name: 'number',
        age: 'String (16)',
        address: t("cardnumberdesc")
      },
      {
        key: '6',
        name: 'expirationMonth',
        age: 'String (2)',
        address: t("expirationyonthdesc")
      },
      {
        key: '7',
        name: 'expirationYear',
        age: 'String (2)',
        address: t("expirationyear")
      },
      {
        key: '8',
        name: 'name',
        age: 'String (250)',
        address: t("namedesc")
      },
      {
        key: '9',
        name: 'securityCode',
        age: 'String (3)',
        address: t("securitycodedesc")
      }, 
      {
        key: '10',
        name: 'cardLocation',
        age: 'String (250)',
        address: t("cardLocationdesc")
      },
      {
        key: '11',
        name: 'cardType',
        age: 'String (3)',
        address: t("cardtype2desc")
      }, 
    ];
    
    const renderContent = (value, row, index) => {
      const obj = {
        children: value,
        props: {},
      };
      if (index === 1) {
        obj.props.colSpan = 0;
      }
      return obj;
    };
    
    const columns__1 = [{
      title: t("field"),
      dataIndex: 'name',
      render: (value, row, index) => {
        if (index != 1) {
          return <code>{value}</code>;
        }
        return {
          children: value,
          props: {
            colSpan: 4,
          },
        };
      },
    }, {
      title: t("type"),
      dataIndex: 'age',
      key: 'age',
      render: renderContent,
      
    },
    {
      title: t("mandatory"),
      dataIndex: 'indication',
      key: 'indication',
      render: renderContent,
    },
    {
      title: t("description"),
      dataIndex: 'address',
      key: 'address',
      render: renderContent,
    }];
    
    const renderContent_2 = (value, row, index) => {
      const obj = {
        children: value,
        props: {},
      };
      if (index === 2) {
        obj.props.colSpan = 0;
      }
      return obj;
    };
    const columns__2 = [{
      title: t("field"),
      dataIndex: 'name',
      render: (value, row, index) => {
        if (index != 2) {
          return <code>{value}</code>;;
        }
        return {
          children: value,
          props: {
            colSpan: 4,
          },
        };
      },
    }, {
      title: t("type"),
      dataIndex: 'age',
      key: 'age',
      render: renderContent_2,
      
    },
    {
      title: t("description"),
      dataIndex: 'address',
      key: 'address',
      render: renderContent_2,
    }];

    return (
      // <div>
      //   <p style={{ color: 'rgba(0, 0, 0, 0.85)' }}>Token API URL:</p>
      //   <p>{`{ENV}/v2/tokens`} - Method POST</p>

      //   <br />

      //   <p className="font-bold">Header</p>
      //   <Table columns={columns} dataSource={dataHeader} pagination={false} bordered />

      //   <br />
      //   <br />

      //   <p style={{color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>Parameter</p>
      //   <Table columns={columns__1} dataSource={dataParameter} pagination={false} bordered />
      //   {/* <Table columns={columnsParameter} dataSource={dataParameter} pagination={false} bordered  /> */}
        
      //   <br />
      //   <br />

      //   <p className="font-bold">Request Example</p>
      //   {renderFormCode()}

      //   <br />
      //   <br />

      //   <p className="font-bold">Response</p>
      //   <Table columns={columns__2} dataSource={dataResponse} pagination={false} bordered />

      //   <br />
      //   <br />

      //   <p className="font-bold">Response Example</p>
      //   {renderFormCodeResponse()}

      //   <br />
      //   <br />

      //   <p className="font-bold">Example Code</p>
      //   {renderFormHtml(this.props.creditCard)}

      // </div>

      <div>
        
        <h2 id="components-tokenapi-implementation" className="title-sub">{t("tokenapi:implementation")}</h2>
        {/* <p>{t("tokenapi:implementationdetail1")}</p> */}

        {/* <br /> */}

        <h2 id="creating-source" className="title-sub2">{t("tokenapi:creatingsource")}</h2>
        <p>{t("tokenapi:creatingsourcedetail1")}</p>
        <p>
          <code>rememberCard</code> {' , '} <code>number</code> {' , '} <code>expirationMonth</code> {' , '} 
          <code>expirationYear</code> {' , '} <code>securityCode</code> {' and '} <code>name</code> 
        </p>

        <br />

        <p>
          <text className="font-bold">Token API URL: </text>
          <text>{`{ENV}/v2/tokens`} - Method POST</text>
        </p>

        <br />

        <p className="font-bold">Header</p>
        <Table columns={columns} dataSource={dataHeader} pagination={false} bordered />

        <br />

        <p className="font-bold">Parameter</p>
        <Table columns={columns__1} dataSource={dataParameter} pagination={false} bordered />

        <br />

        <p className="font-bold">JSON Request</p>
        {renderFormCode()}

        <br />

        <h2 id="response-data" className="title-sub2">{t("tokenapi:responseheader")}</h2>
        <Table columns={columns__2} dataSource={dataResponse} pagination={false} bordered />

        <br />
        <br />

        <p className="font-bold">{t("tokenapi:jsonresponse")}</p>
        {renderFormCodeResponse()}

        <br />
        <br />

        <p className="font-bold">Example Code</p>
          <Tabs defaultActiveKey="1" onChange={this.callback}>
            <TabPane tab="Javascript" key="1">
              {renderFormHtml(this.props.creditCard)}
            </TabPane>
            <TabPane tab="PHP" key="2">
              {renderPhpCode()}
            </TabPane>
            
          </Tabs>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  creditCard: state.creditCard
})
const mapDispatchToProps = dispatch => ({
  updateByKey: (key, value) => dispatch(creditCardAction.updateByKey(key, value)),
  generateToken: () => dispatch(creditCardAction.generateToken()),
})
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(TokenApi)
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(TokenApi));