import React, { Component, Fragment } from "react";
import { Layout, Menu, Breadcrumb, Icon, Anchor } from "antd";
import Demo from "./Demo";
import CardForm from "./CardForm";
import TokenApi from "./TokenApi";
import ChargeApi from "./ChargeApi";
import TDSecureApi from "./TDSecureApi";
import ResultCode from "./ResultCode";
import CreditDemo from './CreditDemo';
import WebHookCode from './WebHookCode';
import { withTranslation } from "react-i18next";

const { Content, Sider } = Layout;

//export default class Tokenization extends Component {
class Tokenization extends Component {
  render() {
    console.log(">>>", this.props.location.pathname);
    const { t , i18n } = this.props;
    return (
      <Fragment>
        {/* <Breadcrumb style={{ margin: "16px 0" }}>
          <Breadcrumb.Item>Credit/Debit card</Breadcrumb.Item>
          <Breadcrumb.Item>Full Payment (3D secure)</Breadcrumb.Item>
        </Breadcrumb> */}
        {/* <Content
          style={{
            background: "#fff",
            padding: 24,
            margin: 0,
            minHeight: 280
          }}
        >
          <h2>Full Payment (3D secure)</h2>
          <h3>1. Demo</h3>
          <hr />
          //<Demo />

          //<br />

          <CreditDemo />

          <br />
          <br />

          <h3>2. How it works</h3>
          <hr />
          <CardForm />

          <br />
          <br />

          //<h3>3. Token API</h3>
          //<hr />
          //<TokenApi />

          <br />
          <br />

          <h3>3. Charge API</h3>
          <hr />
          <ChargeApi />

          <br />
          <br />

          <h3>4. 3-D Secure</h3>
          <hr />
          <TDSecureApi />

          <br />
          <br />

          <h3>5. Web Hook to backgroundUrl</h3>
          <hr />
          <WebHookCode />
          <br />
          <br />

          <h3>6. Result Code</h3>
          <hr />
          <ResultCode />
        </Content> */}

        <Content
          style={{
            background: "#fff",
            padding: 24,
            margin: 0,
            minHeight: 280
          }}
        >
          <h2 className="title">{t("fullpayment3d:titlefullpayment3d")}</h2>
          <hr />
          <p>
            {t("fullpayment3d:detailmessage1")} {t("fullpayment3d:detailmessage2")}
          </p>

          <br />

          <h2 id="components-fullpayment3d-howtoenable" className="title-sub">{t("fullpayment3d:howtoenable")}</h2>
          {/* <p>{t("fullpayment3d:howtoenabledetail1")}<a href="support@gbprimepay.com"> support@gbprimepay.com </a></p>
          <p>{t("fullpayment3d:howtoenabledetail2")}</p> */}

          <br />

          <CardForm />

          <br />

          <h2 id="components-fullpayment3d-paymentflow" className="title-sub">{t("fullpayment3d:paymentflow")}</h2>
          <img
            src={require('../../res/img/PaymentFlow/Flow-CreditCard-3DS.png')}
            alt="step1"
            style={{ width: '80%', height: 'auto' }}
          />

          <br />

          <h2 id="components-fullpayment3d-steptopayment" className="title-sub">{t("fullpayment3d:steptopayment")}</h2>
          <p>{t("fullpayment3d:steptopaymentdetail1")} {t("fullpayment3d:steptopaymentdetail2")}</p>

          <br />

          <h2 id="components-fullpayment3d-demo" className="title-sub">{t("fullpayment3d:demo")}</h2>
          {/* <p>{t("fullpayment3d:demodetail1")}</p> */}
          <CreditDemo />

          <br />

          <h2 id="components-fullpayment3d-chargeapi" className="title-sub">{t("fullpayment3d:chargeapi")}</h2>
          <ChargeApi />

          <br />

          <h2 id="components-fullpayment3d-3dsecure" className="title-sub">{t("fullpayment3d:3dsecure")}</h2>
          <TDSecureApi />

          <br />

          <h2 id="components-fullpayment3d-webhook" className="title-sub">{t("fullpayment3d:webhooktobg")}</h2>
          <WebHookCode />

          <br />

          <h2 id="components-fullpayment3d-resultcode" className="title-sub">{t("fullpayment3d:resultcode")}</h2>
          <ResultCode />

          <br />

          <h2 id="components-fullpayment3d-limit" className="title-sub">{t("fullpayment3d:limit")}</h2>
          {/* <p>{t("fullpayment3d:limitdetail1")}</p>
          <p>{t("fullpayment3d:limitdetail2")}</p> */}

          <p>{t("minimumlimits")} 1.00 (THB)</p>
          <p>{t("maximumlimits")} {t("followingcardlimit")} (THB)</p>

          {/* <p>{i18n.language == 'en' ? t("fullpayment3d:limitdetail2") : ''}</p>  */}
        </Content>

        <Sider breakpoint="md" collapsedWidth="0" style={{ background: '#fff' }} >
          <br />
          <span style={{ color: '#02416D', fontWeight: 'bold', paddingTop: '20px' }}  >
            <Icon type="container" style={{ fontSize: '20px', color: '#08c' }} theme="outlined" />
          </span>
          <span style={{ color: '#02416D', fontWeight: 'bold', paddingTop: '20px' }}>{t("tablecontents")}</span>
          <br />
          <Anchor style={{ paddingTop: '10px' }} >
            <Anchor.Link href="#components-fullpayment3d-howtoenable" title={t("fullpayment3d:howtoenable")} />
            <Anchor.Link href="#components-fullpayment3d-paymentflow" title={t("fullpayment3d:paymentflow")} />
            <Anchor.Link href="#components-fullpayment3d-steptopayment" title={t("fullpayment3d:steptopayment")} />
            <Anchor.Link href="#components-fullpayment3d-demo" title={t("fullpayment3d:demo")} />
            <Anchor.Link href="#components-fullpayment3d-chargeapi" title={t("fullpayment3d:chargeapi")} >
              <Anchor.Link href="#creating-source" title={t("fullpayment3d:creatingsource")} />
              <Anchor.Link href="#response-data" title={t("fullpayment3d:response")} />
            </Anchor.Link>
            <Anchor.Link href="#components-fullpayment3d-3dsecure" title={t("fullpayment3d:3dsecure")} >
              <Anchor.Link href="#creating-source-3dsecure" title={t("fullpayment3d:creatingsource")} />
              <Anchor.Link href="#response-data-3dsecure" title={t("fullpayment3d:response")} />
            </Anchor.Link>
            <Anchor.Link href="#components-fullpayment3d-webhook" title={t("fullpayment3d:webhook")} >
              <Anchor.Link href="#response-data-webhook" title={t("fullpayment3d:response")} />
            </Anchor.Link>
            <Anchor.Link href="#components-fullpayment3d-resultcode" title={t("fullpayment3d:resultcode")} />
            <Anchor.Link href="#components-fullpayment3d-limit" title={t("fullpayment3d:limit")} />
          </Anchor>
        </Sider>

      </Fragment>
    );
  }
}

export default withTranslation()(Tokenization);
