import React, { Component } from 'react'
import { Button, Input, Select } from 'antd'
import get from 'lodash/get'
const { Option } = Select;

export default class TokenForm extends Component {
  onChange = e => {
    this.props.updateByKey(e.target.name, e.target.value)
  }
  onChangeType = v => {
    this.props.updateByKey('isMb', v)
  }
  onSubmit = () => {
    this.props.onSubmit()
  }
  render() {
    return (
      <form action="#" method="POST"> 
        <Input name="amount" value={this.props.data.amount}  maxLength={13} placeholder="Amount ex. 3000.00" onChange={this.onChange} readOnly /><br/>
        <Select name="isMb" defaultValue="kBank" onChange={this.onChangeType}>
        <Option value="004">kBank</Option>
          <Option value="025">Krungsri</Option>
          <Option value="014">SCB</Option>
          <Option value="002">BBL</Option>
          <Option value="006">KTB</Option>
        </Select>
         <br/><br/>
              <Button type="primary" onClick={this.onSubmit} block loading={this.props.loading}>Confirm Phone Number</Button><br/><br/>
      </form>
    )
  }
}
