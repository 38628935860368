import React, { Component, Fragment } from 'react'
import { Layout, Menu, Breadcrumb, Icon, Anchor } from 'antd'
import TokenApi from "./TokenApi";
import TokenDemo from './TokenDemo'
import { withTranslation } from "react-i18next";

const { Content, Sider } = Layout;

// export default class Tokenization extends Component {
class Tokenization extends Component {
  render() {
    console.log('>>>', this.props.location.pathname)
    const { t , i18n } = this.props;
    return (
      <Fragment>
        {/* <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>Credit/Debit card</Breadcrumb.Item>
          <Breadcrumb.Item>Token API</Breadcrumb.Item>
        </Breadcrumb> */}
        {/* <Content
          style={{
            background: '#fff',
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          <h2>Token API</h2>
          <hr />
          <TokenApi />
          <br />
          <TokenDemo />
        </Content> */}

        <Content
          style={{
            background: '#fff',
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          <h2 className="title">{t("tokenapi:titletokenapi")}</h2>
          <hr />
          <p>
            {t("tokenapi:detailmessage1")}
          </p>

          <br />
          <TokenApi />
          <br />
          <TokenDemo />

          {/* <br />

          <h2 id="components-tokenapi-limit" style={{ color: '#454545', fontWeight: 'bold', fontSize: 20 }}>{t("tokenapi:limit")}</h2>
          <p>{t("tokenapi:limitdetail1")}</p>
          <p>{i18n.language == 'en' ? t("tokenapi:limitdetail2") : ''}</p> */}
        </Content>

        <Sider breakpoint="md" collapsedWidth="0" style={{ background: '#fff' }} h>
          <br />
          <span style={{ color: '#02416D', fontWeight: 'bold', paddingTop: '20px' }}  >
            <Icon type="container" style={{ fontSize: '20px', color: '#08c' }} theme="outlined" />
          </span>
          <span style={{ color: '#02416D', fontWeight: 'bold', paddingTop: '20px' }}>{t("tablecontents")}</span>
          <br />
          <Anchor style={{ paddingTop: '10px' }} >
            {/* <Anchor.Link href="#components-tokenapi-howtoenable" title={t("tokenapi:howtoenable")} />
            <Anchor.Link href="#components-tokenapi-paymentflow" title={t("tokenapi:paymentflow")} />
            <Anchor.Link href="#components-tokenapi-steptopayment" title={t("tokenapi:steptopayment")} /> */}
            <Anchor.Link href="#components-tokenapi-implementation" title={t("tokenapi:implementation")} >
              <Anchor.Link href="#creating-source" title={t("tokenapi:creatingsource")} />
              {/* <Anchor.Link href="#creating-charge" title={t("tokenapi:creatingcharge")} /> */}
              <Anchor.Link href="#response-data" title={t("tokenapi:response")} />
            </Anchor.Link>
            {/* <Anchor.Link href="#components-tokenapi-voidrefund" title={t("tokenapi:voidrefunds")} /> */}
            {/* <Anchor.Link href="#components-tokenapi-limit" title={t("tokenapi:limit")} /> */}
          </Anchor>
        </Sider>
        
      </Fragment>
    )
  }
}
export default withTranslation()(Tokenization);
