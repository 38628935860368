import React, { Component, Fragment } from 'react'
import { Layout, Menu, Breadcrumb, Icon, Descriptions, Anchor } from 'antd'

import { withTranslation } from "react-i18next";

const { Content, Sider } = Layout;

//export default class GettingStarted extends Component {
class GettingStarted extends Component {
  render() {
    console.log(">>>", this.props.location.pathname);
    const { t , i18n } = this.props;
    return (
      <Fragment>
        {/* <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>Getting started</Breadcrumb.Item>
        </Breadcrumb> */}

        {/* <Content
          style={{
            background: '#fff',
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          <h2>Getting started</h2>
          <hr />
          <p>
          {`Welcome to GB Prime Pay developer section. This section you will find our API documents. To get started on our UAT and production, please register (`}<a href="https://www.gbprimepay.com/register">https://www.gbprimepay.com/register</a>{`) for our services. If you already have an account, For the first time, you can get your connection keys (public key and secret key) from steps below or check in email register after approved. Please noted that GB Prime Pay only only accepts HTTPS/TLS connections.`}
          </p>
          <br />
          <Descriptions title="Environment" size={'default'}>
            <Descriptions.Item label="Production URL:">https://api.gbprimepay.com/</Descriptions.Item>
            <Descriptions.Item label="Test URL:">https://api.globalprimepay.com/</Descriptions.Item>
          </Descriptions>
        </Content>

        <Content
          style={{
            background: '#fff',
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          <h2>The process of pressing the key to connect API</h2>
          <hr />
          <Descriptions title="1. Login." size={'default'}>
            <Descriptions.Item label="Production URL:">https://www.gbprimepay.com/</Descriptions.Item>
            <Descriptions.Item label="Test URL:">https://www.globalprimepay.com/</Descriptions.Item>
          </Descriptions>
          <br />
          <Descriptions.Item ><img src="./GB_Show Key-03.jpeg" width="85%"></img></Descriptions.Item>
          <br />
          <br />
          <br />
          <Descriptions title="2. Click menu Profile." size={'default'}>
            <Descriptions.Item ><img src="./GB_Show Key-04.jpeg" width="85%"></img></Descriptions.Item>
          </Descriptions>
          <br />
          <Descriptions title="3. Click Show Key." size={'default'}>
            <Descriptions.Item ><img src="./GB_Show Key-05.jpeg" width="85%"></img></Descriptions.Item>
          </Descriptions>
          <br />
          <Descriptions title="4. For the first time, please create PIN." size={'default'}>
            <Descriptions.Item ><img src="./GB_Show Key-06.jpeg" width="85%"></img></Descriptions.Item>
          </Descriptions>
          <br />
          <Descriptions title="5. Enter PIN." size={'default'}>
            <Descriptions.Item ><img src="./GB_Show Key-07.jpeg" width="85%"></img></Descriptions.Item>
          </Descriptions>
          <br />
          <Descriptions title="6. Show key detail." size={'default'}>
            <Descriptions.Item ><img src="./GB_Show Key-08.jpeg" width="85%"></img> </Descriptions.Item>
          </Descriptions>

        </Content> */}

        <Content
          style={{
            background: '#fff',
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          <h2 className="title">{t("gettingstarted:titlegettingstarted")}</h2>
          <hr />

          <h2 id="components-gettingstarted-about" className="title-sub">{t("gettingstarted:about")}</h2>
          <p>{t("gettingstarted:aboutdetail1")}</p>

          <br />

          <h2 id="components-gettingstarted-gettingstarted" className="title-sub">{t("gettingstarted:gettingstarted")}</h2>
          <p>
            {t("gettingstarted:gettingstarteddetail1")}
            {t("gettingstarted:gettingstarteddetail2")}<a href="https://www.gbprimepay.com/register" target="_blank">https://www.gbprimepay.com/register</a>{t("gettingstarted:gettingstarteddetail3")}
            {t("gettingstarted:gettingstarteddetail4")}
            {t("gettingstarted:gettingstarteddetail5")}
          </p>
          
          {/* <p>
          {`Welcome to GB Prime Pay developer section. This section you will find our API documents. To get started on our UAT and production, please register (`}<a href="https://www.gbprimepay.com/register">https://www.gbprimepay.com/register</a>{`) for our services. If you already have an account, For the first time, you can get your connection keys (public key and secret key) from steps below or check in email register after approved. Please noted that GB Prime Pay only only accepts HTTPS/TLS connections.`}
          </p> */}

          <br />
          <h2 id="creating-register" className="title-sub2">{t("gettingstarted:stepconnectapititle")}</h2>
    
          <br />
          <Descriptions title="Environment" size={'default'} >
            <Descriptions.Item label="Production URL">https://api.gbprimepay.com/</Descriptions.Item>
            <Descriptions.Item label="Test URL">https://api.globalprimepay.com/</Descriptions.Item>
          </Descriptions>

          {/* merge */}
          {/* <h2>The process of pressing the key to connect API</h2>
          <hr /> */}
          <Descriptions title={t("gettingstarted:titlelogin")} size={'default'}>
            <Descriptions.Item label="Production URL">https://www.gbprimepay.com/</Descriptions.Item>
            <Descriptions.Item label="Test URL">https://www.globalprimepay.com/</Descriptions.Item>
          </Descriptions>
          <br />
          <Descriptions.Item ><img src="./GB_Show Key-03.jpeg" width="85%"></img></Descriptions.Item>
          <br />
          <br />
          <br />
          <Descriptions title={t("gettingstarted:titleprofile")} size={'default'}>
            <Descriptions.Item ><img src="./GB_Show Key-04.jpeg" width="85%"></img></Descriptions.Item>
          </Descriptions>
          <br />
          <Descriptions title={t("gettingstarted:titlecreatepin")} size={'default'}>
            <Descriptions.Item ><img src="./GB_Show Key-05.jpeg" width="85%"></img></Descriptions.Item>
          </Descriptions>
          <br />
          <Descriptions title={t("gettingstarted:titleshowkey")} size={'default'}>
            <Descriptions.Item ><img src="./GB_Show Key-06.jpeg" width="85%"></img></Descriptions.Item>
          </Descriptions>
          <br />
          <Descriptions title={t("gettingstarted:titleenterpin")} size={'default'}>
            <Descriptions.Item ><img src="./GB_Show Key-07.jpeg" width="85%"></img></Descriptions.Item>
          </Descriptions>
          <br />
          <Descriptions title={t("gettingstarted:titleshowkeydetail")} size={'default'}>
            <Descriptions.Item ><img src="./GB_Show Key-08.jpeg" width="85%"></img> </Descriptions.Item>
          </Descriptions>

          <br />

          <h2 id="components-gettingstarted-paymentmethods" className="title-sub">{t("gettingstarted:paymentmethods")}</h2>
          <p>{t("gettingstarted:paymentmethodsdetail1")}</p>
          <img src="./GB_Payment Methods.png" width="85%"></img>

          <br />
          <br />

          <h2 id="components-gettingstarted-transfertobank" className="title-sub">{t("gettingstarted:transfertobank")}</h2>
          <p>{t("gettingstarted:transfertobankdetail1")}</p>
          <p>{t("gettingstarted:transfertobankdetail2")}</p>
          <p>{t("gettingstarted:transfertobankdetail3")}</p>

          <br />

          <h2 id="components-gettingstarted-voidrefund" className="title-sub">{t("gettingstarted:voidrefund")}</h2>
          <p>{t("gettingstarted:voidandrefundsdetail1")}</p>
          <p>{t("gettingstarted:voidandrefundsdetail2")}</p>
          <p>{t("gettingstarted:voidandrefundsdetail3")}</p>

          <br />

          <h2 id="components-gettingstarted-help" className="title-sub">{t("gettingstarted:help")}</h2>
          <p>{t("gettingstarted:helpdetail1")} <a href="https://www.gbprimepay.com/th/contactus" target="_blank">GB Prime Pay</a></p>


        </Content>

        {/* <Content
          style={{
            background: '#fff',
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          <h2>The process of pressing the key to connect API</h2>
          <hr />
          <Descriptions title="1. Login." size={'default'}>
            <Descriptions.Item label="Production URL:">https://www.gbprimepay.com/</Descriptions.Item>
            <Descriptions.Item label="Test URL:">https://www.globalprimepay.com/</Descriptions.Item>
          </Descriptions>
          <br />
          <Descriptions.Item ><img src="./GB_Show Key-03.jpeg" width="85%"></img></Descriptions.Item>
          <br />
          <br />
          <br />
          <Descriptions title="2. Click menu Profile." size={'default'}>
            <Descriptions.Item ><img src="./GB_Show Key-04.jpeg" width="85%"></img></Descriptions.Item>
          </Descriptions>
          <br />
          <Descriptions title="3. Click Show Key." size={'default'}>
            <Descriptions.Item ><img src="./GB_Show Key-05.jpeg" width="85%"></img></Descriptions.Item>
          </Descriptions>
          <br />
          <Descriptions title="4. For the first time, please create PIN." size={'default'}>
            <Descriptions.Item ><img src="./GB_Show Key-06.jpeg" width="85%"></img></Descriptions.Item>
          </Descriptions>
          <br />
          <Descriptions title="5. Enter PIN." size={'default'}>
            <Descriptions.Item ><img src="./GB_Show Key-07.jpeg" width="85%"></img></Descriptions.Item>
          </Descriptions>
          <br />
          <Descriptions title="6. Show key detail." size={'default'}>
            <Descriptions.Item ><img src="./GB_Show Key-08.jpeg" width="85%"></img> </Descriptions.Item>
          </Descriptions>
        </Content>  */}

        <Sider breakpoint="md" collapsedWidth="0" style={{ background: '#fff' }} h>
          <br />
          <span style={{ color: '#02416D', fontWeight: 'bold', paddingTop: '20px' }}  >
            <Icon type="container" style={{ fontSize: '20px', color: '#08c' }} theme="outlined" />
          </span>
          <span style={{ color: '#02416D', fontWeight: 'bold', paddingTop: '20px' }}>{t("tablecontents")}</span>
          <br />
          <Anchor style={{ paddingTop: '10px' }} >
            <Anchor.Link href="#components-gettingstarted-about" title={t("gettingstarted:about")} />
            <Anchor.Link href="#components-gettingstarted-gettingstarted" title={t("gettingstarted:gettingstarted")}>
              <Anchor.Link href="#creating-register" title={t("gettingstarted:stepconnectapi")}/>
            </Anchor.Link>
            <Anchor.Link href="#components-gettingstarted-paymentmethods" title={t("gettingstarted:paymentmethods")} />
            <Anchor.Link href="#components-gettingstarted-transfertobank" title={t("gettingstarted:transfertobank")} />
            <Anchor.Link href="#components-gettingstarted-voidrefund" title={t("gettingstarted:voidrefund")} />
            <Anchor.Link href="#components-gettingstarted-help" title={t("gettingstarted:help")} />
          </Anchor>
        </Sider>

      </Fragment>
    )
  }
}

export default withTranslation()(GettingStarted);