import React, { Component } from 'react'
import { Table, Tabs } from 'antd'
import PrismCode from './PrismCode'

const { TabPane } = Tabs;

const renderPhpResponse = () => (
  <div>
    <PrismCode
      code={phpResponse}
      language="php"
      plugins={["line-numbers"]}
    />
  </div>
)

const phpResponse = `
<?php
    $respFile = fopen("bg-log_qr.txt", "w") or die("Unable to open file!");

    $json_str = file_get_contents('php://input');
    fwrite($respFile, $json_str . "\\n\\n");
    $json_obj = json_decode($json_str);

    fwrite($respFile, "resultCode=" . $json_obj->resultCode . "\\n");
    fwrite($respFile, "amount=" . $json_obj->amount . "\\n");
    fwrite($respFile, "referenceNo=" . $json_obj->referenceNo . "\\n");
    fwrite($respFile, "gbpReferenceNo=" . $json_obj->gbpReferenceNo . "\\n");
    fwrite($respFile, "currencyCode=" . $json_obj->currencyCode . "\\n");
    fwrite($respFile, "totalAmount=" . $json_obj->totalAmount . "\\n");
    fwrite($respFile, "thbAmount=" . $json_obj->thbAmount . "\\n");

    fclose($respFile);

    echo "ok";

?>
`

const columnsResultCode = [
  {
    title: 'Field',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Type',
    dataIndex: 'age',
    key: 'age',
  },
  {
    title: 'Description',
    dataIndex: 'address',
    key: 'address',
  }
];

const dataResultCode = [
  {
    key: '0',
    name: 'amount',
    age: 'NUMERIC (10,2)',
    address: 'For example: Amount with decimal = 00 (Ex. 15.00)'
  },
  {
    key: '1',
    name: 'referenceNo',
    age: 'String (15)',
    address: 'Merchant Reference'
  },
  {
    key: '2',
    name: 'gbpReferenceNo',
    age: 'String (15)',
    address: 'GB Prime Pay Reference'
  },
  {
    key: '3',
    name: 'currencyCode',
    age: 'String (3)',
    address: 'Currency codes - ISO 4217 (764=Baht)'
  },
  {
    key: '4',
    name: 'resultCode',
    age: 'String (2)',
    address: '00 = Approved, xx = Not Approved (Please see detail in "4. Result Code")'
  },
  {
    key: '5',
    name: 'paymentType',
    age: 'String (1)',
    address: 'Q = QR Cash, C = Credit Card Full Payment I = Credit Card Installment, V = QR Credit, W = Wechat, L = Line Payment, T = True Wallet, M = Mobile Banking'
  },
  {
    key: '6',
    name: 'date',
    age: 'String (8)',
    address: 'DDMMYYYY e.g. 20112007'
  },
  {
    key: '7',
    name: 'time',
    age: 'String (6)',
    address: 'HHMMSS e.g. 141222'
  },
  {
    key: '8',
    name: 'cardNo',
    age: 'String (16)',
    address: '16 digits (shift Left) e.g. 453501XXXXXX5741'
  },
  {
    key: '9',
    name: 'cardHolderName',
    age: 'String (150)',
    address: `Card holder's name (QR Credit Only)`
  },
  {
    key: '10',
    name: 'detail',
    age: 'String (250)',
    address: 'Product Description'
  },
  {
    key: '11',
    name: 'customerName',
    age: 'String (150)',
    address: 'Customer Name'
  },
  {
    key: '12',
    name: 'customerEmail',
    age: 'String (150)',
    address: 'Customer Email'
  },
  {
    key: '13',
    name: 'customerAddress',
    age: 'String (150)',
    address: 'Customer Address'
  },
  {
    key: '14',
    name: 'customerTelephone',
    age: 'String (25)',
    address: 'Customer Telephone Number'
  },
  {
    key: '15',
    name: 'merchantDefined1',
    age: 'String (250)',
    address: 'Merchant Defined 1 (reserved)'
  },
  {
    key: '16',
    name: 'merchantDefined2',
    age: 'String (250)',
    address: 'Merchant Defined 2 (reserved)'
  },
  {
    key: '17',
    name: 'merchantDefined3',
    age: 'String (250)',
    address: 'Merchant Defined 3 (reserved)'
  },
  {
    key: '18',
    name: 'merchantDefined4',
    age: 'String (250)',
    address: 'Merchant Defined 4 (reserved)'
  },
  {
    key: '19',
    name: 'merchantDefined5',
    age: 'String (250)',
    address: 'Merchant Defined 5 (reserved)'
  },
  { 
    key: '20',
    name: 'retryFlag',
    age: 'String(1)',
    address: 'N : first time webhook, Y : retry when timeout or error'
  },
];

export default class WebHookCode extends Component {
  render() {
    return (
      <div>

        <p style={{ color: 'rgba(0, 0, 0, 0.85)' }}>Web Hook Redirect URL: </p>
        <p>URL as submitted backgroundUrl - Method POST</p>

        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>Response</p>
        <Table columns={columnsResultCode} dataSource={dataResultCode} pagination={false} bordered />

        <br />
        <br />
        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>Example Code - Response data redirect to responseUrl</p>
        <Tabs defaultActiveKey="1" onChange={this.callback}>
          <TabPane tab="PHP" key="1">
            {renderPhpResponse()}
          </TabPane>
        </Tabs>
      </div>
    );
  }
}
