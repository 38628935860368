const initialState = { 
  loading: false,
  amount: '1.00',
  isMb: 'mb',
  bankCode: '004',
  referenceNo: ''
};

const mobileBanking = (state = initialState, action) => {
  switch (action.type) {
    case 'MOBILE_BANKING/UPDATE_BY_KEY':
      return {
        ...state,
        [action.key]: action.value
      }
    default:
      return state
  }
}
export default mobileBanking
