import React, { Component } from 'react'
import { Row, Col, Descriptions, Table, Tabs } from 'antd'
import Prism from 'prismjs'
import PrismCode from './PrismCode'

const { TabPane } = Tabs;

const code = `
{
  "amount": 100,
  "referenceNo": "20171128001",
  "detail": "t-shirt",
  "customerName": "John",
  "customerEmail": "example@gbprimepay.com",
  "merchantDefined1": "Promotion",
  "card": {
      "token": "94a08da1fecbb6e8b46990538c7b50b2"
  },
  "otp": "Y",
  "responseUrl": "https://merchant_url",
  "backgroundUrl": "https://merchant_url"
}
`

const phpRequest = `
<?php
  $public_key = '{public_key}';
?>
<form name="form" action="{ENV}/v1/tokens/3d_secured" method="POST">
  publicKey: <input type="text" name="publicKey" value="<?php echo $public_key; ?>" /><br>
  gbpReferenceNo: <input type="text" name="gbpReferenceNo" value="<?php echo $chargeResp['gbpReferenceNo']; ?>" />
  <button type="submit">Pay</button>
</form>
<script>
  window.onload=function(){
    document.forms["form"].submit();
  }
</script>
`

const phpResponse = `
<?php

  $respFile = fopen("resp-log.txt", "w") or die("Unable to open file!");

  $respResultCode = $_POST["resultCode"];
  fwrite($respFile, $respResultCode . "\\n");

  $respAmount = $_POST["amount"];
  fwrite($respFile, $respAmount . "\\n");

  $respReferenceNo = $_POST["referenceNo"];
  fwrite($respFile, $respReferenceNo . "\\n");

  $respGbpReferenceNo = $_POST["gbpReferenceNo"];
  fwrite($respFile, $respGbpReferenceNo . "\\n");

  $respCurrencyCode = $_POST["currencyCode"];
  fwrite($respFile, $respCurrencyCode . "\\n");

  fclose($respFile);

?>
`

const javaResponse = `
// HttpServletRequest request
String respResultCode = request.getParameter("resultCode");
String respAmount = request.getParameter("amount");
String respReferenceNo = request.getParameter("referenceNo");
String respGbpReferenceNo = request.getParameter("gbpReferenceNo");
String respCurrencyCode = request.getParameter("currencyCode");

response.setContentType("text/html;charset=UTF-8");
try (PrintWriter out = response.getWriter()) {
    out.println("<!DOCTYPE html>");
    out.println("<html>");
    out.println("<head>");
    out.println("<title>GB</title>");            
    out.println("</head>");
    out.println("<body>");
    out.println("<p>Result Code = " + respResultCode + "</p>");
    out.println("<p>Amount = " + respAmount + "</p>");
    out.println("<p>Reference No = " + respReferenceNo + "</p>");
    out.println("<p>GBP Reference No = " + respGbpReferenceNo + "</p>");
    out.println("<p>CurrencyCode = " + respCurrencyCode + "</p>");
    out.println("</body>");
    out.println("</html>");
}
`

const codeResponse = `
{
  "resultCode": "00",
  "amount": 100.00,
  "referenceNo": "20171128001",
  "gbpReferenceNo": "gbp0001000900",
  "detail": "t-shirt",
  "customerName": "John",
  "customerEmail": "example@gbprimepay.com",
  "merchantDefined1": "Promotion",
  "currencyCode": '764',
  "date": "28112017",
  "time": "141222",
  "cardNo": "453501XXXXXX5741"
}
`

const renderFormCode = () => (
  <div>
    <PrismCode
      code={code}
      language="json"
      plugins={["line-numbers"]}
    />
  </div>
)

const renderPhpRequest = () => (
  <div>
    <PrismCode
      code={phpRequest}
      language="php"
      plugins={["line-numbers"]}
    />
  </div>
)

const renderPhpResponse = () => (
  <div>
    <PrismCode
      code={phpResponse}
      language="php"
      plugins={["line-numbers"]}
    />
  </div>
)

const renderJavaResponse = () => (
  <div>
    <PrismCode
      code={javaResponse}
      language="java"
      plugins={["line-numbers"]}
    />
  </div>
)

const columns = [
  {
    title: 'Field',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Type',
    dataIndex: 'age',
    key: 'age',
  },
  {
    title: 'Description',
    dataIndex: 'address',
    key: 'address',
  }
];

const columnsParameter = [
  {
    title: 'Field',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Type',
    dataIndex: 'age',
    key: 'age',
  },
  {
    title: 'Mandatory',
    dataIndex: 'mandatory',
    key: 'mandatory',
  },
  {
    title: 'Description',
    dataIndex: 'address',
    key: 'address',
  }
];

const dataHeader = [
  {
    key: '1',
    name: 'Content-Type',
    age: 'String',
    address: 'application/x-www-form-urlencoded'
  },
];

const dataParameter = [
  {
    key: '1',
    name: 'publicKey',
    age: 'String',
    address: 'Public Key'
  },
  {
    key: '2',
    name: 'gbpReferenceNo',
    age: 'String (250)',
    address: 'หมายเลขอ้างอิงของ GBPrimePay'
  },
];

const dataResponse = [
  {
    key: '1',
    name: 'resultCode',
    age: 'String',
    address: `00 = Success, XX = Failure (Please see details below)`
  },
  {
    key: '2',
    name: 'amount',
    age: 'Number',
    address: 'จำนวนเงิน'
  },
  {
    key: '3',
    name: 'referenceNo',
    age: 'String',
    address: 'หมายเลขอ้างอิงของร้านค้า'
  },
  {
    key: '4',
    name: 'gbpReferenceNo',
    age: 'String',
    address: 'หมายเลขอ้างอิงของ GBPrimePay'
  },
  {
    key: '5',
    name: 'detail',
    age: 'String',
    address: 'รายละเอียด'
  },
  {
    key: '6',
    name: 'customerName',
    age: 'String',
    address: 'ชื่อลูกค้า'
  },
  {
    key: '7',
    name: 'customerEmail',
    age: 'String',
    address: 'อีเมลลูกค้า'
  },
  {
    key: '8',
    name: 'merchantDefined1',
    age: 'String',
    address: 'ข้อมูลเพิ่มเติม'
  },
  {
    key: '9',
    name: 'merchantDefined2',
    age: 'String',
    address: 'ข้อมูลเพิ่มเติม'
  },
  {
    key: '10',
    name: 'merchantDefined3',
    age: 'String',
    address: 'ข้อมูลเพิ่มเติม'
  },
  {
    key: '11',
    name: 'merchantDefined4',
    age: 'String',
    address: 'ข้อมูลเพิ่มเติม'
  },
  {
    key: '12',
    name: 'merchantDefined5',
    age: 'String',
    address: 'ข้อมูลเพิ่มเติม'
  },
  {
    key: '13',
    name: 'currencyCode',
    age: 'String',
    address: 'Currency codes - ISO 4217 (764=Baht)'
  },
  {
    key: '14',
    name: 'date',
    age: 'String',
    address: 'วันที่ในการทำรายการตาม รูปแบบ DDMMYYYY เช่น 20112017'
  },
  {
    key: '15',
    name: 'time',
    age: 'String',
    address: 'เวลาในการทำรายการตามรูปแบบ HHMMSS เช่น 141222'
  },
  {
    key: '16',
    name: 'cardNo',
    age: 'String',
    address: 'หมายเลขบัตรเครดิตที่ทำรายการ เช่น 453501XXXXXX5741'
  },
];

export default class TDSecureApi extends Component {
  render() {
    return (
      <div>
        <p style={{ color: 'rgba(0, 0, 0, 0.85)' }}>3-D Secure API URL:</p>
        <p>{`{ENV}/v1/tokens/3d_secured`} - Method POST</p>

        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)' }}>เรียก API นี้ก็ต่อเมื่อข้อที่ 4. Charge API ส่งค่า "otp": "Y"</p>

        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>Header</p>
        <Table columns={columns} dataSource={dataHeader} pagination={false} bordered />

        <br />
        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>Parameter</p>
        <Table columns={columns} dataSource={dataParameter} pagination={false} bordered />

        <br />
        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>Example Code - Redirect User to 3D-Secure Page</p>
        {renderPhpRequest()}

        <br />
        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>Response</p>
        <Table columns={columns} dataSource={dataResponse} pagination={false} bordered />

        <br />
        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>Example Code - Response to Merchant</p>
        <Tabs defaultActiveKey="1" onChange={this.callback}>
          <TabPane tab="PHP" key="1">
            {renderPhpResponse()}
          </TabPane>
          <TabPane tab="Java" key="2">
            {renderJavaResponse()}
          </TabPane>
        </Tabs>

        <br />
        <br />

      </div>
    );
  }
}
