const initialState = { 
  loading: false,
  amount: '1.00',
  isLr: 'lr',
  referenceNo: ''
};

const linePayment = (state = initialState, action) => {
  switch (action.type) {
    case 'LINE_PAYMENT/UPDATE_BY_KEY':
      return {
        ...state,
        [action.key]: action.value
      }
    default:
      return state
  }
}
export default linePayment
