import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Layout, Button, Breadcrumb, Icon, Table, Row, Col, Tabs, Descriptions, Anchor } from 'antd'
import get from 'lodash/get'
import PrismCode from '../Tokenization/PrismCode'
import TokenForm from './Form'
import * as actions from '../../actions/mobileBankingAction'
import WeDemo from './WeDemo'
import { withTranslation } from "react-i18next";

const { Content, Sider } = Layout;
const { TabPane } = Tabs;

const htmlCode = `
<form id="mobileBankingform" action="{url}" method="POST"> 
  <input type="hidden" name="publicKey" value="{public_key}" /><br/>
  <input type="hidden" name="customerTelephone" value="{customerTelephone}" /><br/>
  <input type="hidden" name="referenceNo" value="{referenceNo}" /><br/>
  <input type="hidden" name="backgroundUrl" value="{backgroundUrl}" /><br/>
  <input type="hidden" name="responseUrl" value="{responseUrl}" /><br/>
  <label>Amount: </label>
  <input type="number" name="amount" maxlength="13" placeholder="Amount" value="{amount}" /><br/>
  <div>
  <label>Bank Code: </label>
  <input type="radio" name="bankCode" value="004" checked="checked" /> kBank 
  <input type="radio" name="bankCode" value="014" /> SCB
  <input type="radio" name="bankCode" value="025" /> Krungsri 
  <input type="radio" name="bankCode" value="002" /> BBL
  <input type="radio" name="bankCode" value="006" /> Krungthai 
  </div></br> 
  <input type="hidden" name="detail" placeholder="Detail" value="{detail}" /><br/>
  <input type="hidden" name="customerName" value="{customerName}" /><br/>
  <input type="hidden" name="customerEmail" value="{customerEmail}" /><br/>
  <input type="hidden" name="customerAddress" value="{customerAddress}" /><br/>
  <input type="hidden" name="merchantDefined1" value="{merchantDefined1}" /><br/>
  <input type="hidden" name="merchantDefined2" value="{merchantDefined2}" /><br/>
  <input type="hidden" name="merchantDefined3" value="{merchantDefined3}" /><br/>
  <input type="hidden" name="merchantDefined4" value="{merchantDefined4}" /><br/>
  <input type="hidden" name="merchantDefined5" value="{merchantDefined5}" /><br/>
  <div>
  <label>Checksum: </label> 
  <input type="text" name="checksum" value="" /><br/>
  <input id="button" type="button" onClick="genChecksum()" value="Generate Checksum" />
  </div>
  <div> 
    <button type="submit">Pay</button> 
  </div>
</form>

<script src="https://cdnjs.cloudflare.com/ajax/libs/crypto-js/3.1.9-1/crypto-js.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/crypto-js/3.1.9-1/hmac-sha256.min.js"></script>
<script>
function genChecksum(){ 
  var elements = document.getElementsByName("bankCode");
  for (var i = 0, l = elements.length; i < l; i++) {
  if (elements[i].checked){
  bankCode = elements[i].value;
  }}
  var hash = CryptoJS.HmacSHA256( document.getElementsByName("amount")[0].value 
  + document.getElementsByName("referenceNo")[0].value 
  + document.getElementsByName("responseUrl")[0].value 
  + document.getElementsByName("backgroundUrl")[0].value
  + bankCode , {secret_key}); 

  document.getElementsByName("checksum")[0].value = hash;
  }
</script>
`

const phpResponse = `
<?php

$respFile = fopen("bg-log".$referenceNo.".txt", "w") or die("Unable to open file!");
$gbpReferenceNo = $_POST["gbpReferenceNo"];
fwrite($respFile, "gbpReferenceNo : ".$gbpReferenceNo . "\\n");

$referenceNo = $_POST["referenceNo"];
fwrite($respFile, $referenceNo . "\\n");

$amount = $_POST["amount"];
fwrite($respFile, "amount : ".$amount . "\\n");

$currencyCode = $_POST["currencyCode"];
fwrite($respFile, "currencyCode : ".$currencyCode . "\\n");

$resultCode = $_POST["resultCode"];
fwrite($respFile, "resultCode : ".$resultCode . "\\n");

fclose($respFile);

echo "ok";
?>
`

const javaResponse = `

StringBuffer jb = new StringBuffer();
String line = null;

try {

  BufferedReader reader = request.getReader();

  while ((line = reader.readLine()) != null)

    jb.append(line);

} catch (Exception e) { /*report an error*/ }

try {

  JSONObject jsonObject = new JSONObject(jb.toString());
  System.out.println("resultCode=" + jsonObject.getString("resultCode"));
  System.out.println("amount=" + jsonObject.getBigDecimal("amount"));
  System.out.println("gbpReferenceNo=" + jsonObject.getString("gbpReferenceNo"));
  
} catch (JSONException e) { /*report an error*/ }
`

const phpCode = `
<form action="<?=$_SERVER['PHP_SELF'];?>" method="POST"> 
  <input type="hidden" name="secretkey" value="{secretkey}">
  <input type="hidden" name="referenceNo" value="{referenceNo}">
  <input type="hidden" name="responseUrl" value="{responseUrl}">
  <input type="hidden" name="backgroundUrl" value="{backgroundUrl}">  
  <input type="number" name="amount" maxlength="13" placeholder="Amount" value="{amount}"><br/>
  <input type="text" name="bankCode" maxlength="3" placeholder="Bank Code" value="{bankCode}"><br/>
  <input type="text" name="checksum" placeholder="checksum" value=""><br/>
  <input id="button" type="button" onClick="genChecksum()" value="Generate Checksum">
  <input id="button" type="submit" value="Pay Now">
</form>

<script src="https://cdnjs.cloudflare.com/ajax/libs/crypto-js/3.1.9-1/crypto-js.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/crypto-js/3.1.9-1/hmac-sha256.min.js"></script>

<script>
function genChecksum(){
  var hash = CryptoJS.HmacSHA256(
            document.getElementsByName("amount")[0].value +
            document.getElementsByName("referenceNo")[0].value + 
            document.getElementsByName("responseUrl")[0].value + 
            document.getElementsByName("backgroundUrl")[0].value + 
            document.getElementsByName("bankCode")[0].value ,
            
            document.getElementsByName("secretkey")[0].value);

  document.getElementsByName("checksum")[0].value = hash;
}
</script>

<?php
    if ($_SERVER["REQUEST_METHOD"] == "POST") {

        $mbanking_amount = $_POST['amount'];
        $mbanking_referenceNo = $_POST['referenceNo'];
        $mbanking_responseUrl = $_POST['responseUrl'];
        $mbanking_backgroundUrl = $_POST['backgroundUrl'];
        $mbanking_bankCode = $_POST['bankCode'];    
        $mbanking_secret_key = $_POST['secretkey'];  

        $genChecksum = $mbanking_amount . $mbanking_referenceNo . $mbanking_responseUrl . $mbanking_backgroundUrl . $mbanking_bankCode;
        $itemchecksum = hash_hmac("sha256",$genChecksum,$mbanking_secret_key, false);   //result same function genChecksum
        $mbankingchecksumhex = $itemchecksum;
        $url = "{ENV}/v2/mobileBanking";
        $mbanking_publicKey = '{publicKey}';

        $redirect_collection = array(
            "publicKey" => $mbanking_publicKey,
            "secret_key" => $mbanking_secret_key,           
            'referenceNo' => $mbanking_referenceNo,
            'responseUrl' => $mbanking_responseUrl,
            'backgroundUrl' => $mbanking_backgroundUrl,
            'detail' => "",          
            'amount' => $mbanking_amount,
            'bankCode' => $mbanking_bankCode,          
            'secret_key' => $mbanking_secret_key,      
            'checksum' => $mbankingchecksumhex,         
          );
        $payload = json_encode($data);
        
        $ch = curl_init($url);
        curl_setopt($ch, CURLOPT_POST, 1);
        curl_setopt($ch, CURLOPT_POSTFIELDS, $redirect_collection);
        curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
        curl_setopt($ch, CURLINFO_HEADER_OUT, true);
        curl_setopt($ch, CURLOPT_ENCODING, "");
        curl_setopt($ch, CURLOPT_TIMEOUT, 120);
        curl_setopt($ch, CURLOPT_SSL_VERIFYHOST, 2);
        curl_setopt($ch, CURLOPT_SSL_VERIFYPEER, 0);
        curl_setopt($ch, CURLOPT_CUSTOMREQUEST, "POST");
        $body = curl_exec($ch);
        $json = json_decode($body, true);
        curl_close($ch);   
        print_r($json);
    }
?>
`

const renderFormHtml = data => {
  let url = 'https://api.globalprimepay.com/v2/mobileBanking'
  if (data.isMb === 'bill') {
    url = 'https://api.globalprimepay.com/v2/mobileBanking'
  }
  let _htmlCode = htmlCode.replace(/{amount}/g, data.amount);
  _htmlCode = _htmlCode.replace(/{url}/g, url);
  return (
    <div>
      <PrismCode
        code={_htmlCode}
        language="html"
        plugins={["line-numbers"]}
      />
    </div>
  )
}

const renderPhpResponse = () => (
  <div>
    <PrismCode
      code={phpResponse}
      language="php"
      plugins={["line-numbers"]}
    />
  </div>
)

const renderJavaResponse = () => (
  <div>
    <PrismCode
      code={javaResponse}
      language="java"
      plugins={["line-numbers"]}
    />
  </div>
)

const codeResponse = `
{
  "resultCode": "00",
  "amount": 1.00,
  "referenceNo": "20171128001",
  "gbpReferenceNo": "gbp0001000900",
  "detail": "t-shirt"
}
`

const renderFormCodeResponse = () => (
  <div>
    <PrismCode
      code={codeResponse}
      language="json"
      plugins={["line-numbers"]}
    />
  </div>
)

const renderPhpCode = () => (
  <div>
    <PrismCode
      code={phpCode}
      language="php"
      plugins={["line-numbers"]}
    />
  </div>
)

class QRAndBarcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }
  changeStep = current => {
    this.setState({ current: current })
    console.log(current)
  }
  render() {

    const { t , i18n } = this.props;

    const columns = [
      {
        title: t("field"),
        dataIndex: 'name',
        key: 'name',
        render: text => <code>{text}</code>
      },
      {
        title: t("type"),
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: t("description"),
        dataIndex: 'address',
        key: 'address',
      }
    ];
    
    const columnsParameter = [
      {
        title: t("field"),
        dataIndex: 'name',
        key: 'name',
        render: text => <code>{text}</code>
      },
      {
        title: t("type"),
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: t("mandatory"),
        dataIndex: 'mandatory',
        key: 'mandatory',
      },
      {
        title: t("description"),
        dataIndex: 'address',
        key: 'address',
      }
    ];
    
    const dataHeader = [
      {
        key: '1',
        name: 'content-type',
        age: 'String',
        address: 'application/x-www-form-urlencoded'
      },
    ];
    
    const dataHeaderImage = [
      {
        key: '1',
        name: 'content-type',
        age: 'String',
        address: 'image/png'
      },
    ];
    
    const dataParameter = [
      {
        key: '0',
        name: 'publicKey',
        age: 'String (40)',
        mandatory: 'M',
        address: t("publickeydesc")
      },
      {
        key: '1',
        name: 'amount',
        age: 'Number (10,2)',
        mandatory: 'M',
        address: t("amountdesc")
      },
      {
        key: '2',
        name: 'referenceNo',
        age: 'String (15)',
        mandatory: 'M',
        address: t("referencenodesc")
      },
      {
        key: '3',
        name: 'backgroundUrl',
        age: 'String (250)',
        mandatory: 'M',
        address: t("backgroundurldesc")
      },
      {
        key: '4',
        name: 'responseUrl',
        age: 'String (250)',
        mandatory: 'M',
        address: t("responseurldesc")
      },
      {
        key: '5',
        name: 'bankCode',
        age: 'String (3)',
        mandatory: 'M',
        address: `004 = KPLUS ,
                  014 = SCB EASY (Only open in mobile) , 
                  025 = KMA (Krungsri),
                  002 = BBL (Only open in mobile),
                  006 = KTB (Krungthai)`
      },
      {
        key: '6',
        name: 'checksum',
        age: 'String (64)',
        mandatory: 'M',
        // address: 'Checksum is arrange data in this order (amount + referenceNo + responseUrl + backgroundUrl) and encrypt with Secret Key (sent by GBPrimepay in e-mail) with HMAC SHA256 format Base64'
        address: 'Checksum is arrange data in this order (amount + referenceNo + responseUrl + backgroundUrl + bankCode ) and encrypt with HMAC SHA256'
      },
      {
        key: '7',
        name: 'detail',
        age: 'String (250)',
        mandatory: 'O',
        address: t("responseurldesc")
      },
      {
        key: '8',
        name: 'customerName',
        age: 'String (250)',
        mandatory: 'O',
        address: t("customernamedesc")
      },
      {
        key: '9',
        name: 'customerEmail',
        age: 'String (250)',
        mandatory: 'O',
        address: t("customeremaildesc")
      },
      {
        key: '10',
        name: 'customerTelephone',
        age: 'String (250)',
        mandatory: 'O',
        address: t("customertelephonedesc")
      },
      {
        key: '11',
        name: 'customerAddress',
        age: 'String (250)',
        mandatory:'O',
        address: t("customeraddressdesc")
      },
      {
        key: '12',
        name: 'merchantDefined1',
        age: 'String (250)',
        mandatory: 'O',
        address: t("merchantdefined1desc")
      },
      {
        key: '13',
        name: 'merchantDefined2',
        age: 'String (250)',
        mandatory: 'O',
        address: t("merchantdefined2desc")
      },
      {
        key: '14',
        name: 'merchantDefined3',
        age: 'String (250)',
        mandatory: 'O',
        address: t("merchantdefined3desc")
      },
      {
        key: '15',
        name: 'merchantDefined4',
        age: 'String (250)',
        mandatory: 'O',
        address: t("merchantdefined4desc")
      },
      {
        key: '16',
        name: 'merchantDefined5',
        age: 'String (250)',
        mandatory: 'O',
        address: t("merchantdefined5desc")
      },
    ];
    
    const dataResponse = [
      {
        key: '1',
        name: 'amount',
        age: 'Number (10, 2)',
        address: 'For example: 15.00'
      },
      {
        key: '2',
        name: 'referenceNo',
        age: 'String (15)',
        address: `Issued by Merchant (should be a unique number) For example: 20171128001`
      },
      {
        key: '3',
        name: 'gbpReferenceNo',
        age: 'String (250)',
        address: `GBPrimePay Reference`
      },
      {
        key: '4',
        name: 'resultCode',
        age: 'String (2)',
        address: `00 = Success, XX = Failure (Please see details below)`
      },
      {
        key: '5',
        name: 'date',
        age: 'String (8)',
        address: `DDMMYYYY e.g. 20112007`
      },
      {
        key: '6',
        name: 'time',
        age: 'String (6)',
        address: `HHMMSS e.g. 141222`
      },
      {
        key: '7',
        name: 'expirationMonth',
        age: 'String (2)',
        address: `เดือนที่บัตรหมดอายุ (01)`
      },
      {
        key: '8',
        name: 'cardNo',
        age: 'String (16)',
        address: `16 digits (shift Left) e.g. 4987XXXXXXXX8769`
      },
      {
        key: '9',
        name: 'payMonth',
        age: 'String (2)',
        address: `Number of month for installment e.g. 03 = 3 Months`
      },
      {
        key: '10',
        name: 'amountPerMonth',
        age: 'Number (10,2)',
        address: `Amount payment per Month for smart payment (2 digit Decimal)`
      },
      {
        key: '11',
        name: 'issuerBank',
        age: 'String',
        address: `Bank Name from Card's BIN Number e.g. Kasikornbank Public Company Limited`
      },
      {
        key: '12',
        name: 'detail',
        age: 'String (250)',
        address: 'Product Description'
      },
      {
        key: '13',
        name: 'customerName',
        age: 'String (150)',
        address: 'Customer Name'
      },
      {
        key: '14',
        name: 'customerEmail',
        age: 'String (150)',
        address: 'Customer Email'
      },
      {
        key: '15',
        name: 'merchantDefined1',
        age: 'String (250)',
        address: 'Merchant Defined 1 (reserved)'
      },
      {
        key: '16',
        name: 'merchantDefined2',
        age: 'String (250)',
        address: 'Merchant Defined 2 (reserved)'
      },
      {
        key: '17',
        name: 'merchantDefined3',
        age: 'String (250)',
        address: 'Merchant Defined 3 (reserved)'
      },
      {
        key: '18',
        name: 'merchantDefined4',
        age: 'String (250)',
        address: 'Merchant Defined 4 (reserved)'
      },
      {
        key: '19',
        name: 'merchantDefined5',
        age: 'String (250)',
        address: 'Merchant Defined 5 (reserved)'
      },
    ];
    
    const dataBankCode = [
      {
        key: '1',
        name: '004',
        age: 'Kasikornbank Public Company Limited.',
        address: '3, 4, 5, 6, 7, 8, 9, 10'
      },
      {
        key: '2',
        name: '006',
        age: 'Krung Thai Bank Public Company Limited.',
        address: `3, 4, 5, 6, 7, 8, 9, 10`
      },
      {
        key: '3',
        name: `011`,
        age: `TMBThanachart Bank (ttb)`,
        address: `3, 4, 6, 10`
      },
      {
        key: '4',
        name: '025',
        age: 'Bank of Ayudhya Public Company Limited',
        address: `3, 4, 6, 9, 10`
      },
      {
        key: '5',
        name: '026',
        age: 'Krungsri First Choice',
        address: `3, 4, 6, 9, 10, 12, 18, 24`
      },
      {
        key: '6',
        name: '014',
        age: 'Siam Commercial Bank Public Company Limited',
        address: `3, 4, 6, 10`
      },
    ];
    
    const dataResponseToMerchant = [
      {
        key: '0',
        name: 'amount',
        age: 'Number (10, 2)',
        address: t("amountdesc")
      },
      {
        key: '1',
        name: 'referenceNo',
        age: 'String (15)',
        address: t("referencenodesc")
      },
      {
        key: '2',
        name: 'gbpReferenceNo',
        age: 'String (250)',
        address: t("gbpReferencenodesc")
      },
      {
        key: '3',
        name: 'resultCode',
        age: 'String (2)',
        address: t("resultcodedesc")
      },
      {
        key: '4',
        name: 'detail',
        age: 'String (250)',
        address: t("detaildesc")
      },
      {
        key: '5',
        name: 'currencyCode',
        age: 'Number (10)',
        address: t("currencycodetruedesc")
      },
      {
        key: '6',
        name: 'date',
        age: 'String (8)',
        address:  t("datedesc")
      },
      {
        key: '7',
        name: 'time',
        age: 'String (6)',
        address: t("timedesc")
      },
      {
        key: '8',
        name: 'customerTelephone',
        age: 'Number (10)',
        address: t("customertelephonedesc")
      },
      {
        key: '9',
        name: 'customerName',
        age: 'String (150)',
        address: t("customernamedesc")
      },
      {
        key: '10',
        name: 'customerAddress',
        age: 'String (150)',
        address: t("customeraddressdesc")
      },
      {
        key: '11',
        name: 'customerEmail',
        age: 'String (150)',
        address: t("customeremaildesc")
      },
      {
        key: '12',
        name: 'merchantDefined1',
        age: 'String (250)',
        address: t("merchantdefined1desc")
      },
      {
        key: '13',
        name: 'merchantDefined2',
        age: 'String (250)',
        address: t("merchantdefined2desc")
      },
      {
        key: '14',
        name: 'merchantDefined3',
        age: 'String (250)',
        address: t("merchantdefined3desc")
      },
      {
        key: '15',
        name: 'merchantDefined4',
        age: 'String (250)',
        address: t("merchantdefined4desc")
      },
      {
        key: '16',
        name: 'merchantDefined5',
        age: 'String (250)',
        address: t("merchantdefined5desc")
      },
      { 
        key: '17',
        name: 'retryFlag',
        age: 'String(1)',
        address: t("retryflagdesc")
      },
    ];
    
    const columnsResultCode = [
      {
        title: 'Code',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Meaning',
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: 'Recommendation',
        dataIndex: 'recommendation',
        key: 'recommendation',
      },
    ];
    
    const resultCodeResponseToMerchant = [
      {
        key: '0',
        name: '00',
        age: 'Approved',
        recommendation: 'Payment Completed',
      },
      {
        key: '1',
        name: '01',
        age: 'Customer Cancel Transaction',
        recommendation: 'Customer Cancel Transaction',
      },
      {
        key: '2',
        name: '02',
        age: 'Mobile no is invalid',
        recommendation: 'Mobile Number is invalid',
      },
      {
        key: '3',
        name: '99',
        age: 'Bank Response Fail',
        recommendation: 'Bank Response Fail. Please try again',
      },
    ];

    return (
      <Fragment>
        {/* <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>Mobile Banking</Breadcrumb.Item>
        </Breadcrumb> */}
        {/* <Content
          style={{
            background: '#fff',
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          <h2>Mobile Banking</h2>
          <hr />
          <p style={{ color: `rgba(0, 0, 0, 0.85)` }}>Mobile Banking API URL:</p>
          <p>{`{ENV}/v2/mobileBanking`} - Method POST</p>
          <br />

          <p style={{ color: `rgba(0, 0, 0, 0.85)`, fontWeight: `bold`}}>Mobile Number For UAT (Server Test)</p>
          <p style={{ color: `rgba(0, 0, 0, 0.85)` }}>Number : 0830443596</p>
          <br />
          
          <p style={{ color: `rgba(0, 0, 0, 0.85)`, fontWeight: `bold` }}>Header</p>
          <Table columns={columns} dataSource={dataHeader} pagination={false} bordered />

          <br />
          <br />

          <p style={{ color: `rgba(0, 0, 0, 0.85)`, fontWeight: `bold` }}>Parameter</p>
          <Table columns={columnsParameter} dataSource={dataParameter} pagination={false} bordered />

          <br />
          <br />

          <p style={{ color: `rgba(0, 0, 0, 0.85)`, fontWeight: `bold` }}>Example Code</p>
          {renderFormHtml(this.props.mobileBanking)}

          <br />

          <WeDemo />

          <br />
          <br />

          <p style={{ color: `rgba(0, 0, 0, 0.85)`, fontWeight: `bold` }}> Background URL: Response to Merchant</p>
          <Table columns={columns} dataSource={dataResponseToMerchant} pagination={false} bordered />

          <br />
          <br />

          //<p style={{ color: `rgba(0, 0, 0, 0.85)`, fontWeight: `bold` }}>JSON Response</p>
          //{renderFormCodeResponse()}

          //<br />
          //<br />

          <p style={{ color: `rgba(0, 0, 0, 0.85)`, fontWeight: `bold` }}>Example Code - Response to Merchant </p>
          <Tabs defaultActiveKey="1" onChange={this.callback}>
            <TabPane tab="PHP" key="1">
              {renderPhpResponse()}
            </TabPane>
            <TabPane tab="Java" key="2">
              {renderJavaResponse()}
            </TabPane>
          </Tabs>

          <br />
          <br />

          <p className="font-bold">Result Code</p>
          <Table columns={columnsResultCode} dataSource={resultCodeResponseToMerchant} pagination={false} bordered />
        </Content> */}

        <Content
          style={{
            background: '#fff',
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          <h2 className="title">{t("mobilebanking:titlemobilebanking")}</h2>
          <hr />
          <p>
            {t("mobilebanking:detailmessage1")}
          </p>

          <br />

          <h2 id="components-mobilebanking-howtoenable" className="title-sub">{t("mobilebanking:howtoenable")}</h2>
          <p>{t("mobilebanking:howtoenabledetail1")}<a href="mailto:info@gbprimepay.com" target="_blank"> info@gbprimepay.com </a></p>
          <p>{t("mobilebanking:howtoenabledetail2")}</p>

          <br />

          <h2 id="components-mobilebanking-paymentflow" className="title-sub">{t("mobilebanking:paymentflow")}</h2>
          <img
            src={require('../../res/img/PaymentFlow/Flow-Mobile-Banking.png')}
            alt="step1"
            style={{ width: '80%', height: 'auto' }}
          />
          {/* <img
            src={require('../../res/img/mockup_mobile.png')}
            alt="step2"
            style={{ width: 'auto', height: 'auto' }}
          />
          <img
            src={require('../../res/img/mockup_mobile.png')}
            alt="step3"
            style={{ width: 'auto', height: 'auto' }}
          />
          <img
            src={require('../../res/img/mockup_mobile.png')}
            alt="step4"
            style={{ width: 'auto', height: 'auto' }}
          /> */}

          <br />

          <h2 id="components-mobilebanking-steptopayment" className="title-sub">{t("mobilebanking:steptopayment")}</h2>
          <p>{t("mobilebanking:steptopaymentdetail1")} {t("mobilebanking:steptopaymentdetail2")}</p>
          
          <br />

          <h2 id="components-mobilebanking-implementation" className="title-sub">{t("mobilebanking:implementation")}</h2>
          {/* <p>{t("mobilebanking:implementationdetail1")}</p> */}

          <br />

          <p>{t("mobilebanking:usageexample")}</p>
          <Tabs defaultActiveKey="1" onChange={this.callback}>
            <TabPane tab="Javascript" key="1">
            {renderFormHtml(this.props.mobileBanking)}
            </TabPane>
            <TabPane tab="PHP" key="2">
              {renderPhpCode()}
            </TabPane>            
          </Tabs>

          <br />

          <h2 id="creating-source" className="title-sub2">{t("mobilebanking:creatingsource")}</h2>
          <p>{t("mobilebanking:creatingsourcedetail1")}</p>
          <p>
            <code>publicKey</code> {' , '} <code>amount</code> {' , '} <code>referenceNo</code> {' , '} 
            <code>backgroundUrl</code> {' , '} <code>responseUrl</code> {' , '} <code>bankCode</code> {' , '}
            <code>checksum</code> {' , '}< code>detail</code> {' , '} <code>customerName</code> {' , '}
            <code>customerEmail</code> {' , '}< code>customerTelephone</code> {' , '} <code>customerAddress</code> {' , '}
            <code>merchantDefined1</code> {' , '} <code>merchantDefined2</code> {' , '} <code>merchantDefined3</code> {' , '}
            <code>merchantDefined4</code> {' and '} <code>merchantDefined5</code> {'.'}
          </p>

          <br />

          <p>
            <text className="font-bold">Mobile Banking API URL: </text>
            <text>{`{ENV}/v2/mobileBanking`} - Method POST</text>
          <p></p>
          </p>

          <br />

          <p className="font-bold">Header</p>
          <Table columns={columns} dataSource={dataHeader} pagination={false} bordered />

          <br />

          <p className="font-bold">Parameter</p>
          <Table columns={columnsParameter} dataSource={dataParameter} pagination={false} bordered />

          {/* <br />

          <h2 id="creating-charge" className="title-sub2">{t("mobilebanking:creatingcharge")}</h2>
          <p>{'-'}</p>
          <p>{'-'}</p> */}

          <br />

          <h2 id="response-data" className="title-sub2">{t("mobilebanking:responseheader")}</h2>
          <Table columns={columns} dataSource={dataResponseToMerchant} pagination={false} bordered />

          {/* <br />
          <br />

          <p className="font-bold">JSON Response</p>
          {renderFormCodeResponse()} */}

          <br />

          <WeDemo />

          <br />
          <br />

          <Descriptions title="Mobile Number For Test UAT (Server Test)" size={'default'}>
            <Descriptions.Item label="Mobile Number">083-0443596</Descriptions.Item>
            <Descriptions.Item label="OTP">123456</Descriptions.Item>
          </Descriptions>
          
          <br />

          <p className="font-bold">Example Code - Response to Merchant</p>
          <Tabs defaultActiveKey="1" onChange={this.callback}>
            <TabPane tab="PHP" key="1">
              {renderPhpResponse()}
            </TabPane>
            <TabPane tab="Java" key="2">
              {renderJavaResponse()}
            </TabPane>
          </Tabs>

          {/* <br />

          <h2 id="components-mobilebanking-voidrefund" className="title-sub">{t("mobilebanking:voidrefunds")}</h2>
          <p>{t("mobilebanking:voidrefundsdetail1")} {'Refunds API documentation'} {t("mobilebanking:voidrefundsdetail2")}</p>
          <p>{t("mobilebanking:voidrefundsdetail3")}</p> */}

          <br />

          <h2 id="components-mobilebanking-limit" className="title-sub">{t("mobilebanking:limit")}</h2>
          {/* <p>{t("mobilebanking:limitdetail1")}</p>
          <p>{i18n.language == 'en' ? t("mobilebanking:limitdetail2") : ''}</p> */}

          <p>{t("minimumlimits")} 20.00 (THB)</p>
          <p>{t("maximumlimits")} 2,000,000.00 (THB)</p>
        </Content>

        <Sider breakpoint="md" collapsedWidth="0" style={{ background: '#fff' }} h>
          <br />
          <span style={{ color: '#02416D', fontWeight: 'bold', paddingTop: '20px' }}  >
            <Icon type="container" style={{ fontSize: '20px', color: '#08c' }} theme="outlined" />
          </span>
          <span style={{ color: '#02416D', fontWeight: 'bold', paddingTop: '20px' }}>{t("tablecontents")}</span>
          <br />
          <Anchor style={{ paddingTop: '10px' }} >
            <Anchor.Link href="#components-mobilebanking-howtoenable" title={t("mobilebanking:howtoenable")} />
            <Anchor.Link href="#components-mobilebanking-paymentflow" title={t("mobilebanking:paymentflow")} />
            <Anchor.Link href="#components-mobilebanking-steptopayment" title={t("mobilebanking:steptopayment")} />
            <Anchor.Link href="#components-mobilebanking-implementation" title={t("mobilebanking:implementation")} >
              <Anchor.Link href="#creating-source" title={t("mobilebanking:creatingsource")} />
              {/* <Anchor.Link href="#creating-charge" title={t("mobilebanking:creatingcharge")} /> */}
              <Anchor.Link href="#response-data" title={t("mobilebanking:response")} />
            </Anchor.Link>
            {/* <Anchor.Link href="#components-mobilebanking-voidrefund" title={t("mobilebanking:voidrefunds")} /> */}
            <Anchor.Link href="#components-mobilebanking-limit" title={t("mobilebanking:limit")} />
          </Anchor>
        </Sider>

      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  mobileBanking: state.mobileBanking
})
const mapDispatchToProps = dispatch => ({
  updateByKey: (key, value) => dispatch(actions.updateByKey(key, value))
})
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(QRAndBarcode)
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(QRAndBarcode));
