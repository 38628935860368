import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Layout, Button, Breadcrumb, Icon, Table, Row, Col, Tabs, Anchor } from 'antd'
import PrismCode from '../Tokenization/PrismCode'
import * as actions from '../../actions/shopeePayAction'
import { withTranslation } from "react-i18next";

const { Content, Sider } = Layout;
const { TabPane } = Tabs;

const htmlCode = `
<form id="shopeePayform" action="{ENV}/v1/shopeePay" method="POST">
  <div>
    <h2>Merchant Payment</h>
  </div>
  <label>PublicKey: </label> 
  <input type="text" name="publicKey" value="{publicKey}"><br /><br />
  <label>CustomerTelephone: </label> 
  <input type="text" name="customerTelephone" value="{customerTelephone}" /><br /><br />
  <label>ReferenceNo: </label>
  <input type="text" name="referenceNo" value="{referenceNo}"><br /><br />
  <label>CustomerName: </label>
  <input type="text" name="customerName" value="{customerName}"><br /><br />
  <label>CustomerEmail: </label>
  <input type="text" name="customerEmail" value="{customerEmail}"><br /><br />
  <label>CustomerAddress: </label>
  <input type="text" name="customerAddress" value="{customerAddress}"><br /><br />
  <input type="hidden" name="detail" value="{detail}">
  <label>ResponseUrl: </label>
  <input type="text" name="responseUrl" value="{responseUrl}"><br /><br />
  <label>BackgroundUrl: </label>
  <input type="text" name="backgroundUrl" value="{backgroundUrl}"><br /><br />
  <label>Amount: </label>
  <input type="number" name="amount" maxlength="13" placeholder="Amount" value="1.00"><br /><br />
  
  <div>
    <label>Checksum: </label>
    <input type="text" name="checksum" value="" />
    <button type="button" onclick="genChecksum();">Generate Checksum</button>
  </div>
  <br />
  <div><button type="submit">Pay</button></div>
</form>

  <script src="https://cdnjs.cloudflare.com/ajax/libs/crypto-js/3.1.9-1/crypto-js.min.js"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/crypto-js/3.1.9-1/hmac-sha256.min.js"></script>
  <script>
      function genChecksum() {
          var hash = CryptoJS.HmacSHA256(document.getElementsByName("amount")[0].value +
              document.getElementsByName("referenceNo")[0].value + document.getElementsByName("responseUrl")[0].value
              + document.getElementsByName("backgroundUrl")[0].value
              , { secret_key });
          document.getElementsByName("checksum")[0].value = hash;
      }
  </script>
`

const phpResponse = `
<?php
    $respFile = fopen("bg-log_qr.txt", "w") or die("Unable to open file!");

    $json_str = file_get_contents('php://input');
    fwrite($respFile, $json_str .); \n\n
    $json_obj = json_decode($json_str);

    fwrite($respFile, "resultCode=" . $json_obj->resultCode . );
    fwrite($respFile, "amount=" . $json_obj->amount . );
    fwrite($respFile, "referenceNo=" . $json_obj->referenceNo . );
    fwrite($respFile, "gbpReferenceNo=" . $json_obj->gbpReferenceNo . );
    fwrite($respFile, "currencyCode=" . $json_obj->currencyCode .);

    fclose($respFile);

    echo "ok";

?>
`

const javaResponse = `

StringBuffer jb = new StringBuffer();
String line = null;

try {

  BufferedReader reader = request.getReader();

  while ((line = reader.readLine()) != null)

    jb.append(line);

} catch (Exception e) { /*report an error*/ }

try {

  JSONObject jsonObject = new JSONObject(jb.toString());
  System.out.println("resultCode=" + jsonObject.getString("resultCode"));
  System.out.println("amount=" + jsonObject.getBigDecimal("amount"));
  System.out.println("gbpReferenceNo=" + jsonObject.getString("gbpReferenceNo"));
  
} catch (JSONException e) { /*report an error*/ }
`
const codeResponse = `
{
	"amount" : 100.00,
	"referenceNo" : "20171128001",
	"gbpReferenceNo" : "gbp0001000900",
	"resultCode" : "00",
	"detail" : "t-shirt",
	"currencyCode" : "764",
	"customerTelephone" : "0899999999",
	"customerName" : "John",
	"customerAddress" : "Test",
	"customerEmail" : "example@gbprimepay.com",
	"merchantDefined1":"Merchant Defined 1",
	"merchantDefined2":"Merchant Defined 2",
	"merchantDefined3":"Merchant Defined 3",
	"merchantDefined4":"Merchant Defined 4",
    "merchantDefined5":"Merchant Defined 5"
}
`
const renderFormHtml = data => {
  let url = 'https://api.globalprimepay.com/v1/shopeePay'
  if (data.isTr === 'bill') {
    url = 'https://api.globalprimepay.com/v1/shopeePay'
  }
  let _htmlCode = htmlCode.replace(/{amount}/g, data.amount);
  _htmlCode = _htmlCode.replace(/{url}/g, url);
  return (
    <div>
      <PrismCode
        code={_htmlCode}
        language="html"
        plugins={["line-numbers"]}
      />
    </div>
  )
}

const renderPhpResponse = () => (
  <div>
    <PrismCode
      code={phpResponse}
      language="php"
      plugins={["line-numbers"]}
    />
  </div>
)

const renderJavaResponse = () => (
  <div>
    <PrismCode
      code={javaResponse}
      language="java"
      plugins={["line-numbers"]}
    />
  </div>
)

const renderFormCodeResponse = () => (
  <div>
    <PrismCode
      code={codeResponse}
      language="json"
      plugins={["line-numbers"]}
    />
  </div>
)

class QRAndBarcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }
  changeStep = current => {
    this.setState({ current: current })
    console.log(current)
  }
  render() {

    const { t , i18n } = this.props;

    const columnsResultCode = [
      {
        title: t("shopeepay:code"),
        dataIndex: 'name',
        key: 'name',
        render: text => <code>{text}</code>
      },
      {
        title: t("shopeepay:meaning"),
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: t("shopeepay:recommendation"),
        dataIndex: 'address',
        key: 'address',
      }
    ];
    
    const dataResultCode = [
      {
        key: '0',
        name: '00',
        age: 'Success',
        address: t("shopeepay:paymentcompleted")
      },
      {
        key: '1',
        name: '01',
        age: 'A server dropped the connection',
        address: t("shopeepay:contactgb")
      },
      {
        key: '2',
        name: '02',
        age: 'A server error occurred',
        address: t("shopeepay:contactgb")
      },
      {
        key: '3',
        name: '03',
        age: 'Request parameters error, such as missing mandatory parameters',
        address: t("shopeepay:checkparameter")
      },
      {
        key: '4',
        name: '04',
        age: 'Permission denied',
        address: t("shopeepay:contactgb")
      },
      {
        key: '5',
        name: '05',
        age: 'Merchant/store/transaction not found',
        address: t("shopeepay:contactgb")
      },
      {
        key: '6',
        name: '06',
        age: 'Transaction processing',
        address: t("shopeepay:contactgb")
      },
      {
        key: '7',
        name: '07',
        age: 'The user making the payment has not completed wallet activation',
        address: t("shopeepay:contactgb")
      },
      {
        key: '8',
        name: '08',
        age: 'Expired',
        address: t("shopeepay:shopeepayexpired")
      },
      {
        key: '9',
        name: '09',
        age: 'User’s account is banned',
        address: t("shopeepay:contactgb")
      },
      {
        key: '10',
        name: '10',
        age: 'Duplicate request/transaction',
        address: t("shopeepay:newtransaction")
      },
      {
        key: '11',
        name: '11',
        age: 'Payment amount exceeded limit',
        address: t("shopeepay:checkamount")
      },
      {
        key: '12',
        name: '12',
        age: `User's account is frozen`,
        address: t("shopeepay:contactgb")
      },
      {
        key: '13',
        name: '13',
        age: 'Insufficient balance',
        address: t("shopeepay:checkamount")
      },
      {
        key: '14',
        name: '14',
        age: 'User wallet limit exceeded',
        address: t("shopeepay:checkamount")
      },
      {
        key: '15',
        name: '15',
        age: 'User exceeded daily payment limit Limit will reset the next day',
        address: t("shopeepay:contactgb")
      },
      {
        key: '16',
        name: '17',
        age: 'Authorisation code is invalid',
        address: t("shopeepay:contactgb")
      },
      {
        key: '17',
        name: '18',
        age: 'Client attempts to update completed transaction',
        address: t("shopeepay:checktransaction")
      },
      {
        key: '18',
        name: '19',
        age: 'User transaction limit reached',
        address: t("shopeepay:contactgb")
      },
      {
        key: '19',
        name: '21',
        age: 'Active linking count threshold reached',
        address: t("shopeepay:newtransaction")
      },
      { 
        key: '20',
        name: '22',
        age: 'Fail to unlink due to ongoing auth',
        address: t("shopeepay:contactgb")
      },
      { 
        key: '21',
        name: '23',
        age: 'Invalid payment code or QR content',
        address: t("shopeepay:newtransaction")
      },
      { 
        key: '22',
        name: '24',
        age: 'Merchant is trying to make payment to their own user account',
        address: t("shopeepay:switchaccount")
      },
      { 
        key: '23',
        name: '25',
        age: 'Refund cannot be processed due to payment exceeding validity period',
        address: t("shopeepay:contactgb")
      },
      { 
        key: '24',
        name: '26',
        age: 'Merchant invalid',
        address: t("shopeepay:contactgb")
      },
      { 
        key: '25',
        name: '27',
        age: 'Unable to invalidate order as payment_ref_id cannot be found',
        address: t("shopeepay:contactgb")
      },
      { 
        key: '26',
        name: '28',
        age: 'Request to refund a payment transaction does not meet rules. This error is also returned during refund block periods, set at 11.55pm to 3am by default. This timing is subject to changing during campaign periods or system maintenance',
        address: t("shopeepay:contactgb")
      },
      { 
        key: '27',
        name: '29',
        age: 'Request to refund a payment transaction is unsuccessful',
        address: t("shopeepay:contactgb")
      },
      { 
        key: '28',
        name: '31',
        age: 'User is not allowed to make the transaction',
        address: t("shopeepay:contactgb")
      },
      { 
        key: '29',
        name: '32',
        age: 'This service is currently under maintenance',
        address: t("shopeepay:contactgb")
      },
      { 
        key: '30',
        name: '99',
        age: 'Transaction Timeout',
        address: t("shopeepay:timelimit")
      },
    ];
    
    const columns = [
      {
        title: t("field"),
        dataIndex: 'name',
        key: 'name',
        render: text => <code>{text}</code>
      },
      {
        title: t("type"),
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: t("description"),
        dataIndex: 'address',
        key: 'address',
      }
    ];

    const columnsParameter = [
      {
        title: t("field"),
        dataIndex: 'name',
        key: 'name',
        render: text => <code>{text}</code>
      },
      {
        title: t("type"),
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: t("mandatory"),
        dataIndex: 'mandatory',
        key: 'mandatory',
      },
      {
        title: t("description"),
        dataIndex: 'address',
        key: 'address',
      }
    ];
    
    const dataHeader = [
      {
        key: '1',
        name: 'content-type',
        age: 'String',
        address: 'application/x-www-form-urlencoded'
      },
    ];
    
    const dataParameter = [
      {
        key: '0',
        name: 'publicKey',
        age: 'String (40)',
        mandatory: 'M',
        address: t("publickeydesc")
      },
      {
        key: '1',
        name: 'amount',
        age: 'Number (10,2)',
        mandatory: 'M',
        address: t("amountdesc")
      },
      {
        key: '2',
        name: 'referenceNo',
        age: 'String (15)',
        mandatory: 'M',
        address: t("referencenodesc")
      },
      {
        key: '3',
        name: 'backgroundUrl',
        age: 'String (250)',
        mandatory: 'M',
        address: t("backgroundurlshopeepaydesc")
      },
      {
        key: '4',
        name: 'responseUrl',
        age: 'String (250)',
        mandatory: 'M',
        address: t("responseurlshopeepaydesc")
      },
      // {
      //   key: '5',
      //   name: 'customerTelephone',
      //   age: 'Number (10)',
      //   mandatory: 'O',
      //   address: `Customer Telephone`
      // },
      {
        key: '5',
        name: 'detail',
        age: 'String (250)',
        mandatory: 'O',
        address: t("detaildesc")
      },
      {
        key: '6',
        name: 'customerName',
        age: 'String (150)',
        mandatory: 'O',
        address: t("customernamedesc")
      },
      {
        key: '7',
        name: 'customerEmail',
        age: 'String (150)',
        mandatory: 'O',
        address: t("customeremaildesc")
      },
      {
        key: '8',
        name: 'customerAddress',
        age: 'String (150)',
        mandatory: 'O',
        address: t("customeraddressdesc")
      },
      {
        key: '9',
        name: 'customerTelephone',
        age: 'String (25)',
        mandatory: 'O',
        address: t("customertelephonedesc")
      },
      {
        key: '10',
        name: 'merchantDefined1',
        age: 'String (250)',
        mandatory: 'O',
        address: t("merchantdefined1desc")
      },
      {
        key: '11',
        name: 'merchantDefined2',
        age: 'String (250)',
        mandatory: 'O',
        address: t("merchantdefined2desc")
      },
      {
        key: '12',
        name: 'merchantDefined3',
        age: 'String (250)',
        mandatory: 'O',
        address: t("merchantdefined3desc")
      },
      {
        key: '13',
        name: 'merchantDefined4',
        age: 'String (250)',
        mandatory: 'O',
        address: t("merchantdefined4desc")
      },
      {
        key: '14',
        name: 'merchantDefined5',
        age: 'String (250)',
        mandatory: 'O',
        address: t("merchantdefined5desc")
      },
      {
        key: '15',
        name: 'checksum',
        age: 'String (64)',
        mandatory: 'M',
        address: t("checksumtruedesc")
      },
    ];
    
    const dataResponse = [
      {
        key: '1',
        name: 'amount',
        age: 'Number (10, 2)',
        address: 'For example: 15.00'
      },
      {
        key: '2',
        name: 'referenceNo',
        age: 'String (15)',
        address: `Issued by Merchant (should be a unique number) For example: 20171128001`
      },
      {
        key: '3',
        name: 'gbpReferenceNo',
        age: 'String (250)',
        address: `GBPrimePay Reference`
      },
      {
        key: '4',
        name: 'resultCode',
        age: 'String (2)',
        address: `00 = Success, XX = Failure (Please see details below)`
      },
      {
        key: '5',
        name: 'date',
        age: 'String (8)',
        address: `DDMMYYYY e.g. 20112007`
      },
      {
        key: '6',
        name: 'time',
        age: 'String (6)',
        address: `HHMMSS e.g. 141222`
      },
      {
        key: '7',
        name: 'expirationMonth',
        age: 'String (2)',
        address: `เดือนที่บัตรหมดอายุ (01)`
      },
      {
        key: '8',
        name: 'cardNo',
        age: 'String (16)',
        address: `16 digits (shift Left) e.g. 4987XXXXXXXX8769`
      },
      {
        key: '9',
        name: 'payMonth',
        age: 'String (2)',
        address: `Number of month for installment e.g. 03 = 3 Months`
      },
      {
        key: '10',
        name: 'amountPerMonth',
        age: 'Number (10,2)',
        address: `Amount payment per Month for smart payment (2 digit Decimal)`
      },
      {
        key: '11',
        name: 'issuerBank',
        age: 'String',
        address: `Bank Name from Card's BIN Number e.g. Kasikornbank Public Company Limited`
      },
      {
        key: '12',
        name: 'detail',
        age: 'String (250)',
        address: 'Product Description'
      },
      {
        key: '13',
        name: 'customerName',
        age: 'String (150)',
        address: 'Customer Name'
      },
      {
        key: '14',
        name: 'customerEmail',
        age: 'String (150)',
        address: 'Customer Email'
      },
      {
        key: '15',
        name: 'merchantDefined1',
        age: 'String (250)',
        address: 'Merchant Defined 1 (reserved)'
      },
      {
        key: '16',
        name: 'merchantDefined2',
        age: 'String (250)',
        address: 'Merchant Defined 2 (reserved)'
      },
      {
        key: '17',
        name: 'merchantDefined3',
        age: 'String (250)',
        address: 'Merchant Defined 3 (reserved)'
      },
      {
        key: '18',
        name: 'merchantDefined4',
        age: 'String (250)',
        address: 'Merchant Defined 4 (reserved)'
      },
      {
        key: '19',
        name: 'merchantDefined5',
        age: 'String (250)',
        address: 'Merchant Defined 5 (reserved)'
      },
    ];
    
    
    const dataResponseToMerchant = [
      {
        key: '0',
        name: 'amount',
        age: 'Number (10, 2)',
        address: t("amountdesc"),
      },
      {
        key: '1',
        name: 'referenceNo',
        age: 'String (15)',
        address: t("referencenodesc"),
      },
      {
        key: '2',
        name: 'gbpReferenceNo',
        age: 'String (250)',
        address: t("gbpReferencenodesc"),
      },
      {
        key: '3',
        name: 'resultCode',
        age: 'String (2)',
        address: t("resultcodedesc")
      },
      {
        key: '4',
        name: 'detail',
        age: 'String (250)',
        address: t("detaildesc")
      },
      {
        key: '5',
        name: 'currencyCode',
        age: 'Number (10)',
        address: t("currencycodetruedesc"),
      },
      {
        key: '6',
        name: 'customerTelephone',
        age: 'Number (10)',
        address: t("customertelephonedesc")
      },
      {
        key: '7',
        name: 'customerName',
        age: 'String (250)',
        address: t("customernamedesc")
      },
      {
        key: '8',
        name: 'customerAddress',
        age: 'String (250)',
        address: t("customeraddressdesc")
      },
      {
        key: '9',
        name: 'customerEmail',
        age: 'String (250)',
        address: t("customeremaildesc")
      },
      {
        key: '10',
        name: 'merchantDefined1',
        age: 'String (250)',
        address: t("merchantdefined1desc")
      },
      {
        key: '11',
        name: 'merchantDefined2',
        age: 'String (250)',
        address: t("merchantdefined2desc")
      },
      {
        key: '12',
        name: 'merchantDefined3',
        age: 'String (250)',
        address: t("merchantdefined3desc")
      },
      {
        key: '13',
        name: 'merchantDefined4',
        age: 'String (250)',
        address: t("merchantdefined4desc")
      },
      {
        key: '14',
        name: 'merchantDefined5',
        age: 'String (250)',
        address: t("merchantdefined5desc")
      },
      { 
        key: '15',
        name: 'retryFlag',
        age: 'String(1)',
        address: t("retryflagdesc")
      },
    ];

    return (
      <Fragment>
        {/* <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>ShopeePay</Breadcrumb.Item>
        </Breadcrumb> */}
        {/* <Content
          style={{
            background: '#fff',
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          <h2>ShopeePay</h2>
          <hr />
          <p style={{ color: `rgba(0, 0, 0, 0.85)` }}>ShopeePay API URL:</p>
          <p>{`{ENV}/v1/shopeePay`} - Method POST</p>
          <br />

          <p style={{ color: `rgba(0, 0, 0, 0.85)`, fontWeight: `bold` }}>Header</p>
          <Table columns={columns} dataSource={dataHeader} pagination={false} bordered />

          <br />
          <br />

          <p style={{ color: `rgba(0, 0, 0, 0.85)`, fontWeight: `bold` }}>Parameter</p>
          <Table columns={columnsParameter} dataSource={dataParameter} pagination={false} bordered />

          <br />
          <br />

          //<p style={{ color: `rgba(0, 0, 0, 0.85)`, fontWeight: `bold` }}>Image Response</p>
          //<Table columns={columns} dataSource={dataHeaderImage} pagination={false} bordered />

          //<br />
          //<br />

          <p style={{ color: `rgba(0, 0, 0, 0.85)`, fontWeight: `bold` }}>Example Code</p>
          {renderFormHtml(this.props.shopeePay)}

          //<br />
          //<WeDemo />

          <br />
          <br />

          <p style={{ color: `rgba(0, 0, 0, 0.85)`, fontWeight: `bold` }}> Background URL: Response to Merchant</p>
          <Table columns={columns} dataSource={dataResponseToMerchant} pagination={false} bordered />

          <br />
          <br />

          <p style={{ color: `rgba(0, 0, 0, 0.85)`, fontWeight: `bold` }}>JSON Response</p>
          {renderFormCodeResponse()}

          <br />
          <br />

          <p style={{ color: `rgba(0, 0, 0, 0.85)`, fontWeight: `bold` }}>Example Code - Response to Merchant</p>
          <Tabs defaultActiveKey="1" onChange={this.callback}>
            <TabPane tab="PHP" key="1">
              {renderPhpResponse()}
            </TabPane>
            <TabPane tab="Java" key="2">
              {renderJavaResponse()}
            </TabPane>
          </Tabs>

          <br />
          <br />

          <p style={{ color: `rgba(0, 0, 0, 0.85)`, fontWeight: `bold` }}> Result Code</p>
          <Table columns={columnsResultCode} dataSource={dataResultCode} pagination={false} bordered />

          <br />
          <br />
        </Content> */}

        <Content
          style={{
            background: '#fff',
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          <h2 className="title">{t("shopeepay:titleshopeepay")}</h2>
          <hr />
          <p>
            {t("shopeepay:detailmessage1")}
          </p>
          
          <br />

          <h2 id="components-shopeepay-howtoenable" className="title-sub">{t("shopeepay:howtoenable")}</h2>
          <p>{t("shopeepay:howtoenabledetail1")}<a href="mailto:info@gbprimepay.com" target="_blank"> info@gbprimepay.com </a></p>
          <p>{t("shopeepay:howtoenabledetail2")}</p>

          <br />

          <h2 id="components-shopeepay-paymentflow" className="title-sub">{t("shopeepay:paymentflow")}</h2>
          <p>{t("common:onmobile")}</p>
          <img
            src={require('../../res/img/PaymentFlow/Flow_ShopeePay_App.png')}
            alt="step1"
            style={{ width: '80%', height: 'auto' }}
          />
          <br />
          <br />
          <p>{t("common:onbrowser")}</p>
          <img
            src={require('../../res/img/PaymentFlow/Flow_ShopeePay_Web.png')}
            alt="step1"
            style={{ width: '80%', height: 'auto' }}
          />
          {/* <img
            src={require('../../res/img/mockup_mobile.png')}
            alt="step2"
            style={{ width: 'auto', height: 'auto' }}
          />
          <img
            src={require('../../res/img/mockup_mobile.png')}
            alt="step3"
            style={{ width: 'auto', height: 'auto' }}
          />
          <img
            src={require('../../res/img/mockup_mobile.png')}
            alt="step4"
            style={{ width: 'auto', height: 'auto' }}
          /> */}

          <br />

          <h2 id="components-shopeepay-steptopayment" className="title-sub">{t("shopeepay:steptopayment")}</h2>
          <p>{t("shopeepay:steptopaymentdetail1")} {t("shopeepay:steptopaymentdetail2")}</p>
          {/* <WeDemo /> */}

          <br />

          <h2 id="components-shopeepay-implementation" className="title-sub">{t("shopeepay:implementation")}</h2>
          {/* <p>{t("shopeepay:implementationdetail1")}</p> */}

          <br />

          <p>{t("shopeepay:usageexample")}</p>
          {renderFormHtml(this.props.shopeePay)}

          <br />

          <h2 id="creating-source" className="title-sub2">{t("shopeepay:creatingsource")}</h2>
          <p>{t("shopeepay:creatingsourcedetail1")}</p>
          <p>
            <code>publicKey</code> {' , '} <code>amount</code> {' , '} <code>referenceNo</code> {' , '} 
            <code>backgroundUrl</code> {' , '} <code>responseUrl</code> {' , '}  <code>detail</code> {' , '} 
            <code>customerName</code> {' , '} <code>customerEmail</code> {' , '}< code>customerAddress</code> {' , '} 
            <code>customerTelephone</code> {' , '} <code>merchantDefined1</code> {' , '} <code>merchantDefined2</code> {' , '} 
            <code>merchantDefined3</code> {' , '} <code>merchantDefined4</code> {' , '} <code>merchantDefined5</code> {' , '}
            {' and '} <code>checksum</code> {'.'}
          </p>

          <br />

          <p>
            <text className="font-bold">ShopeePay API URL: </text>
            <text>{`{ENV}/v1/shopeePay`} - Method POST</text>
          </p>

          <br />

          <p className="font-bold">Header</p>
          <Table columns={columns} dataSource={dataHeader} pagination={false} bordered />

          <br />

          <p className="font-bold">Parameter</p>
          <Table  columns={columnsParameter} dataSource={dataParameter} pagination={false} bordered />

          {/* <br />

          <h2 id="creating-charge" className="title-sub2">{t("shopeepay:creatingcharge")}</h2>
          <p>{'-'}</p>
          <p>{'-'}</p> */}

          <br />

          <h2 id="response-data" className="title-sub2">{t("shopeepay:response")}</h2>
          <p>{t("shopeepay:responsedetail1")}</p>

          <br />

          <h2 className="title-sub2">{t("shopeepay:responseheader")}</h2>
          <Table columns={columns} dataSource={dataResponseToMerchant} pagination={false} bordered />

          <br />
          <br />

          <p className="font-bold">JSON Response</p>
          {renderFormCodeResponse()}

          <br />
          <br />

          <p className="font-bold">Example Code - Response to Merchant</p>
          <Tabs defaultActiveKey="1" onChange={this.callback}>
            <TabPane tab="PHP" key="1">
              {renderPhpResponse()}
            </TabPane>
            <TabPane tab="Java" key="2">
              {renderJavaResponse()}
            </TabPane>
          </Tabs>

          <br />

          <p className="font-bold">{t("shopeepay:resultcode")}</p>
          <Table columns={columnsResultCode} dataSource={dataResultCode} pagination={false} bordered />

          {/* <br />

          <h2 id="components-shopeepay-voidrefund" className="title-sub">{t("shopeepay:voidrefunds")}</h2>
          <p>{t("shopeepay:voidrefundsdetail1")} {'Refunds API documentation'} {t("shopeepay:voidrefundsdetail2")}</p>
          <p>{t("shopeepay:voidrefundsdetail3")}</p> */}

          <br />

          <h2 id="components-shopeepay-limit" className="title-sub">{t("shopeepay:limit")}</h2>
          {/* <p>{t("shopeepay:limitdetail1")}</p>
          <p>{i18n.language == 'en' ? t("shopeepay:limitdetail2") : ''}</p> */}

          <p>{t("minimumlimits")} 1.00 (THB)</p>
          <p>{t("maximumlimits")} 200,000.00 (THB)</p>
        </Content>

        <Sider breakpoint="md" collapsedWidth="0" style={{ background: '#fff' }} h>
          <br />
          <span style={{ color: '#02416D', fontWeight: 'bold', paddingTop: '20px' }}  >
            <Icon type="container" style={{ fontSize: '20px', color: '#08c' }} theme="outlined" />
          </span>
          <span style={{ color: '#02416D', fontWeight: 'bold', paddingTop: '20px' }}>{t("tablecontents")}</span>
          <br />
          <Anchor style={{ paddingTop: '10px' }} >
            <Anchor.Link href="#components-shopeepay-howtoenable" title={t("shopeepay:howtoenable")} />
            <Anchor.Link href="#components-shopeepay-paymentflow" title={t("shopeepay:paymentflow")} />
            <Anchor.Link href="#components-shopeepay-steptopayment" title={t("shopeepay:steptopayment")} />
            <Anchor.Link href="#components-shopeepay-implementation" title={t("shopeepay:implementation")} >
              <Anchor.Link href="#creating-source" title={t("shopeepay:creatingsource")} />
              {/* <Anchor.Link href="#creating-charge" title={t("shopeepay:creatingcharge")} /> */}
              <Anchor.Link href="#response-data" title={t("shopeepay:response")} />
            </Anchor.Link>
            {/* <Anchor.Link href="#components-shopeepay-voidrefund" title={t("shopeepay:voidrefunds")} /> */}
            <Anchor.Link href="#components-shopeepay-limit" title={t("shopeepay:limit")} />
          </Anchor>
        </Sider>

      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  shopeePay: state.shopeePay
})
const mapDispatchToProps = dispatch => ({
  updateByKey: (key, value) => dispatch(actions.updateByKey(key, value))
})
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(QRAndBarcode)
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(QRAndBarcode));
