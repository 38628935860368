import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Layout, Button, Breadcrumb, Icon, Table, Row, Col, Tabs, Anchor } from 'antd'
import get from 'lodash/get'
import PrismCode from '../Tokenization/PrismCode'
import TokenForm from './Form'
import QRDemo from './QRDemo'
import * as actions from '../../actions/qrCashAction'
import { withTranslation } from "react-i18next";

const { Content, Sider } = Layout;
const { TabPane } = Tabs;

const htmlCode = `
<form action="{url}" method="POST"> 
  <input type="hidden" name="token" value="{token}">
  <input type="hidden" name="referenceNo" value="{referenceNo}">
  <input type="hidden" name="backgroundUrl" value="{backgroundUrl}">
  <input type="number" name="amount" maxlength="13" placeholder="Amount" value="{amount}"><br/>
  <input id="button" type="submit" value="Pay Now">
</form>
`

const phpResponse = `
<?php

$respFile = fopen("resp-log.txt", "w") or die("Unable to open file!");

$json_str = file_get_contents('php://input');
fwrite($respFile, $json_str . "\\n\\n");

$json_obj = json_decode($json_str);

fwrite($respFile, "resultCode=" . $json_obj->resultCode . "\\n");
fwrite($respFile, "amount=" . $json_obj->amount . "\\n");
fwrite($respFile, "referenceNo=" . $json_obj->referenceNo . "\\n");
fwrite($respFile, "gbpReferenceNo=" . $json_obj->gbpReferenceNo . "\\n");
fwrite($respFile, "currencyCode=" . $json_obj->currencyCode . "\\n");

fclose($respFile);

?>
`

const javaResponse = `

StringBuffer jb = new StringBuffer();
String line = null;

try {

  BufferedReader reader = request.getReader();

  while ((line = reader.readLine()) != null)

    jb.append(line);

} catch (Exception e) { /*report an error*/ }

try {

  JSONObject jsonObject = new JSONObject(jb.toString());
  System.out.println("resultCode=" + jsonObject.getString("resultCode"));
  System.out.println("amount=" + jsonObject.getBigDecimal("amount"));
  System.out.println("gbpReferenceNo=" + jsonObject.getString("gbpReferenceNo"));
  System.out.println("currencyCode=" + jsonObject.getString("currencyCode"));
  
} catch (JSONException e) { /*report an error*/ }
`

const phpCode =`

<form action="<?=$_SERVER['PHP_SELF'];?>" method="POST">
    กรุณายอดเงิน <input type="text" name="itemamount">
    <input type="submit" value="ตกลง">
</form>

<?php
  
    isset( $_POST['itemamount'] ) ? $itemamount = $_POST['itemamount'] : $itemamount = "";
    $request_headers = array(
      "Cache-Control: no-cache",
    );
    
    if( !empty( $itemamount ) ) {
      $token = '';
      $tokenKey = rawurlencode($token);      
      $referenceNo = "";
      $field = 'token='.$tokenKey.'&referenceNo='.$referenceNo.'&amount='.$itemamount.'';
      $url = "{ENV}/v3/qrcode";

      // curl api grcode
      $ch = curl_init();
      curl_setopt($ch, CURLOPT_URL, $url);
      curl_setopt($ch, CURLOPT_POST, 1);
      curl_setopt($ch, CURLOPT_POSTFIELDS, $field);
      curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
      curl_setopt($ch, CURLINFO_HEADER_OUT, true);
      curl_setopt($ch, CURLOPT_ENCODING, "");
      curl_setopt($ch, CURLOPT_SSL_VERIFYHOST, false);
      curl_setopt($ch, CURLOPT_SSL_VERIFYPEER, false);
      curl_setopt($ch, CURLOPT_HTTPHEADER, $request_headers);
      $output = curl_exec($ch);
      curl_close($ch);

      // encode output from api 
      $body = 'data:image/png;base64,' . base64_encode($output) . '';
      

      // output img qrcode by html
      echo '<div class="qrcode_display" id="gbprimepay-qrcode-waiting-payment" style="display:block;">';
      echo '<img src="' . $body . '"  style="padding:0px 0px 120px 0px;windth:100%;" class="aligncenter size-full" />';
      echo '</div>';
      
    }
?>
`

const renderFormHtml = data => {
  // let url = 'https://api.globalprimepay.com/gbp/gateway/qrcode'
  let url = 'https://api.globalprimepay.com/v3/qrcode'
  if (data.isQr === 'bill') {
    // url = 'https://api.globalprimepay.com/gbp/gateway/qrcode/text'
    url = 'https://api.globalprimepay.com/v3/qrcode/text'
  }
  let _htmlCode = htmlCode.replace(/{amount}/g, data.amount);
  _htmlCode = _htmlCode.replace(/{url}/g, url);
  _htmlCode = _htmlCode.replace(/{referenceNo}/g, data.referenceNo);
  return (
    <div>
      <PrismCode
        code={_htmlCode}
        language="html"
        plugins={["line-numbers"]}
      />
    </div>
  )
}

const renderPhpResponse = () => (
  <div>
    <PrismCode
      code={phpResponse}
      language="php"
      plugins={["line-numbers"]}
    />
  </div>
)

const renderJavaResponse = () => (
  <div>
    <PrismCode
      code={javaResponse}
      language="java"
      plugins={["line-numbers"]}
    />
  </div>
)

const codeResponse = `
{
  "resultCode": "00",
  "amount": 100.00,
  "referenceNo": "20171128001",
  "gbpReferenceNo": "gbp0001000900",
  "detail": "t-shirt"
}
`

const renderFormCodeResponse = () => (
  <div>
    <PrismCode
      code={codeResponse}
      language="json"
      plugins={["line-numbers"]}
    />
  </div>
)

const renderPhpCode = () => (
  <div>
    <PrismCode
      code={phpCode}
      language="php"
      plugins={["line-numbers"]}
    />
  </div>
)

class QRAndBarcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }
  changeStep = current => {
    this.setState({ current: current })
    console.log(current)
  }
  render() {

    const { t , i18n } = this.props;

    const columns = [
      {
        title: t("field"),
        dataIndex: 'name',
        key: 'name',
        render: text => <code>{text}</code>
      },
      {
        title: t("type"),
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: t("description"),
        dataIndex: 'address',
        key: 'address',
      }
    ];
    
    const columnsParameter = [
      {
        title: t("field"),
        dataIndex: 'name',
        key: 'name',
        render: text => <code>{text}</code>
      },
      {
        title: t("type"),
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: t("mandatory"),
        dataIndex: 'mandatory',
        key: 'mandatory',
      },
      {
        title: t("description"),
        dataIndex: 'address',
        key: 'address',
      }
    ];
    
    const dataHeader = [
      {
        key: '1',
        name: 'content-type',
        age: 'String',
        address: 'application/x-www-form-urlencoded'
      },
    ];
    
    const dataHeaderImage = [
      {
        key: '1',
        name: 'content-type',
        age: 'String',
        address: 'image/png'
      },
    ];
    
    const dataParameter = [
      {
        key: '0',
        name: 'token (customer key)',
        age: 'String',
        mandatory: 'M',
        address: t("tokencustomerkeydesc")
      },
      {
        key: '1',
        name: 'amount',
        age: 'Number (10, 2)',
        mandatory: 'M',
        address: t("amountdesc")
      },
      {
        key: '2',
        name: 'referenceNo',
        age: 'String (15)',
        mandatory: 'M',
        address: t("referencenodesc")
      },
      {
        key: '3',
        name: 'backgroundUrl',
        age: 'String (250)',
        mandatory: 'O',
        address: t("backgroundurldesc")
      },
      {
        key: '4',
        name: 'detail',
        age: 'String (250)',
        mandatory: 'O',
        address: t("detaildesc")
      },
      {
        key: '5',
        name: 'customerName',
        age: 'String (150)',
        mandatory: 'O',
        address: t("customernamedesc")
      },
      {
        key: '6',
        name: 'customerEmail',
        age: 'String (150)',
        mandatory: 'O',
        address: t("customeremaildesc")
      },
      {
        key: '7',
        name: 'merchantDefined1',
        age: 'String (250)',
        mandatory: 'O',
        address: t("merchantdefined1desc")
      },
      {
        key: '8',
        name: 'merchantDefined2',
        age: 'String (250)',
        mandatory: 'O',
        address: t("merchantdefined2desc")
      },
      {
        key: '9',
        name: 'merchantDefined3',
        age: 'String (250)',
        mandatory: 'O',
        address: t("merchantdefined3desc")
      },
      {
        key: '10',
        name: 'merchantDefined4',
        age: 'String (250)',
        mandatory: 'O',
        address: t("merchantdefined4desc")
      },
      {
        key: '11',
        name: 'merchantDefined5',
        age: 'String (250)',
        mandatory: 'O',
        address: t("merchantdefined5desc")
      },
      {
        key: '12',
        name: 'customerTelephone',
        age: 'Number (10)',
        mandatory: 'O',
        address: t("customertelephonedesc")
      },
      {
        key: '13',
        name: 'customerAddress',
        age: 'String (250)',
        mandatory: 'O',
        address: t("customeraddressdesc")
      },
    ];
    
    const dataResponse = [
      {
        key: '1',
        name: 'amount',
        age: 'Number (10, 2)',
        address: 'For example: 15.00'
      },
      {
        key: '2',
        name: 'referenceNo',
        age: 'String (15)',
        address: `Issued by Merchant (should be a unique number) For example: 20171128001`
      },
      {
        key: '3',
        name: 'gbpReferenceNo',
        age: 'String (250)',
        address: `GBPrimePay Reference`
      },
      {
        key: '4',
        name: 'resultCode',
        age: 'String (2)',
        address: `00 = Success, XX = Failure (Please see details below)`
      },
      {
        key: '5',
        name: 'date',
        age: 'String (8)',
        address: `DDMMYYYY e.g. 20112007`
      },
      {
        key: '6',
        name: 'time',
        age: 'String (6)',
        address: `HHMMSS e.g. 141222`
      },
      {
        key: '7',
        name: 'expirationMonth',
        age: 'String (2)',
        address: `เดือนที่บัตรหมดอายุ (01)`
      },
      {
        key: '8',
        name: 'cardNo',
        age: 'String (16)',
        address: `16 digits (shift Left) e.g. 4987XXXXXXXX8769`
      },
      {
        key: '9',
        name: 'payMonth',
        age: 'String (2)',
        address: `Number of month for installment e.g. 03 = 3 Months`
      },
      {
        key: '10',
        name: 'amountPerMonth',
        age: 'Number (10,2)',
        address: `Amount payment per Month for smart payment (2 digit Decimal)`
      },
      {
        key: '11',
        name: 'issuerBank',
        age: 'String (3)',
        address: `Bank Name from Card's BIN Number e.g. Kasikornbank Public Company Limited`
      },
      {
        key: '12',
        name: 'detail',
        age: 'String (250)',
        address: 'Product Description'
      },
      {
        key: '13',
        name: 'customerName',
        age: 'String (150)',
        address: 'Customer Name'
      },
      {
        key: '14',
        name: 'customerEmail',
        age: 'String (150)',
        address: 'Customer Email'
      },
      {
        key: '15',
        name: 'merchantDefined1',
        age: 'String (250)',
        address: 'Merchant Defined 1 (reserved)'
      },
      {
        key: '16',
        name: 'merchantDefined2',
        age: 'String (250)',
        address: 'Merchant Defined 2 (reserved)'
      },
      {
        key: '17',
        name: 'merchantDefined3',
        age: 'String (250)',
        address: 'Merchant Defined 3 (reserved)'
      },
      {
        key: '18',
        name: 'merchantDefined4',
        age: 'String (250)',
        address: 'Merchant Defined 4 (reserved)'
      },
      {
        key: '19',
        name: 'merchantDefined5',
        age: 'String (250)',
        address: 'Merchant Defined 5 (reserved)'
      },
    ];
    
    const dataBankCode = [
      {
        key: '1',
        name: '004',
        age: 'Kasikornbank Public Company Limited.',
        address: '3, 4, 5, 6, 7, 8, 9, 10'
      },
      {
        key: '2',
        name: '006',
        age: 'Krung Thai Bank Public Company Limited.',
        address: `3, 4, 5, 6, 7, 8, 9, 10`
      },
      {
        key: '3',
        name: `011`,
        age: `TMBThanachart Bank (ttb)`,
        address: `3, 4, 6, 10`
      },
      {
        key: '4',
        name: '025',
        age: 'Bank of Ayudhya Public Company Limited',
        address: `3, 4, 6, 9, 10`
      },
      {
        key: '5',
        name: '026',
        age: 'Krungsri First Choice',
        address: `3, 4, 6, 9, 10, 12, 18, 24`
      },
      {
        key: '6',
        name: '014',
        age: 'Siam Commercial Bank Public Company Limited',
        address: `3, 4, 6, 10`
      },
    ];
    
    const dataResponseToMerchant = [
      {
        key: '0',
        name: 'amount',
        age: 'Number (10, 2)',
        address: t("amountdesc")
      },
      {
        key: '1',
        name: 'referenceNo',
        age: 'String (15)',
        address: t("referencenodesc")
      },
      {
        key: '2',
        name: 'gbpReferenceNo',
        age: 'String (250)',
        address: t("gbpReferencenodesc")
      },
      {
        key: '3',
        name: 'resultCode',
        age: 'String (2)',
        address: t("resultcodedesc")
      },
      {
        key: '4',
        name: 'date',
        age: 'String (8)',
        address: t("datedesc")
      },
      {
        key: '5',
        name: 'time',
        age: 'String (6)',
        address: t("timedesc")
      },
      {
        key: '6',
        name: 'currencyCode',
        age: 'String (3)',
        address: t("currencycodedesc")
      },
      {
        key: '7',
        name: 'detail',
        age: 'String (250)',
        address: t("detaildesc")
      },
      {
        key: '8',
        name: 'customerName',
        age: 'String (150)',
        address: t("customernamedesc")
      },
      {
        key: '9',
        name: 'customerEmail',
        age: 'String (150)',
        address: t("customeremaildesc")
      },
      {
        key: '10',
        name: 'merchantDefined1',
        age: 'String (250)',
        address: t("merchantdefined1desc")
      },
      {
        key: '11',
        name: 'merchantDefined2',
        age: 'String (250)',
        address: t("merchantdefined2desc")
      },
      {
        key: '12',
        name: 'merchantDefined3',
        age: 'String (250)',
        address: t("merchantdefined3desc")
      },
      {
        key: '13',
        name: 'merchantDefined4',
        age: 'String (250)',
        address: t("merchantdefined4desc")
      },
      {
        key: '14',
        name: 'merchantDefined5',
        age: 'String (250)',
        address: t("merchantdefined5desc")
      },
      {
        key: '15',
        name: 'customerTelephone',
        age: 'Number (10)',
        address: t("customertelephonedesc")
      },
      {
        key: '16',
        name: 'customerAddress',
        age: 'String (250)',
        address: t("customeraddressdesc")
      },
      {
        key: '17',
        name: 'retryFlag',
        age: 'String (1)',
        address: t("retryflagdesc")
      },
    ];

    return (
      <Fragment>
        {/* <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>QR Code / QR Cash</Breadcrumb.Item>
        </Breadcrumb> */}
        {/* <Content
          style={{
            background: '#fff',
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          <h2>QR Cash</h2>
          <hr />
          <p style={{ color: 'rgba(0, 0, 0, 0.85)' }}>QR Cash API URL:</p>
          <p>{`{ENV}/v3/qrcode or {ENV}/v3/qrcode/text`} - Method POST</p>
          <br />

          <p className="font-bold">Header</p>
          <Table columns={columns} dataSource={dataHeader} pagination={false} bordered />

          <br />
          <br />

          <p className="font-bold">Parameter</p>
          <Table columns={columnsParameter} dataSource={dataParameter} pagination={false} bordered />

          <br />
          <br />

          <p className="font-bold">Image Response</p>
          <Table columns={columns} dataSource={dataHeaderImage} pagination={false} bordered />

          <br />

          <p className="font-bold">Example Code</p>
          {renderFormHtml(this.props.qrCash)}

          <br />

          <QRDemo />

          <br />
          <br />

          <p className="font-bold"> Background URL: Response to Merchant</p>
          <Table columns={columns} dataSource={dataResponseToMerchant} pagination={false} bordered />

          <br />
          <br />

          <p className="font-bold">JSON Response</p>
          {renderFormCodeResponse()}

          <br />
          <br />

          <p className="font-bold">Example Code - Response to Merchant</p>
          <Tabs defaultActiveKey="1" onChange={this.callback}>
            <TabPane tab="PHP" key="1">
              {renderPhpResponse()}
            </TabPane>
            <TabPane tab="Java" key="2">
              {renderJavaResponse()}
            </TabPane>
          </Tabs>
        </Content> */}

        <Content
          style={{
            background: '#fff',
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          <h2 className="title">{t("qrcash:titleqrcash")}</h2>
          <hr />
          <p>
            {t("qrcash:detailmessage1")} {t("qrcash:detailmessage2")}
          </p>

          <br />

          <h2 id="components-qrcash-howtoenable" className="title-sub">{t("qrcash:howtoenable")}</h2>
          <p>{t("qrcash:howtoenabledetail1")}<a href="mailto:info@gbprimepay.com" target="_blank"> info@gbprimepay.com </a></p>
          <p>{t("qrcash:howtoenabledetail2")}</p>

          <br />

          <h2 id="components-qrcash-paymentflow" className="title-sub">{t("qrcash:paymentflow")}</h2>
          <img
            src={require('../../res/img/PaymentFlow/Flow-QRCash.png')}
            alt="step1"
            style={{ width: '50%', height: 'auto' }}
          />
          {/* <img
            src={require('../../res/img/mockup_mobile.png')}
            alt="step2"
            style={{ width: 'auto', height: 'auto' }}
          />
          <img
            src={require('../../res/img/mockup_mobile.png')}
            alt="step3"
            style={{ width: 'auto', height: 'auto' }}
          />
          <img
            src={require('../../res/img/mockup_mobile.png')}
            alt="step4"
            style={{ width: 'auto', height: 'auto' }}
          /> */}

          <br />

          <h2 id="components-qrcash-steptopayment" className="title-sub">{t("qrcash:steptopayment")}</h2>
          <p>{t("qrcash:steptopaymentdetail1")} {t("qrcash:steptopaymentdetail2")}</p>
          
          <br />

          <h2 id="components-qrcash-implementation" className="title-sub">{t("qrcash:implementation")}</h2>
          {/* <p>{t("qrcash:implementationdetail1")}</p>

          <br /> */}

          <p>{t("qrcash:usageexample")}</p>
          <Tabs defaultActiveKey="1" onChange={this.callback}>
            <TabPane tab="Javascript" key="1">
            {renderFormHtml(this.props.qrCash)}
            </TabPane>
            <TabPane tab="PHP" key="2">
              {renderPhpCode()}
            </TabPane>
            
          </Tabs>
         

          <br />

          <h2 id="creating-source" className="title-sub2">{t("qrcash:creatingsource")}</h2>
          <p>{t("qrcash:creatingsourcedetail1")}</p>
          <p>
            <code>token</code> {' , '} <code>amount</code> {' , '} <code>referenceNo</code> {' , '} 
            <code>backgroundUrl</code> {' , '} <code>detail</code> {' , '} <code>customerName</code> {' , '}
            <code>customerEmail</code> {' , '} <code>merchantDefined1</code> {' , '} <code>merchantDefined2</code> {' , '} 
            <code>merchantDefined3</code> {' , '} <code>merchantDefined4</code> {' , '} <code>merchantDefined5</code>  {' , '} 
            <code>customerTelephone</code> {' and '} < code>customerAddress</code> {'.'}
          </p>

          <br />

          <p>
            <text className="font-bold">QR Cash API URL: </text>
            <text>{`{ENV}/v3/qrcode or {ENV}/v3/qrcode/text`} - Method POST</text>
          </p>

          <br />

          <p className="font-bold">Header</p>
          <Table columns={columns} dataSource={dataHeader} pagination={false} bordered />

          <br />

          <p className="font-bold">Parameter</p>
          <Table  columns={columnsParameter} dataSource={dataParameter} pagination={false} bordered />

          <br />

          <p className="font-bold">Image Response</p>
          <Table columns={columns} dataSource={dataHeaderImage} pagination={false} bordered />
          
          <br />

          {/* <h2 id="creating-charge" className="title-sub2">{t("qrcash:creatingcharge")}</h2>
          <p>{'-'}</p>
          <p>{'-'}</p> */}

          <h2 id="response-data" className="title-sub2">{t("qrcash:response")}</h2>
          <p>{t("qrcash:responsedetail1")}</p>

          <br />

          <h2 className="title-sub2">{t("qrcash:responseheader")}</h2>
          <Table columns={columns} dataSource={dataResponseToMerchant} pagination={false} bordered />

          <br />
          <br />

          <p className="font-bold">JSON Response</p>
          {renderFormCodeResponse()}

          <br />

          <QRDemo />

          <br />
          <br />

          <p className="font-bold">Example Code - Response to Merchant</p>
          <Tabs defaultActiveKey="1" onChange={this.callback}>
            <TabPane tab="PHP" key="1">
              {renderPhpResponse()}
            </TabPane>
            <TabPane tab="Java" key="2">
              {renderJavaResponse()}
            </TabPane>
          </Tabs>

          {/* <br />

          <h2 id="components-qrcash-voidrefund" className="title-sub">{t("qrcash:voidrefunds")}</h2>
          <p>{t("qrcash:voidrefundsdetail1")} {'Refunds API documentation'} {t("qrcash:voidrefundsdetail2")}</p>
          <p>{t("qrcash:voidrefundsdetail3")}</p> */}

          <br />

          <h2 id="components-qrcash-limit" className="title-sub">{t("qrcash:limit")}</h2>
          {/* <p>{t("qrcash:limitdetail1")}</p>
          <p>{i18n.language == 'en' ? t("qrcash:limitdetail2") : ''}</p> */}

          <p>{t("minimumlimits")} 1.00 (THB)</p>
          <p>{t("maximumlimits")} 2,000,000.00 (THB)</p>

        </Content>

        <Sider breakpoint="md" collapsedWidth="0" style={{ background: '#fff' }} h>
          <br />
          <span style={{ color: '#02416D', fontWeight: 'bold', paddingTop: '20px' }}  >
            <Icon type="container" style={{ fontSize: '20px', color: '#08c' }} theme="outlined" />
          </span>
          <span style={{ color: '#02416D', fontWeight: 'bold', paddingTop: '20px' }}>{t("tablecontents")}</span>
          <br />
          <Anchor style={{ paddingTop: '10px' }} >
            <Anchor.Link href="#components-qrcash-howtoenable" title={t("qrcash:howtoenable")} />
            <Anchor.Link href="#components-qrcash-paymentflow" title={t("qrcash:paymentflow")} />
            <Anchor.Link href="#components-qrcash-steptopayment" title={t("qrcash:steptopayment")} />
            <Anchor.Link href="#components-qrcash-implementation" title={t("qrcash:implementation")} >
              <Anchor.Link href="#creating-source" title={t("qrcash:creatingsource")} />
              {/* <Anchor.Link href="#creating-charge" title={t("qrcash:creatingcharge")} /> */}
              <Anchor.Link href="#response-data" title={t("qrcash:response")} />
            </Anchor.Link>
            {/* <Anchor.Link href="#components-qrcash-voidrefund" title={t("qrcash:voidrefunds")} /> */}
            <Anchor.Link href="#components-qrcash-limit" title={t("qrcash:limit")} />
          </Anchor>
        </Sider>

      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  qrCash: state.qrCash
})
const mapDispatchToProps = dispatch => ({
  updateByKey: (key, value) => dispatch(actions.updateByKey(key, value))
})
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(QRAndBarcode));
