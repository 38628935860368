import React, { Component } from 'react'
import { Row, Col, Descriptions, Tabs } from 'antd'
import Prism from 'prismjs'
import PrismCode from './PrismCode'
import { withTranslation } from "react-i18next";

const { TabPane } = Tabs;

const code = `
<form id="checkout-form" action="/">                     
  <div id="gb-form" style="height: 600px;"></div>  
</form>                                                  
<script src="GBPrimePay.js"></script>  <!-- https://github.com/GBPrimepay/gbprimepay-js --> 
<script>                                                 
  new GBPrimePay({                                             
    publicKey: 'publickey_1234abcd',                           
    gbForm: '#gb-form',                                        
    merchantForm: '#checkout-form',                            
    customStyle: {                                             
      backgroundColor: '#eaeaea'                               
    },                                                         
    env: 'prd' // default prd | optional: test, prd           
  });                                                          
</script>
`

const renderFormCode = () => (
  <div>
    <PrismCode
      code={code}
      language="html"
      plugins={["line-numbers"]}
    />
  </div>
)

//export default class CardForm extends Component {
class CardForm extends Component {
  callback = (key) => {
    console.log(key);
  }
  render() {
    const { t , i18n } = this.props;
    return (
      <div>
        <ol>
          <li>{t("recurring:howtoworkdetail1")}</li>
          <li>{t("recurring:howtoworkdetail2")}</li>
        </ol>
      </div>
    );
  }
}

export default withTranslation()(CardForm);