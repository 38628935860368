import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Descriptions, Table } from 'antd'
import Prism from 'prismjs'
import get from 'lodash/get'
import * as creditCardAction from '../../actions/creditCardAction'
import PrismCode from './PrismCode'
import TokenForm from './TokenForm'

const code = `
{
  "rememberCard": false,
  "card": {
      "number": "4987654321098769",
      "expirationMonth": "05",
      "expirationYear": "28",
      "securityCode": "184",
      "name": "Card Test UAT (Server Test)"
  }
}
`

const codeResponse = `
{
  "resultCode": "00",
  "rememberCard": false,
  "card": {
    "number": "453501XXXXXX5741",
    "expirationMonth": "05",
    "expirationYear": "28",
    "name": "Card Test UAT (Server Test)",
    "token": "94a08da1fecbb6e8b46990538c7b50b2"
  }
}
`

const htmlCode = `
<form action="#" method="POST">
  <input id="number" type="text" maxlength="16" placeholder="Card Number" value="{card_number}"><br/>
  <input id="expirationMonth" type="text" maxlength="2" placeholder="MM" value="{expiration_month}"><br/>
  <input id="expirationYear" type="text" maxlength="2" placeholder="YY (Last Two Digits)" value="{expiration_year}"><br/>
  <input id="securityCode" type="password" maxlength="4" autocomplete="off" placeholder="CVV" value="{security_code}"><br/>
  <input id="button" type="submit" value="Pay Now">
</form>
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
<script>
  document.getElementById("button").addEventListener("click", function(event){
    event.preventDefault();
    var publicKey = "{public_key}";
    var dataReq = {
      "rememberCard": false,
      "card": {
          "number": "{card_number}",
          "expirationMonth": "{expiration_month}",
          "expirationYear": "{expiration_year}",
          "securityCode": "{security_code}"
      }
    };
    $.ajax({
        type: "POST",
        url: "https://api.globalprimepay.com/v2/tokens",
        data: JSON.stringify(dataReq),
        contentType: "application/json",
        dataType: "json",
        headers: {
          "Authorization": "Basic " + btoa(publicKey + ":")
        },
        success: function(dataResp){
          var dataStr = JSON.stringify(dataResp);
          alert(dataStr);
        },
        failure: function(errMsg) {
          alert(errMsg);
        }
    });
  });
</script>
`

const renderFormCode = () => (
  <div>
    <PrismCode
      code={code}
      language="json"
      plugins={["line-numbers"]}
    />
  </div>
)

const renderFormCodeResponse = () => (
  <div>
    <PrismCode
      code={codeResponse}
      language="json"
      plugins={["line-numbers"]}
    />
  </div>
)

const renderFormHtml = data => {
  let _htmlCode = htmlCode.replace(/{card_number}/g, data.cardNumber);
  _htmlCode = _htmlCode.replace(/{expiration_month}/g, data.expirationMonth);
  _htmlCode = _htmlCode.replace(/{expiration_year}/g, data.expirationYear);
  _htmlCode = _htmlCode.replace(/{security_code}/g, data.securityCode);
  return (
    <div>
      <PrismCode
        code={_htmlCode}
        language="html"
        plugins={["line-numbers"]}
      />
    </div>
  )
}

const columns = [
  {
    title: 'Field',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Type',
    dataIndex: 'age',
    key: 'age',
  },
  {
    title: 'Description',
    dataIndex: 'address',
    key: 'address',
  }
];

const dataHeader = [
  {
    key: '1',
    name: 'Authorization',
    age: 'String',
    address: `"Basic " + Base64Encode({public_key} + ":")`
  },
];

const dataParameter = [
  {
    key: '1',
    name: 'rememberCard',
    age: 'Boolean',
    indication:'M',
    address: 'true or false'
  },
  {
    key: '2',
    name: 'card' 
  },
  {
    key: '3',
    name: 'number',
    age: 'String (16)',
    indication:'M',
    address: 'Card number'
  },
  {
    key: '4',
    name: 'expirationMonth',
    age: 'String (2)',
    indication:'M',
    address: 'Credit card expiry month'
  },
  {
    key: '5',
    name: 'expirationYear',
    age: 'String (2)',
    indication:'M',
    address: 'Credit card expiry year'
  },
  {
    key: '6',
    name: 'securityCode',
    age: 'String (3)',
    indication:'M',
    address: 'Credit Card Verification Code (CVV or CVC 3-digit)'
  },
  {
    key: '7',
    name: 'name',
    age: 'String (250)',
    indication:'O',
    address: 'Credit card holder name'
  },
];

// const dataParameter = [
//   {
//     key: '1',
//     name: 'rememberCard',
//     age: 'Boolean',
//     address: 'จดจำบัตร'
//   },
//   {
//     key: '2',
//     name: 'card',
//     age: 'Object',
//     address: 'ข้อมูลบัตรเครดิต'
//   },
//   {
//     key: '3',
//     name: 'number',
//     age: 'String',
//     address: 'เลขบัตรเครดิต'
//   },
//   {
//     key: '4',
//     name: 'expirationMonth',
//     age: 'String',
//     address: 'เดือนที่บัตรหมดอายุ (01)'
//   },
//   {
//     key: '5',
//     name: 'expirationYear',
//     age: 'String',
//     address: 'ปี ค.ศ. ที่บัตรหมดอายุ 2 หลักสุดท้าย (25)'
//   },
//   {
//     key: '6',
//     name: 'securityCode',
//     age: 'String',
//     address: 'CVV/CVC (111)'
//   },
//   {
//     key: '7',
//     name: 'name',
//     age: 'String',
//     address: 'ชื่อผู้ถือบัตร'
//   },
// ];

const dataResponse = [
  {
    key: '1',
    name: 'resultCode',
    age: 'String',
    address: `00 = Success, 02 = Invalid data, 54 = Expired Card`
  },
  {
    key: '2',
    name: 'rememberCard',
    age: 'Boolean',
    address: `จดจำบัตร`
  },
  {
    key: '3',
    name: 'card',
    age: 'Object',
    address: `ข้อมูลบัตรเครดิต`
  },
  {
    key: '4',
    name: 'number',
    age: 'String',
    address: `เลขบัตรเครดิต`
  },
  {
    key: '5',
    name: 'expirationMonth',
    age: 'String',
    address: `เดือนที่บัตรหมดอายุ (01)`
  },
  {
    key: '6',
    name: 'expirationYear',
    age: 'String',
    address: `ปี ค.ศ. ที่บัตรหมดอายุ 2 หลักสุดท้าย (25)`
  },
  {
    key: '7',
    name: 'securityCode',
    age: 'String',
    address: `CVV/CVC (111)`
  },
  {
    key: '8',
    name: 'name',
    age: 'String',
    address: `ชื่อผู้ถือบัตร`
  },
  {
    key: '9',
    name: 'token',
    age: 'String',
    address: `Tokenization`
  },
];

class TokenApi extends Component {
  onSubmit = () => {
    this.props.generateToken()
  }
  render() {
    return (
      <div>
        <p style={{ color: 'rgba(0, 0, 0, 0.85)' }}>Token API URL:</p>
        <p>{`{ENV}/v1/tokens`} - Method POST</p>

        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>Header</p>
        <Table columns={columns} dataSource={dataHeader} pagination={false} bordered />

        <br />
        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>Parameter</p>
        <Table columns={columns} dataSource={dataParameter} pagination={false} bordered />

        <br />
        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>Request Example</p>
        {renderFormCode()}

        <br />
        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>Response</p>
        <Table columns={columns} dataSource={dataResponse} pagination={false} bordered />

        <br />
        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>Response Example</p>
        {renderFormCodeResponse()}

        <br />
        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>Example Code</p>
        {renderFormHtml(this.props.creditCard)}

        <br />
        <br />

        <p style={{color: 'rgba(0, 0, 0, 0.85)'}}>Result</p>
        <Row>
          <Col sm={7} md={8}>
            {' '}
          </Col>
          <Col sm={10} md={8}>
            <TokenForm
              updateByKey={this.props.updateByKey}
              onSubmit={this.onSubmit}
              loading={this.props.creditCard.loading}
              token={get(this.props, 'creditCard.tokenResp.card.token', undefined)}
            />
          </Col>
          <Col sm={7} md={8}>
            {' '}
          </Col>
        </Row>

        { this.props.creditCard.tokenResp && (
          <React.Fragment>
            <br /><br />

            <h4>Response</h4>
            <label>Status: </label>{get(this.props.creditCard, 'tokenResp.resultCode', '') === '00' ? 'Successful' : 'Failure'}
            <PrismCode
              code={JSON.stringify(get(this.props.creditCard, 'tokenResp', {}),null,2)}
              language="json"
              plugins={["line-numbers"]}
            />
          </React.Fragment>
        ) }

        <br />
        <br />

      </div>
    );
  }
}

const mapStateToProps = state => ({
  creditCard: state.creditCard
})
const mapDispatchToProps = dispatch => ({
  updateByKey: (key, value) => dispatch(creditCardAction.updateByKey(key, value)),
  generateToken: () => dispatch(creditCardAction.generateToken()),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TokenApi)
