import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Input, Select, Steps, Row, Col } from 'antd'
import get from 'lodash/get'
import * as actions from '../../actions/installmentAction'
import TokenForm from './Form'
import PrismCode from '../Tokenization/PrismCode'
import { generateRef, qrText } from '../../common/Utils'

const { Step } = Steps;

const codeResponse = `
Content-Type: multipart/form-data; boundary=----WebKitFormBoundary

------WebKitFormBoundary
Content-Disposition: form-data; name="amount"

3000.00
------WebKitFormBoundary
Content-Disposition: form-data; name="referenceNo"

{referenceNo}
------WebKitFormBoundary
Content-Disposition: form-data; name="gbpReferenceNo"

gbp{referenceNo}
------WebKitFormBoundary
Content-Disposition: form-data; name="resultCode"

00
------WebKitFormBoundary
Content-Disposition: form-data; name="payMonth"

{payMonth}
------WebKitFormBoundary
Content-Disposition: form-data; name="amountPerMonth"

{amountPerMonth}
------WebKitFormBoundary--
`

const renderFormCodeResponse = data => {
  let _codeResponse = codeResponse.replace(/{amount}/g, data.amount);
  _codeResponse = _codeResponse.replace(/{referenceNo}/g, data.referenceNo);
  _codeResponse = _codeResponse.replace(/{payMonth}/g, (data.term).padStart(2, '0'))
  _codeResponse = _codeResponse.replace(/{amountPerMonth}/g, (3000 / data.term).toFixed(2));
  return (
    <div>
      <PrismCode
        code={_codeResponse}
        language="http"
        plugins={["line-numbers"]}
      />
    </div>
  )
}

class Demo extends Component {
  state = {
    currentStep: 0,
    otp: ''
  }
  componentDidMount() {
    this.props.updateByKey('referenceNo', generateRef())
  }
  changeStep = current => {
    this.setState({ currentStep: current })
  }
  changeStepPrev = () => {
    this.setState({ currentStep: this.state.currentStep - 1 })
  }
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }
  render() {
    const isValid2 = this.state.otp === '123456'
    return (
      <>
        <p style={{color: 'rgba(0, 0, 0, 0.85)', marginTop: 10 }}>Result</p>
        <Steps size="small" current={this.state.currentStep}>
          <Step title="Create" />
          <Step title="3D Secure" />
          <Step title="Response" />
        </Steps>

        <div className="steps-content">
          { this.state.currentStep === 0 && (
            <Row>
              <Col sm={7} md={8}>
                {' '}
              </Col>
              <Col sm={10} md={8}>
                <TokenForm
                  updateByKey={this.props.updateByKey}
                  onSubmit={() => this.changeStep(1)}
                  loading={this.props.qrCash.loading}
                  data={this.props.qrCash}
                />
              </Col>
              <Col sm={7} md={8}>
                {' '}
              </Col>
            </Row>
            )}

            { this.state.currentStep === 1 && (
              <Row>
                <Col sm={5} md={8}>
                  {' '}
                </Col>
                <Col sm={14} md={8}>
                  <div id="divBody">
                    <div className="card-wrapper d-none"></div>
                    <div className="row">
                      <div className="col-sm-12 text-center">
                        <h2 style={{ color: '#337ab7' }}>Verified by GB Pay</h2>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 text-right">
                        <label htmlFor="">
                          The OTP code for your online payment :
                        </label>
                      </div>
                      <div className="col-sm-6">
                        <label className="fix-label d-flex justify--between-md " htmlFor="">
                          <span style={{ color: '#337ab7' }}>123456</span>
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 text-right">
                        <label htmlFor="">Please input OTP code :</label>
                      </div>
                      <div className="col-sm-6">
                        <Input name="otp" maxLength={6} placeholder="123456" onChange={this.onChange} autoComplete="off" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 text-center" style={{ marginTop: '15px' }}>
                        <Button type="primary" onClick={() => this.changeStep(2)} disabled={!isValid2}>Confirm</Button>
                        <Button style={{ marginLeft: 8 }} onClick={this.changeStepPrev}>
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={5} md={8}>
                  {' '}
                </Col>
              </Row>
            )}

            { this.state.currentStep === 2 && (
              <>
                <Row>
                  <Col sm={2} md={4}>
                    {' '}
                  </Col>
                  <Col sm={20} md={16}>
                    <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold', textAlign: 'left' }}>Data Response</p>
                    {renderFormCodeResponse(this.props.qrCash)}
                    <br/>
                  </Col>
                  <Col sm={2} md={4}>
                    {' '}
                  </Col>
                </Row>
                <Row>
                  <Col sm={5} md={8}>
                    {' '}
                  </Col>
                  <Col sm={14} md={8}>
                    <Button type="primary" onClick={() => this.changeStep(0)} block>Done</Button><br/>
                    <Button style={{ marginTop: 8 }} onClick={this.changeStepPrev} block>
                      Previous
                    </Button>
                  </Col>
                  <Col sm={5} md={8}>
                    {' '}
                  </Col>
                </Row>
              </>
            )}
          </div>
      </>
    )
  }
}
const mapStateToProps = state => ({
  qrCash: state.installment
})
const mapDispatchToProps = dispatch => ({
  updateByKey: (key, value) => dispatch(actions.updateByKey(key, value))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Demo)