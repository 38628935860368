import React, { Component } from 'react'
import { Table } from 'antd'
import { withTranslation } from "react-i18next"

//export default class ResultCode extends Component {
class ResultCode extends Component {
  render() {

    const { t , i18n } = this.props;

    const columnsResultCode = [
      {
        title: t("fullpayment3d:code"),
        dataIndex: 'name',
        key: 'name',
        render: text => <code>{text}</code>
      },
      {
        title: t("fullpayment3d:meaning"),
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: t("fullpayment3d:recommendation"),
        dataIndex: 'address',
        key: 'address',
      }
    ];
    
    const dataResultCode = [
      {
        key: '0',
        name: '00',
        age: 'Approved',
        address: t("fullpayment3d:paymentcompleted")
      },
      {
        key: '1',
        name: '01',
        age: 'Refer to Card Issuer',
        address: t("fullpayment3d:refertocardissuer")
      },
      {
        key: '2',
        name: '03',
        age: 'Invalid Merchant ID',
        address: t("fullpayment3d:contactgb")
      },
      {
        key: '3',
        name: '05',
        age: 'Do Not Honour',
        address: t("fullpayment3d:donothonour")
      },
      {
        key: '4',
        name: '12',
        age: 'Invalid Transaction',
        address: t("fullpayment3d:contactgb")
      },
      {
        key: '5',
        name: '13',
        age: 'Invalid Amount',
        address: t("fullpayment3d:invalidamount")
      },
      {
        key: '6',
        name: '14',
        age: 'Invalid Card Number',
        address: t("fullpayment3d:invalidcardno")
      },
      {
        key: '7',
        name: '17',
        age: 'Customer Cancellation',
        address: t("fullpayment3d:customercancellation")
      },
      {
        key: '8',
        name: '19',
        age: 'Re-enter Transaction',
        address: t("fullpayment3d:reentertransaction")
      },
      {
        key: '9',
        name: '30',
        age: 'Format Error',
        address: t("fullpayment3d:forrmaterror")
      },
      {
        key: '10',
        name: '41',
        age: 'Lost Card -Pick Up',
        address: t("fullpayment3d:lostcard")
      },
      {
        key: '11',
        name: '43',
        age: 'Stolen Card -Pick Up',
        address: t("fullpayment3d:stolencard")
      },
      {
        key: '12',
        name: '50',
        age: 'Invalid Payment Condition',
        address: t("fullpayment3d:invalidpayment")
      },
      {
        key: '13',
        name: '51',
        age: 'Insufficient Funds',
        address: t("fullpayment3d:insufficientfunds")
      },
      {
        key: '14',
        name: '54',
        age: 'Expired Card',
        address: t("fullpayment3d:expiredcard")
      },
      {
        key: '15',
        name: '55',
        age: 'Wrong Pin',
        address: t("fullpayment3d:wrongpin")
      },
      {
        key: '16',
        name: '58',
        age: 'Transaction not Permitted to Terminal',
        address: t("fullpayment3d:permittedterminal")
      },
      {
        key: '17',
        name: '68',
        age: 'Response Received Too Late',
        address: t("fullpayment3d:responsetolate")
      },
      {
        key: '18',
        name: '91',
        age: 'Issuer or Switch is Inoperative',
        address: t("fullpayment3d:inoperative")
      },
      {
        key: '19',
        name: '94',
        age: 'Duplicate Transmission',
        address: t("fullpayment3d:duplicatetransmission")
      },
      {
        key: '20',
        name: '96',
        age: 'System Malfunction',
        address: t("fullpayment3d:systemmalfunction")
      },
      {
        key: '21',
        name: 'xx',
        age: 'Transaction Timeout',
        address: t("fullpayment3d:transactiontimeout")
      },
    ];

    return (
      <div>
        
        <Table columns={columnsResultCode} dataSource={dataResultCode} pagination={false} bordered />

      </div>
    );
  }
}

export default withTranslation()(ResultCode);
