import React, { Component, Fragment } from 'react'
import { useSelector, connect } from 'react-redux'
import { Layout, Button, Breadcrumb, Icon, Table, Row, Col, Tabs, Anchor, Tree, Tag, Select, Collapse, Descriptions } from 'antd'
import get from 'lodash/get'
import PrismCode from '../Tokenization/PrismCode'
import * as actions from '../../actions/qrCashAction'
import ResultCode from "../FullPayment3D/ResultCode";
import { withTranslation } from "react-i18next";
import parse from "react-html-parser";

const { Content, Sider } = Layout;
const { TabPane } = Tabs;
const { Option } = Select;
const { Panel } = Collapse;

const codeRequestToken = `
{
  "secretKey":"{secretKey}",
  "publicKey":"{publicKey}"
}
`

const codeRequestTransectionPcCard = `
{
  "apiType": "PC",
  "createPaymentTransactionRequest" : {
      "referenceNo" : "202212260076", 
      "paymentType" : "C", 
      "amount" : 3000.00, 
      "cardNumber" : "5258860689905862", 
      "expirationMonth" : "02", 
      "expirationYear" : "25", 
      "securityCode" : "950", 
      "rememberCard" : true, 
      "otp" : "Y", 
      "responseUrl" : "https://merchant_url", 
      "backgroundUrl" : "https://merchant_url", 
      "detail" : "t-shirt", 
      "customerName" : "Name Lastname", 
      "customerEmail" : "test@mail.com", 
      "customerTelephone" : "0900000000", 
      "customerAddress" : "No.123", 
      "merchantDefined1" : "MerchantDefined 1", 
      "merchantDefined2" : "MerchantDefined 2", 
      "merchantDefined3" : "MerchantDefined 3", 
      "merchantDefined4" : "MerchantDefined 4", 
      "merchantDefined5" : "MerchantDefined 5"
  }
}
`

const codeRequestTransectionPCInstallment = `
{
  "apiType": "PC",
  "createPaymentTransactionRequest" : {
      "referenceNo" : "202212260076", 
      "paymentType" : "I", 
      "term" : "3", 
      "bankCode" : "004", 
      "amount" : 3000.00, 
      "responseUrl" : "https://merchant_url", 
      "backgroundUrl" : "https://merchant_url", 
      "detail" : "t-shirt", 
      "customerName" : "Name Lastname", 
      "customerEmail" : "test@mail.com", 
      "customerTelephone" : "0900000000", 
      "customerAddress" : "No.123", 
      "merchantDefined1" : "MerchantDefined 1", 
      "merchantDefined2" : "MerchantDefined 2", 
      "merchantDefined3" : "MerchantDefined 3", 
      "merchantDefined4" : "MerchantDefined 4", 
      "merchantDefined5" : "MerchantDefined 5"
  }
}
`

const codeRequestTransectionPCBillPayment = `
{
  "apiType": "PC",
  "createPaymentTransactionRequest" : {
      "referenceNo" : "202212260076", 
      "paymentType" : "B", 
      "amount" : 20.00, 
      "responseUrl" : "https://merchant_url", 
      "backgroundUrl" : "https://merchant_url", 
      "detail" : "t-shirt", 
      "customerName" : "Name Lastname", 
      "customerEmail" : "test@mail.com", 
      "customerTelephone" : "0900000000", 
      "customerAddress" : "No.123", 
      "merchantDefined1" : "MerchantDefined 1", 
      "merchantDefined2" : "MerchantDefined 2", 
      "merchantDefined3" : "MerchantDefined 3", 
      "merchantDefined4" : "MerchantDefined 4", 
      "merchantDefined5" : "MerchantDefined 5"
  }
}
`

const codeRequestTransectionPCQrCash = `
{
  "apiType": "PC",
  "createPaymentTransactionRequest" : {
      "referenceNo" : "202212260076", 
      "paymentType" : "Q", 
      "amount" : 20.00, 
      "responseUrl" : "https://merchant_url", 
      "backgroundUrl" : "https://merchant_url", 
      "detail" : "t-shirt", 
      "customerName" : "Name Lastname", 
      "customerEmail" : "test@mail.com", 
      "customerTelephone" : "0900000000", 
      "customerAddress" : "No.123", 
      "merchantDefined1" : "MerchantDefined 1", 
      "merchantDefined2" : "MerchantDefined 2", 
      "merchantDefined3" : "MerchantDefined 3", 
      "merchantDefined4" : "MerchantDefined 4", 
      "merchantDefined5" : "MerchantDefined 5"
  }
}
`

const codeRequestTransectionPCQrCashText = `
{
  "apiType": "PC",
  "createPaymentTransactionRequest" : {
      "referenceNo" : "202212260076", 
      "paymentType" : "Q", 
      "qrType" : "T", 
      "amount" : 20.00, 
      "responseUrl" : "https://merchant_url", 
      "backgroundUrl" : "https://merchant_url", 
      "detail" : "t-shirt", 
      "customerName" : "Name Lastname", 
      "customerEmail" : "test@mail.com", 
      "customerTelephone" : "0900000000", 
      "customerAddress" : "No.123", 
      "merchantDefined1" : "MerchantDefined 1", 
      "merchantDefined2" : "MerchantDefined 2", 
      "merchantDefined3" : "MerchantDefined 3", 
      "merchantDefined4" : "MerchantDefined 4", 
      "merchantDefined5" : "MerchantDefined 5"
  }
}
`

const codeRequestTransectionPCQrCredit = `
{
  "apiType": "PC",
  "createPaymentTransactionRequest" : {
      "referenceNo" : "202212260076", 
      "paymentType" : "V", 
      "amount" : 20.00, 
      "backgroundUrl" : "https://merchant_url", 
      "detail" : "t-shirt", 
      "customerName" : "Name Lastname", 
      "customerEmail" : "test@mail.com", 
      "customerTelephone" : "0900000000", 
      "customerAddress" : "No.123", 
      "merchantDefined1" : "MerchantDefined 1", 
      "merchantDefined2" : "MerchantDefined 2", 
      "merchantDefined3" : "MerchantDefined 3", 
      "merchantDefined4" : "MerchantDefined 4", 
      "merchantDefined5" : "MerchantDefined 5"
  }
}
`

const codeRequestTransectionPCWechat = `
{
  "apiType": "PC",
  "createPaymentTransactionRequest" : {
      "referenceNo" : "202212260076", 
      "paymentType" : "W", 
      "amount" : 20.00, 
      "backgroundUrl" : "https://merchant_url", 
      "detail" : "t-shirt", 
      "customerName" : "Name Lastname", 
      "customerEmail" : "test@mail.com", 
      "customerTelephone" : "0900000000", 
      "customerAddress" : "No.123", 
      "merchantDefined1" : "MerchantDefined 1", 
      "merchantDefined2" : "MerchantDefined 2", 
      "merchantDefined3" : "MerchantDefined 3", 
      "merchantDefined4" : "MerchantDefined 4", 
      "merchantDefined5" : "MerchantDefined 5"
  }
}
`

const codeRequestTransectionPCLinePay = `
{
  "apiType": "PC",
  "createPaymentTransactionRequest" : {
      "referenceNo" : "202212260076", 
      "paymentType" : "L", 
      "amount" : 20.00, 
      "responseUrl" : "https://merchant_url", 
      "backgroundUrl" : "https://merchant_url", 
      "detail" : "t-shirt", 
      "customerName" : "Name Lastname", 
      "customerEmail" : "test@mail.com", 
      "customerTelephone" : "0900000000", 
      "customerAddress" : "No.123", 
      "merchantDefined1" : "MerchantDefined 1", 
      "merchantDefined2" : "MerchantDefined 2", 
      "merchantDefined3" : "MerchantDefined 3", 
      "merchantDefined4" : "MerchantDefined 4", 
      "merchantDefined5" : "MerchantDefined 5"
  }
}
`

const codeRequestTransectionPCTrue = `
{
  "apiType": "PC",
  "createPaymentTransactionRequest" : {
      "referenceNo" : "202212260076", 
      "paymentType" : "T", 
      "amount" : 20.00, 
      "responseUrl" : "https://merchant_url", 
      "backgroundUrl" : "https://merchant_url", 
      "detail" : "t-shirt", 
      "customerName" : "Name Lastname", 
      "customerEmail" : "test@mail.com", 
      "customerTelephone" : "0900000000", 
      "customerAddress" : "No.123", 
      "merchantDefined1" : "MerchantDefined 1", 
      "merchantDefined2" : "MerchantDefined 2", 
      "merchantDefined3" : "MerchantDefined 3", 
      "merchantDefined4" : "MerchantDefined 4", 
      "merchantDefined5" : "MerchantDefined 5"
  }
}
`

const codeRequestTransectionPCShopeePay = `
{
  "apiType": "PC",
  "createPaymentTransactionRequest" : {
      "referenceNo" : "202212260076", 
      "paymentType" : "S", 
      "amount" : 20.00, 
      "responseUrl" : "https://merchant_url", 
      "backgroundUrl" : "https://merchant_url", 
      "detail" : "t-shirt", 
      "customerName" : "Name Lastname", 
      "customerEmail" : "test@mail.com", 
      "customerTelephone" : "0900000000", 
      "customerAddress" : "No.123", 
      "merchantDefined1" : "MerchantDefined 1", 
      "merchantDefined2" : "MerchantDefined 2", 
      "merchantDefined3" : "MerchantDefined 3", 
      "merchantDefined4" : "MerchantDefined 4", 
      "merchantDefined5" : "MerchantDefined 5"
  }
}
`

const codeRequestTransectionPCAtome = `
{
  "apiType": "PC",
  "createPaymentTransactionRequest" : {
      "referenceNo" : "202212260076", 
      "paymentType" : "A", 
      "amount" : 20.00, 
      "responseUrl" : "https://merchant_url", 
      "backgroundUrl" : "https://merchant_url", 
      "detail" : "t-shirt", 
      "customerName" : "Name Lastname", 
      "customerEmail" : "test@mail.com", 
      "customerTelephone" : "0900000000", 
      "customerAddress" : "No.123", 
      "merchantDefined1" : "MerchantDefined 1", 
      "merchantDefined2" : "MerchantDefined 2", 
      "merchantDefined3" : "MerchantDefined 3", 
      "merchantDefined4" : "MerchantDefined 4", 
      "merchantDefined5" : "MerchantDefined 5"
  }
}
`

const codeRequestTransectionPCMobile = `
{
  "apiType": "PC",
  "createPaymentTransactionRequest" : {
      "referenceNo" : "202212260076", 
      "paymentType" : "M", 
      "amount" : 20.00, 
      "responseUrl" : "https://merchant_url", 
      "backgroundUrl" : "https://merchant_url", 
      "detail" : "t-shirt", 
      "customerName" : "Name Lastname", 
      "customerEmail" : "test@mail.com", 
      "customerTelephone" : "0900000000", 
      "customerAddress" : "No.123", 
      "merchantDefined1" : "MerchantDefined 1", 
      "merchantDefined2" : "MerchantDefined 2", 
      "merchantDefined3" : "MerchantDefined 3", 
      "merchantDefined4" : "MerchantDefined 4", 
      "merchantDefined5" : "MerchantDefined 5"
  }
}
`

const codeRequestTransectionPCAlipay = `
{
  "apiType" : "PC",
  "createPaymentTransactionRequest" : {
      "referenceNo" : "202212260076", 
      "paymentType" : "Y", 
      "amount" : 20.00, 
      "cardNumber" : "5258860689905862", 
      "expirationMonth" : "02", 
      "expirationYear" : "25", 
      "securityCode" : "950", 
      "rememberCard" : true, 
      "otp" : "Y", 
      "responseUrl" : "https://merchant_url", 
      "backgroundUrl" : "https://merchant_url", 
      "detail" : "1", 
      "customerName" : "CardName Test", 
      "customerEmail" : "test@mail.com", 
      "customerTelephone" : "0900000000", 
      "customerAddress" : "No.123", 
      "merchantDefined1" : "MerchantDefined 1", 
      "merchantDefined2" : "MerchantDefined 2", 
      "merchantDefined3" : "MerchantDefined 3", 
      "merchantDefined4" : "MerchantDefined 4", 
      "merchantDefined5" : "MerchantDefined 5"
  }
}
`

const codeRequestTransectionPV = `
{
  "apiType" : "PV",
  "voidTransactionRequest" : {
      "gbpReferenceNo" : "gbp0001000900"
  }
}
`

const codeRequestTransectionPR = `
{
  "apiType" : "PR",
  "refundTransactionRequest" : {
      "gbpReferenceNo" : "gbp0001000900",
      "referenceNo" : "202212260076",
      "refundAmount" : 1.00
  }
}
`

const codeRequestTransectionPS = `
{
  "apiType" : "PS",
  "settlementTransactionRequest" : {
      "gbpReferenceNo" : "gbp0001000900",
      "settleAmount" : 1.00,
      "backgroundUrl" : "https://merchant_url"
  }
}
`

const codeRequestTransectionPQ = `
{
  "apiType" : "PQ",
  "queryTransactionRequest" : {
      "fromDate" : "2022-12-26T00:00:00",
      "toDate" : "2022-12-26T23:59:59",
      "limit" : 100,
      "offset" : 0,
      "referenceNo" : "202212260076",
      "orderBy" : "desc"
  }
}
`

const codeRequestTransectionBQ = `
{
  "apiType" : "BQ"
}
`

const codeRequestTransectionTC = `
{
  "apiType" : "TC",
  "transfersRequest" : {
      "referenceNo" : "202212260076",
      "bankAccount" : "7771000241",
      "bankCode" : "025",
      "amount" : 1.00,
      "backgroundUrl" : "https://merchant_url",
      "accHolderName" : "Name Account",
      "merchantDefined1" : "MerchantDefined 1", 
      "merchantDefined2" : "MerchantDefined 2", 
      "merchantDefined3" : "MerchantDefined 3", 
      "merchantDefined4" : "MerchantDefined 4", 
      "merchantDefined5" : "MerchantDefined 5"
  }
}
`

const codeRequestTransectionTQ = `
{
  "apiType" : "TQ",
  "transfersRequest" : {
      "referenceNo" : "202212260076"
  }
}
`

const codeResponseDataPC = `
{
  "apiType" : "PC",
  "status" : "REQUIRES_ACTION",
  "resultCode" : "00",
  "resultMessage" : "Success",
  "redirectUrl" : "https://merchant_url",
  "referenceNo" : "2022122600045",
  "token" : "",
  "transactionStatus" : "G",
  "gbpReferenceNo" : "gbp0001000900",
  "paymentType" : "C",
  "detail" : "Test CreditCard",
  "customerName" : "Name Lastname",
  "customerEmail" : "test@mail.com",
  "customerAddress" : "No.123",
  "customerTelephone" : "0900000000",
  "merchantDefined1" : "MerchantDefined 1", 
  "merchantDefined2" : "MerchantDefined 2", 
  "merchantDefined3" : "MerchantDefined 3", 
  "merchantDefined4" : "MerchantDefined 4", 
  "merchantDefined5" : "MerchantDefined 5"
}
`

const codeResponseDataPCInstallment = `
{
  "apiType" : "PC",
  "status" : "REQUIRES_ACTION",
  "resultCode" : "00",
  "resultMessage" : "Success",
  "redirectUrl" : "https://merchant_url",
  "referenceNo" : "2022122600045",
  "token" : "",
  "transactionStatus" : "G",
  "gbpReferenceNo" : "gbp0001000900",
  "paymentType" : "I",
  "detail" : "Test Installment",
  "customerName" : "Name Lastname",
  "customerEmail" : "test@mail.com",
  "customerAddress" : "No.123",
  "customerTelephone" : "0900000000",
  "merchantDefined1" : "MerchantDefined 1", 
  "merchantDefined2" : "MerchantDefined 2", 
  "merchantDefined3" : "MerchantDefined 3", 
  "merchantDefined4" : "MerchantDefined 4", 
  "merchantDefined5" : "MerchantDefined 5"
}
`

const codeResponseDataPCBillPayment = `
{
  "apiType" : "PC",
  "status" : "REQUIRES_ACTION",
  "resultCode" : "00",
  "resultMessage" : "Success",
  "redirectUrl" : "https://merchant_url",
  "referenceNo" : "2022122600045",
  "transactionStatus" : "G",
  "gbpReferenceNo" : "gbp0001000900",
  "paymentType" : "B",
  "detail" : "Test BillPayment",
  "customerName" : "Name Lastname",
  "customerEmail" : "test@mail.com",
  "customerAddress" : "No.123",
  "customerTelephone" : "0900000000",
  "merchantDefined1" : "MerchantDefined 1", 
  "merchantDefined2" : "MerchantDefined 2", 
  "merchantDefined3" : "MerchantDefined 3", 
  "merchantDefined4" : "MerchantDefined 4", 
  "merchantDefined5" : "MerchantDefined 5"
}
`

const codeResponseDataPCQrCash = `
{
  "apiType" : "PC",
  "status" : "REQUIRES_ACTION",
  "resultCode" : "00",
  "resultMessage" : "Success",
  "redirectUrl" : "https://merchant_url",
  "referenceNo" : "2022122600045",
  "token" : "",
  "transactionStatus" : "G",
  "gbpReferenceNo" : "gbp0001000900",
  "paymentType" : "Q",
  "detail" : "Test QRCash",
  "customerName" : "Name Lastname",
  "customerEmail" : "test@mail.com",
  "customerAddress" : "No.123",
  "customerTelephone" : "0900000000",
  "merchantDefined1" : "MerchantDefined 1", 
  "merchantDefined2" : "MerchantDefined 2", 
  "merchantDefined3" : "MerchantDefined 3", 
  "merchantDefined4" : "MerchantDefined 4", 
  "merchantDefined5" : "MerchantDefined 5"
}
`

const codeResponseDataPCQrCashText = `
{
  "apiType" : "PC",
  "status" : "REQUIRES_ACTION",
  "resultCode" : "00",
  "resultMessage" : "Success",
  "redirectUrl" : "https://merchant_url",
  "referenceNo" : "2022122600045",
  "token" : "",
  "transactionStatus" : "G",
  "dataText": "xxxxx",
  "gbpReferenceNo" : "gbp0001000900",
  "paymentType" : "Q",
  "detail" : "Test QRCash (Text)",
  "customerName" : "Name Lastname",
  "customerEmail" : "test@mail.com",
  "customerAddress" : "No.123",
  "customerTelephone" : "0900000000",
  "merchantDefined1" : "MerchantDefined 1", 
  "merchantDefined2" : "MerchantDefined 2", 
  "merchantDefined3" : "MerchantDefined 3", 
  "merchantDefined4" : "MerchantDefined 4", 
  "merchantDefined5" : "MerchantDefined 5"
}
`

const codeResponseDataPCQrCredit = `
{
  "apiType" : "PC",
  "status" : "REQUIRES_ACTION",
  "resultCode" : "00",
  "resultMessage" : "Success",
  "redirectUrl" : "https://merchant_url",
  "referenceNo" : "2022122600045",
  "transactionStatus" : "G",
  "gbpReferenceNo" : "gbp0001000900",
  "paymentType" : "V",
  "detail" : "Test QRCredit",
  "customerName" : "Name Lastname",
  "customerEmail" : "test@mail.com",
  "customerAddress" : "No.123",
  "customerTelephone" : "0900000000",
  "merchantDefined1" : "MerchantDefined 1", 
  "merchantDefined2" : "MerchantDefined 2", 
  "merchantDefined3" : "MerchantDefined 3", 
  "merchantDefined4" : "MerchantDefined 4", 
  "merchantDefined5" : "MerchantDefined 5"
}
`

const codeResponseDataPCWechat = `
{
  "apiType" : "PC",
  "status" : "REQUIRES_ACTION",
  "resultCode" : "00",
  "resultMessage" : "Success",
  "redirectUrl" : "https://merchant_url",
  "referenceNo" : "2022122600045",
  "transactionStatus" : "G",
  "dataText": "",
  "gbpReferenceNo" : "gbp0001000900",
  "paymentType" : "W",
  "detail" : "Test Wechat",
  "customerName" : "Name Lastname",
  "customerEmail" : "test@mail.com",
  "customerAddress" : "No.123",
  "customerTelephone" : "0900000000",
  "merchantDefined1" : "MerchantDefined 1", 
  "merchantDefined2" : "MerchantDefined 2", 
  "merchantDefined3" : "MerchantDefined 3", 
  "merchantDefined4" : "MerchantDefined 4", 
  "merchantDefined5" : "MerchantDefined 5"
}
`

const codeResponseDataPCLine = `
{
  "apiType" : "PC",
  "status" : "REQUIRES_ACTION",
  "resultCode" : "00",
  "resultMessage" : "Success",
  "redirectUrl" : "https://merchant_url",
  "referenceNo" : "2022122600045",
  "transactionStatus" : "G",
  "gbpReferenceNo" : "gbp0001000900",
  "paymentType" : "L",
  "detail" : "Test LinePay",
  "customerName" : "Name Lastname",
  "customerEmail" : "test@mail.com",
  "customerAddress" : "No.123",
  "customerTelephone" : "0900000000",
  "merchantDefined1" : "MerchantDefined 1", 
  "merchantDefined2" : "MerchantDefined 2", 
  "merchantDefined3" : "MerchantDefined 3", 
  "merchantDefined4" : "MerchantDefined 4", 
  "merchantDefined5" : "MerchantDefined 5"
}
`

const codeResponseDataPCTrue = `
{
  "apiType" : "PC",
  "status" : "REQUIRES_ACTION",
  "resultCode" : "00",
  "resultMessage" : "Success",
  "redirectUrl" : "https://merchant_url",
  "referenceNo" : "2022122600045",
  "transactionStatus" : "G",
  "gbpReferenceNo" : "gbp0001000900",
  "paymentType" : "T",
  "detail" : "Test True",
  "customerName" : "Name Lastname",
  "customerEmail" : "test@mail.com",
  "customerAddress" : "No.123",
  "customerTelephone" : "0900000000",
  "merchantDefined1" : "MerchantDefined 1", 
  "merchantDefined2" : "MerchantDefined 2", 
  "merchantDefined3" : "MerchantDefined 3", 
  "merchantDefined4" : "MerchantDefined 4", 
  "merchantDefined5" : "MerchantDefined 5"
}
`

const codeResponseDataPCShopee = `
{
  "apiType" : "PC",
  "status" : "REQUIRES_ACTION",
  "resultCode" : "00",
  "resultMessage" : "Success",
  "redirectUrl" : "https://merchant_url",
  "referenceNo" : "2022122600045",
  "transactionStatus" : "G",
  "gbpReferenceNo" : "gbp0001000900",
  "paymentType" : "S",
  "detail" : "Test Shopee",
  "customerName" : "Name Lastname",
  "customerEmail" : "test@mail.com",
  "customerAddress" : "No.123",
  "customerTelephone" : "0900000000",
  "merchantDefined1" : "MerchantDefined 1", 
  "merchantDefined2" : "MerchantDefined 2", 
  "merchantDefined3" : "MerchantDefined 3", 
  "merchantDefined4" : "MerchantDefined 4", 
  "merchantDefined5" : "MerchantDefined 5"
}
`

const codeResponseDataPCAtome = `
{
  "apiType" : "PC",
  "status" : "REQUIRES_ACTION",
  "resultCode" : "00",
  "resultMessage" : "Success",
  "redirectUrl" : "https://merchant_url",
  "referenceNo" : "2022122600045",
  "transactionStatus" : "G",
  "gbpReferenceNo" : "gbp0001000900",
  "paymentType" : "A",
  "detail" : "Test Atome",
  "customerName" : "Name Lastname",
  "customerEmail" : "test@mail.com",
  "customerAddress" : "No.123",
  "customerTelephone" : "0900000000",
  "merchantDefined1" : "MerchantDefined 1", 
  "merchantDefined2" : "MerchantDefined 2", 
  "merchantDefined3" : "MerchantDefined 3", 
  "merchantDefined4" : "MerchantDefined 4", 
  "merchantDefined5" : "MerchantDefined 5"
}
`

const codeResponseDataPCMobileBanking = `
{
  "apiType" : "PC",
  "status" : "REQUIRES_ACTION",
  "resultCode" : "00",
  "resultMessage" : "Success",
  "redirectUrl" : "https://merchant_url",
  "referenceNo" : "2022122600045",
  "transactionStatus" : "G",
  "gbpReferenceNo" : "gbp0001000900",
  "paymentType" : "M",
  "detail" : "Test Banking",
  "customerName" : "Name Lastname",
  "customerEmail" : "test@mail.com",
  "customerAddress" : "No.123",
  "customerTelephone" : "0900000000",
  "merchantDefined1" : "MerchantDefined 1", 
  "merchantDefined2" : "MerchantDefined 2", 
  "merchantDefined3" : "MerchantDefined 3", 
  "merchantDefined4" : "MerchantDefined 4", 
  "merchantDefined5" : "MerchantDefined 5"
}
`

const codeResponseDataPCAlipay = `
{
  "apiType" : "PC",
  "status" : "REQUIRES_ACTION",
  "resultCode" : "00",
  "resultMessage" : "Success",
  "redirectUrl" : "https://merchant_url",
  "referenceNo" : "2022122600045",
  "transactionStatus" : "G",
  "gbpReferenceNo" : "gbp0001000900",
  "paymentType" : "Y",
  "detail" : "Test Alipay",
  "customerName" : "Name Lastname",
  "customerEmail" : "test@mail.com",
  "customerAddress" : "No.123",
  "customerTelephone" : "0900000000",
  "merchantDefined1" : "MerchantDefined 1", 
  "merchantDefined2" : "MerchantDefined 2", 
  "merchantDefined3" : "MerchantDefined 3", 
  "merchantDefined4" : "MerchantDefined 4", 
  "merchantDefined5" : "MerchantDefined 5"
}
`

// const codeResponseDataPC = `
// {
//   "apiType": "PC",
//   "status": "REQUIRES_ACTION",
//   "resultCode": "00",
//   "resultMessage": "Success",
//   "redirectUrl": "https://merchant_url",
//   "referenceNo": "2022122600045",
//   "token": "",
//   "transactionStatus": "G",
//   "action": null,
//   "dataText": null,
//   "amount": null,
//   "gbpReferenceNo": "gbp0001000900",
//   "refundReferenceNo": null,
//   "currencyCode": null,
//   "paymentType": null,
//   "date": null,
//   "time": null,
//   "detail": "1",
//   "customerName": "Name Lastname",
//   "customerEmail": "test@mail.com",
//   "customerAddress": "No.123",
//   "customerTelephone": "0900000000",
//   "merchantDefined1" : "MerchantDefined 1", 
//   "merchantDefined2" : "MerchantDefined 2", 
//   "merchantDefined3" : "MerchantDefined 3", 
//   "merchantDefined4" : "MerchantDefined 4", 
//   "merchantDefined5" : "MerchantDefined 5"
// }
// `

const codeResponseDataPV= `
{
  "resultCode" : "00",
  "resultMessage" : "Success",
  "gbpReferenceNo" : "gbp0001000900"
}
`

const codeResponseDataPR = `
{
  "resultCode" : "00",
  "resultMessage" : "Success",
  "gbpReferenceNo" : "gbp0001000900"
  "referenceNo" : "2022122600045",
  "refundReferenceNo" : "221228000022708",
}
`

const codeResponseDataPS = `
{
  "resultCode" : "00",
  "resultMessage" : "Success",
  "gbpReferenceNo" : "gbp0001000900"
}
`

const codeResponseDataPQ = `
{
  "resultCode" : "00",
  "resultMessage" : "Success",
  "limit" : 100,
  "offset" : 0,
  "total" : 2,
  "data" : [
      {
        "gbpReferenceNo" : "gbp0001000900",
        "paymentType" : "C",
        "status" : "V",
        "amount" : 3000.00,
        "referenceNo" : "1231213426",
        "resultCode" : "00",
        "currencyCode" : "764",
        "createTimeStamp" : "2022-12-26T11:23:54",
        "createDate" : "20221226",
        "createTime" : "112354",
        "paymentTimeStamp" : "2022-12-26T11:23:54",
        "paymentDate" : "20221226",
        "paymentTime" : "112354",
        "cardNo" : "453501XXXXXX5741",
        "cardHolderName" : "Name Lastname",
        "settleDate" : "20221226",
        "cardLocation" : "I",
        "cardType" : "VIS",
        "payMonth" : null,
        "amountPerMonth" : ".00",
        "totalAmount" : 0.00,
        "thbAmount" : 0.00,
        "issuerBank" : "Bangkok Bank Public Company Limited",
        "openId" : null,
        "detail" : "1",
        "customerName" : "Name Lastname",
        "customerEmail" : "test@mail.com",
        "customerAddress" : "No.123",
        "customerTelephone" : "0900000000",
        "fee" : 34.50,
        "vat" : 2.41500,
        "merchantDefined1" : "MerchantDefined 1", 
        "merchantDefined2" : "MerchantDefined 2", 
        "merchantDefined3" : "MerchantDefined 3", 
        "merchantDefined4" : "MerchantDefined 4", 
        "merchantDefined5" : "MerchantDefined 5"
      },
      {
        "gbpReferenceNo" : "gbp0001000901",
        "paymentType" : "L",
        "status" : "V",
        "amount" : 3000.00,
        "referenceNo" : "1231213427",
        "resultCode" : null,
        "currencyCode" : null,
        "createTimeStamp" : "2022-12-26T18:05:49",
        "createDate" : "20221226",
        "createTime" : "180549",
        "paymentTimeStamp" : "2022-12-26T18:06:20",
        "paymentDate" : "20221226",
        "paymentTime" : "180620",
        "cardNo" : null,
        "cardHolderName" : null,
        "settleDate" : "20221226",
        "cardLocation" : null,
        "cardType" : null,
        "payMonth" : null,
        "amountPerMonth" : null,
        "totalAmount" : null,
        "thbAmount" : null,
        "issuerBank" : null,
        "openId" : null,
        "detail" : "1",
        "customerName" : "2",
        "customerEmail" : "3",
        "customerAddress" : "5",
        "customerTelephone" : "5",
        "fee" : 96.00,
        "vat" : 6.72000,
        "merchantDefined1" : "MerchantDefined 1", 
        "merchantDefined2" : "MerchantDefined 2", 
        "merchantDefined3" : "MerchantDefined 3", 
        "merchantDefined4" : "MerchantDefined 4", 
        "merchantDefined5" : "MerchantDefined 5"
    }
  ]
}
`

const codeResponseDataBQ = `
{
  "resultCode": "00",
  "resultMessage": "Success",
  "balance": 270285633.09,
  "netAuthorize": 145608.9,
  "authorize": 148218.1,
  "currency": "THB"
}
`

const codeResponseDataTC = `
{
  "resultCode": "00",
  "resultMessage": "Success",
  "referenceNo": "1231213426",
  "gbpReferenceNo": "gbp0001000901",
  "date": "28122022",
  "time": "141326",
  "transferReferenceNo": "xxxxx"
}
`

const codeResponseDataTQ = `
{
  "resultCode": "00",
  "resultMessage": "Success",
  "totalRecord": 1,
  "txns": [
      {
          "gbpReferenceNo": "221228000076697",
          "resultCode": "00",
          "resultMessage": "Success",
          "referenceNo": "1231213426",
          "bankAccount": "1231000123",
          "bankCode": "025",
          "accHolderName": "Name Account",
          "amount": 1.00,
          "timeline": "0",
          "date": "28122022",
          "time": "105327",
          "merchantDefined1" : "MerchantDefined 1", 
          "merchantDefined2" : "MerchantDefined 2", 
          "merchantDefined3" : "MerchantDefined 3", 
          "merchantDefined4" : "MerchantDefined 4", 
          "merchantDefined5" : "MerchantDefined 5",
          "transferReferenceNo": "xxxxx"
      }
  ]
}
`

const htmlCode = `
<form action="{url}" method="POST"> 
  <input type="hidden" name="token" value="{token}">
  <input type="hidden" name="referenceNo" value="{referenceNo}">
  <input type="hidden" name="backgroundUrl" value="{backgroundUrl}">
  <input type="number" name="amount" maxlength="13" placeholder="Amount" value="{amount}"><br/>
  <input id="button" type="submit" value="Pay Now">
</form>
`

const phpResponse = `
<?php

$respFile = fopen("resp-log.txt", "w") or die("Unable to open file!");

$json_str = file_get_contents('php://input');
fwrite($respFile, $json_str . "\\n\\n");

$json_obj = json_decode($json_str);

fwrite($respFile, "resultCode=" . $json_obj->resultCode . "\\n");
fwrite($respFile, "amount=" . $json_obj->amount . "\\n");
fwrite($respFile, "referenceNo=" . $json_obj->referenceNo . "\\n");
fwrite($respFile, "gbpReferenceNo=" . $json_obj->gbpReferenceNo . "\\n");
fwrite($respFile, "currencyCode=" . $json_obj->currencyCode . "\\n");

fclose($respFile);

?>
`

const htmlTokensCode = `
<form action="{url}" method="POST"> 
  <input type="hidden" name="secretKey" value="{secretKey}">
  <input type="hidden" name="publicKey" value="{publicKey}">
  <input id="button" type="submit" value="Submit">
</form>
`


const javaResponse = `

StringBuffer jb = new StringBuffer();
String line = null;

try {

  BufferedReader reader = request.getReader();

  while ((line = reader.readLine()) != null)

    jb.append(line);

} catch (Exception e) { /*report an error*/ }

try {

  JSONObject jsonObject = new JSONObject(jb.toString());
  System.out.println("resultCode=" + jsonObject.getString("resultCode"));
  System.out.println("amount=" + jsonObject.getBigDecimal("amount"));
  System.out.println("gbpReferenceNo=" + jsonObject.getString("gbpReferenceNo"));
  System.out.println("currencyCode=" + jsonObject.getString("currencyCode"));
  
} catch (JSONException e) { /*report an error*/ }
`

const phpCode =`

<form action="<?=$_SERVER['PHP_SELF'];?>" method="POST">
    กรุณายอดเงิน <input type="text" name="itemamount">
    <input type="submit" value="ตกลง">
</form>

<?php
  
    isset( $_POST['itemamount'] ) ? $itemamount = $_POST['itemamount'] : $itemamount = "";
    $request_headers = array(
      "Cache-Control: no-cache",
    );
    
    if( !empty( $itemamount ) ) {
      $token = '';
      $tokenKey = rawurlencode($token);      
      $referenceNo = "";
      $field = 'token='.$tokenKey.'&referenceNo='.$referenceNo.'&amount='.$itemamount.'';
      $url = "{ENV}/v3/qrcode";

      // curl api grcode
      $ch = curl_init();
      curl_setopt($ch, CURLOPT_URL, $url);
      curl_setopt($ch, CURLOPT_POST, 1);
      curl_setopt($ch, CURLOPT_POSTFIELDS, $field);
      curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
      curl_setopt($ch, CURLINFO_HEADER_OUT, true);
      curl_setopt($ch, CURLOPT_ENCODING, "");
      curl_setopt($ch, CURLOPT_SSL_VERIFYHOST, false);
      curl_setopt($ch, CURLOPT_SSL_VERIFYPEER, false);
      curl_setopt($ch, CURLOPT_HTTPHEADER, $request_headers);
      $output = curl_exec($ch);
      curl_close($ch);

      // encode output from api 
      $body = 'data:image/png;base64,' . base64_encode($output) . '';
      

      // output img qrcode by html
      echo '<div class="qrcode_display" id="gbprimepay-qrcode-waiting-payment" style="display:block;">';
      echo '<img src="' . $body . '"  style="padding:0px 0px 120px 0px;windth:100%;" class="aligncenter size-full" />';
      echo '</div>';
      
    }
?>
`

const renderFormHtmlToken = data => {
  let url = 'https://api.globalprimepay.com/v1/tokens'
  let _htmlTokensCode = htmlTokensCode.replace(/{amount}/g, data.amount);
  _htmlTokensCode = _htmlTokensCode.replace(/{url}/g, url);
  _htmlTokensCode = _htmlTokensCode.replace(/{referenceNo}/g, data.referenceNo);
  return (
    <div>
      <PrismCode 
        code={_htmlTokensCode}
        language="html"
        plugins={["line-numbers"]}
      />
    </div>
  )
}

const renderPhpResponse = () => (
  <div>
    <PrismCode
      code={phpResponse}
      language="php"
      plugins={["line-numbers"]}
    />
  </div>
)

const renderJavaResponse = () => (
  <div>
    <PrismCode
      code={javaResponse}
      language="java"
      plugins={["line-numbers"]}
    />
  </div>
)

const codeResponseToken = `
{
  "resultCode": "00",
  "resultMessage": "Success",
  "authToken": "b6f5b1e2-030e-401e-9abc-7de97fba501f",
  "expireIn": 300
}
`

const codeResponseToMerchant = `
{
  "amount": "20.00",
  "referenceNo": "20171128001",
  "gbpReferenceNo": "221228000076697",
  "currencyCode": "THB",
  "resultCode": "00",
  "paymentType": "C",
  "date": "20112007",
  "time": "141222",
  "cardNo": "453501XXXXXX5741",
  "cardHolderName": "Name Lastname",
  "detail": "Detail",
  "customerName": "Customer Name",
  "customerEmail": "test@test.com",
  "customerAddress": "No.123",
  "customerTelephone": "0890000000",
  "merchantDefined1": "",
  "merchantDefined2": "",
  "merchantDefined3": "",
  "merchantDefined4": "",
  "merchantDefined5": "",
  "retryFlag": "Y"
}
`

const state = {
  selectRequestValue: 11,
  selectResponseValue: 1
};

const renderFormJsonRequestToken = () => (
  <div>
    <PrismCode
      code={codeRequestToken}
      language="json"
      plugins={["line-numbers"]}
    />
  </div>
)

const renderFormJsonRequestTransection = (value) => {
  switch (value) {
    case '11':
      return <div>
      <PrismCode 
        code={codeRequestTransectionPcCard}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
    case '12':
      return <div>
      <PrismCode
        code={codeRequestTransectionPCInstallment}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
    case '13':
      return <div>
      <PrismCode
        code={codeRequestTransectionPCBillPayment}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
    case '14':
      return <div>
      <PrismCode
        code={codeRequestTransectionPCQrCash}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
    case '15':
      return <div>
      <PrismCode
        code={codeRequestTransectionPCQrCashText}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
    case '16':
      return <div>
      <PrismCode
        code={codeRequestTransectionPCQrCredit}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
    case '17':
      return <div>
      <PrismCode
        code={codeRequestTransectionPCWechat}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
    case '18':
      return <div>
      <PrismCode
        code={codeRequestTransectionPCLinePay}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
    case '19':
      return <div>
      <PrismCode
        code={codeRequestTransectionPCTrue}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
    case '110':
      return <div>
      <PrismCode
        code={codeRequestTransectionPCShopeePay}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
    case '111':
      return <div>
      <PrismCode
        code={codeRequestTransectionPCAtome}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
    case '112':
      return <div>
      <PrismCode
        code={codeRequestTransectionPCMobile}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
    case '113':
      return <div>
      <PrismCode
        code={codeRequestTransectionPCAlipay}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
    case '2':
      return <div>
      <PrismCode
        code={codeRequestTransectionPV}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
     case '3':
      return <div>
      <PrismCode
        code={codeRequestTransectionPR}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
     case '4':
      return <div>
      <PrismCode
        code={codeRequestTransectionPS}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
     case '5':
      return <div>
      <PrismCode
        code={codeRequestTransectionPQ}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
     case '6':
      return <div>
      <PrismCode
        code={codeRequestTransectionBQ}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
     case '7':
      return <div>
      <PrismCode
        code={codeRequestTransectionTC}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
     case '8':
      return <div>
      <PrismCode
        code={codeRequestTransectionTQ}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
    default:
      return <div>
      <PrismCode
        code={codeRequestTransectionPcCard}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
  }
}

const renderFormJsonResponseTransection = (value) => {
  switch (value) {
    case '11':
      return <div>
      <PrismCode
        code={codeResponseDataPC}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
    case '12':
      return <div>
      <PrismCode
        code={codeResponseDataPCInstallment}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
    case '13':
      return <div>
      <PrismCode
        code={codeResponseDataPCBillPayment}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
    case '14':
      return <div>
      <PrismCode
        code={codeResponseDataPCQrCash}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
    case '15':
      return <div>
      <PrismCode
        code={codeResponseDataPCQrCashText}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
    case '16':
      return <div>
      <PrismCode
        code={codeResponseDataPCQrCredit}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
    case '17':
      return <div>
      <PrismCode
        code={codeResponseDataPCWechat}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
    case '18':
      return <div>
      <PrismCode
        code={codeResponseDataPCLine}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
    case '19':
      return <div>
      <PrismCode
        code={codeResponseDataPCTrue}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
    case '110':
      return <div>
      <PrismCode
        code={codeResponseDataPCShopee}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
    case '111':
      return <div>
      <PrismCode
        code={codeResponseDataPCAtome}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
    case '112':
      return <div>
      <PrismCode
        code={codeResponseDataPCMobileBanking}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
    case '113':
      return <div>
      <PrismCode
        code={codeResponseDataPCAlipay}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
    case '2':
      return <div>
      <PrismCode
        code={codeResponseDataPV}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
     case '3':
      return <div>
      <PrismCode
        code={codeResponseDataPR}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
     case '4':
      return <div>
      <PrismCode
        code={codeResponseDataPS}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
     case '5':
      return <div>
      <PrismCode
        code={codeResponseDataPQ}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
     case '6':
      return <div>
      <PrismCode
        code={codeResponseDataBQ}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
     case '7':
      return <div>
      <PrismCode
        code={codeResponseDataTC}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
     case '8':
      return <div>
      <PrismCode
        code={codeResponseDataTQ}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
    default:
      return <div>
      <PrismCode
        code={codeResponseDataPC}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>;
  }
}

const renderFormJsonRequestTransectionPCCard = () => (
  <div>
    <PrismCode 
      code={codeRequestTransectionPcCard}
      language="json"
      plugins={["line-numbers","code-api"]}
    />
  </div>
)

const renderFormJsonRequestTransectionPV = () => (
  <div>
    <PrismCode 
      code={codeRequestTransectionPV}
      language="json"
      plugins={["line-numbers","code-api"]}
    />
  </div>
)

const renderFormJsonRequestTransectionPR = () => (
  <div>
    <PrismCode 
      code={codeRequestTransectionPR}
      language="json"
      plugins={["line-numbers","code-api"]}
    />
  </div>
)

const renderFormJsonRequestTransectionPS = () => (
  <div>
    <PrismCode 
      code={codeRequestTransectionPS}
      language="json"
      plugins={["line-numbers","code-api"]}
    />
  </div>
)

const renderFormJsonRequestTransectionPQ = () => (
  <div>
    <PrismCode 
      code={codeRequestTransectionPQ}
      language="json"
      plugins={["line-numbers","code-api"]}
    />
  </div>
)

const renderFormJsonRequestTransectionBQ = () => (
  <div>
    <PrismCode 
      code={codeRequestTransectionBQ}
      language="json"
      plugins={["line-numbers","code-api"]}
    />
  </div>
)

const renderFormJsonRequestTransectionTC = () => (
  <div>
    <PrismCode 
      code={codeRequestTransectionTC}
      language="json"
      plugins={["line-numbers","code-api"]}
    />
  </div>
)

const renderFormJsonRequestTransectionTQ = () => (
  <div>
    <PrismCode 
      code={codeRequestTransectionTQ}
      language="json"
      plugins={["line-numbers","code-api"]}
    />
  </div>
)

const renderFormJsonResponseTransectionPCCard = () => (
  <div>
      <PrismCode
        code={codeResponseDataPC}
        language="json"
        plugins={["line-numbers","code-api"]}
      />
  </div>
)

const renderFormJsonResponseTransectionPV = () => (
  <div>
      <PrismCode
        code={codeResponseDataPV}
        language="json"
        plugins={["line-numbers","code-api"]}
      />
  </div>
)

const renderFormJsonResponseTransectionPR = () => (
  <div>
      <PrismCode
        code={codeResponseDataPR}
        language="json"
        plugins={["line-numbers","code-api"]}
      />
  </div>
)

const renderFormJsonResponseTransectionPS = () => (
  <div>
      <PrismCode
        code={codeResponseDataPS}
        language="json"
        plugins={["line-numbers","code-api"]}
      />
  </div>
)

const renderFormJsonResponseTransectionPQ = () => (
  <div>
      <PrismCode
        code={codeResponseDataPQ}
        language="json"
        plugins={["line-numbers","code-api"]}
      />
  </div>
)

const renderFormJsonResponseTransectionBQ = () => (
  <div>
      <PrismCode
        code={codeResponseDataBQ}
        language="json"
        plugins={["line-numbers","code-api"]}
      />
  </div>
)

const renderFormJsonResponseTransectionTC = () => (
  <div>
      <PrismCode
        code={codeResponseDataTC}
        language="json"
        plugins={["line-numbers","code-api"]}
      />
  </div>
)

const renderFormJsonResponseTransectionTQ = () => (
  <div>
      <PrismCode
        code={codeResponseDataTQ}
        language="json"
        plugins={["line-numbers","code-api"]}
      />
  </div>
)

const renderFormCodeResponse = () => (
  <div>
    <PrismCode 
      code={codeResponseToken}
      language="json"
      plugins={["line-numbers"]}
    />
  </div>
)

const renderFormCodeResponseToMerchant = () => (
  <div>
    <PrismCode
      code={codeResponseToMerchant}
      language="json"
      plugins={["line-numbers"]}
    />
  </div>
)

const renderPhpCode = () => (
  <div>
    <PrismCode
      code={phpCode}
      language="php"
      plugins={["line-numbers"]}
    />
  </div>
)

class UnifiedAPI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }

  changeStep = current => {
    this.setState({ current: current })
    console.log(current)
  }

  render() {

    const { t , i18n } = this.props;

    const htmlPaymentType = "<p class='ant-tag'>C</p>" + " = Credit / Debit Card Full Payment" 
      + "<br/>" + "<p class='ant-tag'>I</p>" + " = Installment"
      + "<br/>" + "<p class='ant-tag'>B</p>" + " = Bill Payment"
      + "<br/>" + "<p class='ant-tag'>Q</p>" + " = QR Cash"
      + "<br/>" + "<p class='ant-tag'>V</p>" + " = QR Credit"
      + "<br/>" + "<p class='ant-tag'>W</p>" + " = QR Wechat Pay"
      + "<br/>" + "<p class='ant-tag'>L</p>" + " = Line Payment"
      + "<br/>" + "<p class='ant-tag'>T</p>" + " = True Wallet"
      + "<br/>" + "<p class='ant-tag'>S</p>" + " = Shopee Pay"
      + "<br/>" + "<p class='ant-tag'>A</p>" + " = Atome"
      + "<br/>" + "<p class='ant-tag'>M</p>" + " = Mobile Banking"
      + "<br/>" + "<p class='ant-tag'>Y</p>" + " = Alipay" 
      + "<br/>" + t("unifiedapi:paymenttyperequestnew");

    const htmlString = "<p class='ant-tag'>REQUIRE_ACTION</p>" + t("unifiedapi:requireactionnew") 
      + "<br/>" + "<p class='ant-tag'>SUCCEDED</p>" + t("unifiedapi:succedednew")
      + "<br/>" + "<p class='ant-tag'>FAILED</p>" + t("unifiedapi:failednew")
      + "<br/>" + "<p class='ant-tag'>INVALIDREQUEST</p>" + t("unifiedapi:invalidrequestnew");

    const columnsBankCode = [
      {
        title: t("bankcode"),
        dataIndex: 'name',
        key: 'name',
        render: text => <code>{text}</code>
      },
      {
        title: t("bankname"),
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: t("terms"),
        dataIndex: 'address',
        key: 'address',
      }
    ];

    const columns = [
      {
        title: t("field"),
        dataIndex: 'name',
        key: 'name',
        render: text => <code>{text}</code>
      },
      {
        title: t("type"),
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: t("description"),
        dataIndex: 'address',
        key: 'address',
      }
    ];
    
    const columnsParameter = [
      {
        title: t("field"),
        dataIndex: 'name',
        key: 'name',
        render: text => <code>{text}</code>,
      },
      {
        title: t("type"),
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: t("mandatory"),
        dataIndex: 'mandatory',
        key: 'mandatory',
      },
      {
        title: t("description"),
        dataIndex: 'address',
        key: 'address',
      }
    ];

    const columnsLimit = [
      {
        title: t("paymentmethod"),
        dataIndex: 'payment',
        key: 'payment',
      },
      {
        title: t("unifiedapi:minimumlimits"),
        dataIndex: 'min',
        key: 'min',
      },
      {
        title: t("unifiedapi:maximumlimits"),
        dataIndex: 'max',
        key: 'max',
      }
    ];
    
    const dataHeader = [
      {
        key: '1',
        name: 'content-type',
        age: 'String',
        address: t("unifiedapi:contenttypenew"),
      },
    ];

    const dataHeaderTransaction = [
      {
        key: `0`,
        name: `content-type`,
        age: `String`,
        address: t("unifiedapi:contenttypenew"),
      },
      {
        key: `1`,
        name: `Authorization`,
        age: `String`,
        address: t("unifiedapi:authorizationnew"),
      },
    ];
    
    const dataHeaderImage = [
      {
        key: '1',
        name: 'content-type',
        age: 'String',
        address: 'image/png'
      },
    ];

    const dataParameterToken = [
      {
        key: '0',
        name: 'secretKey',
        age: 'String',
        mandatory: 'M',
        address: t("unifiedapi:secretkeynew")
      },
      {
        key: '1',
        name: 'publicKey',
        age: 'String',
        mandatory: 'M',
        address: t("unifiedapi:publickeynew")
      },
    ];
    
    const dataParameter = [
      {
        key: '0',
        name: 'token (customer key)',
        age: 'String',
        mandatory: 'M',
        address: t("tokencustomerkeydesc")
      },
      {
        key: '1',
        name: 'amount',
        age: 'Number (10, 2)',
        mandatory: 'M',
        address: t("amountdesc")
      },
      {
        key: '2',
        name: 'referenceNo',
        age: 'String (15)',
        mandatory: 'M',
        address: t("referencenodesc")
      },
      {
        key: '3',
        name: 'backgroundUrl',
        age: 'String (250)',
        mandatory: 'O',
        address: t("backgroundurldesc")
      },
      {
        key: '4',
        name: 'detail',
        age: 'String (250)',
        mandatory: 'O',
        address: t("detaildesc")
      },
      {
        key: '5',
        name: 'customerName',
        age: 'String (150)',
        mandatory: 'O',
        address: t("customernamedesc")
      },
      {
        key: '6',
        name: 'customerEmail',
        age: 'String (150)',
        mandatory: 'O',
        address: t("customeremaildesc")
      },
      {
        key: '7',
        name: 'merchantDefined1',
        age: 'String (250)',
        mandatory: 'O',
        address: t("merchantdefined1desc")
      },
      {
        key: '8',
        name: 'merchantDefined2',
        age: 'String (250)',
        mandatory: 'O',
        address: t("merchantdefined2desc")
      },
      {
        key: '9',
        name: 'merchantDefined3',
        age: 'String (250)',
        mandatory: 'O',
        address: t("merchantdefined3desc")
      },
      {
        key: '10',
        name: 'merchantDefined4',
        age: 'String (250)',
        mandatory: 'O',
        address: t("merchantdefined4desc")
      },
      {
        key: '11',
        name: 'merchantDefined5',
        age: 'String (250)',
        mandatory: 'O',
        address: t("merchantdefined5desc")
      },
      {
        key: '12',
        name: 'customerTelephone',
        age: 'Number (10)',
        mandatory: 'O',
        address: t("customertelephonedesc")
      },
      {
        key: '13',
        name: 'customerAddress',
        age: 'String (250)',
        mandatory: 'O',
        address: t("customeraddressdesc")
      },
    ];
    
    const dataResponseTransection = [
      {
        key: '0',
        name: 'apiType',
        age: 'String (2)',
        address: 
          <span>
            <Tag>PC</Tag> = Create Payment
            <br/>
            <Tag>PV</Tag> = Void
            <br/>
            <Tag>PR</Tag> = Refund
            <br/>
            <Tag>PS</Tag> = Settle
            <br/>
            <Tag>PQ</Tag> = Query Transaction
            <br/>
            <Tag>BQ</Tag> = Query Balance
            <br/>
            <Tag>TC</Tag> = Transfer Manual Create
            <br/>
            <Tag>TQ</Tag> = Transfer Manual Query
            <br/>
          </span>
      },
      {
        key: 1,
        name: <table-code-header>Response from PC (Create Payment)</table-code-header>,
        children: [
          {
            key: '11',
            name: 'status',
            age: 'String (15)',
            address: 
              <span>
                  <Tag>REQUIRE_ACTION</Tag> = The request passed validation but requires additional steps to complete payment.
                  <br/>
                  <Tag>PENDING</Tag> = The transaction passed initial validation and the payment channel is currently processing the transaction.
                  <br/>
                  <Tag>SUCCEDED</Tag> = The payment was successfully completed.
                  <br/>
                  <Tag>FAILED</Tag> = The payment request failed. See resultCode for the specific reason why the transaction failed. 
                  <br/>
                  <Tag>INVALIDREQUEST</Tag> = Invalid Request parameter. 
              </span>
          },
          {
            key: '12',
            name: 'resultCode',
            age: 'String (2)',
            address: 
              <span>
                <Tag>00</Tag> = Success
                <br/>
                <Tag>01</Tag> = Invalid ApiType
                <br/>
                <Tag>02</Tag> = Request Parameter is Invalid
                <br/>
                <Tag>03</Tag> = Invalid PaymentType
                <br/>
                <Tag>90</Tag> = Create Payment Fail
                <br/>
                <Tag>91</Tag> = Customer telephone must not blank (Atome)
                <br/>
                <Tag>92</Tag> = Invalid Amount Per Month (Installment)
                <br/>
                <Tag>93</Tag> = Invalid Term (Installment)
                <br/>
                <Tag>94</Tag> = Duplicate referenceNo
                <br/>
                <Tag>95</Tag> = Invalid amount
                <br/>
                <Tag>96</Tag> = Invalid bankCode
                <br/>
                <Tag>97</Tag> = Disallow Payment Method
                <br/>
                <Tag>98</Tag> = This User is disable
                <br/>
                <Tag>99</Tag> = Invalid key
                <br/>
              </span>
          },
          {
            key: '13',
            name: 'resultMessage',
            age: 'String (250)',
            address: 'This field is Mandatory when “resultCode” is not equal 00',
          },
          {
            key: '14',
            name: 'redirectUrl',
            age: 'String (250)',
            address: 'This field is Mandatory when “resultCode” is not equal 00',
          },
          {
            key: '15',
            name: 'referenceNo',
            age: 'String (250)',
            address: t("referencenodesc"),
          },
          {
            key: '16',
            name: 'token',
            age: 'String (250)',
            address: t("tokendesc"),
          },
          {
            key: '17',
            name: 'transactionStatus',
            age: 'String (1)',
            address: t("transactionstatusdesc"),
          },
          {
            key: '18',
            name: 'action',
            age: 'String (10)',
            address: 'Action',
          },
          {
            key: '19',
            name: 'dataText',
            age: 'String (10)',
            address: 'Data Text',
          },
          {
            key: '110',
            name: 'amount',
            age: 'Number (10,2)',
            address: t("amountdesc"),
          },
          {
            key: '111',
            name: 'gbpReferenceNo',
            age: 'String (15)',
            address: t("gbpReferencenodesc"),
          },
          {
            key: '112',
            name: 'currencyCode',
            age: 'String (2)',
            address: t("currencycodedesc"),
          },
          {
            key: '113',
            name: 'paymentType',
            age: 'String (1)',
            address: 
              <span>
                <Tag>C</Tag> = Credit / Debit Card Full Payment
                <br/>
                <Tag>I</Tag> = Installment
                <br/>
                <Tag>B</Tag> = Bill Payment
                <br/>
                <Tag>Q</Tag> = QR Cash
                <br/>
                <Tag>V</Tag> = QR Credit
                <br/>
                <Tag>W</Tag> = QR Wechat Pay
                <br/>
                <Tag>L</Tag> = Line Payment
                <br/>
                <Tag>T</Tag> = True Wallet
                <br/>
                <Tag>S</Tag> = Shopee Pay
                <br/>
                <Tag>A</Tag> = Atome
                <br/>
                <Tag>M</Tag> = Mobile Banking
                <br/>
                <Tag>Y</Tag> = Alipay
                <br/>
              </span>,
          },
          {
            key: '114',
            name: 'date',
            age: 'String (8)',
            address: t("datedesc"),
          },
          {
            key: '115',
            name: 'time',
            age: 'String (6)',
            address: t("timedesc"),
          },
          {
            key: '116',
            name: 'detail',
            age: 'String (2)',
            address: t("detaildesc"),
          },
          {
            key: '117',
            name: 'customerEmail',
            age: 'String (20)',
            address: t("customeremaildesc"),
          },
          {
            key: '118',
            name: 'customerAddress',
            age: 'String (250)',
            address: t("customeraddressdesc"),
          },
          {
            key: '119',
            name: 'customerTelephone',
            age: 'String (250)',
            address: t("customertelephonedesc"),
          },
          {
            key: '120',
            name: 'merchantDefined1',
            age: 'String (250)',
            address: t("merchantdefined1desc")
          },
          {
            key: '121',
            name: 'merchantDefined2',
            age: 'String (250)',
            address: t("merchantdefined2desc")
          },
          {
            key: '122',
            name: 'merchantDefined3',
            age: 'String (250)',
            address: t("merchantdefined3desc")
          },
          {
            key: '123',
            name: 'merchantDefined4',
            age: 'String (250)',
            address: t("merchantdefined4desc")
          },
          {
            key: '124',
            name: 'merchantDefined5',
            age: 'String (250)',
            address: t("merchantdefined5desc")
          },
          {
            key: '125',
            name: 'cardNo',
            age: 'String (16)',
            address: t("cardnodesc")
          },
          {
            key: '126',
            name: 'cardType',
            age: 'String (250)',
            address: t("cardtypedesc")
          },
          {
            key: '127',
            name: 'debitType',
            age: 'String (250)',
            address: 'Debit Type'
          },
          {
            key: '128',
            name: 'issuerBank',
            age: 'String (250)',
            address: t("issuerbankdesc")
          },
          {
            key: '129',
            name: 'approvalCode',
            age: 'String (250)',
            address: 'Approval Code'
          },
          {
            key: '130',
            name: 'fee',
            age: 'Number (10,2)',
            address: 'Fee'
          },
          {
            key: '131',
            name: 'vat',
            age: 'Number (10,2)',
            address: t("vatdesc")
          },
          {
            key: '132',
            name: 'cardHolderName',
            age: 'String (250)',
            address: 'Holder name of card'
          },
        ],
      },
      {
        key: '2',
        name: <table-code-header>Response from PV (Void)</table-code-header>,
        span: 2,
        children: [
          {
            key: '21',
            name: 'resultCode',
            age: 'String (2)',
            mandatory: 'M',
            address: 
            <span>
              <Tag>00</Tag> = Success
              <br/>
              <Tag>01</Tag> = Transaction is not available
              <br/>
              <Tag>90</Tag> = System error
              <br/>
            </span>,
          },
          {
            key: '22',
            name: 'resultMessage',
            age: 'String (250)',
            mandatory: 'M',
            address: 'This field is Mandatory when “resultCode” is not equal 00',
          },
          {
            key: '23',
            name: 'gbpReferenceNo',
            age: 'String (15)',
            mandatory: 'M',
            address: 'Gbp reference no',
          },
        ],
      },
      {
        key: '3',
        name: <table-code-header>Response from PR (Refund)</table-code-header>,
        span: 2,
        children: [
          {
            key: '31',
            name: 'resultCode',
            age: 'String (2)',
            mandatory: 'M',
            address: 
            <span>
              <Tag>00</Tag> = Success
              <br/>
              <Tag>01</Tag> = Invalid gbpReferenceNo
              <br/>
              <Tag>02</Tag> = Refund amount more than remaining amount
              <br/>
              <Tag>03</Tag> = This Payment type can not refund
              <br/>
              <Tag>04</Tag> = Refund Amount is null or zero
              <br/>
              <Tag>05</Tag> = Duplicate Reference No
              <br/>
              <Tag>90</Tag> = Error
              <br/>
            </span>,
          },
          {
            key: '32',
            name: 'resultMessage',
            age: 'String (250)',
            mandatory: 'M',
            address: 'This field is Mandatory when “resultCode” is not equal 00',
          },
          {
            key: '33',
            name: 'gbpReferenceNo',
            age: 'String (15)',
            mandatory: 'M',
            address: 'Gbp reference no',
          },
          {
            key: '34',
            name: 'referenceNo',
            age: 'String (15)',
            mandatory: 'M',
            address: 'Reference no',
          },
          {
            key: '35',
            name: 'refundReferenceNo',
            age: 'String (15)',
            mandatory: 'M',
            address: 'Refund reference no',
          },
        ],
      },
      {
        key: '4',
        name: <table-code-header>Response from PS (Settle)</table-code-header>,
        span: 2,
        children: [
          {
            key: '41',
            name: 'resultCode',
            age: 'String (2)',
            mandatory: 'M',
            address: 
            <span>
              <Tag>00</Tag> = Success
              <br/>
              <Tag>01</Tag> = Invalid gbpReferenceNo
              <br/>
              <Tag>02</Tag> = Refund amount more than remaining amount
              <br/>
              <Tag>03</Tag> = This Payment type can not refund
              <br/>
              <Tag>04</Tag> = Refund Amount is null or zero
              <br/>
              <Tag>05</Tag> = Duplicate Reference No
              <br/>
              <Tag>90</Tag> = Error
              <br/>
            </span>,
          },
          {
            key: '42',
            name: 'resultMessage',
            age: 'String (250)',
            mandatory: 'M',
            address: 'This field is Mandatory when “resultCode” is not equal 00',
          },
          {
            key: '43',
            name: 'gbpReferenceNo',
            age: 'String (15)',
            mandatory: 'M',
            address: 'Gbp reference no',
          },
          {
            key: '44',
            name: 'referenceNo',
            age: 'String (15)',
            mandatory: 'M',
            address: 'Reference no',
          },
          {
            key: '45',
            name: 'refundReferenceNo',
            age: 'String (15)',
            mandatory: 'M',
            address: 'Refund reference no',
          },
        ],
      },
      {
        key: '5',
        name: <table-code-header>Response from PQ (Query Transaction)</table-code-header>,
        span: 2,
        children: [
          {
            key: '51',
            name: 'resultCode',
            age: 'String (2)',
            mandatory: 'M',
            address: 
            <span>
              <Tag>00</Tag> = Success
              <br/>
              <Tag>01</Tag> = Invalid gbpReferenceNo
              <br/>
              <Tag>02</Tag> = Refund amount more than remaining amount
              <br/>
              <Tag>03</Tag> = This Payment type can not refund
              <br/>
              <Tag>04</Tag> = Refund Amount is null or zero
              <br/>
              <Tag>05</Tag> = Duplicate Reference No
              <br/>
              <Tag>90</Tag> = Error
              <br/>
            </span>,
          },
          {
            key: '52',
            name: 'resultMessage',
            age: 'String (250)',
            mandatory: 'M',
            address: 'This field is Mandatory when “resultCode” is not equal 00',
          },
          {
            key: '53',
            name: 'limit',
            age: 'Number',
            mandatory: 'M',
            address: 'Limit',
          },
          {
            key: '54',
            name: 'offset',
            age: 'Number (10,2)',
            mandatory: 'M',
            address: 'Offset',
          },
          {
            key: '55',
            name: 'total',
            age: 'Number (10,2)',
            mandatory: 'M',
            address: 'Total Transaction',
          },
          {
            key: '56',
            name: <span className="font-object-parameter">data</span>,
            children: [
              {
                key: '561',
                name: 'gbpReferenceNo',
                age: 'String (15)',
                address: 'Gbp reference no',
              },
              {
                key: '562',
                name: 'paymentType',
                age: 'String (1)',
                address: 'Payment type',
              },
              {
                key: '563',
                name: 'status',
                age: 'String (1)',
                address: 'Status',
              },
              {
                key: '564',
                name: 'amount',
                age: 'Integer (10,2)',
                address: 'Amount',
              },
              {
                key: '565',
                name: 'referenceNo',
                age: 'String (15)',
                address: 'Reference no',
              },
              {
                key: '566',
                name: 'resultCode',
                age: 'String (2)',
                address: 'Result Code',
              },
              {
                key: '567',
                name: 'currencyCode',
                age: 'String (3)',
                address: 'Currency Code',
              },
              {
                key: '568',
                name: 'createTimeStamp',
                age: 'String (8)',
                address: t("timedesc"),
              },
              {
                key: '569',
                name: 'createDate',
                age: 'String (8)',
                address: t("datedesc"),
              },
              {
                key: '5610',
                name: 'createTime',
                age: 'String (8)',
                address: t("timedesc"),
              },
              {
                key: '5611',
                name: 'paymentTimeStamp',
                age: 'String (8)',
                address: t("timedesc"),
              },
              {
                key: '5612',
                name: 'paymentDate',
                age: 'String (8)',
                address: t("datedesc"),
              },
              {
                key: '5613',
                name: 'paymentTime',
                age: 'String (8)',
                address: t("timedesc"),
              },
              {
                key: '5614',
                name: 'cardNo',
                age: 'String (15)',
                address: 'Card no',
              },
              {
                key: '5615',
                name: 'cardHolderName',
                age: 'String (250)',
                address: 'Card Holder name',
              },
              {
                key: '5616',
                name: 'settleDate',
                age: 'String (8)',
                address: 'Settle Date',
              },
              {
                key: '5617',
                name: 'cardLocation',
                age: 'String (1)',
                address: 'Card Location',
              },
              {
                key: '5618',
                name: 'cardType',
                age: 'String (3)',
                address: 'Card Type',
              },
              {
                key: '5619',
                name: 'payMonth',
                age: 'Integer',
                address: 'Pay Month',
              },
              {
                key: '5620',
                name: 'amountPerMonth',
                age: 'Integer',
                address: 'Amount Per Month',
              },
              {
                key: '5621',
                name: 'totalAmount',
                age: 'Integer (10,2)',
                address: 'Total Amount',
              },
              {
                key: '5622',
                name: 'thbAmount',
                age: 'Integer (10,2)',
                address: 'Thai Baht Amount',
              },
              {
                key: '5623',
                name: 'issuerBank',
                age: 'String (250)',
                address: 'Bangkok Bank Public Company Limited',
              },
              {
                key: '5624',
                name: 'openId',
                age: 'Number',
                address: 'Id',
              },
              {
                key: '5625',
                name: 'detail',
                age: 'String (250)',
                address: 'Detail',
              },
              {
                key: '5626',
                name: 'customerName',
                age: 'String (150)',
                address: t("customernamedesc")
              },
              {
                key: '5627',
                name: 'customerEmail',
                age: 'String (150)',
                address: t("customeremaildesc")
              },
              {
                key: '5628',
                name: 'customerAddress',
                age: 'String (250)',
                address: t("customeraddressdesc")
              },
              {
                key: '5629',
                name: 'customerTelephone',
                age: 'Number (10)',
                address: t("customertelephonedesc")
              },
              {
                key: '5630',
                name: 'merchantDefined1',
                age: 'String (250)',
                address: t("merchantdefined1desc")
              },
              {
                key: '5631',
                name: 'merchantDefined2',
                age: 'String (250)',
                address: t("merchantdefined2desc")
              },
              {
                key: '5632',
                name: 'merchantDefined3',
                age: 'String (250)',
                address: t("merchantdefined3desc")
              },
              {
                key: '5633',
                name: 'merchantDefined4',
                age: 'String (250)',
                address: t("merchantdefined4desc")
              },
              {
                key: '5634',
                name: 'merchantDefined5',
                age: 'String (250)',
                address: t("merchantdefined5desc")
              },
            ]
          },
        ],
      },
      {
        key: '6',
        name: <table-code-header>Response from BQ (Balance Query)</table-code-header>,
        children: [
          {
            key: '61',
            name: 'resultCode',
            age: 'String (2)',
            mandatory: 'M',
            address: 
            <span>
              <Tag>00</Tag> = Success
              <br/>
              <Tag>01</Tag> = Invalid gbpReferenceNo
              <br/>
              <Tag>02</Tag> = Refund amount more than remaining amount
              <br/>
              <Tag>03</Tag> = This Payment type can not refund
              <br/>
              <Tag>04</Tag> = Refund Amount is null or zero
              <br/>
              <Tag>05</Tag> = Duplicate Reference No
              <br/>
              <Tag>90</Tag> = Error
              <br/>
            </span>,
          },
          {
            key: '62',
            name: 'resultMessage',
            age: 'String (250)',
            mandatory: 'M',
            address: 'This field is Mandatory when “resultCode” is not equal 00',
          },
          {
            key: '63',
            name: 'balance',
            age: 'Number (10,2)',
            mandatory: 'M',
            address: 'Balance',
          },
          {
            key: '64',
            name: 'netAuthorize',
            age: 'Number (10,2)',
            mandatory: 'M',
            address: 'Net Authorize',
          },
          {
            key: '65',
            name: 'authorize',
            age: 'Number (10,2)',
            mandatory: 'M',
            address: 'Authorize',
          },
          {
            key: '66',
            name: 'currency',
            age: 'String (3)',
            mandatory: 'M',
            address: 'Currency',
          },
        ],
      },
      {
        key: '7',
        name: <table-code-header>Response from TC (Transfer Manual Create)</table-code-header>,
        span: 2,
        children: [
          {
            key: '71',
            name: 'resultCode',
            age: 'String (2)',
            mandatory: 'M',
            address: 
            <span>
              <Tag>00</Tag> = Success
              <br/>
              <Tag>01</Tag> = Invalid gbpReferenceNo
              <br/>
              <Tag>02</Tag> = Refund amount more than remaining amount
              <br/>
              <Tag>03</Tag> = This Payment type can not refund
              <br/>
              <Tag>04</Tag> = Refund Amount is null or zero
              <br/>
              <Tag>05</Tag> = Duplicate Reference No
              <br/>
              <Tag>90</Tag> = Error
              <br/>
            </span>,
          },
          {
            key: '72',
            name: 'resultMessage',
            age: 'String (250)',
            mandatory: 'M',
            address: 'This field is Mandatory when “resultCode” is not equal 00',
          },
          {
            key: '73',
            name: 'referenceNo',
            age: 'String (15)',
            mandatory: 'M',
            address: 'Reference No',
          },
          {
            key: '74',
            name: 'gbpReferenceNo',
            age: 'String (15)',
            mandatory: 'M',
            address: 'Gbp reference no',
          },
          {
            key: '75',
            name: 'date',
            age: 'String (8)',
            mandatory: 'M',
            address: t("timedesc"),
          },
          {
            key: '76',
            name: 'time',
            mandatory: 'M',
            age: 'String (6)',
            address: t("datedesc"),
          },
          {
            key: '77',
            name: 'transferReferenceNo',
            mandatory: 'M',
            age: 'String (15)',
            address: 'Transfer ReferenceNo',
          },
        ],
      },
      {
        key: '8',
        name: <table-code-header>Response from TQ (Transfer Manual Query)</table-code-header>,
        span: 2,
        children: [
          {
            key: '81',
            name: 'resultCode',
            age: 'String (2)',
            mandatory: 'M',
            address: 
            <span>
              <Tag>00</Tag> = Success
              <br/>
              <Tag>01</Tag> = Invalid gbpReferenceNo
              <br/>
              <Tag>02</Tag> = Refund amount more than remaining amount
              <br/>
              <Tag>03</Tag> = This Payment type can not refund
              <br/>
              <Tag>04</Tag> = Refund Amount is null or zero
              <br/>
              <Tag>05</Tag> = Duplicate Reference No
              <br/>
              <Tag>90</Tag> = Error
              <br/>
            </span>,
          },
          {
            key: '82',
            name: 'resultMessage',
            age: 'String (250)',
            mandatory: 'M',
            address: 'This field is Mandatory when “resultCode” is not equal 00',
          },
          {
            key: '83',
            name: 'totalRecord',
            age: 'Number',
            mandatory: 'M',
            address: 'Total Record',
          },
          {
            key: '84',
            name: <span className="font-object-parameter">txns</span>,
            children: [
              {
                key: '841',
                name: 'gbpReferenceNo',
                age: 'String (15)',
                mandatory: 'M',
                address: 'Gbp reference no',
              },
              {
                key: '842',
                name: 'resultCode',
                age: 'String (2)',
                mandatory: 'M',
                address: 'Result Code',
              },
              {
                key: '843',
                name: 'resultMessage',
                age: 'String (250)',
                mandatory: 'M',
                address: 'This field is Mandatory when “resultCode” is not equal 00',
              },
              {
                key: '844',
                name: 'referenceNo',
                age: 'String (15)',
                mandatory: 'M',
                address: 'Reference No',
              },
              {
                key: '845',
                name: 'bankAccount',
                age: 'String (10)',
                mandatory: 'M',
                address: 'Bank Account',
              },
              {
                key: '846',
                name: 'bankCode',
                age: 'String (3)',
                mandatory: 'M',
                address: 'Bank Code',
              },
              {
                key: '847',
                name: 'accHolderName',
                age: 'String (10)',
                mandatory: 'M',
                address: 'Account Holder Name',
              },
              {
                key: '848',
                name: 'amount',
                age: 'Number (10,2)',
                mandatory: 'M',
                address: 'Amount (THB)',
              },
              {
                key: '849',
                name: 'timeline',
                age: 'String (250)',
                mandatory: 'M',
                address: 'Time Line',
              },
              {
                key: '850',
                name: 'date',
                age: 'String (8)',
                mandatory: 'M',
                address: 'Date',
              },
              {
                key: '851',
                name: 'time',
                age: 'String (6)',
                mandatory: 'M',
                address: 'Time',
              },
              {
                key: '852',
                name: 'merchantDefined1',
                age: 'String (250)',
                mandatory: 'O',
                address: t("merchantdefined1desc")
              },
              {
                key: '853',
                name: 'merchantDefined2',
                age: 'String (250)',
                mandatory: 'O',
                address: t("merchantdefined2desc")
              },
              {
                key: '854',
                name: 'merchantDefined3',
                age: 'String (250)',
                mandatory: 'O',
                address: t("merchantdefined3desc")
              },
              {
                key: '855',
                name: 'merchantDefined4',
                age: 'String (250)',
                mandatory: 'O',
                address: t("merchantdefined4desc")
              },
              {
                key: '856',
                name: 'merchantDefined5',
                age: 'String (250)',
                mandatory: 'O',
                address: t("merchantdefined5desc")
              },
              {
                key: '857',
                name: 'transferReferenceNo',
                age: 'String (15)',
                mandatory: 'O',
                address: 'Transfer Reference No'
              },
            ]
          },
        ],
      },
    ];
    
    const dataBankCode = [
      {
        key: '1',
        name: '004',
        age: t("kbankname"),
        address: '3, 4, 5, 6, 7, 8, 9, 10'
      },
      {
        key: '2',
        name: '006',
        age: t("ktbname"),
        address: `3, 4, 5, 6, 7, 8, 9, 10`
      },
      {
        key: '3',
        name: `011`,
        age: t("ttbname"),
        address: `3, 4, 6, 10`
      },
      {
        key: '4',
        name: '025',
        age: t("bayname"),
        address: `3, 4, 6, 9, 10`
      },
      {
        key: '5',
        name: '026',
        age: t("kfcname"),
        address: `3, 4, 6, 9, 10, 12, 18, 24`
      },
      {
        key: '6',
        name: '014',
        age: t("scbname"),
        address: `3, 4, 6, 10`
      },
      {
        key: '7',
        name: '002',
        age: t("bblname"),
        address: `3, 4, 6, 8, 9, 10`
      },
    ];

    const dataResponseToken = [
      {
        key: '0',
        name: 'resultCode',
        age: 'String (2)',
        address: t("unifiedapi:resultcodedesc")
      },
      {
        key: '1',
        name: 'resultMessage',
        age: 'String (250)',
        address: t("unifiedapi:resultmessagenew")
      },
      {
        key: '2',
        name: 'authToken',
        age: 'String (250)',
        address: t("unifiedapi:authtokennew")
      },
      {
        key: '3',
        name: 'expireIn',
        age: 'Number (3)',
        address: t("unifiedapi:expireinnew")
      },
    ];
    
    const dataBackgroudResponseToMerchant = [
      {
        key: '0',
        name: 'amount',
        age: 'NUMERIC (10,2)',
        address: t("unifiedapi:amountnew")
      },
      {
        key: '1',
        name: 'referenceNo',
        age: 'String (15)',
        address: t("unifiedapi:referencenonew")
      },
      {
        key: '2',
        name: 'gbpReferenceNo',
        age: 'String (15)',
        address: t("unifiedapi:gbpreferencenonew")
      },
      {
        key: '3',
        name: 'currencyCode',
        age: 'String (3)',
        address: t("unifiedapi:currencycodenew")
      },
      {
        key: '4',
        name: 'resultCode',
        age: 'String (2)',
        address: t("unifiedapi:resultcodenew")
      },
      {
        key: '5',
        name: 'paymentType',
        age: 'String (1)',
        address: t("unifiedapi:paymenttypenew")
      },
      {
        key: '6',
        name: 'date',
        age: 'String (8)',
        address: t("datedesc")
      },
      {
        key: '7',
        name: 'time',
        age: 'String (6)',
        address: t("timedesc")
      },
      {
        key: '8',
        name: 'cardNo',
        age: 'String (16)',
        address: t("unifiedapi:cardnumbernew")
      },
      {
        key: '9',
        name: 'cardHolderName',
        age: 'String (150)',
        address: t("unifiedapi:cardholdernamenew")
      },
      {
        key: '10',
        name: 'detail',
        age: 'String (250)',
        address: t("unifiedapi:detailnew")
      },
      {
        key: '11',
        name: 'customerName',
        age: 'String (250)',
        address: t("unifiedapi:customernamenew")
      },
      {
        key: '12',
        name: 'customerEmail',
        age: 'String (250)',
        address: t("unifiedapi:customeremailnew")
      },
      {
        key: '13',
        name: 'customerAddress',
        age: 'String (250)',
        address: t("unifiedapi:customeraddressnew")
      },
      {
        key: '14',
        name: 'customerTelephone',
        age: 'String (250)',
        address: t("unifiedapi:customertelephonenew")
      },
      {
        key: '15',
        name: 'merchantDefined1',
        age: 'String (250)',
        address: t("unifiedapi:merchantdefined1new")
      },
      {
        key: '16',
        name: 'merchantDefined2',
        age: 'String (250)',
        address: t("unifiedapi:merchantdefined2new")
      },
      {
        key: '17',
        name: 'merchantDefined3',
        age: 'String (250)',
        address: t("unifiedapi:merchantdefined3new")
      },
      {
        key: '18',
        name: 'merchantDefined4',
        age: 'String (250)',
        address: t("unifiedapi:merchantdefined4new")
      },
      {
        key: '19',
        name: 'merchantDefined5',
        age: 'String (250)',
        address: t("unifiedapi:merchantdefined5new")
      },
      { 
        key: '20',
        name: 'retryFlag',
        age: 'String(1)',
        address: t("unifiedapi:retryflagnew")
      },
    ];

    const dataLimit = [
      {
        key: '0',
        payment: 'Credit/Debit card Full Payment (3D secure)',
        min: '1.00 (THB)',
        max: t("unifiedapi:followingcardlimit")
      },
      {
        key: '1',
        payment: 'Credit/Debit card Full Payment (non-3DS)',
        min: '1.00 (THB)',
        max: t("unifiedapi:followingcardlimit")
      },
      {
        key: '2',
        payment: 'Installment',
        min: '3000.00 (THB)',
        max: t("unifiedapi:followingcardlimit")
      },
      {
        key: '3',
        payment: 'Recurring',
        min: '1.00 (THB)',
        max: t("unifiedapi:followingcardlimit")
      },
      {
        key: '4',
        payment: 'QR Cash',
        min: '1.00 (THB)',
        max: '2,000,000.00 (THB)'
      },
      {
        key: '5',
        payment: 'QR Credit',
        min: '1.00 (THB)',
        max: t("unifiedapi:followingcardlimit")
      },
      {
        key: '6',
        payment: 'Wechat Pay',
        min: '1.00 (THB)',
        max: '10,000,000.00 (THB)'
      },
      {
        key: '7',
        payment: 'Rabbit Line Pay',
        min: '1.00 (THB)',
        max: '50,000.00 (THB)'
      },
      {
        key: '8',
        payment: 'TrueMoney Wallet',
        min: '1.00 (THB)',
        max: '50,000.00 (THB)'
      },
      {
        key: '9',
        payment: 'Shopeepay',
        min: '1.00 (THB)',
        max: '200,000.00 (THB)'
      },
      {
        key: '9',
        payment: 'Atome',
        min: '20.00 (THB)',
        max: t("unifiedapi:followingcardlimit")
      },
      {
        key: '10',
        payment: 'Mobile Banking',
        min: '20.00 (THB)',
        max: '2,000,000.00 (THB)'
      },
      {
        key: '11',
        payment: 'Bill Payment',
        min: '1.00 (THB)',
        max: '2,000,000.00 (THB)'
      },
    ];

    const columnsUnifiedApi = [
      {
        title: t("field"),
        dataIndex: 'name',
        key: 'name',
        render: text => <code>{text}</code>,
      },
      {
        title: t("type"),
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: t("mandatory"),
        dataIndex: 'mandatory',
        key: 'mandatory',
      },
      {
        title: t("description"),
        dataIndex: 'address',
        key: 'address',
      }
    ];

    const columnsUnifiedApiHeader = [
      {
        title: t("field"),
        dataIndex: 'name',
        key: 'name',
        render: (text, row, index) => {
          if (index != 0) {
            return <code>{text}</code>
          }
          return {
            children: <a>{text}</a>,
            props: {
              colSpan: 2,
            },
          };
        },
      },
      {
        title: t("type"),
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: t("mandatory"),
        dataIndex: 'mandatory',
        key: 'mandatory',
      },
      {
        title: t("description"),
        dataIndex: 'address',
        key: 'address',
      }
    ];

    const dataUnifiedApi = [
      {
        key: '0',
        name: 'apiType',
        age: 'String (2)',
        mandatory: 'M',
        address: 
          <span>
            <Tag>PC</Tag> = Create Payment
            <br/>
            <Tag>PV</Tag> = Void
            <br/>
            <Tag>PR</Tag> = Refund
            <br/>
            <Tag>PS</Tag> = Settle
            <br/>
            <Tag>PQ</Tag> = Query Transaction
            <br/>
            <Tag>BQ</Tag> = Query Balance
            <br/>
            <Tag>TC</Tag> = Transfer Manual Create
            <br/>
            <Tag>TQ</Tag> = Transfer Manual Query
            <br/>
            <br/>
            See more detail below...
          </span> 
          ,
      },
      {
        key: '01',
        name: <table-code-parameter-header>Parameter ที่ต้องส่งเพิ่มเติมสำหรับแต่ละ API Type</table-code-parameter-header>,
      },
      {
        key: '1',
        name: <table-code-header>Parameter for PC (Create Payment)</table-code-header>,
        children: [
          {
            key: '11',
            name: <span className="font-object-parameter">createPaymentTransactionRequest</span>
          },
          {
            key: '12',
            name: 'referenceNo',
            age: 'String (15)',
            mandatory: 'M',
            address: 'Merchant reference no',
          },
          {
            key: '13',
            name: 'paymentType',
            age: 'String (1)',
            mandatory: 'M',
            // address: 
            //   <span>
            //     <Tag>C</Tag> = Credit / Debit Card Full Payment
            //     <br/>
            //     <Tag>I</Tag> = Installment
            //     <br/>
            //     <Tag>B</Tag> = Bill Payment
            //     <br/>
            //     <Tag>Q</Tag> = QR Cash
            //     <br/>
            //     <Tag>V</Tag> = QR Credit
            //     <br/>
            //     <Tag>W</Tag> = QR Wechat Pay
            //     <br/>
            //     <Tag>L</Tag> = Line payment
            //     <br/>
            //     <Tag>T</Tag> = True Wallet
            //     <br/>
            //     <Tag>S</Tag> = Shopee Pay
            //     <br/>
            //     <Tag>A</Tag> = Atome
            //     <br/>
            //     <Tag>M</Tag> = Mobile Banking
            //     <br/>
            //     <Tag>Y</Tag> = Alipay
            //     <br/>
            //   </span>
            address : parse(htmlPaymentType),
          },
          {
            key: '15',
            name: 'amount',
            age: 'Number',
            mandatory: 'M',
            address: 'Please specify the decimal for each required result. For example: Amount with decimal = 00 (Ex. 15.00 Baht)',
          },
          {
            key: '17',
            name: 'cardNumber',
            age: 'String',
            mandatory: 'C',
            address: 'Payment Card Number (This parameter is mandatory when paymentType = ‘C’)',
          },
          {
            key: '18',
            name: 'expirationMonth',
            age: 'String',
            mandatory: 'C',
            address: 'Expiration Month Example : 01-12 (This parameter is mandatory when paymentType = ‘C’)',
          },
          {
            key: '19',
            name: 'expirationYear',
            age: 'String',
            mandatory: 'C',
            address: 'Expiration Year Example : 2025 (This parameter is mandatory when paymentType = ‘C’)',
          },
          {
            key: '110',
            name: 'securityCode',
            age: 'String',
            mandatory: 'C',
            address: 'securityCode, cvv',
          },
          {
            key: '16',
            name: 'cardToken',
            age: 'String',
            mandatory: 'C',
            address: 
            <span>
              Tokens after call API Token or create transaction with API Unified Credits Card.
              <br/>
              Can be used in place of the (cardNumber, expirationMonth, expirationYear, securityCode)
            </span>
          },
          {
            key: '111',
            name: 'rememberCard',
            age: 'Boolean',
            mandatory: 'C',
            address: 'True or False',
          },
          {
            key: '112',
            name: 'otp',
            age: 'String',
            mandatory: 'C',
            address: 'Y or N',
          },
          {
            key: '113',
            name: 'bankCode',
            age: 'String',
            mandatory: 'C',
            address: 'This parameter is mandatory when paymentType = ‘I’, ‘M’',
          },
          {
            key: '114',
            name: 'term',
            age: 'Number',
            mandatory: 'C',
            address: 'This parameter is mandatory when paymentType = ‘I’',
          },
          {
            key: '115',
            name: 'mktcode',
            age: 'String',
            mandatory: 'C',
            address: 'This parameter is mandatory when paymentType = ‘I’',
          },
          {
            key: '116',
            name: 'procode',
            age: 'String',
            mandatory: 'C',
            address: 'This parameter is mandatory when paymentType = ‘I’',
          },
          {
            key: '14',
            name: 'qrType',
            age: 'String',
            mandatory: 'C',
            address: 
            <span>
              This parameter is mandatory when paymentType = ‘Q’, ‘B’, ‘V’, ‘W’
              <br/>
              <Tag>I</Tag> = Image QR
              <br/>
              <Tag>T</Tag> = Text For Generate QR
              <br/>
            </span>,
          },
          {
            key: '117',
            name: 'responseUrl',
            age: 'String (250)',
            mandatory: 'O',
            address: 'URL to redirect after the transaction is completed (If this parameter is null or empty GB will show success or fail page)',
          },
          {
            key: '118',
            name: 'backgroundUrl',
            age: 'String (250)',
            mandatory: 'O',
            address: 'URL to send response data from the GBPrimePay back office system after the transaction is completed',
          },
          {
            key: '119',
            name: 'detail',
            age: 'String',
            mandatory: 'O',
            address: 'Product Description',
          },
          {
            key: '120',
            name: 'customerName',
            age: 'String',
            mandatory: 'O',
            address: 'Customer Name',
          },
          {
            key: '121',
            name: 'customerEmail',
            age: 'String',
            mandatory: 'O',
            address: 'Customer Email',
          },
          {
            key: '122',
            name: 'customerTelephone',
            age: 'String',
            mandatory: 'O',
            address: 'Customer Telephone',
          },
          {
            key: '123',
            name: 'customerAddress',
            age: 'String',
            mandatory: 'O',
            address: 'Customer Address',
          },
          {
            key: '124',
            name: 'merchantDefined1',
            age: 'String (250)',
            mandatory: 'O',
            address: 'Other Detail',
          },
          {
            key: '125',
            name: 'merchantDefined2',
            age: 'String (250)',
            mandatory: 'O',
            address: 'Other Detail',
          },
          {
            key: '126',
            name: 'merchantDefined3',
            age: 'String (250)',
            mandatory: 'O',
            address: 'Other Detail',
          },
          {
            key: '127',
            name: 'merchantDefined4',
            age: 'String (250)',
            mandatory: 'O',
            address: 'Other Detail',
          },
          {
            key: '128',
            name: 'merchantDefined5',
            age: 'String (250)',
            mandatory: 'O',
            address: 'Other Detail',
          },
        ],
      },
      {
        key: '2',
        name: <table-code-header>Parameter for PV (Void)</table-code-header>,
        span: 2,
        children: [
          {
            key: '21',
            name: <span className="font-object-parameter">voidTransactionRequest</span>
          },
          {
            key: '22',
            name: 'gbpReferenceNo',
            age: 'String (15)',
            mandatory: 'M',
            address: 'Gbp reference no',
          },
        ],
      },
      {
        key: '3',
        name: <table-code-header>Parameter for PR (Refund)</table-code-header>,
        span: 2,
        children: [
          {
            key: '31',
            name: <span className="font-object-parameter">refundTransactionRequest</span>
          },
          {
            key: '32',
            name: 'gbpReferenceNo',
            age: 'String (15)',
            mandatory: 'M',
            address: 'Gbp reference no',
          },
          {
            key: '33',
            name: 'refundAmount',
            age: 'Number (10, 2)',
            mandatory: 'M',
            address: 'Settle amount (If this parameter is empty GB Will settle with full amount) Please specify the decimal for each required result. For example: Amount with decimal = 00 (Ex. 15.00 Baht)',
          },
          {
            key:'34',
            name: 'referenceNo',
            age: 'String (15)',
            mandatory: 'M',
            address: 'Merchant reference no',
          },
          {
            key: '35',
            name: 'backgroundUrl',
            age: 'String (250)',
            mandatory: 'M',
            address: 'URL to send response data from the GBPrimePay back office system after the refund is completed',
          },
          {
            key: '36',
            name: 'merchantDefined1',
            age: 'String (250)',
            mandatory: 'O',
            address: 'Other Detail',
          },
          {
            key: '37',
            name: 'merchantDefined2',
            age: 'String (250)',
            mandatory: 'O',
            address: 'Other Detail',
          },
          {
            key: '38',
            name: 'merchantDefined3',
            age: 'String (250)',
            mandatory: 'O',
            address: 'Other Detail',
          },
          {
            key: '39',
            name: 'merchantDefined4',
            age: 'String (250)',
            mandatory: 'O',
            address: 'Other Detail',
          },
          {
            key: '310',
            name: 'merchantDefined5',
            age: 'String (250)',
            mandatory: 'O',
            address: 'Other Detail',
          },
        ],
      },
      {
        key: '4',
        name: <table-code-header>Parameter for PS (Settle)</table-code-header>,
        span: 2,
        children: [
          {
            key: '41',
            name: <span className="font-object-parameter">settlementTransactionRequest</span>
          },
          {
            key: '42',
            name: 'gbpReferenceNo',
            age: 'String (15)',
            mandatory: 'M',
            address: 'Gbp reference no',
          },
          {
            key: '43',
            name: 'settleAmount',
            age: 'Number (10, 2)',
            mandatory: 'O',
            address: 'Settle amount (If this parameter is empty GB Will settle with full amount) Please specify the decimal for each required result. For example: Amount with decimal = 00 (Ex. 15.00 Baht)',
          },
          {
            key: '44',
            name: 'backgroundUrl',
            age: 'String (250)',
            mandatory: 'M',
            address: 'URL to send response data from the GBPrimePay back office system after the transaction is completed',
          },
        ],
      },
      {
        key: '5',
        name: <table-code-header>Parameter for PQ (Query Transaction)</table-code-header>,
        span: 2,
        children: [
          {
            key: '51',
            name: <span className="font-object-parameter">queryTransactionRequest</span>
          },
          {
            key: '52',
            name: 'fromDate',
            age: 'Datetime (19)',
            mandatory: 'M',
            address: `Format: YYYY-MM-DDTHH:MM:SS Ex. 2021-05-01T00:00:00 Optional when referenceNo is not null`
          },
          {
            key: '53',
            name: 'toDate',
            age: 'Datetime (19)',
            mandatory: 'M',
            address: `Format: YYYY-MM-DDTHH:MM:SS Ex. 2021-05-01T00:00:00 Optional when referenceNo is not null`
          },
          {
            key: '54',
            name: 'limit',
            age: 'Integer',
            mandatory: 'M',
            address: `Limit of Response Transaction (10, 25, 50, 100, 250) Optional when referenceNo is not null`
          },
          {
            key: '55',
            name: 'offset',
            age: 'Integer',
            mandatory: 'M',
            address: `Offset of Transaction Optional when referenceNo is not null`
          },
          {
            key: '56',
            name: 'referenceNo',
            age: 'String (15)',
            mandatory: 'M',
            address: `Merchant Reference No Optional when fromDate and toDate is not null`
          },
          {
            key: '57',
            name: 'orderBy',
            age: 'Integer',
            mandatory: 'O',
            address: `"asc", "desc" Default : "asc"`
          },
        ],
      },
      {
        key: '6',
        name: <table-code-header>Parameter for TC (Transfer Manual Create)</table-code-header>,
        span: 2,
        children: [
          {
            key: '61',
            name: <span className="font-object-parameter">transfersRequest</span>
          },
          {
            key: '62',
            name: 'referenceNo',
            age: 'String',
            mandatory: 'M',
            address: 'Merchant Reference No Optional when fromDate and toDate is not null',
          },
          {
            key: '63',
            name: 'bankAccount',
            age: 'String (10)',
            mandatory: 'M',
            address: 'Bank Account No of Merchant',
          },
          {
            key: '64',
            name: 'bankCode',
            age: 'String (3)',
            mandatory: 'M',
            address: '004 = KPLUS , 014 = SCB EASY (Only open in mobile) , 025 = KMA (Krungsri), 002 = BBL (Only open in mobile), 006 = KTB (Krungthai)',
          },
          {
            key: '65',
            name: 'amount',
            age: 'Number (10,2)',
            mandatory: 'M',
            address: 'Please specific the decimal for each required result. For example: Amount with decimal = 00 (Ex. 15.00 Baht)',
          },
          {
            key: '66',
            name: 'backgroundUrl',
            age: 'String (250)',
            mandatory: 'M',
            address: 'After the transaction is completed as a response from the back-end system',
          },
          {
            key: '67',
            name: 'accHolderName',
            age: 'String',
            mandatory: 'M',
            address: 'Holder Name of account bank',
          },
          {
            key: '68',
            name: 'merchantDefined1',
            age: 'String (250)',
            mandatory: 'O',
            address: 'Other Detail',
          },
          {
            key: '69',
            name: 'merchantDefined2',
            age: 'String (250)',
            mandatory: 'O',
            address: 'Other Detail',
          },
          {
            key: '610',
            name: 'merchantDefined3',
            age: 'String (250)',
            mandatory: 'O',
            address: 'Other Detail',
          },
          {
            key: '611',
            name: 'merchantDefined4',
            age: 'String (250)',
            mandatory: 'O',
            address: 'Other Detail',
          },
          {
            key: '612',
            name: 'merchantDefined5',
            age: 'String (250)',
            mandatory: 'O',
            address: 'Other Detail',
          },
        ],
      },
      {
        key: '7',
        name: <table-code-header>Parameter for TQ (Transfer Manual Query)</table-code-header>,
        span: 2,
        children: [
          {
            key: '71',
            name: <span className="font-object-parameter">transfersRequest</span>
          },
          {
            key: '72',
            name: 'referenceNo',
            age: 'String',
            mandatory: 'M',
            address: 'Merchant Reference No Optional when fromDate and toDate is not null',
          },
        ],
      },
    ];

    const dataUnifiedApiMain = [
      {
        key: '0',
        name: 'apiType',
        age: 'String (2)',
        mandatory: 'M',
        address: 
          <span>
            <Tag>PC</Tag> = Create Payment
            <br/>
            <Tag>PV</Tag> = Void
            <br/>
            <Tag>PR</Tag> = Refund
            <br/>
            <Tag>PS</Tag> = Settle
            <br/>
            <Tag>PQ</Tag> = Query Transaction
            <br/>
            <Tag>BQ</Tag> = Query Balance
            <br/>
            <Tag>TC</Tag> = Transfer Manual Create
            <br/>
            <Tag>TQ</Tag> = Transfer Manual Query
            <br/>
            <br/>            
            See more detail below...
          </span> 
          ,
      },
    ];

    const dataUnifiedApiPC = [
      {
        key: '11',
        name: <span className="font-object-parameter">createPaymentTransactionRequest</span>,
      },
      {
        key: '12',
        name: 'referenceNo',
        age: 'String (15)',
        mandatory: 'M',
        address: t("unifiedapi:referencenonew"),
      },
      {
        key: '13',
        name: 'paymentType',
        age: 'String (1)',
        mandatory: 'M',
        // address: 
        //   <span>
        //     <Tag>C</Tag> = Credit / Debit Card Full Payment
        //     <br/>
        //     <Tag>I</Tag> = Installment
        //     <br/>
        //     <Tag>B</Tag> = Bill Payment
        //     <br/>
        //     <Tag>Q</Tag> = QR Cash
        //     <br/>
        //     <Tag>V</Tag> = QR Credit
        //     <br/>
        //     <Tag>W</Tag> = QR Wechat Pay
        //     <br/>
        //     <Tag>L</Tag> = Line payment
        //     <br/>
        //     <Tag>T</Tag> = True Wallet
        //     <br/>
        //     <Tag>S</Tag> = Shopee Pay
        //     <br/>
        //     <Tag>A</Tag> = Atome
        //     <br/>
        //     <Tag>M</Tag> = Mobile Banking
        //     <br/>
        //     <Tag>Y</Tag> = Alipay
        //     <br/>
        //   </span>
          address : parse(htmlPaymentType),
      },
      {
        key: '15',
        name: 'amount',
        age: 'Number (10,2)',
        mandatory: 'M',
        address: t("unifiedapi:amountnew"),
      },
      {
        key: '17',
        name: 'cardNumber',
        age: 'String (16)',
        mandatory: 'C',
        address: t("unifiedapi:cardnumbernew"),
      },
      {
        key: '18',
        name: 'expirationMonth',
        age: 'String (2)',
        mandatory: 'C',
        address: t("unifiedapi:expirationmonthnew"),
        // address: 'Expiration Month Example : 01-12 (This parameter is mandatory when paymentType = ‘C’)',
      },
      {
        key: '19',
        name: 'expirationYear',
        age: 'String (4)',
        mandatory: 'C',
        address: t("unifiedapi:expirationyearnew"),
        // address: 'Expiration Year Example : 2025 (This parameter is mandatory when paymentType = ‘C’)',
      },
      {
        key: '110',
        name: 'securityCode',
        age: 'String (3)',
        mandatory: 'C',
        address: t("unifiedapi:securitycodenew"),
        // address: 'securityCode, cvv',
      },
      {
        key: '16',
        name: 'cardToken',
        age: 'String (150)',
        mandatory: 'C',
        // address: 
        // <span>
        //   Tokens after call API Token or create transaction with API Unified Credits Card.
        //   <br/>
        //   Can be used in place of the (cardNumber, expirationMonth, expirationYear, securityCode)
        // </span>
          address: t("unifiedapi:cardtokennew"),
      },
      {
        key: '111',
        name: 'rememberCard',
        age: 'Boolean',
        mandatory: 'C',
        //address: 'True or False',
        address: t("unifiedapi:remembercardnew"),
      },
      {
        key: '112',
        name: 'otp',
        age: 'String (1)',
        mandatory: 'C',
        address: t("unifiedapi:otpnew"),
        // address: 'Y or N',
      },
      {
        key: '113',
        name: 'bankCode',
        age: 'String (3)',
        mandatory: 'C',
        address: t("unifiedapi:bankcodenew"),
        // address: 'This parameter is mandatory when paymentType = ‘I’, ‘M’',
      },
      {
        key: '114',
        name: 'term',
        age: 'Number',
        mandatory: 'C',
        address: t("unifiedapi:termnew"),
        // address: 'This parameter is mandatory when paymentType = ‘I’',
      },
      {
        key: '14',
        name: 'qrType',
        age: 'String (1)',
        mandatory: 'C',
        // address: 
        // <span>
        //   This parameter is mandatory when paymentType = ‘Q’, ‘B’, ‘V’, ‘W’
        //   <br/>
        //   <Tag>I</Tag> = Image QR
        //   <br/>
        //   <Tag>T</Tag> = Text For Generate QR
        //   <br/>
        // </span>,
        // address: <span>
        //   รูปแบบของ QR ที่จะได้รับ
        //   <br/>
        //   I = Image QR
        //   <br/>
        //   T = Text For Generate QR
        //   <br/>
        //   (ใช้สำหรับการชำระด้วย Bill Payment, QR Cash, QR Credit, QR Wechat Pay)
        // </span>
        address: t("unifiedapi:datatextnew"),
      },
      {
        key: '117',
        name: 'responseUrl',
        age: 'String (250)',
        mandatory: 'O',
        address: t("unifiedapi:responseurlnew"),
      },
      {
        key: '118',
        name: 'backgroundUrl',
        age: 'String (250)',
        mandatory: 'O',
        address: t("unifiedapi:backgroundurlnew"),
        //address: 'URL to send response data from the GBPrimePay back office system after the transaction is completed',
      },
      {
        key: '119',
        name: 'detail',
        age: 'String (250)',
        mandatory: 'O',
       // address: 'Product Description',
        address: t("unifiedapi:detailnew"),
      },
      {
        key: '120',
        name: 'customerName',
        age: 'String (250)',
        mandatory: 'O',
        // address: 'Customer Name',
        address: t("unifiedapi:customernamenew"),
      },
      {
        key: '121',
        name: 'customerEmail',
        age: 'String (250)',
        mandatory: 'O',
        // address: 'Customer Email',
        address: t("unifiedapi:customeremailnew"),
      },
      {
        key: '122',
        name: 'customerTelephone',
        age: 'String (250)',
        mandatory: 'O',
        // address: 'Customer Telephone',
        address: t("unifiedapi:customertelephonenew"),
      },
      {
        key: '123',
        name: 'customerAddress',
        age: 'String (250)',
        mandatory: 'O',
        //address: 'Customer Address',
        address: t("unifiedapi:customeraddressnew"),
      },
      {
        key: '124',
        name: 'merchantDefined1',
        age: 'String (250)',
        mandatory: 'O',
        // address: 'Other Detail',
        address: t("unifiedapi:merchantdefined1new"),
      },
      {
        key: '125',
        name: 'merchantDefined2',
        age: 'String (250)',
        mandatory: 'O',
        // address: 'Other Detail',
        address: t("unifiedapi:merchantdefined2new"),
      },
      {
        key: '126',
        name: 'merchantDefined3',
        age: 'String (250)',
        mandatory: 'O',
        // address: 'Other Detail',
        address: t("unifiedapi:merchantdefined3new"),
      },
      {
        key: '127',
        name: 'merchantDefined4',
        age: 'String (250)',
        mandatory: 'O',
        // address: 'Other Detail',
        address: t("unifiedapi:merchantdefined4new"),
      },
      {
        key: '128',
        name: 'merchantDefined5',
        age: 'String (250)',
        mandatory: 'O',
        // address: 'Other Detail',
        address: t("unifiedapi:merchantdefined5new"),
      },
    ];

    const dataUnifiedApiPV = [
      {
        key: '1',
        name: <span className="font-object-parameter">voidTransactionRequest</span>,
      },
      {
        key: '2',
        name: 'gbpReferenceNo',
        age: 'String (15)',
        mandatory: 'M',
        address: t("unifiedapi:gbpreferencenonew"),
      },
    ]

    const dataUnifiedApiPR = [
      {
        key: '1',
        name: <span className="font-object-parameter">refundTransactionRequest</span>,
      },
      {
        key: '2',
        name: 'gbpReferenceNo',
        age: 'String (15)',
        mandatory: 'M',
        address: t("unifiedapi:gbpreferencenonew"),
      },
      {
        key: '3',
        name: 'refundAmount',
        age: 'Number (10, 2)',
        mandatory: 'M',
        address: t("unifiedapi:refundamountnew"),
      },
      {
        key:'4',
        name: 'referenceNo',
        age: 'String (15)',
        mandatory: 'M',
        address: t("unifiedapi:referencenonew"),
      },
      {
        key: '5',
        name: 'backgroundUrl',
        age: 'String (250)',
        mandatory: 'M',
        address: t("unifiedapi:backgroundurlnew"),
      },
      {
        key: '6',
        name: 'merchantDefined1',
        age: 'String (250)',
        mandatory: 'O',
        address: t("unifiedapi:merchantdefined1new"),
      },
      {
        key: '7',
        name: 'merchantDefined2',
        age: 'String (250)',
        mandatory: 'O',
        address: t("unifiedapi:merchantdefined2new"),
      },
      {
        key: '8',
        name: 'merchantDefined3',
        age: 'String (250)',
        mandatory: 'O',
        address: t("unifiedapi:merchantdefined3new"),
      },
      {
        key: '9',
        name: 'merchantDefined4',
        age: 'String (250)',
        mandatory: 'O',
        address: t("unifiedapi:merchantdefined4new"),
      },
      {
        key: '10',
        name: 'merchantDefined5',
        age: 'String (250)',
        mandatory: 'O',
        address: t("unifiedapi:merchantdefined5new"),
      },
    ]

    const dataUnifiedApiPS = [
      {
        key: '1',
        name: <span className="font-object-parameter">settlementTransactionRequest</span>,
      },
      {
        key: '2',
        name: 'gbpReferenceNo',
        age: 'String (15)',
        mandatory: 'M',
        address: t("unifiedapi:gbpreferencenonew"),
      },
      {
        key: '3',
        name: 'settleAmount',
        age: 'Number (10, 2)',
        mandatory: 'O',
        address: t("unifiedapi:settleamountnew"),
      },
      {
        key: '4',
        name: 'backgroundUrl',
        age: 'String (250)',
        mandatory: 'M',
        address: t("unifiedapi:backgroundurlnew"),
      },
    ]

    const dataUnifiedApiPQ = [
      {
        key: '1',
        name: <span className="font-object-parameter">queryTransactionRequest</span>,
      },
      {
        key: '2',
        name: 'fromDate',
        age: 'Datetime (19)',
        mandatory: 'M',
        address: t("unifiedapi:fromdatenew"),
      },
      {
        key: '3',
        name: 'toDate',
        age: 'Datetime (19)',
        mandatory: 'M',
        address: t("unifiedapi:todatenew"),
      },
      {
        key: '4',
        name: 'limit',
        age: 'Integer',
        mandatory: 'M',
        address: t("unifiedapi:limitnew"),
      },
      {
        key: '5',
        name: 'offset',
        age: 'Integer',
        mandatory: 'M',
        address: t("unifiedapi:offsetnew"),
      },
      {
        key: '6',
        name: 'referenceNo',
        age: 'String (15)',
        mandatory: 'M',
        address: t("unifiedapi:referencenonew"),
      },
      {
        key: '7',
        name: 'orderBy',
        age: 'String',
        mandatory: 'O',
        address: t("unifiedapi:orderbynew"),
      },
    ]

    const dataUnifiedApiBQ = [
      
    ]

    const dataUnifiedApiTC = [
      {
        key: '1',
        name: <span className="font-object-parameter">transfersRequest</span>,
      },
      {
        key: '2',
        name: 'referenceNo',
        age: 'String (15)',
        mandatory: 'M',
        address: t("unifiedapi:referencenonew"),
      },
      {
        key: '3',
        name: 'bankAccount',
        age: 'String (10)',
        mandatory: 'M',
        address: t("unifiedapi:bankaccountnew"),
      },
      {
        key: '4',
        name: 'bankCode',
        age: 'String (3)',
        mandatory: 'M',
        address: '004 = KPLUS , 014 = SCB EASY (Only open in mobile) , 025 = KMA (Krungsri), 002 = BBL (Only open in mobile), 006 = KTB (Krungthai)',
      },
      {
        key: '5',
        name: 'amount',
        age: 'Number (10,2)',
        mandatory: 'M',
        address: t("unifiedapi:amountnew"),
      },
      {
        key: '6',
        name: 'backgroundUrl',
        age: 'String (250)',
        mandatory: 'M',
        address: t("unifiedapi:backgroundurlnew"),
      },
      {
        key: '7',
        name: 'accHolderName',
        age: 'String (250)',
        mandatory: 'M',
        address: t("unifiedapi:accholdernamenew"),
      },
      {
        key: '8',
        name: 'merchantDefined1',
        age: 'String (250)',
        mandatory: 'O',
        address: t("unifiedapi:merchantdefined1new"),
      },
      {
        key: '9',
        name: 'merchantDefined2',
        age: 'String (250)',
        mandatory: 'O',
        address: t("unifiedapi:merchantdefined2new"),
      },
      {
        key: '10',
        name: 'merchantDefined3',
        age: 'String (250)',
        mandatory: 'O',
        address: t("unifiedapi:merchantdefined3new"),
      },
      {
        key: '11',
        name: 'merchantDefined4',
        age: 'String (250)',
        mandatory: 'O',
        address: t("unifiedapi:merchantdefined4new"),
      },
      {
        key: '12',
        name: 'merchantDefined5',
        age: 'String (250)',
        mandatory: 'O',
        address: t("unifiedapi:merchantdefined5new"),
      },
    ]

    const dataUnifiedApiTQ = [
      {
        key: '1',
        name: <span className="font-object-parameter">transfersRequest</span>,
      },
      {
        key: '2',
        name: 'referenceNo',
        age: 'String (15)',
        mandatory: 'M',
        address: t("unifiedapi:referencenonew"),
      },
    ]

    const dataResponseMain = [
      {
        key: '0',
        name: 'apiType',
        age: 'String (2)',
        address: 
          <span>
            <Tag>PC</Tag> = Create Payment
            <br/>
            <Tag>PV</Tag> = Void
            <br/>
            <Tag>PR</Tag> = Refund
            <br/>
            <Tag>PS</Tag> = Settle
            <br/>
            <Tag>PQ</Tag> = Query Transaction
            <br/>
            <Tag>BQ</Tag> = Query Balance
            <br/>
            <Tag>TC</Tag> = Transfer Manual Create
            <br/>
            <Tag>TQ</Tag> = Transfer Manual Query
            <br/>
          </span>
      },
    ]

    const dataResponsePC = [
      {
        key: '1',
        name: 'status',
        age: 'String (15)',
        // address: 
        //   <span>
        //       <Tag>REQUIRE_ACTION</Tag> = The request passed validation but requires additional steps to complete payment.
        //       <br/>
        //       <Tag>SUCCEDED</Tag> = The payment was successfully completed.
        //       <br/>
        //       <Tag>FAILED</Tag> = The payment request failed. See resultCode for the specific reason why the transaction failed. 
        //       <br/>
        //       <Tag>INVALIDREQUEST</Tag> = Invalid Request parameter. 
        //   </span>
        address : parse(htmlString),
      },
      {
        key: '2',
        name: 'resultCode',
        age: 'String (2)',
        address: 
          <span>
            <Tag>00</Tag> = Success
            <br/>
            <Tag>01</Tag> = Invalid Api Type
            <br/>
            <Tag>02</Tag> = Request Parameter is Invalid
            <br/>
            <Tag>03</Tag> = Invalid Payment Type
            <br/>
            <Tag>90</Tag> = Create Payment Fail
            <br/>
            <Tag>91</Tag> = Customer telephone must not blank (Atome)
            <br/>
            <Tag>92</Tag> = Invalid Amount Per Month (Installment)
            <br/>
            <Tag>93</Tag> = Invalid Term (Installment)
            <br/>
            <Tag>94</Tag> = Duplicate referenceNo
            <br/>
            <Tag>95</Tag> = Invalid amount
            <br/>
            <Tag>96</Tag> = Invalid bankCode
            <br/>
            <Tag>97</Tag> = Disallow Payment Method
            <br/>
            <Tag>98</Tag> = This User is disable
            <br/>
            <Tag>99</Tag> = Invalid key
            <br/>
          </span>
      },
      {
        key: '3',
        name: 'resultMessage',
        age: 'String (250)',
        address: t("unifiedapi:resultmessagenew"),
      },
      {
        key: '4',
        name: 'redirectUrl',
        age: 'String (250)',
        address: t("unifiedapi:redirecturlnew"),
      },
      {
        key: '5',
        name: 'referenceNo',
        age: 'String (250)',
        address: t("unifiedapi:referencenonew"),
      },
      {
        key: '6',
        name: 'token',
        age: 'String (250)',
        address: t("unifiedapi:cardtokennew"),
      },
      {
        key: '7',
        name: 'transactionStatus',
        age: 'String (1)',
        address: t("unifiedapi:transactionstatusnew"),
      },
      // {
      //   key: '8',
      //   name: 'action',
      //   age: 'String (10)',
      //   address: 'Action',
      // },
      {
        key: '8',
        name: 'dataText',
        age: 'String (10)',
        address: t("unifiedapi:datatextnew"),
      },
      {
        key: '9',
        name: 'amount',
        age: 'Number (10,2)',
        address: t("unifiedapi:amountnew"),
      },
      {
        key: '10',
        name: 'gbpReferenceNo',
        age: 'String (15)',
        address: t("unifiedapi:gbpreferencenonew"),
      },
      {
        key: '11',
        name: 'currencyCode',
        age: 'String (2)',
        address: t("unifiedapi:currencycodenew"),
      },
      {
        key: '12',
        name: 'paymentType',
        age: 'String (1)',
        address: 
          <span>
            <Tag>C</Tag> = Credit / Debit Card Full Payment
            <br/>
            <Tag>I</Tag> = Installment
            <br/>
            <Tag>B</Tag> = Bill Payment
            <br/>
            <Tag>Q</Tag> = QR Cash
            <br/>
            <Tag>V</Tag> = QR Credit
            <br/>
            <Tag>W</Tag> = QR Wechat Pay
            <br/>
            <Tag>L</Tag> = Line Payment
            <br/>
            <Tag>T</Tag> = True Wallet
            <br/>
            <Tag>S</Tag> = Shopee Pay
            <br/>
            <Tag>A</Tag> = Atome
            <br/>
            <Tag>M</Tag> = Mobile Banking
            <br/>
            <Tag>Y</Tag> = Alipay
            <br/>
          </span>,
      },
      {
        key: '13',
        name: 'date',
        age: 'String (8)',
        address: t("datedesc"),
      },
      {
        key: '14',
        name: 'time',
        age: 'String (6)',
        address: t("timedesc"),
      },
      {
        key: '15',
        name: 'detail',
        age: 'String (2)',
        address: t("unifiedapi:detailnew"),
      },
      {
        key: '16',
        name: 'customerEmail',
        age: 'String (20)',
        address: t("unifiedapi:customeremailnew"),
      },
      {
        key: '17',
        name: 'customerAddress',
        age: 'String (250)',
        address: t("unifiedapi:customeraddressnew"),
      },
      {
        key: '18',
        name: 'customerTelephone',
        age: 'String (250)',
        address: t("unifiedapi:customertelephonenew"),
      },
      {
        key: '19',
        name: 'merchantDefined1',
        age: 'String (250)',
        address: t("unifiedapi:merchantdefined1new"),
      },
      {
        key: '20',
        name: 'merchantDefined2',
        age: 'String (250)',
        address: t("unifiedapi:merchantdefined2new"),
      },
      {
        key: '21',
        name: 'merchantDefined3',
        age: 'String (250)',
        address: t("unifiedapi:merchantdefined3new"),
      },
      {
        key: '22',
        name: 'merchantDefined4',
        age: 'String (250)',
        address: t("unifiedapi:merchantdefined4new"),
      },
      {
        key: '23',
        name: 'merchantDefined5',
        age: 'String (250)',
        address: t("unifiedapi:merchantdefined5new"),
      },
      {
        key: '24',
        name: 'cardNo',
        age: 'String (16)',
        address: t("unifiedapi:cardnumbernew"),
      },
      {
        key: '25',
        name: 'cardType',
        age: 'String (250)',
        address: t("unifiedapi:cardtypenew"),
      },
      {
        key: '26',
        name: 'debitType',
        age: 'String (250)',
        address: t("unifiedapi:debittypenew"),
      },
      {
        key: '27',
        name: 'issuerBank',
        age: 'String (250)',
        address: t("unifiedapi:issuerbanknew"),
      },
      {
        key: '28',
        name: 'approvalCode',
        age: 'String (250)',
        address: t("unifiedapi:approvalcodenew"),
      },
      {
        key: '29',
        name: 'fee',
        age: 'Number (10,2)',
        address: t("unifiedapi:feenew"),
      },
      {
        key: '30',
        name: 'vat',
        age: 'Number (10,2)',
        address: t("unifiedapi:vatnew"),
      },
      {
        key: '31',
        name: 'cardHolderName',
        age: 'String (250)',
        address: t("unifiedapi:accholdernamenew"),
      },
    ];

    const dataResponsePV = [
      {
        key: '1',
        name: 'resultCode',
        age: 'String (2)',
        mandatory: 'M',
        address: 
        <span>
          <Tag>00</Tag> = Success
          <br/>
          <Tag>01</Tag> = Transaction is not available
          <br/>
          <Tag>90</Tag> = System error
          <br/>
        </span>,
      },
      {
        key: '2',
        name: 'resultMessage',
        age: 'String (250)',
        mandatory: 'M',
        address: t("unifiedapi:resultmessagenew"),
      },
      {
        key: '3',
        name: 'gbpReferenceNo',
        age: 'String (15)',
        mandatory: 'M',
        address: t("unifiedapi:gbpreferencenonew"),
      },
    ]

    const dataResponsePR = [
      {
        key: '1',
        name: 'resultCode',
        age: 'String (2)',
        mandatory: 'M',
        address: 
        <span>
          <Tag>00</Tag> = Success
          <br/>
          <Tag>01</Tag> = Invalid gbpReferenceNo
          <br/>
          <Tag>02</Tag> = Refund amount more than remaining amount
          <br/>
          <Tag>03</Tag> = This Payment type can not refund
          <br/>
          <Tag>04</Tag> = Refund Amount is null or zero
          <br/>
          <Tag>05</Tag> = Duplicate Reference No
          <br/>
          <Tag>90</Tag> = Error
          <br/>
        </span>,
      },
      {
        key: '2',
        name: 'resultMessage',
        age: 'String (250)',
        mandatory: 'M',
        address: t("unifiedapi:resultmessagenew"),
      },
      {
        key: '3',
        name: 'gbpReferenceNo',
        age: 'String (15)',
        mandatory: 'M',
        address: t("unifiedapi:gbpreferencenonew"),
      },
      {
        key: '4',
        name: 'referenceNo',
        age: 'String (15)',
        mandatory: 'M',
        address: t("unifiedapi:referencenonew"),
      },
      {
        key: '5',
        name: 'refundReferenceNo',
        age: 'String (15)',
        mandatory: 'M',
        address: t("unifiedapi:refundreferencenonew"),
      },
    ]

    const dataResponsePS = [
      {
        key: '1',
        name: 'resultCode',
        age: 'String (2)',
        mandatory: 'M',
        address: 
        <span>
          <Tag>00</Tag> = Success
          <br/>
          <Tag>01</Tag> = Invalid gbpReferenceNo
          <br/>
          <Tag>02</Tag> = Refund amount more than remaining amount
          <br/>
          <Tag>03</Tag> = This Payment type can not refund
          <br/>
          <Tag>04</Tag> = Refund Amount is null or zero
          <br/>
          <Tag>05</Tag> = Duplicate Reference No
          <br/>
          <Tag>90</Tag> = Error
          <br/>
        </span>,
      },
      {
        key: '2',
        name: 'resultMessage',
        age: 'String (250)',
        mandatory: 'M',
        address: t("unifiedapi:resultmessagenew"),
      },
      {
        key: '3',
        name: 'gbpReferenceNo',
        age: 'String (15)',
        mandatory: 'M',
        address: t("unifiedapi:gbpreferencenonew"),
      },
      {
        key: '4',
        name: 'referenceNo',
        age: 'String (15)',
        mandatory: 'M',
        address: t("unifiedapi:referencenonew"),
      },
      {
        key: '5',
        name: 'refundReferenceNo',
        age: 'String (15)',
        mandatory: 'M',
        address: t("unifiedapi:refundreferencenonew"),
      },
    ]

    const dataResponsePQ = [
      {
        key: '1',
        name: 'resultCode',
        age: 'String (2)',
        mandatory: 'M',
        address: 
        <span>
          <Tag>00</Tag> = Success
          <br/>
          <Tag>01</Tag> = Invalid gbpReferenceNo
          <br/>
          <Tag>02</Tag> = Refund amount more than remaining amount
          <br/>
          <Tag>03</Tag> = This Payment type can not refund
          <br/>
          <Tag>04</Tag> = Refund Amount is null or zero
          <br/>
          <Tag>05</Tag> = Duplicate Reference No
          <br/>
          <Tag>90</Tag> = Error
          <br/>
        </span>,
      },
      {
        key: '2',
        name: 'resultMessage',
        age: 'String (250)',
        mandatory: 'M',
        address: t("unifiedapi:resultmessagenew"),
      },
      {
        key: '3',
        name: 'limit',
        age: 'Number',
        mandatory: 'M',
        address: t("unifiedapi:limitnew"),
      },
      {
        key: '4',
        name: 'offset',
        age: 'Number (10,2)',
        mandatory: 'M',
        address: t("unifiedapi:offsetnew"),
      },
      {
        key: '5',
        name: 'total',
        age: 'Number (10,2)',
        mandatory: 'M',
        address: t("unifiedapi:totalrecordnew"),
      },
      {
        key: '6',
        name: <span className="font-object-parameter">data</span>,
        children: [
          {
            key: '61',
            name: 'gbpReferenceNo',
            age: 'String (15)',
            address: t("unifiedapi:gbpreferencenonew"),
          },
          {
            key: '62',
            name: 'paymentType',
            age: 'String (1)',
            address: t("unifiedapi:paymenttypenew"),
          },
          {
            key: '63',
            name: 'status',
            age: 'String (1)',
            address: t("unifiedapi:transactionstatusnew"),
          },
          {
            key: '64',
            name: 'amount',
            age: 'Integer (10,2)',
            address: t("unifiedapi:amountdesc"),
          },
          {
            key: '65',
            name: 'referenceNo',
            age: 'String (15)',
            address: t("unifiedapi:referencenonew"),
          },
          {
            key: '66',
            name: 'resultCode',
            age: 'String (2)',
            address: t("unifiedapi:resultcodenew"),
          },
          {
            key: '67',
            name: 'currencyCode',
            age: 'String (3)',
            address: t("unifiedapi:currencycodenew"),
          },
          {
            key: '68',
            name: 'createTimeStamp',
            age: 'String (8)',
            address: t("timedesc"),
          },
          {
            key: '69',
            name: 'createDate',
            age: 'String (8)',
            address: t("datedesc"),
          },
          {
            key: '610',
            name: 'createTime',
            age: 'String (8)',
            address: t("timedesc"),
          },
          {
            key: '611',
            name: 'paymentTimeStamp',
            age: 'String (8)',
            address: t("timedesc"),
          },
          {
            key: '612',
            name: 'paymentDate',
            age: 'String (8)',
            address: t("datedesc"),
          },
          {
            key: '613',
            name: 'paymentTime',
            age: 'String (8)',
            address: t("timedesc"),
          },
          {
            key: '614',
            name: 'cardNo',
            age: 'String (15)',
            address: t("unifiedapi:cardnumbernew"),
          },
          {
            key: '615',
            name: 'cardHolderName',
            age: 'String (250)',
            address: t("unifiedapi:cardholdernamenew"),
          },
          {
            key: '616',
            name: 'settleDate',
            age: 'String (8)',
            address: 'Settle Date',
          },
          {
            key: '617',
            name: 'cardLocation',
            age: 'String (1)',
            address: 'Card Location',
          },
          {
            key: '618',
            name: 'cardType',
            age: 'String (3)',
            address: 'Card Type',
          },
          {
            key: '619',
            name: 'payMonth',
            age: 'Integer',
            address: 'Pay Month',
          },
          {
            key: '620',
            name: 'amountPerMonth',
            age: 'Integer',
            address: 'Amount Per Month',
          },
          {
            key: '621',
            name: 'totalAmount',
            age: 'Integer (10,2)',
            address: 'Total Amount',
          },
          {
            key: '622',
            name: 'thbAmount',
            age: 'Integer (10,2)',
            address: 'Thai Baht Amount',
          },
          {
            key: '623',
            name: 'issuerBank',
            age: 'String (250)',
            address: 'Bangkok Bank Public Company Limited',
          },
          {
            key: '624',
            name: 'openId',
            age: 'Number',
            address: 'Id',
          },
          {
            key: '625',
            name: 'detail',
            age: 'String (250)',
            address: 'Detail',
          },
          {
            key: '5626',
            name: 'customerName',
            age: 'String (150)',
            address: t("unifiedapi:customernamenew"),
          },
          {
            key: '627',
            name: 'customerEmail',
            age: 'String (150)',
            address: t("unifiedapi:customeremailnew"),
          },
          {
            key: '628',
            name: 'customerAddress',
            age: 'String (250)',
            address: t("unifiedapi:customeraddressnew"),
          },
          {
            key: '629',
            name: 'customerTelephone',
            age: 'Number (10)',
            address: t("unifiedapi:customertelephone"),
          },
          {
            key: '630',
            name: 'merchantDefined1',
            age: 'String (250)',
            address: t("unifiedapi:merchantdefined1new"),
          },
          {
            key: '631',
            name: 'merchantDefined2',
            age: 'String (250)',
            address: t("unifiedapi:merchantdefined2new"),
          },
          {
            key: '632',
            name: 'merchantDefined3',
            age: 'String (250)',
            address: t("unifiedapi:merchantdefined3new"),
          },
          {
            key: '633',
            name: 'merchantDefined4',
            age: 'String (250)',
            address: t("unifiedapi:merchantdefined4new"),
          },
          {
            key: '634',
            name: 'merchantDefined5',
            age: 'String (250)',
            address: t("unifiedapi:merchantdefined5new"),
          },
        ]
      },
    ]

    const dataResponseBQ = [
      {
        key: '1',
        name: 'resultCode',
        age: 'String (2)',
        mandatory: 'M',
        address: 
        <span>
          <Tag>00</Tag> = Success
          <br/>
          <Tag>01</Tag> = Invalid gbpReferenceNo
          <br/>
          <Tag>02</Tag> = Refund amount more than remaining amount
          <br/>
          <Tag>03</Tag> = This Payment type can not refund
          <br/>
          <Tag>04</Tag> = Refund Amount is null or zero
          <br/>
          <Tag>05</Tag> = Duplicate Reference No
          <br/>
          <Tag>90</Tag> = Error
          <br/>
        </span>,
      },
      {
        key: '2',
        name: 'resultMessage',
        age: 'String (250)',
        mandatory: 'M',
        address: t("unifiedapi:resultmessagenew"),
      },
      {
        key: '3',
        name: 'balance',
        age: 'Number (10,2)',
        mandatory: 'M',
        address: t("unifiedapi:balancenew"),
      },
      {
        key: '4',
        name: 'netAuthorize',
        age: 'Number (10,2)',
        mandatory: 'M',
        address: t("unifiedapi:netauthorizenew"),
      },
      {
        key: '5',
        name: 'authorize',
        age: 'Number (10,2)',
        mandatory: 'M',
        address: t("unifiedapi:authorizenew"),
      },
      {
        key: '6',
        name: 'currency',
        age: 'String (3)',
        mandatory: 'M',
        address: t("unifiedapi:currencycodenew"),
      },
    ]

    const dataResponseTC = [
      {
        key: '1',
        name: 'resultCode',
        age: 'String (2)',
        mandatory: 'M',
        address: 
        <span>
          <Tag>00</Tag> = Success
          <br/>
          <Tag>01</Tag> = Invalid gbpReferenceNo
          <br/>
          <Tag>02</Tag> = Refund amount more than remaining amount
          <br/>
          <Tag>03</Tag> = This Payment type can not refund
          <br/>
          <Tag>04</Tag> = Refund Amount is null or zero
          <br/>
          <Tag>05</Tag> = Duplicate Reference No
          <br/>
          <Tag>90</Tag> = Error
          <br/>
        </span>,
      },
      {
        key: '2',
        name: 'resultMessage',
        age: 'String (250)',
        mandatory: 'M',
        address: t("unifiedapi:resultmessagenew"),
      },
      {
        key: '3',
        name: 'referenceNo',
        age: 'String (15)',
        mandatory: 'M',
        address: t("unifiedapi:referencenonew"),
      },
      {
        key: '4',
        name: 'gbpReferenceNo',
        age: 'String (15)',
        mandatory: 'M',
        address: t("unifiedapi:gbpreferencenonew"),
      },
      {
        key: '5',
        name: 'date',
        age: 'String (8)',
        mandatory: 'M',
        address: t("datedesc"),
      },
      {
        key: '6',
        name: 'time',
        mandatory: 'M',
        age: 'String (6)',
        address: t("timedesc"),
      },
      {
        key: '7',
        name: 'transferReferenceNo',
        age: 'String (15)',
        mandatory: 'M',
        address: t("unifiedapi:transferreferencenonew"),
      },
    ]

    const dataResponseTQ = [
      {
        key: '1',
        name: 'resultCode',
        age: 'String (2)',
        mandatory: 'M',
        address: 
        <span>
          <Tag>00</Tag> = Success
          <br/>
          <Tag>01</Tag> = Invalid gbpReferenceNo
          <br/>
          <Tag>02</Tag> = Refund amount more than remaining amount
          <br/>
          <Tag>03</Tag> = This Payment type can not refund
          <br/>
          <Tag>04</Tag> = Refund Amount is null or zero
          <br/>
          <Tag>05</Tag> = Duplicate Reference No
          <br/>
          <Tag>90</Tag> = Error
          <br/>
        </span>,
      },
      {
        key: '2',
        name: 'resultMessage',
        age: 'String (250)',
        mandatory: 'M',
        address: t("unifiedapi:resultmessagenew"),
      },
      {
        key: '3',
        name: 'totalRecord',
        age: 'Number',
        mandatory: 'M',
        address: t("unifiedapi:totalrecordnew"),
      },
      {
        key: '4',
        name: <span className="font-object-parameter">txns</span>,
        children: [
          {
            key: '41',
            name: 'gbpReferenceNo',
            age: 'String (15)',
            mandatory: 'M',
            address: t("unifiedapi:gbpreferencenonew"),
          },
          {
            key: '42',
            name: 'resultCode',
            age: 'String (2)',
            mandatory: 'M',
            address: t("unifiedapi:resultcodenew"),
          },
          {
            key: '43',
            name: 'resultMessage',
            age: 'String (250)',
            mandatory: 'M',
            address: t("unifiedapi:resultmessagenew"),
          },
          {
            key: '44',
            name: 'referenceNo',
            age: 'String (15)',
            mandatory: 'M',
            address: t("unifiedapi:referencenonew"),
          },
          {
            key: '45',
            name: 'bankAccount',
            age: 'String (10)',
            mandatory: 'M',
            address: t("unifiedapi:bankaccountnew"),
          },
          {
            key: '46',
            name: 'bankCode',
            age: 'String (3)',
            mandatory: 'M',
            address: t("unifiedapi:bankcodenew"),
          },
          {
            key: '47',
            name: 'accHolderName',
            age: 'String (10)',
            mandatory: 'M',
            address: t("unifiedapi:accholdernamenew"),
          },
          {
            key: '48',
            name: 'amount',
            age: 'Number (10,2)',
            mandatory: 'M',
            address: t("unifiedapi:amountdesc"),
          },
          {
            key: '49',
            name: 'timeline',
            age: 'String (250)',
            mandatory: 'M',
            address: t("unifiedapi:timelinenew"),
          },
          {
            key: '50',
            name: 'date',
            age: 'String (8)',
            mandatory: 'M',
            address: 'Date',
          },
          {
            key: '51',
            name: 'time',
            age: 'String (6)',
            mandatory: 'M',
            address: 'Time',
          },
          {
            key: '52',
            name: 'merchantDefined1',
            age: 'String (250)',
            mandatory: 'O',
            address: t("unifiedapi:merchantdefined1new"),
          },
          {
            key: '53',
            name: 'merchantDefined2',
            age: 'String (250)',
            mandatory: 'O',
            address: t("unifiedapi:merchantdefined2new"),
          },
          {
            key: '54',
            name: 'merchantDefined3',
            age: 'String (250)',
            mandatory: 'O',
            address: t("unifiedapi:merchantdefined3new"),
          },
          {
            key: '55',
            name: 'merchantDefined4',
            age: 'String (250)',
            mandatory: 'O',
            address: t("unifiedapi:merchantdefined4new"),
          },
          {
            key: '56',
            name: 'merchantDefined5',
            age: 'String (250)',
            mandatory: 'O',
            address: t("unifiedapi:merchantdefined5new"),
          },
          {
            key: '57',
            name: 'transferReferenceNo',
            age: 'String (15)',
            mandatory: 'O',
            address: t("unifiedapi:transferreferencenonew"),
          },
        ]
      },
    ]

    const switchingRequestApiJavaScript = (value) => {
      this.setState({ selectRequestValue: value })
    }

    const switchingResponseApiJavaScript = (value) => {
      this.setState({ selectResponseValue: value })
    }

    return (
      <Fragment>
        <Content
          style={{
            background: '#fff',
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >

{/* temp */}
          <h2 className="title">{t("unifiedapi:titleunifiedapi")}</h2>
          <hr />
          <p>
            {t("unifiedapi:detailmessage1")} 
          </p>

          {/* <br />

          <h2 id="components-unified-howtoenable" className="title-sub">{t("qrcash:howtoenable")}</h2>
          <p>{t("unifiedapi:howtoenabledetail1")}</p> */}

          <br />

          {/* <h2 id="components-qrcash-paymentflow" className="title-sub">{t("qrcash:paymentflow")}</h2>
          <p>-</p>

          <br />

          <h2 id="components-qrcash-steptopayment" className="title-sub">{t("qrcash:steptopayment")}</h2>
          <p>-</p>
          <br /> */}

          <h2 id="components-unified-requestauthtoken" className="title-sub3">{t("unifiedapi:steptopayment1")}</h2>
          <p>{t("unifiedapi:step1message")}</p>

          {/* <h2 id="components-unified-implementation" className="title-sub2">{t("qrcash:implementation")}</h2> */}

          {/* <br />

          <h2 id="creating-source-token" className="title-sub2">{t("qrcash:creatingsource")}</h2>
          <p>{t("enquiry:creatingsourcedetail1")}</p>
          <p>
            <code>secretKey</code> {t("unifiedapi:and")} <code>publicKey</code> {'.'}
          </p> */}

          <br />

          <Descriptions title="Environment" size={'default'} >
            <Descriptions.Item label="Production URL">https://api.gbprimepay.com/</Descriptions.Item>
            <Descriptions.Item label="Test URL">https://api.globalprimepay.com/</Descriptions.Item>
          </Descriptions>

          <br />

          <p>
            <text className="font-bold">Authentication API URL: </text>
            <text>{`{ENV}/unified/authToken/create`} - Method POST</text>
          </p>

          <br />

          <h2 id="creating-request-token" className="title-sub2">Create Request</h2>

          <br />

          <p className="font-bold">Header</p>
          <Table columns={columns} dataSource={dataHeader} pagination={false} bordered />

          <br />

          <p className="font-bold">Parameter</p>
          <Table  columns={columnsParameter} dataSource={dataParameterToken} pagination={false} bordered />
          <p>M=Mandatory, C=Condition, O=Optional</p>

          {/* <p>{t("qrcash:usageexample")}</p>
          <Tabs defaultActiveKey="1" onChange={this.callback}>
            <TabPane tab="Javascript" key="1">
            {renderFormHtmlToken(this.props.qrCash)}
            </TabPane>
            <TabPane tab="PHP" key="2">
              {renderPhpCode()}
            </TabPane>
          </Tabs> */}

          <br />

          <p className="font-bold">JSON Request Body</p>
          {renderFormJsonRequestToken()}

          <br />

          <h2 id="response-data-token" className="title-sub2">{t("qrcash:response")}</h2>
          <Table columns={columns} dataSource={dataResponseToken} pagination={false} bordered />

          <br />
          <br />

          <p className="font-bold">JSON Response</p>
          {renderFormCodeResponse()}

          <br />

          <h2 id="components-unified-apitransaction"  className="title-sub3">{t("unifiedapi:steptopayment2")}</h2>
          {/* <p>{t("unifiedapi:step2message")}</p> */}

          {/* <br />

          <h2 id="creating-source-apitransaction" className="title-sub2">{t("unifiedapi:creatingsource")}</h2>
          <p>{t("unifiedapi:creatingsourcedetail1")}</p> */}

          <br />

          <p>
            <text className="font-bold">Authentication API URL: </text>
            <text>{`{ENV}/unified/transaction`} - Method POST</text>
          </p>

          <br />

          <h2 id="creating-request-apitransaction" className="title-sub2">Create Request</h2>

          <br />
          
          <p className="font-bold">Header</p>
          <Table columns={columns} dataSource={dataHeaderTransaction} pagination={false} bordered />

          <br />

          <p className="font-bold">Parameter</p>
          <p>{t("unifiedapi:parameterdescnew")}</p>

          {/* <Tree
            showLine
            switcherIcon={
              <span style={{ color: '#02416D', fontWeight: 'bold', paddingTop: '0px' }}  >
                <Icon type="down" style={{ fontSize: '14px', color: '#08c' }} theme="outlined" />
              </span>
            }
            defaultExpandedKeys={['0-0-0']}
            treeData={treeData}
          /> */}

      {/* example 1
      <Row gutter={16}>
        <Col className="gutter-row" span={15}>
          <div >
          <Table
              columns={columnsUnifiedApi}
              dataSource={dataUnifiedApi}
              pagination={false}
            />
            </div>
        </Col>
        <Col className="gutter-row" span={9}>
          <div >
          <p className="font-bold">JSON Request Body</p>
            <Select name="apijavascript" style={{ width: 300 }} 
                defaultValue={'11'}
                onChange={switchingRequestApiJavaScript} >
                <Option className="optionsMenu" value="11">JSON Request For Payment Create Credit / Debit Card</Option>
                <Option className="optionsMenu" value="12">JSON Request For Payment Installment</Option>
                <Option className="optionsMenu" value="13">JSON Request For Payment Bill Payment</Option>
                <Option className="optionsMenu" value="14">JSON Request For Payment QR Cash</Option>
                <Option className="optionsMenu" value="15">JSON Request For Payment QR Cash (Text)</Option>
                <Option className="optionsMenu" value="16">JSON Request For Payment QR Credit</Option>
                <Option className="optionsMenu" value="17">JSON Request For Payment Wechat Pay</Option>
                <Option className="optionsMenu" value="18">JSON Request For Payment Line Payment</Option>
                <Option className="optionsMenu" value="19">JSON Request For Payment True Wallet</Option>
                <Option className="optionsMenu" value="110">JSON Request For Payment Shopee Pay</Option>
                <Option className="optionsMenu" value="111">JSON Request For Payment Atome</Option>
                <Option className="optionsMenu" value="112">JSON Request For Payment Mobile Banking</Option>
                <Option className="optionsMenu" value="113">JSON Request For Payment Alipay</Option>
                <Option className="optionsMenu" value="2">JSON Request For Void</Option>
                <Option className="optionsMenu" value="3">JSON Request For Refund</Option>
                <Option className="optionsMenu" value="4">JSON Request For Settle</Option>
                <Option className="optionsMenu" value="5">JSON Request For Query Transaction</Option>
                <Option className="optionsMenu" value="6">JSON Request For Balance Query</Option>
                <Option className="optionsMenu" value="7">JSON Request For Transfer Manual Create</Option>
                <Option className="optionsMenu" value="8">JSON Request For Transfer Manual Query</Option>
            </Select>
            <br />
            <br />
            {renderFormJsonRequestTransection(this.state.selectRequestValue)} 
          </div>
        </Col>
      </Row> */}

          {/* example 2
          <Table
            columns={columnsUnifiedApi}
            dataSource={dataUnifiedApi}
            pagination={false}
          />

          <br />
          <br />

          <p className="font-bold">JSON Request Body</p>
          <Select name="apijavascript" style={{ width: 500 }} 
              defaultValue={'11'}
              onChange={switchingRequestApiJavaScript} >
              <Option className="optionsMenu" value="11">JSON Request For Payment Create Credit / Debit Card</Option>
              <Option className="optionsMenu" value="12">JSON Request For Payment Installment</Option>
              <Option className="optionsMenu" value="13">JSON Request For Payment Bill Payment</Option>
              <Option className="optionsMenu" value="14">JSON Request For Payment QR Cash</Option>
              <Option className="optionsMenu" value="15">JSON Request For Payment QR Cash (Text)</Option>
              <Option className="optionsMenu" value="16">JSON Request For Payment QR Credit</Option>
              <Option className="optionsMenu" value="17">JSON Request For Payment Wechat Pay</Option>
              <Option className="optionsMenu" value="18">JSON Request For Payment Line Payment</Option>
              <Option className="optionsMenu" value="19">JSON Request For Payment True Wallet</Option>
              <Option className="optionsMenu" value="110">JSON Request For Payment Shopee Pay</Option>
              <Option className="optionsMenu" value="111">JSON Request For Payment Atome</Option>
              <Option className="optionsMenu" value="112">JSON Request For Payment Mobile Banking</Option>
              <Option className="optionsMenu" value="113">JSON Request For Payment Alipay</Option>
              <Option className="optionsMenu" value="2">JSON Request For Void</Option>
              <Option className="optionsMenu" value="3">JSON Request For Refund</Option>
              <Option className="optionsMenu" value="4">JSON Request For Settle</Option>
              <Option className="optionsMenu" value="5">JSON Request For Query Transaction</Option>
              <Option className="optionsMenu" value="6">JSON Request For Balance Query</Option>
              <Option className="optionsMenu" value="7">JSON Request For Transfer Manual Create</Option>
              <Option className="optionsMenu" value="8">JSON Request For Transfer Manual Query</Option>
          </Select> */}

        <Table
          columns={columnsUnifiedApi}
          dataSource={dataUnifiedApiMain}
          pagination={false}
        />
        <br />
        <table-code-parameter-header>{t("unifiedapi:headertableparameternew")}</table-code-parameter-header>
        <br />
        <br />
        <Collapse>
          <Panel header="Parameter for PC (Create Payment)" key="1">
            <Row gutter={2}>
              <Col className="gutter-row" span={15}>
                <Table className="css-newline"
                  columns={columnsUnifiedApiHeader}
                  dataSource={dataUnifiedApiPC}
                  pagination={false}
                />
              </Col>
              <Col className="gutter-row" span={9}>
                {renderFormJsonRequestTransectionPCCard()} 
              </Col>
            </Row>
          </Panel>
          <Panel header="Parameter for PV (Void)" key="2">
            <Row gutter={2}>
              <Col className="gutter-row" span={15}>
                <Table 
                  columns={columnsUnifiedApiHeader}
                  dataSource={dataUnifiedApiPV}
                  pagination={false}
                />
              </Col>
              <Col className="gutter-row" span={9}>
                {renderFormJsonRequestTransectionPV()} 
              </Col>
            </Row>
          </Panel>
          <Panel header="Parameter for PR (Refund)" key="3">
           <Row gutter={2}>
              <Col className="gutter-row" span={15}>
                <Table 
                  columns={columnsUnifiedApiHeader}
                  dataSource={dataUnifiedApiPR}
                  pagination={false}
                />
              </Col>
              <Col className="gutter-row" span={9}>
                {renderFormJsonRequestTransectionPR()} 
              </Col>
            </Row>
          </Panel>
          <Panel header="Parameter for PS (Settle)" key="4">
           <Row gutter={2}>
              <Col className="gutter-row" span={15}>
                <Table 
                  columns={columnsUnifiedApiHeader}
                  dataSource={dataUnifiedApiPS}
                  pagination={false}
                />
              </Col>
              <Col className="gutter-row" span={9}>
                {renderFormJsonRequestTransectionPS()} 
              </Col>
            </Row>
          </Panel>
          <Panel header="Parameter for PQ (Query Transaction)" key="5">
           <Row gutter={2}>
              <Col className="gutter-row" span={15}>
                <Table 
                  columns={columnsUnifiedApiHeader}
                  dataSource={dataUnifiedApiPQ}
                  pagination={false}
                />
              </Col>
              <Col className="gutter-row" span={9}>
                {renderFormJsonRequestTransectionPQ()} 
              </Col>
            </Row>
          </Panel>
          {/* <Panel header="BQ" key="6">
           <Row gutter={16}>
              <Col className="gutter-row" span={13}>
                <Table 
                  columns={columnsUnifiedApiHeader}
                  dataSource={dataUnifiedApiBQ}
                  pagination={false}
                />
              </Col>
              <Col className="gutter-row" span={11}>
                {renderFormJsonRequestTransectionBQ()} 
              </Col>
            </Row>
          </Panel> */}
          <Panel header="Parameter for TC (Transfer Manual Create)" key="6">
           <Row gutter={2}>
              <Col className="gutter-row" span={15}>
                <Table 
                  columns={columnsUnifiedApiHeader}
                  dataSource={dataUnifiedApiTC}
                  pagination={false}
                />
              </Col>
              <Col className="gutter-row" span={9}>
                {renderFormJsonRequestTransectionTC()} 
              </Col>
            </Row>
          </Panel>
          <Panel header="Parameter for TQ (Transfer Manual Query)" key="7">
           <Row gutter={2}>
              <Col className="gutter-row" span={15}>
                <Table 
                  columns={columnsUnifiedApiHeader}
                  dataSource={dataUnifiedApiTQ}
                  pagination={false}
                />
              </Col>
              <Col className="gutter-row" span={9}>
                {renderFormJsonRequestTransectionTQ()} 
              </Col>
            </Row>
          </Panel>
        </Collapse>
        <p>M=Mandatory, C=Condition, O=Optional</p>

          {/* <Tabs defaultActiveKey="1" onChange={this.callback}>
            <TabPane tab="Javascript" key="1">
            {renderFormHtmlToken(this.props.qrCash)}
            </TabPane>
            <TabPane tab="PHP" key="2">
              {renderPhpCode()}
            </TabPane>
          </Tabs> */}

          {/* <br />
          <br />
          
          {renderFormJsonRequestTransection(this.state.selectRequestValue)}  */}

        <br />

        <h2 id="response-data-apitransaction"  className="title-sub2">{t("qrcash:response")}</h2>
        {/* <Table columns={columns} dataSource={dataResponseTransection} pagination={false} bordered /> */}

        {/* <Table
          columns={columns}
          dataSource={dataResponseTransection}
          pagination={false}
        />

        <br />
        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>JSON Response Data</p>
        <Select name="apijavascript" style={{ width: 500 }} 
            defaultValue={'11'}
            onChange={switchingResponseApiJavaScript} >
            <Option className="optionsMenu" value="11">JSON Response For Payment Create Credit / Debit Card</Option>
            <Option className="optionsMenu" value="12">JSON Response For Payment Installment</Option>
            <Option className="optionsMenu" value="13">JSON Response For Payment Bill Payment</Option>
            <Option className="optionsMenu" value="14">JSON Response For Payment QR Cash</Option>
            <Option className="optionsMenu" value="15">JSON Response For Payment QR Cash (Text)</Option>
            <Option className="optionsMenu" value="16">JSON Response For Payment QR Credit</Option>
            <Option className="optionsMenu" value="17">JSON Response For Payment Wechat Pay</Option>
            <Option className="optionsMenu" value="18">JSON Response For Payment Line Payment</Option>
            <Option className="optionsMenu" value="19">JSON Response For Payment True Wallet</Option>
            <Option className="optionsMenu" value="110">JSON Response For Payment Shoppee Pay</Option>
            <Option className="optionsMenu" value="111">JSON Response For Payment Atome</Option>
            <Option className="optionsMenu" value="112">JSON Response For Payment Mobile Banking</Option>
            <Option className="optionsMenu" value="113">JSON Response For Payment Alipay</Option>
            <Option className="optionsMenu" value="2">JSON Response For Void</Option>
            <Option className="optionsMenu" value="3">JSON Response For Refund</Option>
            <Option className="optionsMenu" value="4">JSON Response For Settle</Option>
            <Option className="optionsMenu" value="5">JSON Response For Query Transaction</Option>
            <Option className="optionsMenu" value="6">JSON Response For Balance Query</Option>
            <Option className="optionsMenu" value="7">JSON Response For Transfer Manual Create</Option>
            <Option className="optionsMenu" value="8">JSON Response For Transfer Manual Query</Option>
        </Select>

        <br />
        <br /> */}

        <Table
          columns={columns}
          dataSource={dataResponseMain}
          pagination={false}
        />
        <br />
        <table-code-parameter-header>Response Data เพิ่มเติมสำหรับแต่ละ API Type</table-code-parameter-header>
        <br />
        <br />
        <Collapse>
          <Panel header="Response from PC (Create Payment)" key="1">
            <Row gutter={2}>
              <Col className="gutter-row" span={15}>
                <Table
                  columns={columns}
                  dataSource={dataResponsePC}
                  pagination={false}
                />
              </Col>
              <Col className="gutter-row" span={9}>
                {renderFormJsonResponseTransectionPCCard()} 
              </Col>
            </Row>
          </Panel>

          <Panel header="Response from PV (Void)" key="2">
            <Row gutter={2}>
              <Col className="gutter-row" span={15}>
                <Table
                  columns={columns}
                  dataSource={dataResponsePV}
                  pagination={false}
                />
              </Col>
              <Col className="gutter-row" span={9}>
                {renderFormJsonResponseTransectionPV()} 
              </Col>
            </Row>
          </Panel>

          <Panel header="Response from PR (Refund)" key="3">
            <Row gutter={2}>
              <Col className="gutter-row" span={15}>
                <Table
                  columns={columns}
                  dataSource={dataResponsePR}
                  pagination={false}
                />
              </Col>
              <Col className="gutter-row" span={9}>
                {renderFormJsonResponseTransectionPR()} 
              </Col>
            </Row>
          </Panel>

          <Panel header="Response from PS (Settle)" key="4">
            <Row gutter={2}>
              <Col className="gutter-row" span={15}>
                <Table
                  columns={columns}
                  dataSource={dataResponsePS}
                  pagination={false}
                />
              </Col>
              <Col className="gutter-row" span={9}>
                {renderFormJsonResponseTransectionPS()} 
              </Col>
            </Row>
          </Panel>

          <Panel header="Response from PQ (Query Transaction)" key="5">
            <Row gutter={2}>
              <Col className="gutter-row" span={15}>
                <Table className="css-newline"
                  columns={columns}
                  dataSource={dataResponsePQ}
                  pagination={false}
                />
              </Col>
              <Col className="gutter-row" span={9}>
                {renderFormJsonResponseTransectionPQ()} 
              </Col>
            </Row>
          </Panel>

          <Panel header="Response from BQ (Balance Query)" key="6">
            <Row gutter={2}>
              <Col className="gutter-row" span={15}>
                <Table
                  columns={columns}
                  dataSource={dataResponseBQ}
                  pagination={false}
                />
              </Col>
              <Col className="gutter-row" span={9}>
                {renderFormJsonResponseTransectionBQ()} 
              </Col>
            </Row>
          </Panel>

          <Panel header="Response from TC (Transfer Manual Create)" key="7">
            <Row gutter={2}>
              <Col className="gutter-row" span={15}>
                <Table
                  columns={columns}
                  dataSource={dataResponseTC}
                  pagination={false}
                />
              </Col>
              <Col className="gutter-row" span={9}>
                {renderFormJsonResponseTransectionTC()} 
              </Col>
            </Row>
          </Panel>

          <Panel header="Response from TQ (Transfer Manual Query)" key="8">
            <Row gutter={2}>
              <Col className="gutter-row" span={15}>
                <Table
                  columns={columns}
                  dataSource={dataResponseTQ}
                  pagination={false}
                />
              </Col>
              <Col className="gutter-row" span={9}>
                {renderFormJsonResponseTransectionTQ()} 
              </Col>
            </Row>
          </Panel>
        </Collapse>

        {/* <Table
          columns={columns}
          dataSource={dataResponseTransection}
          pagination={false}
        />

        {renderFormJsonResponseTransection(this.state.selectResponseValue)}  */}

        <br />

          <h2 id="components-unified-backgroundurl" className="title-sub2">{t("qrcash:responseheader")}</h2>
          <Table className="css-newline" columns={columns} dataSource={dataBackgroudResponseToMerchant} pagination={false} bordered />

          <br />

          <p className="font-bold">Background JSON Response to Merchant</p>
          {renderFormCodeResponseToMerchant()}

          {/* <br />

          <h2 id="components-unified-resultcode" className="title-sub">{t("fullpayment3d:resultcode")}</h2>
          <ResultCode /> */}

          <br />

          <h2 id="issuers-bank" className="title-sub2">{t("installment:issuersBank")}</h2>
          <Table columns={columnsBankCode} dataSource={dataBankCode} pagination={false} bordered />

          <br />

          <h2 id="components-unified-limit" className="title-sub">{t("qrcash:limit")}</h2>
          <Table columns={columnsLimit} dataSource={dataLimit} pagination={false} bordered />

        </Content>

        <Sider className='sider-unifiedapi' breakpoint="md" collapsedWidth="0" style={{ background: '#fff' }}>
          <br />
          <span style={{ color: '#02416D', fontWeight: 'bold', paddingTop: '20px' }}  >
            <Icon type="container" style={{ fontSize: '20px', color: '#08c' }} theme="outlined" />
          </span>
          <span style={{ color: '#02416D', fontWeight: 'bold', paddingTop: '20px' }}>{t("tablecontents")}</span>
          <br />
          <Anchor  style={{ paddingTop: '8px' }} >
            <Anchor.Link href="#components-unified-requestauthtoken" title={t("unifiedapi:requestauthtoken")} >
              <Anchor.Link href="#creating-request-token" title={t("unifiedapi:request")} />
              <Anchor.Link href="#response-data-token" title={t("unifiedapi:response")} />
            </Anchor.Link>
            <Anchor.Link href="#components-unified-apitransaction" title={t("unifiedapi:createunifiedapi")} >
              <Anchor.Link href="#creating-request-apitransaction" title={t("unifiedapi:request")}  />
              <Anchor.Link href="#response-data-apitransaction" title={t("unifiedapi:response")} />
            </Anchor.Link>
            <Anchor.Link href="#components-unified-backgroundurl" title='Background Response' />
            <Anchor.Link href="#issuers-bank" title={t("unifiedapi:issuersBank")} />
            <Anchor.Link href="#components-unified-limit" title={t("unifiedapi:limit")} />
          </Anchor>
        </Sider>

      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  qrCash: state.qrCash
})
const mapDispatchToProps = dispatch => ({
  updateByKey: (key, value) => dispatch(actions.updateByKey(key, value))
})
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(UnifiedAPI));
