import React, { Component } from 'react'
import { Card, Descriptions } from 'antd';
import Prism from 'prismjs';
import PrismCode from './PrismCode';

const tabList = [
  {
    key: 'tab1',
    tab: 'Result',
  },
  {
    key: 'tab2',
    tab: 'Code',
  },
];

const code = `
const foo = 'foo';
const bar = 'bar';
console.log(foo + bar);
`

const codePhp = `
<?php
  $name = 'por';
  echo $name;
?>
`

const renderResult = () => (
  <div>
    <iframe src='http://merchant-dev.globalprimepay.com/index.html' style={{width: '100%', height: '380px'}} frameBorder="0" scrolling="no"></iframe>
    <br />
    <Descriptions title="Card Test UAT (Server Test)" size={'default'}>
      <Descriptions.Item label="Number">4987 6543 2109 8769</Descriptions.Item>
      <Descriptions.Item label="MM/YYYY">05/2021</Descriptions.Item>
      <Descriptions.Item label="CVV">111</Descriptions.Item>
    </Descriptions>
  </div>
)

const renderFormCode = () => (
  <div>
    <PrismCode
      code={code}
      language="js"
      plugins={["line-numbers"]}
    />
  </div>
)

const contentList = {
  tab1: renderResult(),
  tab2: renderFormCode(),
};

export default class Demo extends Component {
  state = {
    key: 'tab1',
    noTitleKey: 'app',
  };
  onTabChange = (key, type) => {
    console.log(key, type);
    this.setState({ [type]: key });
  };
  render() {
    return (
      <div>
        <iframe src='http://merchant-dev.globalprimepay.com/index.html' style={{width: '100%', height: '380px'}} frameBorder="0" scrolling="no"></iframe>
        <br />
          <Descriptions title="Card Test UAT (Server Test)" size={'default'}>
            <Descriptions.Item label="Card Number">4535 0177 1053 5741</Descriptions.Item>
            <Descriptions.Item label="MM/YY">05/28</Descriptions.Item>
            <Descriptions.Item label="CVV">184</Descriptions.Item>
          </Descriptions>
          <br />

          <Descriptions title="Card Test UAT (Server Test)" size={'default'}>
            <Descriptions.Item label="Card Number">5258 8606 8990 5862</Descriptions.Item>
            <Descriptions.Item label="MM/YY">02/25</Descriptions.Item>
            <Descriptions.Item label="CVV">950</Descriptions.Item>
          </Descriptions>
      </div>
    )
  }
}
