import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Input, Select, Steps, Row, Col } from 'antd'
import get from 'lodash/get'
import * as actions from '../../actions/qrCashAction'
import TokenForm from './Form'
import PrismCode from '../Tokenization/PrismCode'
import { generateRef, qrText } from '../../common/Utils'

const { Step } = Steps;

const codeResponse = `
{
  "resultCode": "00",
  "amount": {amount},
  "referenceNo": "{referenceNo}",
  "gbpReferenceNo": "gbp{referenceNo}"
}
`

const renderFormCodeResponse = data => {
  let _codeResponse = codeResponse.replace(/{amount}/g, data.amount);
  _codeResponse = _codeResponse.replace(/{referenceNo}/g, data.referenceNo);
  return (
    <div>
      <PrismCode
        code={_codeResponse}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>
  )
}

class QRCreditDemo extends Component {
  state = {
    currentStep: 0
  }
  componentDidMount() {
    this.props.updateByKey('referenceNo', generateRef())
  }
  changeStep = current => {
    this.setState({ currentStep: current })
  }
  changeStepPrev = () => {
    this.setState({ currentStep: this.state.currentStep - 1 })
  }
  render() {
    return (
      <>
        <p style={{color: 'rgba(0, 0, 0, 0.85)', marginTop: 10 }}>Result</p>
        <Steps size="small" current={this.state.currentStep}>
          <Step title="Create" />
          <Step title="Pay" />
          <Step title="Response" />
        </Steps>

        <div className="steps-content">
          { this.state.currentStep === 0 && (
            <Row>
              <Col sm={7} md={8}>
                {' '}
              </Col>
              <Col sm={10} md={8}>
                <TokenForm
                  updateByKey={this.props.updateByKey}
                  onSubmit={() => this.changeStep(1)}
                  loading={this.props.qrCash.loading}
                  data={this.props.qrCash}
                />
              </Col>
              <Col sm={7} md={8}>
                {' '}
              </Col>
            </Row>
            )}

            { this.state.currentStep === 1 && (
              <Row>
                <Col sm={5} md={8}>
                  {' '}
                </Col>
                <Col sm={14} md={8}>
                  { this.props.qrCash.isQr === 'qr' && (
                    <img
                      src={require('../../res/img/qrcredit.png')}
                      alt="QR Cash"
                      style={{ width: '100%', height: 'auto' }}
                    />
                  )}
                  { this.props.qrCash.isQr !== 'qr' && (
                    <>
                      <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold', textAlign: 'left' }}>Data Text</p>
                      <div style={{ wordWrap: 'break-word', textAlign: 'left' }}>{qrText()}</div>
                    </>
                  )}
                  <br/>
                  <br/>
                  <Button type="primary" onClick={() => this.changeStep(2)} block>Pay Now</Button><br/>
                  <Button style={{ marginTop: 8 }} onClick={this.changeStepPrev} block>
                    Previous
                  </Button>
                </Col>
                <Col sm={5} md={8}>
                  {' '}
                </Col>
              </Row>
            )}

            { this.state.currentStep === 2 && (
              <>
                <Row>
                  <Col sm={2} md={4}>
                    {' '}
                  </Col>
                  <Col sm={20} md={16}>
                    <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold', textAlign: 'left' }}>Data Response</p>
                    {renderFormCodeResponse(this.props.qrCash)}
                    <br/>
                  </Col>
                  <Col sm={2} md={4}>
                    {' '}
                  </Col>
                </Row>
                <Row>
                  <Col sm={5} md={8}>
                    {' '}
                  </Col>
                  <Col sm={14} md={8}>
                    <Button type="primary" onClick={() => this.changeStep(0)} block>Done</Button><br/>
                    <Button style={{ marginTop: 8 }} onClick={this.changeStepPrev} block>
                      Previous
                    </Button>
                  </Col>
                  <Col sm={5} md={8}>
                    {' '}
                  </Col>
                </Row>
              </>
            )}
          </div>
      </>
    )
  }
}
const mapStateToProps = state => ({
  qrCash: state.qrCash
})
const mapDispatchToProps = dispatch => ({
  updateByKey: (key, value) => dispatch(actions.updateByKey(key, value))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QRCreditDemo)