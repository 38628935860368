import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Layout, Button, Breadcrumb, Icon, Table, Row, Col, Tabs, Anchor } from 'antd'
import get from 'lodash/get'
import PrismCode from '../Tokenization/PrismCode'
import * as actions from '../../actions/linePaymentAction'
import { withTranslation } from "react-i18next";

const { Content, Sider } = Layout;
const { TabPane } = Tabs;

const htmlCode = `
<form id="atomeform" action="{url}" method="POST"> 
  <input type="hidden" name="publicKey" value="{publicKey}" /><br/>
  <input type="hidden" name="customerTelephone" value="{customerTelephone}" /><br />
  <input type="hidden" name="referenceNo" value="{referenceNo}" /><br/>
  <input type="hidden" name="customerName" value="{customerName}"><br />
  <input type="hidden" name="customerEmail" value="{customerEmail}" /><br/>
  <input type="hidden" name="customerAddress" value="{customerAddress}"><br />
  <input type="hidden" name="detail" placeholder="Detail" value="{detail}" /><br/>
  <input type="hidden" name="responseUrl" value="{responseUrl}" /><br/>
  <input type="hidden" name="backgroundUrl" value="{backgroundUrl}" /><br/>
  <label>Amount: </label>
  <input type="number" name="amount" maxlength="13" placeholder="Amount" value="{amount}" /><br/>
  <input type="hidden" name="merchantDefined1" value="{merchantDefined1}" /><br/>
  <input type="hidden" name="merchantDefined2" value="{merchantDefined2}" /><br/>
  <input type="hidden" name="merchantDefined3" value="{merchantDefined3}" /><br/>
  <input type="hidden" name="merchantDefined4" value="{merchantDefined4}" /><br/>
  <input type="hidden" name="merchantDefined5" value="{merchantDefined5}" /><br/>
  <div>
  <label>Checksum: </label> 
  <input type="text" name="checksum" value="" /><br/>
  <input id="button" type="button" onClick="genChecksum()" value="Generate Checksum" />
  </div>
  <div> 
    <button type="submit">Pay</button> 
  </div>
</form>

<script src="https://cdnjs.cloudflare.com/ajax/libs/crypto-js/3.1.9-1/crypto-js.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/crypto-js/3.1.9-1/hmac-sha256.min.js"></script>
<script>
function genChecksum(){
  var hash = CryptoJS.HmacSHA256(document.getElementsByName("amount")[0].value 
  + document.getElementsByName("referenceNo")[0].value   
  + document.getElementsByName("responseUrl")[0].value 
  + document.getElementsByName("backgroundUrl")[0].value,
  {secret_key});
    document.getElementsByName("checksum")[0].value = hash;  
}
</script>
`

const phpResponse = `
    <?php 
        $respFile = fopen("bg-log_qr.txt", "w") or die("Unable to open file!");

        $json_str = file_get_contents('php://input'); 
        fwrite($respFile, $json_str .); 
        $json_obj = json_decode($json_str);

        fwrite($respFile, "resultCode=" . $json_obj->resultCode . ); 
        fwrite($respFile, "amount=" . $json_obj->amount . );
        fwrite($respFile, "referenceNo=" . $json_obj->referenceNo . ); 
        fwrite($respFile, "gbpReferenceNo=" . $json_obj->gbpReferenceNo . ); 
        fwrite($respFile, "currencyCode=" . $json_obj->currencyCode .);

        fclose($respFile);

        echo "ok"; 
    ?>
`

const javaResponse = `

StringBuffer jb = new StringBuffer();
String line = null;

try {

  BufferedReader reader = request.getReader();

  while ((line = reader.readLine()) != null)

    jb.append(line);

} catch (Exception e) { /*report an error*/ }

try {

  JSONObject jsonObject = new JSONObject(jb.toString());
  System.out.println("resultCode=" + jsonObject.getString("resultCode"));
  System.out.println("amount=" + jsonObject.getBigDecimal("amount"));
  System.out.println("referenceNo=" + jsonObject.getBigDecimal("referenceNo"));
  System.out.println("gbpReferenceNo=" + jsonObject.getString("gbpReferenceNo"));
  System.out.println("currencyCode=" + jsonObject.getString("currencyCode"));
  
} catch (JSONException e) { /*report an error*/ }
`

const renderFormHtml = data => {
  let url = `https://api.globalprimepay.com/v1/atome`
  if (data.isLr === `bill`) {
    url = `https://api.globalprimepay.com/v1/atome"`
  }
  let _htmlCode = htmlCode.replace(/{amount}/g, data.amount);
  _htmlCode = _htmlCode.replace(/{url}/g, url);
  return (
    <div>
      <PrismCode
        code={_htmlCode}
        language="html"
        plugins={["line-numbers"]}
      />
    </div>
  )
}

const renderPhpResponse = () => (
  <div>
    <PrismCode
      code={phpResponse}
      language="php"
      plugins={["line-numbers"]}
    />
  </div>
)

const renderJavaResponse = () => (
  <div>
    <PrismCode
      code={javaResponse}
      language="java"
      plugins={["line-numbers"]}
    />
  </div>
)

const codeResponse = `
{
  "resultCode": "00",
  "resultMessage": "-",
  "gbpReferenceNo": "gbp0001000900",
  "referenceNo": "20171128001",
  "amount": 1.00
}
`

const renderFormCodeResponse = () => (
  <div>
    <PrismCode
      code={codeResponse}
      language="json"
      plugins={["line-numbers"]}
    />
  </div>
)

class Atome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }
  changeStep = current => {
    this.setState({ current: current })
    console.log(current)
  }
  render() {

    const { t , i18n } = this.props;

    const columns = [
        {
          title: t("field"),
          dataIndex: 'name',
          key: 'name',
          render: text => <code>{text}</code>
        },
        {
          title: t("type"),
          dataIndex: 'age',
          key: 'age',
        },
        {
          title: t("description"),
          dataIndex: 'address',
          key: 'address',
        }
    ];
    
    const columnsParameter = [
        {
          title: t("field"),
          dataIndex: 'name',
          key: 'name',
          render: text => <code>{text}</code>
        },
        {
          title: t("type"),
          dataIndex: 'age',
          key: 'age',
        },
        {
          title: t("mandatory"),
          dataIndex: 'mandatory',
          key: 'mandatory',
        },
        {
          title: t("description"),
          dataIndex: 'address',
          key: 'address',
        }
    ];
    
    const dataHeader = [
      {
        key: `1`,
        name: `content-type`,
        age: `String`,
        address: `application/x-www-form-urlencoded`
      },
    ];
    
    const dataHeaderImage = [
      {
        key: `1`,
        name: `content-type`,
        age: `String`,
        address: `image/png`
      },
    ];

    const dataParameter = [
      {
        key: `0`,
        name: `publicKey`,
        age: `String (40)`,
        mandatory: `M`,
        address: t("publickeydesc")
      },
      {
        key: `1`,
        name: `amount`,
        age: `Number (10,2)`,
        mandatory: `M`,
        address: t("amountdesc")
      },
      {
        key: `2`,
        name: `referenceNo`,
        age: `String (15)`,
        mandatory: `M`,
        address: t("referencenodesc")
      },
      {
        key: `3`,
        name: `backgroundUrl`,
        age: `String (250)`,
        mandatory: `O`,
        address: t("backgroundurldesc")
      },
      {
        key: `4`,
        name: `responseUrl`,
        age: `String (250)`,
        mandatory: `M`,
        address: t("responseurldesc")
      },
      { 
        key: `5`,
        name: `detail`,
        age: `String (250)`,
        mandatory: `M`,
        address: t("detaildesc")
      },
      {
        key: `6`,
        name: `customerName`,
        age: `String (150)`,
        mandatory: `O`,
        address: t("customernamedesc")
      },
      {
        key: `7`,
        name: `customerEmail`,
        age: `String (150)`,
        mandatory: `O`,
        address: t("customeremaildesc")
      },
      {
        key: `8`,
        name: `customerTelephone`,
        age: `String (150)`,
        mandatory: `O`,
        address: t("customertelephonedesc")
      },
      {
        key: `9`,
        name: `customerAddress`,
        age: `String (150)`,
        mandatory: `O`,
        address: t("customeraddressdesc")
      },
      {
        key: `10`,
        name: `checksum`,
        age: `String (64)`,
        mandatory: `M`,
        address: t("checksumdescatome")
      },
      {
        key: `11`,
        name: `merchantDefined1`,
        age: `String (250)`,
        mandatory: `O`,
        address: t("merchantdefined1desc")
      },
      {
        key: `12`,
        name: `merchantDefined2`,
        age: `String (250)`,
        mandatory: `O`,
        address: t("merchantdefined2desc")
      },
      {
        key: `13`,
        name: `merchantDefined3`,
        age: `String (250)`,
        mandatory: `O`,
        address: t("merchantdefined3desc")
      },
      {
        key: `14`,
        name: `merchantDefined4`,
        age: `String (250)`,
        mandatory: `O`,
        address: t("merchantdefined4desc")
      },
      {
        key: `15`,
        name: `merchantDefined5`,
        age: `String (250)`,
        mandatory: `O`,
        address: t("merchantdefined5desc")
      },
    ];
    
    const dataResponse = [
      {
        key: `1`,
        name: `amount`,
        age: `Number (10, 2)`,
        address: t("amountdesc")
      },
      {
        key: `2`,
        name: `referenceNo`,
        age: `String (15)`,
        address: t("referencenodesc")
      },
      {
        key: `3`,
        name: `gbpReferenceNo`,
        age: `String (250)`,
        address: t("gbpReferencenodesc")
      },
      {
        key: `4`,
        name: `resultCode`,
        age: `String (2)`,
        address: t("resultcodedesc")
      },
      {
        key: `5`,
        name: `date`,
        age: `String (8)`,
        address: t("datedesc")
      },
      {
        key: `6`,
        name: `time`,
        age: `String (6)`,
        address: t("timedesc")
      },
      {
        key: `7`,
        name: `expirationMonth`,
        age: `String (2)`,
        address: t("expirationmonthdesc")
      },
      {
        key: `8`,
        name: `cardNo`,
        age: `String (16)`,
        address: t("cardnodesc")
      },
      {
        key: `9`,
        name: `payMonth`,
        age: `String (2)`,
        address: t("paymonthdesc")
      },
      {
        key: `10`,
        name: `amountPerMonth`,
        age: `Number (10,2)`,
        address: t("amountpermonthdesc")
      },
      {
        key: `11`,
        name: `issuerBank`,
        age: `String`,
        address: t("issuerbankdesc")
      },
      {
        key: `12`,
        name: `detail`,
        age: `String (250)`,
        address: t("detaildesc")
      },
      {
        key: `13`,
        name: `customerName`,
        age: `String (150)`,
        address: t("customernamedesc")
      },
      {
        key: `14`,
        name: `customerEmail`,
        age: `String (150)`,
        address: t("customeremaildesc")
      },
      {
        key: `15`,
        name: `merchantDefined1`,
        age: `String (250)`,
        address: t("merchantdefined1desc")
      },
      {
        key: `16`,
        name: `merchantDefined2`,
        age: `String (250)`,
        address: t("merchantdefined2desc")
      },
      {
        key: `17`,
        name: `merchantDefined3`,
        age: `String (250)`,
        address: t("merchantdefined3desc")
      },
      {
        key: `18`,
        name: `merchantDefined4`,
        age: `String (250)`,
        address: t("merchantdefined4desc")
      },
      {
        key: `19`,
        name: `merchantDefined5`,
        age: `String (250)`,
        address: t("merchantdefined5desc")
      },
    ];
    
    const dataResponseToMerchant = [
      {
        key: `1`,
        name: `resultCode`,
        age: `String (2)`,
        address: t("resultcodedesc"),
      },
      {
        key: `2`,
        name: `resultMessage`,
        age: `String (2)`,
        address: t("resultmessagedesc"),
      },
      {
        key: `3`,
        name: `gbpReferenceNo`,
        age: `String (250)`,
        address: t("gbpReferencenodesc"),
      },
      {
        key: `4`,
        name: `referenceNo`,
        age: `String (15)`,
        address: t("referencenodesc"),
      },
      {
        key: `5`,
        name: `amount`,
        age: `Number (10, 2)`,
        address: t("amountdesc"),
      }
    ];

    return (
      <Fragment>
        <Content
          style={{
            background: `#fff`,
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >

          <h2 className="title">{t("atome:titleatome")}</h2>
          <hr />
          <p>
            {t("atome:detailmessage1")}
          </p>

          <br />

          <h2 id="components-atome-howtoenable" className="title-sub">{t("atome:howtoenable")}</h2>
          <p>{t("atome:howtoenabledetail1")}<a href="mailto:info@gbprimepay.com" target="_blank"> info@gbprimepay.com </a></p>
          <p>{t("atome:howtoenabledetail2")}</p>

          <br />

          <h2 id="components-atome-paymentflow" className="title-sub">{t("atome:paymentflow")}</h2>
          <p className="font-bold">On Browser</p>
          <img
            src={require('../../res/img/PaymentFlow/Flow-Atome-1.png')}
            alt="step1"
            style={{ width: '80%', height: 'auto' }}
          />
          <p className="font-bold">On Mobile</p>
          <img
            src={require('../../res/img/PaymentFlow/Flow-Atome-2.png')}
            alt="step2"
            style={{ width: '80%', height: 'auto' }}
          />
          {/* <img
            src={require('../../res/img/mockup_mobile.png')}
            alt="step3"
            style={{ width: 'auto', height: 'auto' }}
          />
          <img
            src={require('../../res/img/mockup_mobile.png')}
            alt="step4"
            style={{ width: 'auto', height: 'auto' }}
          /> */}

          <br />

          <h2 id="components-atome-steptopayment" className="title-sub">{t("atome:steptopayment")}</h2>
          <p>{t("atome:steptopaymentdetail1")}</p>

          <br />

          <h2 id="components-atome-implementation" className="title-sub">{t("atome:implementation")}</h2>
          {/* <p>{t("atome:implementationdetail1")}</p> */}

          <br />

          <p>{t("atome:usageexample")}</p>
          {renderFormHtml(this.props.linePayment)}

          <br />

          <h2 id="creating-source" className="title-sub2">{t("atome:creatingsource")}</h2>
          <p>{t("atome:creatingsourcedetail1")}</p>
          <p>
            <code>publicKey</code> {' , '} <code>amount</code> {' , '} <code>referenceNo</code> {' , '} 
            <code>backgroundUrl</code> {' , '} <code>responseUrl</code> {' , '} <code>detail</code> {' , '}  
            <code>customerName</code> {' , '} <code>customerEmail</code> {' , '} <code>customerTelephone</code> {' , '}
            <code>customerAddress</code> {' , '} <code>checksum</code> {' , '} <code>merchantDefined1</code> {' , '} 
            <code>merchantDefined2</code> {' , '} <code>merchantDefined3</code> {' , '} <code>merchantDefined4</code> 
            {' , and '} <code>merchantDefined5</code> {'.'}
          </p>

          <br />

          <p>
            <text className="font-bold">Atome Pay API URL: </text>
            <text>{`{ENV}/v1/atome - Method POST`} - Method POST</text>
          </p>

          <br />


          <p className="font-bold">Header</p>
          <Table columns={columns} dataSource={dataHeader} pagination={false} bordered />

          <br />

          <p className="font-bold">Parameter</p>
          <Table  columns={columnsParameter} dataSource={dataParameter} pagination={false} bordered />

          {/* <br />

          <h2 id="creating-charge" className="title-sub2">{t("atome:creatingcharge")}</h2>
          <p>{'-'}</p>
          <p>{'-'}</p> */}

          <br />

          <h2 id="response-data" className="title-sub2">{t("atome:response")}</h2>
          <p>{t("atome:responsedetail1")}</p>

          {/* <h2 id="response-data" className="title-sub2">{t("atome:response")}</h2>
          <Table columns={columns} dataSource={dataResponse} pagination={false} bordered /> */}

          <br />

          <h2 className="title-sub2">{t("atome:responseheader")}</h2>
          <Table columns={columns} dataSource={dataResponseToMerchant} pagination={false} bordered />

          <br />
          <br />

          <p className="font-bold">JSON Response</p>
          {renderFormCodeResponse()}

          <br />
          <br />

          <p className="font-bold">Example Code - Response to Merchant</p>
          <Tabs defaultActiveKey="1" onChange={this.callback}>
            <TabPane tab="PHP" key="1">
              {renderPhpResponse()}
            </TabPane>
            <TabPane tab="Java" key="2">
              {renderJavaResponse()}
            </TabPane>
          </Tabs>

          {/* <br />

          <h2 id="components-atome-voidrefund" className="title-sub">{t("atome:voidrefunds")}</h2>
          <p>{t("atome:voidrefundsdetail1")} {'Refunds API documentation'} {t("atome:voidrefundsdetail2")}</p>
          <p>{t("atome:voidrefundsdetail3")}</p> */}

          <br />

          <h2 id="components-atome-limit" className="title-sub">{t("atome:limit")}</h2>
          {/* <p>{t("atome:limitdetail1")}</p>
          <p>{i18n.language == 'en' ? t("atome:limitdetail2") : ''}</p> */}

          <p>{t("minimumlimits")} 20.00 (THB)</p>
          <p>{t("maximumlimits")} {t("followingcardlimit")} (THB)</p>
        </Content>

        <Sider breakpoint="md" collapsedWidth="0" style={{ background: '#fff' }} h>
          <br />
          <span style={{ color: '#02416D', fontWeight: 'bold', paddingTop: '20px' }}  >
            <Icon type="container" style={{ fontSize: '20px', color: '#08c' }} theme="outlined" />
          </span>
          <span style={{ color: '#02416D', fontWeight: 'bold', paddingTop: '20px' }}>{t("tablecontents")}</span>
          <br />
          <Anchor style={{ paddingTop: '10px' }} >
            <Anchor.Link href="#components-atome-howtoenable" title={t("atome:howtoenable")} />
            <Anchor.Link href="#components-atome-paymentflow" title={t("atome:paymentflow")} />
            <Anchor.Link href="#components-atome-steptopayment" title={t("atome:steptopayment")} />
            <Anchor.Link href="#components-atome-implementation" title={t("atome:implementation")} >
              <Anchor.Link href="#creating-source" title={t("atome:creatingsource")} />
              {/* <Anchor.Link href="#creating-charge" title={t("atome:creatingcharge")} /> */}
              <Anchor.Link href="#response-data" title={t("atome:response")} />
            </Anchor.Link>
            {/* <Anchor.Link href="#components-atome-voidrefund" title={t("atome:voidrefunds")} /> */}
            <Anchor.Link href="#components-atome-limit" title={t("atome:limit")} />
          </Anchor>
        </Sider>

      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  linePayment: state.linePayment
})
const mapDispatchToProps = dispatch => ({
  updateByKey: (key, value) => dispatch(actions.updateByKey(key, value))
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Atome));
