import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Descriptions, Table, Tabs } from 'antd'
import Prism from 'prismjs'
import get from 'lodash/get'
import PrismCode from './PrismCode'
import { withTranslation } from "react-i18next";

const { TabPane } = Tabs;

const code = `
{
  "processType": "I",
  "referenceNo": "20171128001",
  "recurringAmount": 100,
  "recurringInterval": "M",
  "recurringCount": 3,
  "recurringPeriod": "01",
  "allowAccumulate": "Y",
  "cardToken": "{card_token}",
  "backgroundUrl": "https://merchant_url"
}
`

const phpRequest = `
<?php  
   
    // Create Recurring No
    $data = array(
        "processType" => "I",   //I = Create
        "referenceNo" => "F202109101038",        
        "recurringAmount" => 20,
        "recurringInterval" => "Y",
        "recurringPeriod" => "01",
        "allowAccumulate" => "Y",
        "cardToken" =>  "{card_token}",   //get card_token by Token API
        "backgroundUrl" => "https://merchant_url",
       
    );
    $payload = json_encode($data);
    $url = '{ENV}/v2/recurring';

    //Secret Key
    $configkey = "{secret_Key}";

    $key = base64_encode("{$configkey}".":");
    $ch = curl_init($url);
   
    $request_headers = array(
        "Accept: application/json",
        "Authorization: Basic {$key}",
        "Cache-Control: no-cache",
        "Content-Type: application/json",
    );
  
    curl_setopt($ch, CURLOPT_POST, 1);
    curl_setopt($ch, CURLOPT_POSTFIELDS, $payload);
    curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
    curl_setopt($ch, CURLINFO_HEADER_OUT, true);
    curl_setopt($ch, CURLOPT_ENCODING, "");
    curl_setopt($ch, CURLOPT_TIMEOUT, 120);
    curl_setopt($ch, CURLOPT_SSL_VERIFYHOST, 2);
    curl_setopt($ch, CURLOPT_SSL_VERIFYPEER, 0);
    curl_setopt($ch, CURLOPT_CUSTOMREQUEST, "POST");
    curl_setopt($ch, CURLOPT_HTTPHEADER, $request_headers);
    $body = curl_exec($ch);
    curl_close($ch);
?>
`

const javaRequest = `
/* Dependency
<dependency>
    <groupId>org.apache.httpcomponents</groupId>
    <artifactId>httpclient</artifactId>
    <version>4.5.10</version>
</dependency>
*/
String secretKey = "{secret_key}";
String auth = secretKey + ":";
byte[] encodedAuth = Base64.getEncoder().encode(auth.getBytes(StandardCharsets.UTF_8));
String authHeaderValue = "Basic " + new String(encodedAuth);
String gbToken = "{gb_token}";
        
URL url = new URL ("{ENV}/v2/tokens/charge");
HttpURLConnection con = (HttpURLConnection) url.openConnection();
con.setRequestMethod("POST");
con.setRequestProperty("Content-Type", "application/json");
con.setRequestProperty("Authorization", authHeaderValue);
con.setDoOutput(true);

String jsonInputString = "{"
        + " \\"amount\\" : 100, "
        + " \\"referenceNo\\" : \\"20171128001\\", "
        + " \\"detail\\" : \\"t-shirt\\", "
        + " \\"customerName\\" : \\"John\\","
        + " \\"customerEmail\\" : \\"example@gbprimepay.com\\", "
        + " \\"merchantDefined1\\" : \\"Promotion\\", "
        + " \\"card\\" : { "
        + "   \\"token\\" : \\"{card_token}\\" "
        + " }, "
        + " \\"otp\\" : \\"N\\""
        + "}";

try(OutputStream os = con.getOutputStream()){
    byte[] input = jsonInputString.getBytes("utf-8");
    os.write(input, 0, input.length);			
}

int code = con.getResponseCode();
System.out.println("Response Code: " + code);

try(BufferedReader br = new BufferedReader(new InputStreamReader(con.getInputStream(), "utf-8"))){
    StringBuilder response = new StringBuilder();
    String responseLine = null;
    while ((responseLine = br.readLine()) != null) {
            response.append(responseLine.trim());
    }
    System.out.println("Response: " + response.toString());
}
`

const codeResponse = `
{
  "resultCode": "00",
  "referenceNo": "20171128001",
  "recurringNo": "201711281000001"
}
`

const renderFormCode = data => {
  let _code = code.replace(/{card_token}/g, get(data, 'card.token', ''));
  return (
    <div>
      <PrismCode
        code={_code}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>
  )
}

const renderPhpRequest = data => {
  let _code = phpRequest.replace(/{card_token}/g, get(data, 'card.token', ''));
  return (
    <div>
      <PrismCode
        code={_code}
        language="php"
        plugins={["line-numbers"]}
      />
    </div>
  )
}

const renderJavaRequest = data => {
  let _code = javaRequest.replace(/{card_token}/g, get(data, 'card.token', ''));
  return (
    <div>
      <PrismCode
        code={_code}
        language="java"
        plugins={["line-numbers"]}
      />
    </div>
  )
}

const renderFormCodeResponse = () => (
  <div>
    <PrismCode
      code={codeResponse}
      language="json"
      plugins={["line-numbers"]}
    />
  </div>
)

class ChargeApi extends Component {
  callback = (key) => {
    console.log(key);
  }
  render() {
    const { t , i18n } = this.props;

    const columns = [
      {
        title: t("field"),
        dataIndex: 'name',
        key: 'name',
        render: text => <code>{text}</code>
      },
      {
        title: t("type"),
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: t("description"),
        dataIndex: 'address',
        key: 'address',
      }
    ];
    
    const columnsParameter = [
      {
        title: t("field"),
        dataIndex: 'name',
        key: 'name',
        render: text => <code>{text}</code>
      },
      {
        title: t("type"),
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: t("mandatory"),
        dataIndex: 'mandatory',
        key: 'mandatory',
      },
      {
        title: t("description"),
        dataIndex: 'address',
        key: 'address',
      }
    ];
    
    const columnsResultCode = [
      {
        title: 'Code',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Meaning',
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: 'Recommendation',
        dataIndex: 'address',
        key: 'address',
      }
    ];
    
    const dataHeader = [
      {
        key: '1',
        name: 'Authorization',
        age: 'String',
        address: `"Basic " + Base64Encode({secret_key} + ":")`
      },
    ];
    
    const dataParameter = [
      {
        key: '1',
        name: 'processType',
        age: 'String (1)',
        mandatory: 'M',
        address: t("recurring:processtypei")
      },
      {
        key: '2',
        name: 'referenceNo',
        age: 'String (15)',
        mandatory: 'M',
        address: t("referencenodesc")
      },
      {
        key: '3',
        name: 'recurringAmount',
        age: 'Number (10,2)',
        mandatory: 'M',
        address: t("recurring:recurringamountdesc")
      },
      {
        key: '4',
        name: 'recurringInterval',
        age: 'String (1)',
        mandatory: 'M',
        address: t("recurring:recurringintervaldesc")
      },
      {
        key: '5',
        name: 'recurringPeriod',
        age: 'String (2)',
        mandatory: 'M',
        address: t("recurring:recurringperioddesc")
      },
      {
        key: '6',
        name: 'allowAccumulate',
        age: 'String (1)',
        mandatory: 'M',
        address: t("recurring:allowAccumulatedesc")
      },
      {
        key: '7',
        name: 'cardToken',
        age: 'String (250)',
        mandatory: 'M',
        address: t("recurring:cardtokendesc")
      },
      {
        key: '8',
        name: 'backgroundUrl',
        age: 'String (250)',
        mandatory: 'O',
        address: t("backgroundurldesc")
      },
      {
        key: '9',
        name: 'recurringCount',
        age: 'Number (2)',
        mandatory: 'O',
        address: t("recurring:recurringcountdesc")
      },
      {
        key: '10',
        name: 'beginningAmount',
        age: 'Number (10,2)',
        mandatory: 'O',
        address: t("recurring:beginningamountdesc")
      },
      {
        key: '11',
        name: 'endingAmount',
        age: 'Number (10,2)',
        mandatory: 'O',
        address: t("recurring:endingamountdesc")
      },
      {
        key: '12',
        name: 'endingPeriod',
        age: 'Date',
        mandatory: 'O',
        address: t("recurring:endingperioddesc")
      },
    ];
    
    const dataResponse = [
      {
        key: '1',
        name: 'resultCode',
        age: 'String (2)',
        address: t("resultcodedesc")
      },
      {
        key: '2',
        name: 'referenceNo',
        age: 'String (15)',
        address: t("referencenodesc")
      },
      {
        key: '3',
        name: 'recurringNo',
        age: 'String (15)',
        address: t("recurring:recurringnodesc")
      }
    ];
    
    const dataResultCode = [
      {
        key: '1',
        name: '00',
        age: 'Approved',
        address: 'Payment Completed'
      },
      {
        key: '2',
        name: '01',
        age: 'Refer to Card Issuer',
        address: 'Give cardholder contacts issuer bank'
      },
      {
        key: '3',
        name: '03',
        age: 'Invalid Merchant ID',
        address: 'Please contact GBPrimePay'
      },
      {
        key: '4',
        name: '05',
        age: 'Do Not Honour',
        address: 'Cardholder input invalid card information. Ex. Expiry date, CVV2 or card number. Give cardholder contacts issuer bank.'
      },
      {
        key: '5',
        name: '12',
        age: 'Invalid Transaction',
        address: 'Please contact GBPrimePay'
      },
      {
        key: '6',
        name: '13',
        age: 'Invalid Amount',
        address: 'Payment amount must more than 0.1'
      },
      {
        key: '7',
        name: '14',
        age: 'Invalid Card Number',
        address: 'Please check all digits of card no.'
      },
      {
        key: '8',
        name: '17',
        age: 'Customer Cancellation',
        address: 'Customers click at cancel button in payment page when they make transaction. Customers have to make new payment transaction.'
      },
      {
        key: '9',
        name: '19',
        age: 'Re-enter Transaction',
        address: 'Duplicate payment. Please contact GBPrimePay'
      },
      {
        key: '10',
        name: '30',
        age: 'Format Error',
        address: 'Transaction format error. Please contact GBPrimePay'
      },
      {
        key: '11',
        name: '41',
        age: 'Lost Card -Pick Up',
        address: 'Lost Card and Cardholder give up.'
      },
      {
        key: '12',
        name: '43',
        age: 'Stolen Card -Pick Up',
        address: 'Stolen Card and Cardholder give up.'
      },
      {
        key: '13',
        name: '50',
        age: 'Invalid Payment Condition',
        address: 'Ex. Session time out or invalid VbV Password : ask cardholders to try ma again and complete transaction within 15 minutes with correct card information.'
      },
      {
        key: '14',
        name: '51',
        age: 'Insufficient Funds',
        address: 'Not enough credit limit to pay. Please contact issuer'
      },
      {
        key: '15',
        name: '54',
        age: 'Expired Card',
        address: 'Cardholder key in invalid expiry date'
      },
      {
        key: '16',
        name: '58',
        age: 'Transaction not Permitted to Terminal',
        address: 'Issuer does not allow to pay with debit card (Visa Electron, Mastercard Electron)'
      },
      {
        key: '17',
        name: '91',
        age: 'Issuer or Switch is Inoperative',
        address: 'Issuer system is not available to authorize payment'
      },
      {
        key: '18',
        name: '94',
        age: 'Duplicate Transmission',
        address: 'Please inform GBPrimePay to investigate'
      },
      {
        key: '19',
        name: '96',
        age: 'System Malfunction',
        address: 'Issuer bank system can not give a service'
      },
      {
        key: '20',
        name: 'xx',
        age: 'Transaction Timeout',
        address: 'Can not receive response code from issuer with in the time limit'
      },
    ];
    
    const ptin = [
      "3/1/2020",
      "3/2/2020",
      "3/3/2020"
    ]

    return (
      <div>
        {/* <p>
          <text className="font-bold">Charge Recurring API URL: </text>
          <text>{`{ENV}/v2/recurring`} - Method POST</text>
        </p>

        <br />

        <p className="font-bold">Header</p>
        <Table columns={columns} dataSource={dataHeader} pagination={false} bordered />

        <br />
        <br />

        <p className="font-bold">Parameter</p>
        <Table columns={columnsParameter} dataSource={dataParameter} pagination={false} bordered />

        <br />
        <br />

        <p className="font-bold">JSON Request</p>
        {renderFormCode(get(this.props, 'creditCard.tokenResp', {}))}

        <br />
        <br />

        <p className="font-bold">Response</p>
        <Table columns={columns} dataSource={dataResponse} pagination={false} bordered />

        <br />
        <br />

        <p className="font-bold">JSON Response</p>
        {renderFormCodeResponse()}

        <br />
        <br /> */}

        {/** 
        <p className="font-bold">Example Code</p>

        <Tabs defaultActiveKey="1" onChange={this.callback}>
          <TabPane tab="PHP" key="1">
            {renderPhpRequest(get(this.props, 'creditCard.tokenResp', {}))}
          </TabPane>
          <TabPane tab="Java" key="2">
            {renderJavaRequest(get(this.props, 'creditCard.tokenResp', {}))}
          </TabPane>
        </Tabs>

        <br />
        <br />
        */}

        {/* <p>{t("fullpaymentnon3d:chargeapidetail1")}</p> */}

        <br />

        <h2 id="creating-source-chargeapi" className="title-sub2">{t("fullpaymentnon3d:creatingsource")}</h2>

        <p>
          <code>processType</code> {' , '} <code>referenceNo</code> {' , '} <code>recurringAmount</code> {' , '} 
          <code>recurringInterval	</code> {' , '} <code>recurringPeriod</code> {' , '} <code>allowAccumulate</code> {' , '} 
          <code>cardToken</code> {' , '} <code>backgroundUrl</code> {' , '} <code>recurringCount</code> {' , '} 
          <code>beginningAmount	</code> {' , '} <code>endingAmount</code> {' and '} <code>endingPeriod</code> {'.'}
        </p>

        <br />

        <p>
          <text className="font-bold">Charge Recurring API URL: </text>
          <text>{`{ENV}/v2/recurring`} - Method POST</text>
        </p>

        <br />

        <p className="font-bold">Header</p>
        <Table columns={columns} dataSource={dataHeader} pagination={false} bordered />

        <br />
        <br />

        <p className="font-bold">Parameter</p>
        <Table columns={columnsParameter} dataSource={dataParameter} pagination={false} bordered />

        <br />
        <br />

        <p className="font-bold">JSON Request</p>
        {renderFormCode(get(this.props, 'creditCard.tokenResp', {}))}

        <br />
        <br />

        <p className="font-bold">Example Code</p>
          <Tabs defaultActiveKey="1" onChange={this.callback}>            
            <TabPane tab="PHP" key="1">
              {renderPhpRequest()}
            </TabPane>
            
          </Tabs>

        <br />
        <br />

        <p id="response-data-chargeapi" className="font-bold">Response</p>
        <Table columns={columns} dataSource={dataResponse} pagination={false} bordered />

        <br />
        <br />

        <p className="font-bold">JSON Response</p>
        {renderFormCodeResponse()}

        <br />

      </div>
    );
  }
}

const mapStateToProps = state => ({
  creditCard: state.creditCard
})
const mapDispatchToProps = dispatch => ({})
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(ChargeApi)
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ChargeApi));
