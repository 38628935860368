const initialState = { 
  loading: false,
  name:'',
  cardNumber: '',
  expirationMonth: '',
  expirationYear: '',
  securityCode: '',
  tokenResp: null,
  amount: '1.00',
  referenceNo: '',
  detail: ''
};

const creditCard = (state = initialState, action) => {
  switch (action.type) {
    case 'CREDIT_CARD/UPDATE_BY_KEY':
      return {
        ...state,
        [action.key]: action.value
      }
    default:
      return state
  }
}
export default creditCard
