import React, { Component } from 'react'
import { Button, Input } from 'antd'
import get from 'lodash/get'

export default class TokenForm extends Component {
  onChange = e => {
    this.props.updateByKey(e.target.name, e.target.value)
  }
  onSubmit = () => {
    this.props.onSubmit()
  }
  render() {
    return (
      <form action="#" method="POST"> 
        <Input name="name" maxLength={250} placeholder="Holder Name" onChange={this.onChange} /><br/>
        <Input name="cardNumber" maxLength={16} placeholder="Card Number" onChange={this.onChange} /><br/>
        <Input name="expirationMonth" maxLength={2} placeholder="MM" onChange={this.onChange} /><br/>
        <Input name="expirationYear" maxLength={2} placeholder="YY (Last Two Digits)" onChange={this.onChange} /><br/>
        <Input.Password name="securityCode" maxLength={4} autoComplete="off" placeholder="CVV" onChange={this.onChange} /><br/><br/>
        <Button type="primary" onClick={this.onSubmit} block loading={this.props.loading}>Pay Now</Button><br/><br/>
        
        {
          get(this.props, 'token', undefined) && (
            <React.Fragment>
              <h4>Token for use in the next step</h4>
              {get(this.props, 'token', '')}
            </React.Fragment>
          )
        }

      </form>
    )
  }
}
