import React, { Component } from 'react'
import { Table, Tabs } from 'antd'
import PrismCode from './PrismCode'
import { withTranslation } from "react-i18next";

const { TabPane } = Tabs;

const renderPhpResponse = () => (
  <div>
    <PrismCode
      code={phpResponse}
      language="php"
      plugins={["line-numbers"]}
    />
  </div>
)

const phpResponse = `
<?php
    $respFile = fopen("bg-log_qr.txt", "w") or die("Unable to open file!");

    $json_str = file_get_contents('php://input');
    fwrite($respFile, $json_str . "\\n\\n");
    $json_obj = json_decode($json_str);

    fwrite($respFile, "resultCode=" . $json_obj->resultCode . "\\n");
    fwrite($respFile, "amount=" . $json_obj->amount . "\\n");
    fwrite($respFile, "referenceNo=" . $json_obj->referenceNo . "\\n");
    fwrite($respFile, "gbpReferenceNo=" . $json_obj->gbpReferenceNo . "\\n");
    fwrite($respFile, "currencyCode=" . $json_obj->currencyCode . "\\n");
    fwrite($respFile, "totalAmount=" . $json_obj->totalAmount . "\\n");
    fwrite($respFile, "thbAmount=" . $json_obj->thbAmount . "\\n");

    fclose($respFile);

    echo "ok";

?>
`

//export default class WebHookCode extends Component {
class WebHookCode extends Component {
  render() {

    const { t , i18n } = this.props;

    const columnsResultCode = [
      {
        title: t("field"),
        dataIndex: 'name',
        key: 'name',
        render: text => <code>{text}</code>
      },
      {
        title: t("type"),
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: t("description"),
        dataIndex: 'address',
        key: 'address',
      }
    ];
    
    const dataResultCode = [
      {
        key: '0',
        name: 'amount',
        age: 'NUMERIC (10,2)',
        address: t("amountdesc")
      },
      {
        key: '1',
        name: 'referenceNo',
        age: 'String (15)',
        address: t("referencenodesc")
      },
      {
        key: '2',
        name: 'gbpReferenceNo',
        age: 'String (15)',
        address: t("gbpReferencenodesc")
      },
      {
        key: '3',
        name: 'currencyCode',
        age: 'String (3)',
        address: t("currencycodedesc")
      },
      {
        key: '4',
        name: 'resultCode',
        age: 'String (2)',
        address: t("resultcodedesc")
      },
      {
        key: '5',
        name: 'paymentType',
        age: 'String (1)',
        address: 'Q = QR Cash, C = Credit Card Full Payment I = Credit Card Installment, V = QR Credit, W = Wechat, L = Line Payment, T = True Wallet, M = Mobile Banking'
      },
      {
        key: '6',
        name: 'date',
        age: 'String (8)',
        address: t("datedesc")
      },
      {
        key: '7',
        name: 'time',
        age: 'String (6)',
        address: t("timedesc")
      },
      {
        key: '8',
        name: 'cardNo',
        age: 'String (16)',
        address: t("cardnodesc")
      },
      {
        key: '9',
        name: 'cardHolderName',
        age: 'String (150)',
        address: t("namedesc")
      },
      {
        key: '10',
        name: 'detail',
        age: 'String (250)',
        address: t("detaildesc")
      },
      {
        key: '11',
        name: 'customerName',
        age: 'String (150)',
        address: t("customernamedesc")
      },
      {
        key: '12',
        name: 'customerEmail',
        age: 'String (150)',
        address: t("customeremaildesc")
      },
      {
        key: '13',
        name: 'customerAddress',
        age: 'String (150)',
        address: t("customeraddressdesc")
      },
      {
        key: '14',
        name: 'customerTelephone',
        age: 'String (25)',
        address: t("customertelephonedesc")
      },
      {
        key: '15',
        name: 'merchantDefined1',
        age: 'String (250)',
        address: t("merchantdefined1desc")
      },
      {
        key: '16',
        name: 'merchantDefined2',
        age: 'String (250)',
        address: t("merchantdefined2desc")
      },
      {
        key: '17',
        name: 'merchantDefined3',
        age: 'String (250)',
        address: t("merchantdefined3desc")
      },
      {
        key: '18',
        name: 'merchantDefined4',
        age: 'String (250)',
        address: t("merchantdefined4desc")
      },
      {
        key: '19',
        name: 'merchantDefined5',
        age: 'String (250)',
        address: t("merchantdefined5desc")
      },
      { 
        key: '20',
        name: 'retryFlag',
        age: 'String(1)',
        address: t("retryflagdesc")
      },
    ];

    return (
      <div>

        {/* <p style={{ color: 'rgba(0, 0, 0, 0.85)' }}>Web Hook Redirect URL: </p>
        <p>URL as submitted backgroundUrl - Method POST</p>

        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>Response</p>
        <Table columns={columnsResultCode} dataSource={dataResultCode} pagination={false} bordered />

        <br />
        <br />
        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>Example Code - Response data redirect to responseUrl</p>
        <Tabs defaultActiveKey="1" onChange={this.callback}>
          <TabPane tab="PHP" key="1">
            {renderPhpResponse()}
          </TabPane>
        </Tabs> */}


        {/* <p>{t("fullpaymentnon3d:webhookmessage1")}</p> */}

        <br />

        <p>
          <text style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>Web Hook Redirect URL: </text>
          <text>URL as submitted backgroundUrl - Method POST</text>
        </p>

        <h2 id="response-data-webhook" style={{ color: '#454545', fontWeight: 'bold', fontSize: 16 }}>{t("fullpaymentnon3d:response")}</h2>
        <Table columns={columnsResultCode} dataSource={dataResultCode} pagination={false} bordered />

        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>Example Code</p>
        <Tabs defaultActiveKey="1" onChange={this.callback}>
          <TabPane tab="PHP" key="1">
            {renderPhpResponse()}
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default withTranslation()(WebHookCode);
