import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Descriptions, Table, Tabs } from 'antd'
import Prism from 'prismjs'
import get from 'lodash/get'
import PrismCode from './PrismCode'
import { withTranslation } from "react-i18next";

const { TabPane } = Tabs;

const code = `
{
  "amount": 100,
  "referenceNo": "20171128001",
  "detail": "t-shirt",
  "customerName": "John",
  "customerEmail": "example@gbprimepay.com",
  "customerAddress": "Test",
  "customerTelephone": "0899999999",
  "merchantDefined1": "Promotion",
  "card": {
      "token": "{card_token}"   //get card_token by Token API
  },
  "otp": "N"
}
`

const phpRequest = `
<?php

  $data = array(
    'amount' => 100,
    'referenceNo' => '20171128001',
    'detail' => 't-shirt',
    'customerName' => 'John',
    'customerEmail' => 'example@gbprimepay.com',
    'merchantDefined1' => 'Promotion',
    'card' => array(
      'token' => '{card_token}',    //get card_token by Token API
    ),
    'otp' => 'N'
  );
  $payload = json_encode($data);
  
  $url = '{ENV}/v2/tokens/charge';
  
  $configkey = "{secret_key}";
  $key = base64_encode("{$configkey}".":");
  
  $request_headers = array(
    "Accept: application/json",
    "Authorization: Basic {$key}",
    "Cache-Control: no-cache",
    "Content-Type: application/json",
  );

  $ch = curl_init($url);
  curl_setopt($ch, CURLOPT_POST, 1);
  curl_setopt($ch, CURLOPT_POSTFIELDS, $payload);
  curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
  curl_setopt($ch, CURLINFO_HEADER_OUT, true);
  curl_setopt($ch, CURLOPT_ENCODING, "");
  curl_setopt($ch, CURLOPT_TIMEOUT, 120);
  curl_setopt($ch, CURLOPT_SSL_VERIFYHOST, 2);
  curl_setopt($ch, CURLOPT_SSL_VERIFYPEER, 0);
  curl_setopt($ch, CURLOPT_CUSTOMREQUEST, "POST");
  curl_setopt($ch, CURLOPT_HTTPHEADER, $request_headers);
  $body = curl_exec($ch);
  $json = json_decode($body, true);
  curl_close($ch);

?>
`

const javaRequest = `
/* Dependency
<dependency>
    <groupId>org.apache.httpcomponents</groupId>
    <artifactId>httpclient</artifactId>
    <version>4.5.10</version>
</dependency>
*/
String secretKey = "{secret_key}";
String auth = secretKey + ":";
byte[] encodedAuth = Base64.getEncoder().encode(auth.getBytes(StandardCharsets.UTF_8));
String authHeaderValue = "Basic " + new String(encodedAuth);
String gbToken = "{gb_token}";
        
URL url = new URL ("{ENV}/v2/tokens/charge");
HttpURLConnection con = (HttpURLConnection) url.openConnection();
con.setRequestMethod("POST");
con.setRequestProperty("Content-Type", "application/json");
con.setRequestProperty("Authorization", authHeaderValue);
con.setDoOutput(true);

String jsonInputString = "{"
        + " \\"amount\\" : 100, "
        + " \\"referenceNo\\" : \\"20171128001\\", "
        + " \\"detail\\" : \\"t-shirt\\", "
        + " \\"customerName\\" : \\"John\\","
        + " \\"customerEmail\\" : \\"example@gbprimepay.com\\", "
        + " \\"merchantDefined1\\" : \\"Promotion\\", "
        + " \\"card\\" : { "
        + "   \\"token\\" : \\"{card_token}\\" "
        + " }, "
        + " \\"otp\\" : \\"N\\""
        + "}";

try(OutputStream os = con.getOutputStream()){
    byte[] input = jsonInputString.getBytes("utf-8");
    os.write(input, 0, input.length);			
}

int code = con.getResponseCode();
System.out.println("Response Code: " + code);

try(BufferedReader br = new BufferedReader(new InputStreamReader(con.getInputStream(), "utf-8"))){
    StringBuilder response = new StringBuilder();
    String responseLine = null;
    while ((responseLine = br.readLine()) != null) {
            response.append(responseLine.trim());
    }
    System.out.println("Response: " + response.toString());
}
`

const codeResponse = `
{
  "resultCode": "00",
  "amount": 100.00,
  "referenceNo": "20171128001",
  "gbpReferenceNo": "gbp0001000900",
  "detail": "t-shirt",
  "currencyCode": '764',
  "customerAddress": "Test",
  "customerTelephone": "0899999999",
  "date": "28112017",
  "time": "141222",
  "cardNo": "453501XXXXXX5741"
}
`

const renderFormCode = data => {
  let _code = code.replace(/{card_token}/g, get(data, 'card.token', ''));
  return (
    <div>
      <PrismCode
        code={_code}
        language="json"
        plugins={["line-numbers"]}
      />
    </div>
  )
}

const renderPhpRequest = data => {
  let _code = phpRequest.replace(/{card_token}/g, get(data, 'card.token', ''));
  return (
    <div>
      <PrismCode
        code={_code}
        language="php"
        plugins={["line-numbers"]}
      />
    </div>
  )
}

const renderJavaRequest = data => {
  let _code = javaRequest.replace(/{card_token}/g, get(data, 'card.token', ''));
  return (
    <div>
      <PrismCode
        code={_code}
        language="java"
        plugins={["line-numbers"]}
      />
    </div>
  )
}

const renderFormCodeResponse = () => (
  <div>
    <PrismCode
      code={codeResponse}
      language="json"
      plugins={["line-numbers"]}
    />
  </div>
)

class ChargeApi extends Component {
  callback = (key) => {
    console.log(key);
  }
  render() {

    const { t , i18n } = this.props;

    const columns = [
      {
        title: t("field"),
        dataIndex: 'name',
        key: 'name',
        render: text => <code>{text}</code>
      },
      {
        title: t("type"),
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: t("description"),
        dataIndex: 'address',
        key: 'address',
      }
    ];
    
    const columnsParameter = [
      {
        title: t("field"),
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: t("type"),
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: t("mandatory"),
        dataIndex: 'indication',
        key: 'indication',
      },
      {
        title: t("description"),
        dataIndex: 'address',
        key: 'address',
      }
    ];
    
    const columnsResultCode = [
      {
        title: 'Code',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Meaning',
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: 'Recommendation',
        dataIndex: 'address',
        key: 'address',
      }
    ];
    
    const renderContent = (value, row, index) => {
      const obj = {
        children: value,
        props: {},
      };
      if (index === 14) {
        obj.props.colSpan = 0;
      }
      return obj;
    };
    const columns__1 = [{
      title: t("field"),
      dataIndex: 'name',
      render: (value, row, index) => {
        if (index != 14 ) {
          return <code>{value}</code>;
        }
        return {
          children: value,
          props: {
            colSpan: 4,
          },
        };
      },
    }, {
      title: t("type"),
      dataIndex: 'age',
      key: 'age',
      render: renderContent,
      
    },
    {
      title: t("mandatory"),
      dataIndex: 'mandatory',
      key: 'mandatory',
      render: renderContent,
    },
    {
      title: t("description"),
      dataIndex: 'address',
      key: 'address',
      render: renderContent,
    }];
    
    const dataHeader = [
      {
        key: '1',
        name: 'Authorization',
        age: 'String',
        address: `"Basic " + Base64Encode({secret_key} + ":")`
      },
    ];
    
    const dataParameter = [
      {
        key: '0',
        name: 'amount',
        age: 'Number (10,2)',
        mandatory: 'M',
        address: t("amountdesc")
      },
      // {
      //   key: '1',
      //   name: 'amount',
      //   age: 'Number (10,2)',
      //   mandatory:'M',
      //   address: 'Amount (2-digit Decimal)'
      // },
      {
        key: '2',
        name: 'referenceNo',
        age: 'String (15)',
        mandatory:'M',
        address: t("referencenodesc")
      },
      {
        key: '3',
        name: 'otp',
        age: 'String (1)',
        mandatory:'M',
        address: t("otpdesc")
      },
      {
        key: '4',
        name: 'backgroundUrl',
        age: 'String (250)',
        mandatory:'O',
        address: t("backgroundurldesc")
      },
      {
        key: '5',
        name: 'detail',
        age: 'String (250)',
        mandatory:'O',
        address: t("detaildesc")
      },
      {
        key: '6',
        name: 'customerName',
        age: 'String (250)',
        mandatory:'O',
        address: t("customernamedesc")
      },
      {
        key: '7',
        name: 'customerEmail',
        age: 'String (250)',
        mandatory:'O',
        address: t("customeremaildesc")
      },  {
        key: '8',
        name: 'customerAddress',
        age: 'String (250)',
        mandatory:'O',
        address: t("customeraddressdesc")
      },
      {
        key: '9',
        name: 'customerTelephone',
        age: 'String (250)',
        mandatory:'O',
        address: t("customertelephonedesc")
      },
      {
        key: '10',
        name: 'merchantDefined1',
        age: 'String (250)',
        mandatory:'O',
        address: t("otherdetail1desc")
      },
      {
        key: '11',
        name: 'merchantDefined2',
        age: 'String (250)',
        mandatory:'O',
        address: t("otherdetail2desc")
      },
      {
        key: '12',
        name: 'merchantDefined3',
        age: 'String (250)',
        mandatory:'O',
        address: t("otherdetail3desc")
      },
      {
        key: '13',
        name: 'merchantDefined4',
        age: 'String (250)',
        mandatory:'O',
        address: t("otherdetail4desc")
      },
      {
        key: '14',
        name: 'merchantDefined5',
        age: 'String (250)',
        mandatory:'O',
        address: t("otherdetail5desc")
      },
      {
        key: '15',
        name: 'card',
      },
      {
        key: '16',
        name: 'token',
        age: 'String (250)',
        mandatory:'M',
        address: `Token will receive from response on step 1`
      },
    ];
    
    const dataResponse = [
      {
        key: '0',
        name: 'resultCode',
        age: 'String (2)',
        address: t("resultcodedesc")
      },
      {
        key: '1',
        name: 'amount',
        age: 'Number (10,2)',
        address: t("amountdesc")
      },
      {
        key: '2',
        name: 'referenceNo',
        age: 'String (15)',
        address: t("referencenodesc")
      },
      {
        key: '3',
        name: 'gbpReferenceNo',
        age: 'String (250)',
        address: t("gbpReferencenodesc")
      },
      {
        key: '4',
        name: 'currencyCode',
        age: 'String (3)',
        address: t("currencycodedesc")
      },
      {
        key: '5',
        name: 'date',
        age: 'String (8)',
        address: t("datedesc")
      },
      {
        key: '6',
        name: 'time',
        age: 'String (6)',
        address: t("timedesc")
      },
      {
        key: '7',
        name: 'cardNo',
        age: 'Number (16)',
        address: t("cardnodesc")
      },
    ];
    
    const dataResultCode = [
      {
        key: '1',
        name: '00',
        age: 'Success',
        address: 'Payment Completed'
      },
      {
        key: '2',
        name: '01',
        age: 'Refer to Card Issuer',
        address: 'Give cardholder contacts issuer bank'
      },
      {
        key: '3',
        name: '03',
        age: 'Invalid Merchant ID',
        address: 'Please contact GBPrimePay'
      },
      {
        key: '4',
        name: '05',
        age: 'Do Not Honour',
        address: 'Cardholder input invalid card information. Ex. Expiry date, CVV2 or card number. Give cardholder contacts issuer bank.'
      },
      {
        key: '5',
        name: '12',
        age: 'Invalid Transaction',
        address: 'Please contact GBPrimePay'
      },
      {
        key: '6',
        name: '13',
        age: 'Invalid Amount',
        address: 'Payment amount must more than 0.1'
      },
      {
        key: '7',
        name: '14',
        age: 'Invalid Card Number',
        address: 'Please check all digits of card no.'
      },
      {
        key: '8',
        name: '17',
        age: 'Customer Cancellation',
        address: 'Customers click at cancel button in payment page when they make transaction. Customers have to make new payment transaction.'
      },
      {
        key: '9',
        name: '19',
        age: 'Re-enter Transaction',
        address: 'Duplicate payment. Please contact GBPrimePay'
      },
      {
        key: '10',
        name: '30',
        age: 'Format Error',
        address: 'Transaction format error. Please contact GBPrimePay'
      },
      {
        key: '11',
        name: '41',
        age: 'Lost Card -Pick Up',
        address: 'Lost Card and Cardholder give up.'
      },
      {
        key: '12',
        name: '43',
        age: 'Stolen Card -Pick Up',
        address: 'Stolen Card and Cardholder give up.'
      },
      {
        key: '13',
        name: '50',
        age: 'Invalid Payment Condition',
        address: 'Ex. Session time out or invalid VbV Password : ask cardholders to try ma again and complete transaction within 15 minutes with correct card information.'
      },
      {
        key: '14',
        name: '51',
        age: 'Insufficient Funds',
        address: 'Not enough credit limit to pay. Please contact issuer'
      },
      {
        key: '15',
        name: '54',
        age: 'Expired Card',
        address: 'Cardholder key in invalid expiry date'
      },
      {
        key: '16',
        name: '55',
        age: 'Wrong Pin',
        address: 'Wrong Pin'
      },
      {
        key: '17',
        name: '58',
        age: 'Transaction not Permitted to Terminal',
        address: 'Issuer does not allow to pay with debit card (Visa Electron, Mastercard Electron)'
      },
      {
        key: '18',
        name: '91',
        age: 'Issuer or Switch is Inoperative',
        address: 'Issuer system is not available to authorize payment'
      },
      {
        key: '19',
        name: '94',
        age: 'Duplicate Transmission',
        address: 'Please inform GBPrimePay to investigate'
      },
      {
        key: '20',
        name: '96',
        age: 'System Malfunction',
        address: 'Issuer bank system can not give a service'
      },
      {
        key: '21',
        name: 'xx',
        age: 'Transaction Timeout',
        address: 'Can not receive response code from issuer with in the time limit'
      },
    ];

    return (
      <div>
        {/* <p style={{ color: 'rgba(0, 0, 0, 0.85)' }}>Charge API URL:</p>
        <p>{`{ENV}/v2/tokens/charge`} - Method POST</p>

        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)' }}>Non 3-D Secure Send the following information</p>
        <ul>
          <li>{`"otp": "N"`}</li>
        </ul>

        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>Header</p>
        <Table columns={columns} dataSource={dataHeader} pagination={false} bordered />

        <br />
        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>Parameter</p>
        <Table columns={columns__1} dataSource={dataParameter} pagination={false} bordered />

        <br />
        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>JSON Request</p>
        {renderFormCode(get(this.props, 'creditCard.tokenResp', {}))}

        <br />
        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>Response</p>
        <Table columns={columns} dataSource={dataResponse} pagination={false} bordered />

        <br />
        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>JSON Response</p>
        {renderFormCodeResponse()}

        <br />
        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>Example Code</p>

        <Tabs defaultActiveKey="1" onChange={this.callback}>
          <TabPane tab="PHP" key="1">
            {renderPhpRequest(get(this.props, 'creditCard.tokenResp', {}))}
          </TabPane>
          <TabPane tab="Java" key="2">
            {renderJavaRequest(get(this.props, 'creditCard.tokenResp', {}))}
          </TabPane>
        </Tabs>

        <br />
        <br /> */}

        {/* <p>{t("fullpaymentnon3d:chargeapidetail1")}</p> */}

        <br />

        <h2 id="creating-source" style={{ color: '#454545', fontWeight: 'bold', fontSize: 16 }}>{t("fullpaymentnon3d:creatingsource")}</h2>

        <p>
          <code>publicKey</code> {' , '} <code>amount</code> {' , '} <code>referenceNo</code> {' , '} 
          <code>backgroundUrl</code> {' , '} <code>detail</code> {' , '} <code>customerName</code> {' , '}
          <code>customerEmail</code> {' , '}< code>customerAddress</code> {' , '} <code>customerTelephone</code> {' , '}
          <code>merchantDefined1</code> {' , '} <code>merchantDefined2</code> {' , '} <code>merchantDefined3</code> {' , '}
          <code>merchantDefined4</code> {' , '} <code>merchantDefined5</code> {' , '}
          {' and '} <code>checksum</code> {'.'}
        </p>

        <br />

        <p>
          <text style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>Charge API URL: </text>
          <text>{`{ENV}/v2/tokens/charge`} - Method POST</text>
        </p>

        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)' }}>Non 3-D Secure Send the following information</p>
        <ul>
          <li>{`"otp": "N"`}</li>
        </ul>

        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>Header</p>
        <Table columns={columns} dataSource={dataHeader} pagination={false} bordered />

        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>Parameter</p>
        <Table columns={columns__1} dataSource={dataParameter} pagination={false} bordered />

        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>JSON Request</p>
        {renderFormCode(get(this.props, 'creditCard.tokenResp', {}))}

        <br />

        <h2 id="response-data" style={{ color: '#454545', fontWeight: 'bold', fontSize: 16 }}>{t("fullpaymentnon3d:response")}</h2>
        <Table columns={columns} dataSource={dataResponse} pagination={false} bordered />

        <br />
        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>JSON Response</p>
        {renderFormCodeResponse()}

        <br />

        <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold' }}>Example Code</p>
        <Tabs defaultActiveKey="1" onChange={this.callback}>
          <TabPane tab="PHP" key="1">
            {renderPhpRequest(get(this.props, 'creditCard.tokenResp', {}))}
          </TabPane>
          <TabPane tab="Java" key="2">
            {renderJavaRequest(get(this.props, 'creditCard.tokenResp', {}))}
          </TabPane>
        </Tabs>

      </div>
    );
  }
}

const mapStateToProps = state => ({
  creditCard: state.creditCard
})
const mapDispatchToProps = dispatch => ({})
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(ChargeApi)
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ChargeApi));

