import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Input, Select, Steps, Row, Col, Descriptions } from 'antd'
import get from 'lodash/get'
import * as actions from '../../actions/creditCardAction'
import TokenForm from './TokenForm'
import PrismCode from '../Tokenization/PrismCode'
import { generateRef, qrText } from '../../common/Utils'

const { Step } = Steps;

const codeResponse = `
Content-Type: multipart/form-data; boundary=----WebKitFormBoundary

------WebKitFormBoundary
Content-Disposition: form-data; name="amount"

{amount}
------WebKitFormBoundary
Content-Disposition: form-data; name="referenceNo"

{referenceNo}
------WebKitFormBoundary
Content-Disposition: form-data; name="gbpReferenceNo"

gbp{referenceNo}
------WebKitFormBoundary
Content-Disposition: form-data; name="resultCode"

00
`

const renderFormCodeResponse = data => {
  let amount = data.amount
  try {
    amount = parseFloat(amount).toFixed(2)
  } catch (e) {
    amount = 0.00
  }
  let _codeResponse = codeResponse.replace(/{amount}/g, amount);
  _codeResponse = _codeResponse.replace(/{referenceNo}/g, data.referenceNo);

  if (data.detail) {
    _codeResponse += '------WebKitFormBoundary\nContent-Disposition: form-data; name="detail"\n\n{detail}\n'

    _codeResponse = _codeResponse.replace(/{detail}/g, data.detail);
  }

  _codeResponse += '------WebKitFormBoundary--'

  return (
    <div>
      <PrismCode
        code={_codeResponse}
        language="http"
        plugins={["line-numbers"]}
      />
    </div>
  )
}

class Demo extends Component {
  state = {
    currentStep: 0,
    otp: ''
  }
  componentDidMount() {
    this.props.updateByKey('referenceNo', generateRef())
  }
  changeStep = current => {
    this.setState({ currentStep: current })
  }
  changeStepPrev = () => {
    this.setState({ currentStep: this.state.currentStep - 1 })
  }
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }
  onChangeByKey = e => {
    this.props.updateByKey(e.target.name, e.target.value )
  }
  render() {
    const isValidCharge = this.props.creditCard.amount > 0
    const isValid2 = this.state.otp === '123456'
    return (
      <>
        <p style={{color: 'rgba(0, 0, 0, 0.85)', marginTop: 10 }}>Result</p>
        <Steps size="small" current={this.state.currentStep}>
          <Step title="Token" />
          <Step title="Charge" />
          <Step title="3D Secure" />
          <Step title="Response" />
        </Steps>

        <div className="steps-content">
          { this.state.currentStep === 0 && (
            <Row>
              <Col sm={7} md={8}>
                {' '}
              </Col>
              <Col sm={10} md={8}>
                <TokenForm
                  updateByKey={this.props.updateByKey}
                  onSubmit={() => this.changeStep(1)}
                  loading={this.props.creditCard.loading}
                  data={this.props.creditCard}
                />
              </Col>
              <Col sm={7} md={8}>
                {' '}
              </Col>
            </Row>
            )}

            { this.state.currentStep === 1 && (
              <Row>
                <Col sm={5} md={8}>
                  {' '}
                </Col>
                <Col sm={14} md={8}>
                  <div id="divBody">
                    <div className="card-wrapper d-none"></div>
                    <div className="row">
                      <div className="col-sm-12 text-center">
                        <h2 style={{ color: '#337ab7' }}>Checkout</h2>
                      </div>
                    </div>
                    
                    <Input name="referenceNo" value={this.props.creditCard.referenceNo} maxLength={15} placeholder="Reference No" onChange={this.onChangeByKey} readOnly />
                    
                    <br/>

                    <Input type="number" name="amount" value={this.props.creditCard.amount} maxLength={13} placeholder="Amount ex. 3000.00" onChange={this.onChangeByKey} />
                    
                    <br/>

                    <Input name="detail" value={this.props.creditCard.detail} maxLength={50} placeholder="Detail ex. T-Shirt" onChange={this.onChangeByKey} />
                    
                    <br/>

                    <div className="row">
                      <div className="col-sm-12 text-center" style={{ marginTop: '15px' }}>
                        <Button type="primary" onClick={() => this.changeStep(2)} disabled={!isValidCharge}>Pay Now</Button>
                        <Button style={{ marginLeft: 8 }} onClick={this.changeStepPrev}>
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={5} md={8}>
                  {' '}
                </Col>
              </Row>
            )}

            { this.state.currentStep === 2 && (
              <Row>
                <Col sm={5} md={8}>
                  {' '}
                </Col>
                <Col sm={14} md={8}>
                  <div id="divBody">
                    <div className="card-wrapper d-none"></div>
                    <div className="row">
                      <div className="col-sm-12 text-center">
                        <h2 style={{ color: '#337ab7' }}>Verified by GB Pay</h2>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 text-right">
                        <label htmlFor="">
                          The OTP code for your online payment :
                        </label>
                      </div>
                      <div className="col-sm-6">
                        <label className="fix-label d-flex justify--between-md " htmlFor="">
                          <span style={{ color: '#337ab7' }}>123456</span>
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 text-right">
                        <label htmlFor="">Please input OTP code :</label>
                      </div>
                      <div className="col-sm-6">
                        <Input name="otp" maxLength={6} placeholder="123456" onChange={this.onChange} autoComplete="off" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 text-center" style={{ marginTop: '15px' }}>
                        <Button type="primary" onClick={() => this.changeStep(3)} disabled={!isValid2}>Confirm</Button>
                        <Button style={{ marginLeft: 8 }} onClick={this.changeStepPrev}>
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={5} md={8}>
                  {' '}
                </Col>
              </Row>
            )}

            { this.state.currentStep === 3 && (
              <>
                <Row>
                  <Col sm={2} md={4}>
                    {' '}
                  </Col>
                  <Col sm={20} md={16}>
                    <p style={{ color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold', textAlign: 'left' }}>Data Response</p>
                    {renderFormCodeResponse(this.props.creditCard)}
                    <br/>
                  </Col>
                  <Col sm={2} md={4}>
                    {' '}
                  </Col>
                </Row>
                <Row>
                  <Col sm={5} md={8}>
                    {' '}
                  </Col>
                  <Col sm={14} md={8}>
                    <Button type="primary" onClick={() => this.changeStep(0)} block>Done</Button><br/>
                    <Button style={{ marginTop: 8 }} onClick={this.changeStepPrev} block>
                      Previous
                    </Button>
                  </Col>
                  <Col sm={5} md={8}>
                    {' '}
                  </Col>
                </Row>
              </>
            )}
          </div>

          <br />

          <Descriptions title="Card Test UAT (Server Test)" size={'default'}>
            <Descriptions.Item label="Card Number">4535 0177 1053 5741</Descriptions.Item>
            <Descriptions.Item label="MM/YY">05/28</Descriptions.Item>
            <Descriptions.Item label="CVV">184</Descriptions.Item>
          </Descriptions>
          <br />

          <Descriptions title="Card Test UAT (Server Test)" size={'default'}>
            <Descriptions.Item label="Card Number">5258 8606 8990 5862</Descriptions.Item>
            <Descriptions.Item label="MM/YY">02/25</Descriptions.Item>
            <Descriptions.Item label="CVV">950</Descriptions.Item>
          </Descriptions>
      </>
    )
  }
}
const mapStateToProps = state => ({
  creditCard: state.creditCard
})
const mapDispatchToProps = dispatch => ({
  updateByKey: (key, value) => dispatch(actions.updateByKey(key, value))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Demo)