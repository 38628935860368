import get from 'lodash/get'

export const updateByKey = (key, value) => {
  console.log(key, value)
  return {
    type: 'CREDIT_CARD/UPDATE_BY_KEY',
    key,
    value
  }
}

export const generateToken = () => {
  return (dispatch, getState) => {
    dispatch(updateByKey('loading', true))
    fetch('https://api.globalprimepay.com/v1/tokens', {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Authorization': 'Basic ' + btoa(`A0yB4SY3fpTKOwQbdF7UJepnyjGjjRCw:`),
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "rememberCard": false,
        "card": {
          "name": get(getState(), 'creditCard.name', ''),
            "number": get(getState(), 'creditCard.cardNumber', ''),
            "expirationMonth": get(getState(), 'creditCard.expirationMonth', ''),
            "expirationYear": get(getState(), 'creditCard.expirationYear', ''),
            "securityCode": get(getState(), 'creditCard.securityCode', '')
        }
      })
    })
    .then((response) => {
      return response.json();
    })
    .then((myJson) => {
      console.log(myJson);
      dispatch(updateByKey('tokenResp', myJson))
      dispatch(updateByKey('loading', false))
    });
    console.log(getState().creditCard.cardNumber)
  }
}