import React, { Component, Fragment } from 'react'
import { Layout, Menu, Breadcrumb, Icon, Anchor } from 'antd'
import Demo from './Demo'
import CardForm from './CardForm'
import TokenApi from './TokenApi'
import ChargeApi from './ChargeApi'
import UpdateChargeApi from './UpdateChargeApi'
import CancelChargeApi from './CancelChargeApi'
import TDSecureApi from './TDSecureApi'
import ResultCode from './ResultCode'
import WebHookCode from './WebHookCode'
import AuthorizeResultCode from './AuthorizeResultCode'
import { withTranslation } from "react-i18next";

const { Content, Sider } = Layout;

// export default class Tokenization extends Component {
class Tokenization extends Component {
  render() {
    const { t , i18n } = this.props;
    return (
      <Fragment>
        {/* <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>Credit/Debit card</Breadcrumb.Item>
          <Breadcrumb.Item>Recurring</Breadcrumb.Item>
        </Breadcrumb> */}
        {/* <Content
          style={{
            background: '#fff',
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          <h2>Recurring</h2>
          <h3>1. How it works</h3>
          <hr />
          <p style={{color: 'red'}}>
            Non-3DS transaction is considered high-risk transaction. GB Prime Pay disable non-3DS payment processing by default. If you have specific request for using non-3DS please contact our staffs. 
          </p>
          <CardForm />

          <br />
          <br />

          <h3>2. Token API</h3>
          <hr />
          <TokenApi />

          <br />
          <br />

          <h3>3. Charge Recurring API</h3>
          <hr />
          <ChargeApi />

          <h3>4. Web Hook to backgroundUrl</h3>
          <hr />
          <WebHookCode />
          <br />
          <br />
          <br />

          <h3>5. Update Charge Recurring API</h3>
          <hr />
          <UpdateChargeApi />

          <br />
          <br />

          <h3>6. Cancel​ Recurring API</h3>
          <hr />
          <CancelChargeApi />

          <br />
          <br />

          <h3>7. API Result Code</h3>
          <hr />
          <ResultCode />

          <br />
          <br />
          
          <h3>8. Authorize Result Code</h3>
          <hr />
          <AuthorizeResultCode />
          <br />
          <br />
          
        </Content> */}

        <Content
          style={{
            background: "#fff",
            padding: 24,
            margin: 0,
            minHeight: 280
          }}
        >
          <h2 className="title">{t("recurring:titlerecurring")}</h2>
          <hr />
          <p>
            {t("recurring:detailmessage1")} {t("recurring:detailmessage2")}
          </p>
          
          <br />

          <h2 id="components-recurring-howtoenable" className="title-sub">{t("recurring:howtoenable")}</h2>
          <p>{t("recurring:howtoenabledetail1")}<a href="mailto:info@gbprimepay.com" target="_blank"> info@gbprimepay.com </a></p>
          <p>{t("recurring:howtoenabledetail2")}</p>

          <br />

          <p style={{color: 'red'}}>
            {t("recurring:howtoworkdmessage1")}
          </p>
          <br />
          <CardForm />

          <br />

          <h2 id="components-recurring-tokenapi" className="title-sub">{t("recurring:tokenapi")}</h2>
          <TokenApi />

          <br />

          <h2 id="components-recurring-chargeapi" className="title-sub">{t("recurring:chargeapi")}</h2>
          <ChargeApi />

          <br />

          <h2 id="components-recurring-webhook" className="title-sub">{t("recurring:webhooktobg")}</h2>
          <WebHookCode />

          <br />

          <h2 id="components-recurring-updatechargeapi" className="title-sub">{t("recurring:updatechargeapi")}</h2>
          <UpdateChargeApi />

          <br />

          <h2 id="components-recurring-cancelrecurringapi" className="title-sub">{t("recurring:cancelrecurringapi")}</h2>
          <CancelChargeApi />

          <br />

          <h2 id="components-recurring-resultcode" className="title-sub">{t("recurring:resultcode")}</h2>
          <ResultCode />

          <br />

          <h2 id="components-recurring-limit" className="title-sub">{t("recurring:limit")}</h2>
          {/* <p>{t("recurring:limitdetail1")}</p>
          <p>{i18n.language == 'en' ? t("recurring:limitdetail2") : ''}</p>  */}

          <p>{t("minimumlimits")} 1.00 (THB)</p>
          <p>{t("maximumlimits")} {t("followingcardlimit")} (THB)</p>
        </Content>

        <Sider breakpoint="md" collapsedWidth="0" style={{ background: '#fff' }} h>
          <br />
          <span style={{ color: '#02416D', fontWeight: 'bold', paddingTop: '20px' }}  >
            <Icon type="container" style={{ fontSize: '20px', color: '#08c' }} theme="outlined" />
          </span>
          <span style={{ color: '#02416D', fontWeight: 'bold', paddingTop: '20px' }}>{t("tablecontents")}</span>
          <br />
          <Anchor style={{ paddingTop: '10px' }} >
            <Anchor.Link href="#components-recurring-howtoenable" title={t("recurring:howtoenable")} />
            <Anchor.Link href="#components-recurring-tokenapi" title={t("recurring:tokenapi")} >
              <Anchor.Link href="#creating-source" title={t("recurring:creatingsource")} />
              <Anchor.Link href="#response-data" title={t("recurring:response")} />
              <Anchor.Link href="#response-data-demo" title={t("recurring:demo")} />
            </Anchor.Link>
            <Anchor.Link href="#components-recurring-chargeapi" title={t("recurring:chargeapi")} >
              <Anchor.Link href="#creating-source-chargeapi" title={t("recurring:creatingsource")} />
              <Anchor.Link href="#response-data-chargeapi" title={t("recurring:response")} />
            </Anchor.Link>
            <Anchor.Link href="#components-recurring-webhook" title={t("recurring:webhook")} >
              <Anchor.Link href="#response-data-webhook" title={t("recurring:response")} />
            </Anchor.Link>
            <Anchor.Link href="#components-recurring-updatechargeapi" title={t("recurring:updatechargeapi")} >
              <Anchor.Link href="#creating-source-updatechargeapi" title={t("recurring:creatingsource")} />
              <Anchor.Link href="#response-data-updatechargeapi" title={t("recurring:response")} />
            </Anchor.Link>
            <Anchor.Link href="#components-recurring-cancelrecurringapi" title={t("recurring:cancelrecurringapi")} >
              <Anchor.Link href="#creating-source-cancelrecurringapi" title={t("recurring:creatingsource")} />
              <Anchor.Link href="#response-data-cancelrecurringapi" title={t("recurring:response")} />
            </Anchor.Link>
            <Anchor.Link href="#components-recurring-resultcode" title={t("recurring:resultcode")} />
            <Anchor.Link href="#components-recurring-limit" title={t("recurring:limit")} />
          </Anchor>
        </Sider>

      </Fragment>
    )
  }
}

export default withTranslation()(Tokenization);
