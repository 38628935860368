const initialState = { 
  loading: false,
  amount: '3000.00',
  bankCode: '',
  term: '',
};

const creditCard = (state = initialState, action) => {
  switch (action.type) {
    case 'INSTALLMENT/UPDATE_BY_KEY':
      return {
        ...state,
        [action.key]: action.value
      }
    default:
      return state
  }
}
export default creditCard
