import { combineReducers } from 'redux';
import creditCard from './creditCard';
import installment from './installment';
import qrCash from './qrCash';
import barcode from './barcode';
import trueWallet from './trueWallet';
import linePayment from  './linePayment';
import apiQuery from './apiQuery';
import mobileBanking from './mobileBanking';
import shopeePay from './shopeePay';

export default combineReducers({
  creditCard,
  installment,
  qrCash,
  barcode,
  trueWallet,
  linePayment,
  apiQuery,
  mobileBanking,
  shopeePay,
})