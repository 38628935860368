import React, { Component } from 'react'
import { Table } from 'antd'

const columnsResultCode = [
  {
    title: 'Code',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Meaning',
    dataIndex: 'age',
    key: 'age',
  },
  {
    title: 'Recommendation',
    dataIndex: 'address',
    key: 'address',
  }
];


const dataResultCode = [
  {
    key: '0',
    name: '00',
    age: 'Approved',
    address: 'Payment Completed'
  },
  {
    key: '1',
    name: '01',
    age: 'Refer to Card Issuer',
    address: 'Give cardholder contacts issuer bank'
  },
  {
    key: '2',
    name: '03',
    age: 'Invalid Merchant ID',
    address: 'Please contact GBPrimePay'
  },
  {
    key: '3',
    name: '05',
    age: 'Do Not Honour',
    address: 'Cardholder input invalid card information. Ex. Expiry date, CVV2 or card number. Give cardholder contacts issuer bank.'
  },
  {
    key: '4',
    name: '12',
    age: 'Invalid Transaction',
    address: 'Please contact GBPrimePay'
  },
  {
    key: '5',
    name: '13',
    age: 'Invalid Amount',
    address: 'Payment amount must more than 0.1'
  },
  {
    key: '6',
    name: '14',
    age: 'Invalid Card Number',
    address: 'Please check all digits of card no.'
  },
  {
    key: '7',
    name: '17',
    age: 'Customer Cancellation',
    address: 'Customers click at cancel button in payment page when they make transaction. Customers have to make new payment transaction.'
  },
  {
    key: '8',
    name: '19',
    age: 'Re-enter Transaction',
    address: 'Duplicate payment. Please contact GBPrimePay'
  },
  {
    key: '9',
    name: '30',
    age: 'Format Error',
    address: 'Transaction format error. Please contact GBPrimePay'
  },
  {
    key: '10',
    name: '41',
    age: 'Lost Card -Pick Up',
    address: 'Lost Card and Cardholder give up.'
  },
  {
    key: '11',
    name: '43',
    age: 'Stolen Card -Pick Up',
    address: 'Stolen Card and Cardholder give up.'
  },
  {
    key: '12',
    name: '50',
    age: 'Invalid Payment Condition',
    address: 'Ex. Session time out or invalid VbV Password : ask cardholders to try ma again and complete transaction within 15 minutes with correct card information.'
  },
  {
    key: '13',
    name: '51',
    age: 'Insufficient Funds',
    address: 'Not enough credit limit to pay. Please contact issuer'
  },
  {
    key: '14',
    name: '54',
    age: 'Expired Card',
    address: 'Cardholder key in invalid expiry date'
  },
  {
    key: '15',
    name: '55',
    age: 'Wrong Pin',
    address: 'Wrong Pin'
  },
  {
    key: '16',
    name: '58',
    age: 'Transaction not Permitted to Terminal',
    address: 'Issuer does not allow to pay with debit card (Visa Electron, Mastercard Electron)'
  },
  {
    key: '17',
    name: '68',
    age: 'Response Received Too Late',
    address: 'Response Received Too Late'
  },
  {
    key: '18',
    name: '91',
    age: 'Issuer or Switch is Inoperative',
    address: 'Issuer system is not available to authorize payment'
  },
  {
    key: '19',
    name: '94',
    age: 'Duplicate Transmission',
    address: 'Please inform GBPrimePay to investigate'
  },
  {
    key: '20',
    name: '96',
    age: 'System Malfunction',
    address: 'Issuer bank system can not give a service'
  },
  {
    key: '21',
    name: 'xx',
    age: 'Transaction Timeout',
    address: 'Can not receive response code from issuer with in the time limit'
  },
];

export default class ResultCode extends Component {
  render() {
    return (
      <div>
        
        <Table columns={columnsResultCode} dataSource={dataResultCode} pagination={false} bordered />

      </div>
    );
  }
}
