function generateRef() {
  const d = new Date().toJSON()

  const y = d.substr(2, 2)
  const m = d.substr(5, 2)
  const da = d.substr(8, 2)
  const h = d.substr(11, 2)
  const mi = d.substr(14, 2)
  const s = d.substr(17, 2)
  const ms = d.substr(20, 3)

  return `${y}${m}${da}${h}${mi}${s}${ms}`
}

function qrText() {
  return '00020101021230830016A0000006770101120115XXXXXXXXXXXXXXX0218XXXXXXXXXXXXXXXXXX0318XXXXXXXXXXXXXXXXXX530376454041.005802TH5910GBPrimePay'
}

function barcodeText(ref) {
  return `|XXXXXXXXXXXXXXX\\r000${ref}\\r000${ref}\\r10000`
}

function qrWe(ref) {
  return `weixin://wxpay/bizpayurl?pr=XXXXXXX`
}

export {
  generateRef,
  qrText,
  barcodeText,
  qrWe
}