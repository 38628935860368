const initialState = { 
  loading: false,
  amount: '1.00',
  isQr: 'qr',
  referenceNo: ''
};

const qrCash = (state = initialState, action) => {
  switch (action.type) {
    case 'QR_CASH/UPDATE_BY_KEY':
      return {
        ...state,
        [action.key]: action.value
      }
    default:
      return state
  }
}
export default qrCash
