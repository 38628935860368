import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
//import { reactI18nextModule } from 'react-i18next';
import { initReactI18next } from 'react-i18next';
import UnifiedAPI from './container/UnifiedAPI';
i18n
    .use(Backend)
    .use(LanguageDetector)
    //.use(reactI18nextModule)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath: "/assets/i18n/{{ns}}/{{lng}}.json",
        },
        fallbackLng: "en",
        debug: false,
        ns: ["common", "gettingstarted", "fullpayment3d", "fullpaymentnon3d", "installment", "recurring"
            , "qrcash", "qrcredit", "wechatpay", "rabbitlinepay", "truemoneywallet", "shopeepay", "alipay"
            , "enquiry", "query", "tokenapi", "atome", "woocommerce", "mobilebanking", "billpayment", "gblinkpayment"
            , "unifiedapi"],
        defaultNS: "common",

        interpolation: {
            escapeValue: false,
            formatSeparator: ",",
        },
        react: {
            wait: true,
            useSuspense: false,
        },
    });
export default i18n;