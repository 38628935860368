import React, { Component } from 'react'
import { Button, Input } from 'antd'
import get from 'lodash/get'

export default class TokenForm extends Component {
  onChange = e => {
    this.props.updateByKey(e.target.name, e.target.value)
  }
  onSubmit = () => {
    this.props.onSubmit()
  }
  render() {
    const { cardNumber, expirationMonth, expirationYear, securityCode } = this.props.data
    const isValid = (cardNumber === '4535017710535741' && expirationMonth === '05' && expirationYear === '28' && securityCode === '184') || (cardNumber === '5258860689905862' && expirationMonth === '02' && expirationYear === '25' && securityCode === '950')
    return (
      <form action="#" method="POST">
        {/* <Input name="name" value={this.props.data.name} maxLength={250} placeholder="Holder Name" onChange={this.onChange} /><br/> */}
        <Input name="cardNumber" value={this.props.data.cardNumber} maxLength={16} placeholder="Card Number" onChange={this.onChange} /><br/>
        <Input name="expirationMonth" value={this.props.data.expirationMonth} maxLength={2} placeholder="MM" onChange={this.onChange} /><br/>
        <Input name="expirationYear" value={this.props.data.expirationYear} maxLength={2} placeholder="YY (Last Two Digits)" onChange={this.onChange} /><br/>
        <Input.Password name="securityCode" value={this.props.data.securityCode} maxLength={4} autoComplete="off" placeholder="CVV" onChange={this.onChange} /><br/><br/>
        <Button type="primary" onClick={this.onSubmit} block loading={this.props.loading} disabled={!isValid}>Create Token</Button><br/><br/>
        
        {
          get(this.props, 'token', undefined) && (
            <React.Fragment>
              <h4>Token for use in the next step</h4>
              {get(this.props, 'token', '')}
            </React.Fragment>
          )
        }

      </form>
    )
  }
}
