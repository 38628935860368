import React, { Component } from 'react'
import { Button, Input, Select } from 'antd'
import get from 'lodash/get'
const { Option } = Select;

export default class TokenForm extends Component {
  onChange = e => {
    this.props.updateByKey(e.target.name, e.target.value)
  }
  onChangeBankCode = v => {
    this.props.updateByKey('bankCode', v)
  }
  onChangeTerm = v => {
    this.props.updateByKey('term', v)
  }
  onSubmit = () => {
    this.props.onSubmit()
  }
  render() {
    const isValid = this.props.data.bankCode && this.props.data.term
    return (
      <form action="#" method="POST"> 
        <Input name="amount" value={this.props.data.amount} maxLength={13} placeholder="Amount ex. 3000.00" onChange={this.onChange} readOnly /><br/>
        <Select name="bankCode" defaultValue="" onChange={this.onChangeBankCode}>
          <Option value="">Card issuer bank</Option>
          <Option value="004">Kasikornbank Public Company Limited.</Option>
          <Option value="006">Krung Thai Bank Public Company Limited.</Option>
          <Option value="011">TMBThanachart Bank (ttb)</Option>
          {/* <Option value="025">Bank of Ayudhya Public Company Limited.</Option>
          <Option value="026">Krungsri First Choice.</Option> */}
          <Option value="014">Siam Commercial Bank Public Company Limited.</Option>
          <Option value="002">Bangkok Bank Public Company Limited.</Option>
        </Select>
        <Select name="term" defaultValue="" onChange={this.onChangeTerm}>
          <Option value="">The number of monthly installments</Option>
          <Option value="3">3</Option>
          <Option value="4">4</Option>
          <Option value="5">5</Option>
          <Option value="6">6</Option>
          <Option value="7">7</Option>
          <Option value="8">8</Option>
          <Option value="9">9</Option>
          <Option value="10">10</Option>
          <Option value="12">12</Option>
          <Option value="18">18</Option>
          <Option value="24">24</Option>
        </Select>
        <br /><br />
        <Button type="primary" onClick={this.onSubmit} block loading={this.props.loading} disabled={!isValid}>Pay Now</Button><br/><br/>
      </form>
    )
  }
}
