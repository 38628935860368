import React, { Component, Suspense } from 'react';
import logo from './logo.svg';
import './App.css';
import { Route, Link } from 'react-router-dom';
import { Layout, Menu, Anchor, Icon, Select } from 'antd';
import GettingStarted from './container/GettingStarted';
// import Tokenization from './container/Tokenization';
import TokenAPI from './container/TokenAPI'
import FullPayment3D from './container/FullPayment3D';
import FullPaymentN3D from './container/FullPaymentN3D';
import Installment from './container/Installment';
import Recurring from './container/Recurring';
import QRCash from './container/QRCash';
import QRCredit from './container/QRCredit';
import Enquiry from './container/Enquiry';
import Barcode from './container/Barcode';
import Wechat from './container/Wechat';
import Linepay from './container/Linepay';
import TrueWallet from './container/TrueWallet';
import Card from './container/Card';
import ApiQuery from './container/ApiQuery';
import MobileBanking from './container/MobileBanking';
import ShopeePay from './container/ShopeePay';
import Alipay from './container/Alipay';
import Atome from './container/Atome';
import WooCommerce from './container/WooCommerce';
import UnifiedAPI from './container/UnifiedAPI';

// import i18n from 'i18next';
// import { initReactI18next, useTranslation } from 'react-i18next'; 

// import { useTranslation } from "react-i18next";

import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import i18next from 'i18next';
import GBLinkPayment from './container/GBLinkPayment';

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
const { Option } = Select;

// const translationsEn = { welcome: "Welcome!!", title: "GB Prime Pay" };
// const translationsTh = { welcome: "ยินดีต้อนรับ!!", title: "จีบี ไพรม์ เพย์"};

// i18n.use(initReactI18next)
// .init( {
//   resources: {
//     en: { translation: translationsEn },
//     th: { translation: translationsTh },
//   },
//   lng: "en",
//   fallbackLng: "en",
//   interpolation: { escapeValue: false },
// });

// class App extends Component {
//   render() {

const App = () => {

  // const { t } = useTranslation();
  // const switchingLanguage = (event) => {
  //   console.log(">>>>> Change Lang");
  //   i18n.changeLanguage(event.target.value)
  // };

  // const { t, i18n } = this.props;
  // const { t } = useTranslation(["common"]);
  const { t, i18n } = useTranslation(["common"]);

  useEffect(() => {
    if(localStorage.getItem("i18nextLng")?.length > 1){
      i18next.changeLanguage("en");
    }
  }, []);
  
  // const switchingLanguage = () => {
  //   if (i18n.language === "en") {
  //     i18n.changeLanguage("th");
  //   } else {
  //     i18n.changeLanguage("en");
  //   }
  // };

  // const switchingLanguage = (e) => {
  //   i18n.changeLanguage(e.target.value)
  // };

  const switchingLanguage = (value) => {
    i18n.changeLanguage(value)
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Layout>
        <Header className="header">
          {/* <div className="logo"> */}
          <div style={{width: '80%', marginLeft: 30, textAlign: 'left'}}>
            <img src={logo} style={{ height: '48px' }} />
            <span className="title-header">
              {t('title')}
            </span>
          </div>

          <div style={{width: '20%', textAlign: 'right'}}>
            {/* <select name="language" style={{ width: 120 }} 
                value={localStorage.getItem("i18nextLng")}
                onChange={switchingLanguage} >
                <option className="optionsMenu" value="en">
                  English
                </option>
                <option className="optionsMenu" value="th">
                  ไทย
                </option>
            </select> */}
            <Select name="language" style={{ width: 120 }} 
                defaultValue={'en'}
                onChange={switchingLanguage} >
                <Option className="optionsMenu" value="en">English</Option>
                <Option className="optionsMenu" value="th">ไทย</Option>
            </Select>
          </div>
          {/*<Menu
            theme="light"
            mode="horizontal"
            defaultSelectedKeys={['2']}
            style={{ lineHeight: '64px' }}
          >
            <Menu.Item key="1">nav 1</Menu.Item>
            <Menu.Item key="2">nav 2</Menu.Item>
            <Menu.Item key="3">nav 3</Menu.Item>
          </Menu>*/}
        </Header>
        {/*<nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/card">Card</Link>
            </li>
            <li>
              <Link to="/users">Users</Link>
            </li>
          </ul>
        </nav>
        <div>
          <Route exact path='/' component = {Login}></Route>
          <Route exact path='/card' component = {Card}></Route>
        </div>*/}
        <Layout style={{ paddingTop: '15px' }}>
          <Sider breakpoint="lg" collapsedWidth="0" width="220" style={{ background: '#fff' }}>
            <Menu
              mode="inline"
              defaultSelectedKeys={['getting_started']}
              style={{ height: '100%', borderRight: 0, paddingTop: '20px' }} >

              <Menu.ItemGroup
                title={
                  <span style={{ color: '#454545', fontWeight: 'bold' }} className="font-side">
                    {t('introduction')}
                  </span>
                } >
                <Menu.Item key="getting_started" style={{ fontSize: '14px' }} >
                  <Link to="/">{t('gettingStarted')}</Link>
                </Menu.Item>

                {/* <Menu.Item key="integration">
                  <Link to="/">{t('integration')}</Link>
                </Menu.Item> */}
              </Menu.ItemGroup>

              <Menu.ItemGroup
                title={
                  <span style={{ color: '#454545', fontWeight: 'bold' }} className="font-side">
                    {t('paymethods')}
                  </span>
                }>
                <SubMenu
                  key="card"
                  title={
                    <span className="font-side">
                      {t('creditcard')}
                    </span>
                  } >
                  <Menu.Item key="card_1" style={{ fontSize: '14px' }}>
                    <Link to="/Token-API">{t('tokenapi')}</Link>
                  </Menu.Item>
                  <Menu.Item key="card_2" style={{ fontSize: '14px' }}>
                    <Link to="/full-payment-3d">{t('fullpayment3ds')}</Link>
                  </Menu.Item>
                  <Menu.Item key="card_3" style={{ fontSize: '14px' }}>
                    <Link to="/full-payment-non-3d">{t('fullpaymentnon3ds')}</Link>
                  </Menu.Item>
                  <Menu.Item key="card_4" style={{ fontSize: '14px' }}>
                    <Link to="/installment">{t('installment')}</Link>
                  </Menu.Item>
                  <Menu.Item key="card_5" style={{ fontSize: '14px' }}>
                    <Link to="/recurring">{t('recurring')}</Link>
                  </Menu.Item>                  
                </SubMenu>

                <SubMenu
                  key="qr"
                  title={
                    <span className="font-side">
                      {t('qrcode')}
                    </span>
                  }
                >
                  <Menu.Item key="qr_1" style={{ fontSize: '14px' }}>
                    <Link to="/qrcash">{t('qrcash')}</Link>
                  </Menu.Item>
                  <Menu.Item key="qr_2" style={{ fontSize: '14px' }}>
                    <Link to="/qrcredit">{t('qrcredit')}</Link>
                  </Menu.Item>
                </SubMenu>

                <SubMenu
                  key="wl"
                  title={
                    <span className="font-side">
                      {t('wallet')}
                    </span>
                  }
                >
                  <Menu.Item key="wechat_pay" style={{ fontSize: '14px' }}>
                    <Link to="/wechat">{t('wechatpay')}</Link>
                  </Menu.Item>
                  <Menu.Item key="line_pay" style={{ fontSize: '14px' }}>
                    <Link to="/linepay">{t('rabbitlinepay')}</Link>
                  </Menu.Item>
                  <Menu.Item key="true_wallet" style={{ fontSize: '14px' }}>
                    <Link to="/truewallet">{t('truemoneywallet')}</Link>
                  </Menu.Item>
                  <Menu.Item key="shopee_pay" style={{ fontSize: '14px' }}>
                    <Link to="/shopeepay">{t('shopeepay')}</Link>
                  </Menu.Item>
                  <Menu.Item key="ali_pay" style={{ fontSize: '14px' }}>
                    <Link to="/alipay">{t('alipay')}</Link>
                  </Menu.Item>
                </SubMenu>

                <SubMenu
                  key="bnpl"
                  title={
                    <span className="font-side">
                      {t('buynowpaylater')}
                    </span>
                  }
                >
                  <Menu.Item key="atome" style={{ fontSize: '14px' }}>
                    <Link to="/atome">{t('atome')}</Link>
                  </Menu.Item>
                </SubMenu>
                <Menu.Item key="mobile_banking" style={{ fontSize: '14px' }}>
                  <Link to="/mobilebanking">{t('mobilebanking')}</Link>
                </Menu.Item>
                <Menu.Item key="bill_payment" style={{ fontSize: '14px' }}>
                  <Link to="/bill-payment">{t('billpayment')}</Link>
                </Menu.Item>
              </Menu.ItemGroup>

              <Menu.ItemGroup
                title={
                  <span style={{ color: '#454545', fontWeight: 'bold' }} className="font-side">
                    {t('apireferences')}
                  </span>
                }>
                <Menu.Item key="api_01" style={{ fontSize: '14px' }}>
                  <Link to="/enquiry">{t('enquiry')}</Link>
                </Menu.Item>
                <Menu.Item key="api_02" style={{ fontSize: '14px' }}>
                  <Link to="/apiquery">{t('query')}</Link>
                </Menu.Item>
              </Menu.ItemGroup>

              <Menu.ItemGroup
                title={
                  <span style={{ color: '#454545', fontWeight: 'bold' }} className="font-side">
                    {t('plugins')}
                  </span>
                }>
                <Menu.Item key="woocommerce" style={{ fontSize: '14px' }}>
                  <Link to="/woocommerce">{t('woocommerce')}</Link>
                </Menu.Item>
              </Menu.ItemGroup>

              <Menu.ItemGroup
                title={
                  <span style={{ color: '#454545', fontWeight: 'bold' }} className="font-side">
                    {t('other')}
                  </span>
                }>
                <Menu.Item key="gblinkpayment" style={{ fontSize: '14px' }}>
                  <Link to="/gblinkpayment">{t('gblinkpayment')}</Link>
                </Menu.Item>
                <Menu.Item key="unifiedapi" style={{ fontSize: '14px' }}>
                  <Link to="/unifiedAPI">{t('unifiedapi')}</Link>
                </Menu.Item>
              </Menu.ItemGroup>
            </Menu>

            {/* <Menu
              mode="inline"
              defaultSelectedKeys={['getting_started']}
              // defaultOpenKeys={['card']}
              style={{ height: '100%', borderRight: 0 }}
            >
              <Menu.Item key="getting_started">
                <Link to="/">Getting started</Link>
              </Menu.Item>
              <SubMenu
                key="card"
                title={
                  <span>
                    Credit/Debit card
                  </span>
                }
              >
                <Menu.Item key="card_0">
                  <Link to="/Token-API">Token API</Link>
                </Menu.Item>
                <Menu.Item key="card_1">
                  <Link to="/full-payment-3d">Full Payment (3D)</Link>
                </Menu.Item>
                <Menu.Item key="card_2">
                  <Link to="/full-payment-non-3d">Full Payment (non-3D)</Link>
                </Menu.Item>
                <Menu.Item key="card_3">
                  <Link to="/installment">Installment (IPP)</Link>
                </Menu.Item>
                <Menu.Item key="card_4">
                  <Link to="/recurring">Recurring</Link>
                </Menu.Item>
              </SubMenu>

              <SubMenu
                key="qr"
                title={
                  <span>
                    QR code
                  </span>
                }
              >
                <Menu.Item key="qr_1">
                  <Link to="/qrcash">QR cash</Link>
                </Menu.Item>
                <Menu.Item key="qr_2">
                  <Link to="/qrcredit">QR credit</Link>
                </Menu.Item>
                <Menu.Item key="qr_3">
                  <Link to="/enquiry">Enquiry (Optional)</Link>
                </Menu.Item>
              </SubMenu>

              <Menu.Item key="mobile_banking">
                <Link to="/mobilebanking">Mobile Banking</Link>
              </Menu.Item>

              <SubMenu
                key="wl"
                title={
                  <span>
                    Wallet
                  </span>
                }
              >
                <Menu.Item key="wechat_pay">
                <Link to="/wechat">Wechat Pay</Link>
                </Menu.Item>
                <Menu.Item key="line_pay">
                <Link to="/linepay">Rabbit Line pay</Link>
                </Menu.Item>
                <Menu.Item key="true_wallet">
                <Link to="/truewallet">TrueMoney Wallet</Link>
                </Menu.Item>
                <Menu.Item key="shopee_pay">
                <Link to="/shopeepay">ShopeePay</Link>
                </Menu.Item>
                <Menu.Item key="ali_pay">
                <Link to="/alipay">Alipay</Link>
                </Menu.Item>
              </SubMenu>
 
              <Menu.Item key="bill_payment">
                <Link to="/bill-payment">Bill Payment</Link>
              </Menu.Item>
              
              <Menu.Item key="api_query">
                <Link to="/apiquery">Query</Link>
              </Menu.Item>
            </Menu> */}
          </Sider>
          <Layout style={{ padding: '0 15px 15px' }}>
            <Route exact path='/' component={GettingStarted}></Route>
            <Route exact path='/Token-API' component={TokenAPI}></Route>
            <Route exact path='/full-payment-3d' component={FullPayment3D}></Route>
            <Route exact path='/full-payment-non-3d' component={FullPaymentN3D}></Route>
            <Route exact path='/card' component={Card}></Route>
            <Route exact path='/installment' component={Installment}></Route>
            <Route exact path='/recurring' component={Recurring}></Route>
            <Route exact path='/qrcash' component={QRCash}></Route>
            <Route exact path='/qrcredit' component={QRCredit}></Route>
            <Route exact path='/enquiry' component={Enquiry}></Route>
            <Route exact path='/bill-payment' component={Barcode}></Route>
            <Route exact path='/wechat' component={Wechat}></Route>
            <Route exact path='/apiquery' component={ApiQuery}></Route>
            <Route exact path='/linepay' component={Linepay}></Route>
            <Route exact path='/truewallet' component={TrueWallet}></Route>
            <Route exact path='/mobilebanking' component={MobileBanking}></Route>
            <Route exact path='/shopeepay' component={ShopeePay}></Route>
            <Route exact path='/alipay' component={Alipay}></Route>
            <Route exact path='/atome' component={Atome}></Route>
            <Route exact path='/woocommerce' component={WooCommerce}></Route>
            <Route exact path='/gblinkpayment' component={GBLinkPayment}></Route>
            <Route exact path='/unifiedAPI' component={UnifiedAPI}></Route>

            {/*
            <Breadcrumb style={{ margin: '16px 0' }}>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item>List</Breadcrumb.Item>
              <Breadcrumb.Item>App</Breadcrumb.Item>
            </Breadcrumb>
            <Content
              style={{
                background: '#fff',
                padding: 24,
                margin: 0,
                minHeight: 280,
              }}
            >
              Content
            </Content>
            */}
          </Layout>

          {/* <Layout style={{ background: '#fff', padding: '0 24px 24px'}} >
            <Sider breakpoint="md" collapsedWidth="0" style={{ background: '#fff' }} h>
              <br />
              <span style={{ color: '#02416D', fontWeight: 'bold', paddingTop: '20px' }}  >
                  <Icon type="container" style={{ fontSize: '20px', color: '#08c' }} theme="outlined" />
              </span>
              <span style={{ color: '#02416D', fontWeight: 'bold', paddingTop: '20px' }}>Table of Contents</span>
              <br />
              <Anchor style={{ paddingTop: '10px' }} >
                <Anchor.Link href="#components-installment-howtoenable" title="How to enable" />
                <Anchor.Link href="#components-installment-paymentflow" title="Payment Flow" />
                <Anchor.Link href="#components-installment-steptopayment" title="Step to payment" />
                <Anchor.Link href="#components-installment-implementation" title="Implementation">
                  <Anchor.Link href="#creating-source" title="Creating a source" />
                  <Anchor.Link href="#creating-charge" title="Creating a charge" />
                </Anchor.Link>
                <Anchor.Link href="#components-installment-voidrefund" title="Void & Refunds" />
                <Anchor.Link href="#components-installment-limit" title="Limit" />
              </Anchor>
            </Sider>
          </Layout> */}

        </Layout>
      </Layout>
    </Suspense>

  );

  }

// }

export default App;
//export default useTranslation("translations")(App);
// export default useTranslation("common")(App);
