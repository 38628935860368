import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Layout, Button, Breadcrumb, Icon, Table, Row, Col, Tabs, Anchor } from 'antd'
import get from 'lodash/get'
import PrismCode from '../Tokenization/PrismCode'
import TokenForm from './Form'
import * as actions from '../../actions/qrCashAction'
import WeDemo from './WeDemo'
import { withTranslation } from "react-i18next";

const { Content, Sider } = Layout;
const { TabPane } = Tabs;

const dataHeader = [
  {
    key: '1',
    name: 'content-type',
    age: 'String',
    address: 'application/x-www-form-urlencoded'
  },
];

const dataHeaderImage = [
  {
    key: '1',
    name: 'content-type',
    age: 'String',
    address: 'image/png'
  },
];

const htmlCode = `
<form action="{url}" method="POST"> 
  <input type="hidden" name="publicKey" value="{public_key}">
  <input type="hidden" name="referenceNo" value="{referenceNo}">
  <input type="hidden" name="backgroundUrl" value="{backgroundUrl}">
  <input type="number" name="amount" maxlength="13" placeholder="Amount" value="{amount}"><br/>
  <input type="hidden" name="detail" placeholder="Detail" value="{detail}"><br/>
  <input type="hidden" name="checksum" value="">
  <input id="button" type="button" onClick="genChecksum()" value="Generate Checksum">
</form>

<script src="https://cdnjs.cloudflare.com/ajax/libs/crypto-js/3.1.9-1/crypto-js.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/crypto-js/3.1.9-1/hmac-sha256.min.js"></script>
<script>
function genChecksum(){
var hash = CryptoJS.HmacSHA256(document.getElementsByName("amount")[0].value +
document.getElementsByName("referenceNo")[0].value + document.getElementsByName("backgroundUrl")[0].value,
{secret_key});

document.getElementsByName("checksum")[0].value = hash;
}
</script>
`

const phpResponse = `
<?php

$respFile = fopen("resp-log.txt", "w") or die("Unable to open file!");

$json_str = file_get_contents('php://input');
fwrite($respFile, $json_str . "\\n\\n");

$json_obj = json_decode($json_str);

fwrite($respFile, "resultCode=" . $json_obj->resultCode . "\\n");
fwrite($respFile, "amount=" . $json_obj->amount . "\\n");
fwrite($respFile, "referenceNo=" . $json_obj->referenceNo . "\\n");
fwrite($respFile, "gbpReferenceNo=" . $json_obj->gbpReferenceNo . "\\n");

fclose($respFile);

?>
`

const javaResponse = `

StringBuffer jb = new StringBuffer();
String line = null;

try {

  BufferedReader reader = request.getReader();

  while ((line = reader.readLine()) != null)

    jb.append(line);

} catch (Exception e) { /*report an error*/ }

try {

  JSONObject jsonObject = new JSONObject(jb.toString());
  System.out.println("resultCode=" + jsonObject.getString("resultCode"));
  System.out.println("amount=" + jsonObject.getBigDecimal("amount"));
  System.out.println("gbpReferenceNo=" + jsonObject.getString("gbpReferenceNo"));
  
} catch (JSONException e) { /*report an error*/ }
`

const renderFormHtml = data => {
  let url = 'https://api.globalprimepay.com/v2/wechat'
  if (data.isQr === 'bill') {
    url = 'https://api.globalprimepay.com/v2/wechat/text'
  }
  let _htmlCode = htmlCode.replace(/{amount}/g, data.amount);
  _htmlCode = _htmlCode.replace(/{url}/g, url);
  return (
    <div>
      <PrismCode
        code={_htmlCode}
        language="html"
        plugins={["line-numbers"]}
      />
    </div>
  )
}

const renderPhpResponse = () => (
  <div>
    <PrismCode
      code={phpResponse}
      language="php"
      plugins={["line-numbers"]}
    />
  </div>
)

const renderJavaResponse = () => (
  <div>
    <PrismCode
      code={javaResponse}
      language="java"
      plugins={["line-numbers"]}
    />
  </div>
)

const codeResponse = `
{
  "resultCode": "00",
  "amount": 100.00,
  "referenceNo": "20171128001",
  "gbpReferenceNo": "gbp0001000900",
  "openId":"XXXXXXXXXXXXXXXXXXXXXXXXXXXX",
  "detail": "t-shirt"
}
`

const renderFormCodeResponse = () => (
  <div>
    <PrismCode
      code={codeResponse}
      language="json"
      plugins={["line-numbers"]}
    />
  </div>
)

class QRAndBarcode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }
  changeStep = current => {
    this.setState({ current: current })
    console.log(current)
  }
  render() {

    const { t , i18n } = this.props;

    const columns = [
      {
        title: t("field"),
        dataIndex: 'name',
        key: 'name',
        render: text => <code>{text}</code>
      },
      {
        title: t("type"),
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: t("description"),
        dataIndex: 'address',
        key: 'address',
      }
    ];

    const columnsParameter = [
      {
        title: t("field"),
        dataIndex: 'name',
        key: 'name',
        render: text => <code>{text}</code>
      },
      {
        title: t("type"),
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: t("mandatory"),
        dataIndex: 'mandatory',
        key: 'mandatory',
      },
      {
        title: t("description"),
        dataIndex: 'address',
        key: 'address',
      }
    ];

    const dataParameter = [
      {
        key: '0',
        name: 'publicKey',
        age: 'String (40)',
        mandatory: 'M',
        address: t("publickeydesc")
      },
      {
        key: '1',
        name: 'amount',
        age: 'Number (10,2)',
        mandatory: 'M',
        address: t("amountdesc")
      },
      {
        key: '2',
        name: 'referenceNo',
        age: 'String (15)',
        mandatory: 'M',
        address: t("referencenodesc")
      },
      {
        key: '3',
        name: 'backgroundUrl',
        age: 'String (250)',
        mandatory: 'M',
        address: t("backgroundurldesc")
      },
      {
        key: '4',
        name: 'detail',
        age: 'String (250)',
        mandatory: 'M',
        address: t("detaildesc")
      },
      {
        key: '5',
        name: 'customerName',
        age: 'String (150)',
        mandatory: 'O',
        address: t("customernamedesc")
      },
      {
        key: '6',
        name: 'customerEmail',
        age: 'String (150)',
        mandatory: 'O',
        address: t("customeremaildesc")
      },
      {
        key: '7',
        name: 'customerAddress',
        age: 'String (150)',
        mandatory: 'O',
        address: t("customeraddressdesc")
      },
      {
        key: '8',
        name: 'customerTelephone',
        age: 'String (25)',
        mandatory: 'O',
        address: t("customertelephonedesc")
      },
      {
        key: '9',
        name: 'merchantDefined1',
        age: 'String (250)',
        mandatory: 'O',
        address: t("merchantdefined1desc")
      },
      {
        key: '10',
        name: 'merchantDefined2',
        age: 'String (250)',
        mandatory: 'O',
        address: t("merchantdefined2desc")
      },
      {
        key: '11',
        name: 'merchantDefined3',
        age: 'String (250)',
        mandatory: 'O',
        address: t("merchantdefined3desc")
      },
      {
        key: '12',
        name: 'merchantDefined4',
        age: 'String (250)',
        mandatory: 'O',
        address: t("merchantdefined4desc")
      },
      {
        key: '13',
        name: 'merchantDefined5',
        age: 'String (250)',
        mandatory: 'O',
        address: t("merchantdefined5desc")
      },
      {
        key: '14',
        name: 'checksum',
        age: 'String (64)',
        mandatory: 'M',
        address: t("checksumdescwechat")
      },
    ];
    
    const dataResponse = [
      {
        key: '1',
        name: 'amount',
        age: 'Number (10, 2)',
        address: t("amountdesc")
      },
      {
        key: '2',
        name: 'referenceNo',
        age: 'String (15)',
        address: t("referencenodesc")
      },
      {
        key: '3',
        name: 'gbpReferenceNo',
        age: 'String (250)',
        address: t("gbpReferencenodesc")
      },
      {
        key: '4',
        name: 'resultCode',
        age: 'String (2)',
        address: t("resultcodedesc")
      },
      {
        key: '5',
        name: 'date',
        age: 'String (8)',
        address: t("datedesc")
      },
      {
        key: '6',
        name: 'time',
        age: 'String (6)',
        address: t("timedesc")
      },
      {
        key: '7',
        name: 'expirationMonth',
        age: 'String (2)',
        address: t("expirationmonthdesc")
      },
      {
        key: '8',
        name: 'cardNo',
        age: 'String (16)',
        address: t("cardnodesc")
      },
      {
        key: '9',
        name: 'payMonth',
        age: 'String (2)',
        address: t("paymonthdesc")
      },
      {
        key: '10',
        name: 'amountPerMonth',
        age: 'Number (10,2)',
        address: t("amountpermonthdesc")
      },
      {
        key: '11',
        name: 'issuerBank',
        age: 'String',
        address: t("issuerbankdesc")
      },
      {
        key: '12',
        name: 'detail',
        age: 'String (250)',
        address: t("detaildesc")
      },
      {
        key: '13',
        name: 'customerName',
        age: 'String (150)',
        address: t("customernamedesc")
      },
      {
        key: '14',
        name: 'customerEmail',
        age: 'String (150)',
        address: t("customeremaildesc")
      },
      {
        key: '15',
        name: 'merchantDefined1',
        age: 'String (250)',
        address: t("merchantdefined1desc")
      },
      {
        key: '16',
        name: 'merchantDefined2',
        age: 'String (250)',
        address: t("merchantdefined2desc")
      },
      {
        key: '17',
        name: 'merchantDefined3',
        age: 'String (250)',
        address: t("merchantdefined3desc")
      },
      {
        key: '18',
        name: 'merchantDefined4',
        age: 'String (250)',
        address: t("merchantdefined4desc")
      },
      {
        key: '19',
        name: 'merchantDefined5',
        age: 'String (250)',
        address: t("merchantdefined5desc")
      },
    ];

    const dataBankCode = [
      {
        key: '1',
        name: '004',
        age: t("kbankname"),
        address: '3, 4, 5, 6, 7, 8, 9, 10'
      },
      {
        key: '2',
        name: '006',
        age: t("ktbname"),
        address: `3, 4, 5, 6, 7, 8, 9, 10`
      },
      {
        key: '3',
        name: `011`,
        age: t("ttbname"),
        address: `3, 4, 6, 10`
      },
      {
        key: '6',
        name: '014',
        age: t("scbname"),
        address: `3, 4, 6, 10`
      },
      {
        key: '7',
        name: '002',
        age: t("bblname"),
        address: `3, 4, 6, 8, 9, 10`
      },
    ];
    
    const dataResponseToMerchant = [
      {
        key: '1',
        name: 'amount',
        age: 'Number (10, 2)',
        address: t("amountdesc"),
      },
      {
        key: '2',
        name: 'cashFee',
        age: 'Number (10, 2)',
        address: t("cashfeedesc"),
      },
      {
        key: '3',
        name: 'rateValue',
        age: 'Number (10, 4)',
        address: t("ratevaluedesc"),
      },
      {
        key: '4',
        name: 'referenceNo',
        age: 'String (15)',
        address: t("referencenodesc"),
      },
      {
        key: '5',
        name: 'gbpReferenceNo',
        age: 'String (250)',
        address: t("gbpReferencenodesc"),
      },
      {
        key: '6',
        name: 'openId',
        age: 'String',
        address: t("openiddesc"),
      },
      {
        key: '7',
        name: 'resultCode',
        age: 'String (2)',
        address: t("resultcodedesc"),
      },
      {
        key: '8',
        name: 'detail',
        age: 'String (250)',
        address: t("detaildesc"),
      },
      {
        key: '9',
        name: 'customerName',
        age: 'String (150)',
        address: t("customernamedesc"),
      },
      {
        key: '10',
        name: 'customerEmail',
        age: 'String (150)',
        address: t("customeremaildesc"),
      },
      {
        key: '11',
        name: 'merchantDefined1',
        age: 'String (250)',
        address: t("merchantdefined1desc"),
      },
      {
        key: '12',
        name: 'merchantDefined2',
        age: 'String (250)',
        address: t("merchantdefined2desc"),
      },
      {
        key: '13',
        name: 'merchantDefined3',
        age: 'String (250)',
        address: t("merchantdefined3desc"),
      },
      {
        key: '14',
        name: 'merchantDefined4',
        age: 'String (250)',
        address: t("merchantdefined4desc"),
      },
      {
        key: '15',
        name: 'merchantDefined5',
        age: 'String (250)',
        address: t("merchantdefined5desc"),
      },
      {
        key: '16',
        name: 'customerAddress',
        age: 'String (150)',
        address: t("customeraddressdesc"),
      },
      {
        key: '17',
        name: 'customerTelephone',
        age: 'String (25)',
        address: t("customertelephonedesc"),
      },
      { 
        key: '18',
        name: 'retryFlag',
        age: 'String(1)',
        address: t("retryflagdesc"),
      },
    ];

    return (
      <Fragment>
        {/* <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item>Wechat Pay</Breadcrumb.Item>
        </Breadcrumb> */}
        {/* <Content
          style={{
            background: '#fff',
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          <h2>Wechat Pay</h2>
          <hr />
          <p style={{ color: 'rgba(0, 0, 0, 0.85)' }}>Wechat Pay API URL:</p>
          <p>{`{ENV}/v2/wechat or {ENV}/v2/wechat/text`} - Method POST</p>
          <br />

          <p className="font-bold">Header</p>
          <Table columns={columns} dataSource={dataHeader} pagination={false} bordered />

          <br />
          <br />

          <p className="font-bold">Parameter</p>
          <Table columns={columnsParameter} dataSource={dataParameter} pagination={false} bordered />

          <br />
          <br />

          <p className="font-bold">Image Response</p>
          <Table columns={columns} dataSource={dataHeaderImage} pagination={false} bordered />

          <br />
          <br />

          <p className="font-bold">Example Code</p>
          {renderFormHtml(this.props.qrCash)}

          <br />

          <WeDemo />

          <br />
          <br />

          <p className="font-bold"> Background URL: Response to Merchant</p>
          <Table columns={columns} dataSource={dataResponseToMerchant} pagination={false} bordered />

          <br />
          <br />

          <p className="font-bold">JSON Response</p>
          {renderFormCodeResponse()}

          <br />
          <br />

          <p className="font-bold">Example Code - Response to Merchant</p>
          <Tabs defaultActiveKey="1" onChange={this.callback}>
            <TabPane tab="PHP" key="1">
              {renderPhpResponse()}
            </TabPane>
            <TabPane tab="Java" key="2">
              {renderJavaResponse()}
            </TabPane>
          </Tabs>
        </Content> */}

        <Content
          style={{
            background: '#fff',
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          <h2 className="title">{t("wechatpay:titlewechatpay")}</h2>
          <hr />
          <p>
            {t("wechatpay:detailmessage1")}
          </p>

          <br />

          <h2 id="components-wechatpay-howtoenable" className="title-sub">{t("wechatpay:howtoenable")}</h2>
          <p>{t("wechatpay:howtoenabledetail1")}<a href="mailto:info@gbprimepay.com" target="_blank"> info@gbprimepay.com </a></p>
          <p>{t("wechatpay:howtoenabledetail2")}</p>

          <br />

          <h2 id="components-wechatpay-paymentflow" className="title-sub">{t("wechatpay:paymentflow")}</h2>
          <img
            src={require('../../res/img/PaymentFlow/Flow-WeChatPay-ScanQR.png')}
            alt="step1"
            style={{ width: '50%', height: 'auto' }}
          />
          {/* <img
            src={require('../../res/img/PaymentFlow/Flow-WeChatPay-forMerchant.png')}
            alt="step2"
            style={{ width: '80%', height: 'auto' }}
          /> */}
          {/* <img
            src={require('../../res/img/mockup_mobile.png')}
            alt="step3"
            style={{ width: 'auto', height: 'auto' }}
          />
          <img
            src={require('../../res/img/mockup_mobile.png')}
            alt="step4"
            style={{ width: 'auto', height: 'auto' }}
          /> */}

          <br />

          <h2 id="components-wechatpay-steptopayment" className="title-sub">{t("wechatpay:steptopayment")}</h2>
          <p>{t("wechatpay:steptopaymentdetail1")} {t("wechatpay:steptopaymentdetail2")}</p>

          <br />

          <h2 id="components-wechatpay-implementation" className="title-sub">{t("wechatpay:implementation")}</h2>
          {/* <p>{t("wechatpay:implementationdetail1")}</p> */}

          <br />

          <p>{t("wechatpay:usageexample")}</p>
          {renderFormHtml(this.props.qrCash)}

          <br />

          <h2 id="creating-source" className="title-sub2">{t("wechatpay:creatingsource")}</h2>
          <p>{t("wechatpay:creatingsourcedetail1")}</p>
          <p>
            <code>publicKey</code> {' , '} <code>amount</code> {' , '} <code>referenceNo</code> {' , '} 
            <code>backgroundUrl</code> {' , '} <code>detail</code> {' , '} <code>customerName</code> {' , '}
            <code>customerEmail</code> {' , '}< code>customerAddress</code> {' , '} <code>customerTelephone</code> {' , '}
            <code>merchantDefined1</code> {' , '} <code>merchantDefined2</code> {' , '} <code>merchantDefined3</code> {' , '}
            <code>merchantDefined4</code> {' , '} <code>merchantDefined5</code> {' , '}
            {' and '} <code>checksum</code> {'.'}
          </p>

          <br />

          <p>
            <text className="font-bold">Wechat Pay API URL: </text>
            <text>{`{ENV}/v2/wechat or {ENV}/v2/wechat/text`} - Method POST</text>
          </p>

          <br />

          <p className="font-bold">Header</p>
          <Table columns={columns} dataSource={dataHeader} pagination={false} bordered />

          <br />

          <p className="font-bold">Parameter</p>
          <Table  columns={columnsParameter} dataSource={dataParameter} pagination={false} bordered />

          {/* <br />

          <h2 id="creating-charge" style={{ color: '#454545', fontWeight: 'bold', fontSize: 16 }}>{t("wechatpay:creatingcharge")}</h2>
          <p>{'-'}</p>
          <p>{'-'}</p> */}

          <br />

          <h2 id="response-data" className="title-sub2">{t("wechatpay:responseheader")}</h2>
          <Table columns={columns} dataSource={dataResponseToMerchant} pagination={false} bordered />

          <br />
          <br />

          <p className="font-bold">JSON Response</p>
          {renderFormCodeResponse()}

          <br />

          <WeDemo />

          <br />
          <br />

          <p className="font-bold">Example Code - Response to Merchant</p>
          <Tabs defaultActiveKey="1" onChange={this.callback}>
            <TabPane tab="PHP" key="1">
              {renderPhpResponse()}
            </TabPane>
            <TabPane tab="Java" key="2">
              {renderJavaResponse()}
            </TabPane>
          </Tabs>

          {/* <br />

          <h2 id="components-wechatpay-voidrefund" className="title-sub">{t("wechatpay:voidrefunds")}</h2>
          <p>{t("wechatpay:voidrefundsdetail1")} {'Refunds API documentation'} {t("wechatpay:voidrefundsdetail2")}</p>
          <p>{t("wechatpay:voidrefundsdetail3")}</p> */}

          <br />

          <h2 id="components-wechatpay-limit" className="title-sub">{t("wechatpay:limit")}</h2>
          {/* <p>{t("wechatpay:limitdetail1")}</p>
          <p>{i18n.language == 'en' ? t("wechatpay:limitdetail2") : ''}</p> */}

          <p>{t("minimumlimits")} 1.00 (THB)</p>
          <p>{t("maximumlimits")} 10,000,000.00 (THB)</p>
          
        </Content>

        <Sider breakpoint="md" collapsedWidth="0" style={{ background: '#fff' }} h>
          <br />
          <span style={{ color: '#02416D', fontWeight: 'bold', paddingTop: '20px' }}  >
            <Icon type="container" style={{ fontSize: '20px', color: '#08c' }} theme="outlined" />
          </span>
          <span style={{ color: '#02416D', fontWeight: 'bold', paddingTop: '20px' }}>{t("tablecontents")}</span>
          <br />
          <Anchor style={{ paddingTop: '10px' }} >
            <Anchor.Link href="#components-wechatpay-howtoenable" title={t("wechatpay:howtoenable")} />
            <Anchor.Link href="#components-wechatpay-paymentflow" title={t("wechatpay:paymentflow")} />
            <Anchor.Link href="#components-wechatpay-steptopayment" title={t("wechatpay:steptopayment")} />
            <Anchor.Link href="#components-wechatpay-implementation" title={t("wechatpay:implementation")} >
              <Anchor.Link href="#creating-source" title={t("wechatpay:creatingsource")} />
              {/* <Anchor.Link href="#creating-charge" title={t("wechatpay:creatingcharge")} /> */}
              <Anchor.Link href="#response-data" title={t("wechatpay:response")} />
            </Anchor.Link>
            {/* <Anchor.Link href="#components-wechatpay-voidrefund" title={t("wechatpay:voidrefunds")} /> */}
            <Anchor.Link href="#components-wechatpay-limit" title={t("wechatpay:limit")} />
          </Anchor>
        </Sider>

      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  qrCash: state.qrCash
})
const mapDispatchToProps = dispatch => ({
  updateByKey: (key, value) => dispatch(actions.updateByKey(key, value))
})
// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(QRAndBarcode)
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(QRAndBarcode));
